export const APP_NAME = '内装工事くん';

export const REQUIRED_MESSAGE = '必須項目です。';

export const SELECT_TIME = [
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30'
];

export const SERVER_ERROR_MESSAGE =
  'エラーが発生しました\r\n時間をおいてもう一度お試しください。';

export const VENDOR_API_PREFIX = '/vendors';

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const TEL_REGEX = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
