import React from 'react';
import Styles from './statuses.module.scss';
import { StatusHandleClientButtons } from '~/components/common/statuses/StatusHandleClientButtons';
import { AWAITING_ACCEPTANCE, SEND_ESTIMATE } from '~/models/Status';
import useItandiAccount from '~/hooks/useItandiAccount';

export const InConstruction: React.FC = () => {
  const itandiAccountApi = useItandiAccount();
  const { data } = itandiAccountApi.currentAccount();
  if (data === null) {
    return null;
  }
  return (
    <div>
      <h3 className={Styles.Title}>工事を依頼しました</h3>
      <p className={Styles.Description}>
        工事が完了して、報告書が提出されるまでお待ち下さい
      </p>
      <StatusHandleClientButtons
        forwardDisabled
        nextStatusId={AWAITING_ACCEPTANCE}
        prevStatusId={data.isAdmin ? SEND_ESTIMATE : null}
      />
    </div>
  );
};
