import React from 'react';
import clsx from 'clsx';
import { RiArrowRightSLine, RiLock2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Styles from './UserLoginModal.module.scss';
import { Modal } from '~/components/common/parts/modal/Modal';
import { UserLoginModalPassword } from '~/components/common/user_login_modal/UserLoginModalPassword';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

// VendorUserとStaffが利用する想定
export type LoginStaffModel = {
  id: number;
  name: string;
  email: string | null;
  hasPassword: boolean;
};

type Props = {
  loginStaffs: LoginStaffModel[];
  handleLogin: (
    id: number,
    password: string | null
  ) => Promise<NskResponse<void>>;
  mutateSession: () => Promise<void>;
};

export const UserLoginModal: React.FC<Props> = ({
  loginStaffs,
  handleLogin,
  mutateSession
}: Props) => {
  const [selectedUser, setSelectedUser] =
    React.useState<LoginStaffModel | null>(null);
  const renderRow = (loginUser: LoginStaffModel): React.ReactElement => {
    const onClickRow = async (e: React.MouseEvent): Promise<void> => {
      e.preventDefault();
      if (loginUser.hasPassword) {
        setSelectedUser(loginUser);
        return;
      }
      const result = await handleLogin(loginUser.id, null);
      if (isSuccess(result)) {
        await mutateSession();
        toast.success('ログインしました');
        return;
      }
      toast.error(`エラーが発生しました。${result.message}`);
    };
    return (
      <div key={loginUser.id}>
        <a className={Styles.UserLink} href="#" onClick={onClickRow}>
          <div>
            <div className={Styles.UserName}> {loginUser.name} </div>
            <div className={Styles.Email}> {loginUser.email}</div>
          </div>
          <div className={Styles.IconWrapper}>
            {loginUser.hasPassword && (
              <RiLock2Line className={Styles.LockIcon} />
            )}
            <RiArrowRightSLine className={Styles.ArrowIcon} />
          </div>
        </a>
      </div>
    );
  };
  const handleClosePassword = (): void => {
    setSelectedUser(null);
  };
  return (
    <Modal isOpen title="担当者ログイン">
      <div className={Styles.Container}>
        <div
          className={clsx(
            Styles.UserListPanel,
            selectedUser === null ? '' : Styles.Hide
          )}
        >
          {loginStaffs.map(renderRow)}
        </div>
        {selectedUser !== null && (
          <UserLoginModalPassword
            handleClose={handleClosePassword}
            handleLogin={handleLogin}
            loginUser={selectedUser}
            mutateSession={mutateSession}
          />
        )}
      </div>
    </Modal>
  );
};
