import { VendorCompany } from './VendorCompany';

export type VendorPartner = {
  id: number;
  seq: number;
  partnerVendorCompany: VendorCompany;
  allowMessage: boolean;
};

/**
 * partner側から見た、一次請けのvendor_partners
 */
export type RelatedVendorPartner = {
  id: number;
  seq: number;
  vendorCompany: VendorCompany;
  allowMessage: boolean;
};

export const convertToComboBoxOption = (
  vendorPartner: VendorPartner
): { label: string; value: string } => ({
  label: vendorPartner.partnerVendorCompany.name,
  value: String(vendorPartner.partnerVendorCompany.id)
});
