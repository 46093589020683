import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import Styles from './DealMainWorkflowVendorSub.module.scss';
import { useVendorPartners } from '~/hooks/vendors/useVendorPartners';
import { useSessions } from '~/hooks/vendors/useSessions';
import { getSubVendor, isOriginVendor } from '~/models/DealVendorCompany';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import {
  VendorSelectModal,
  VendorSelectOption
} from '~/components/common/vendors/VendorSelectModal';
import { VendorPartner } from '~/models/VendorPartner';
import { create as postDealVendorCompany } from '~/utils/api/vendors/deals/DealVendorCompany';
import { isSuccess } from '~/utils/api/api_handler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { isCanceled, isCompleted } from '~/models/Status';

export const DealMainWorkflowVendorSub: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const {
    isOpen: isOpenConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: vendorPartners } = useVendorPartners().index();
  const { data: deal, mutate: mutateDeal } = useDeals().show(id);
  const { data: session } = useSessions().index();
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  if (
    deal === null ||
    vendorPartners === null ||
    session === null ||
    !isOriginVendor(deal, session.vendorCompany.id) ||
    isCompleted(deal.status) ||
    isCanceled(deal.status)
  ) {
    return null;
  }
  const getConfirmMessage = (): ReactNode | null => {
    if (selectedId === null) {
      return null;
    }
    if (selectedId === session.vendorCompany.id) {
      return '自社による実施で決定してよろしいですか？';
    }
    const vendorName =
      vendorPartners.find(
        (p: VendorPartner) => p.partnerVendorCompany?.id === selectedId
      )?.partnerVendorCompany?.name ?? null;
    if (vendorName === null) {
      throw new Error();
    }
    return (
      <>
        <p>{vendorName}に依頼してよろしいですか？</p>
        ※依頼メールが送信されます
      </>
    );
  };
  const subVendor = getSubVendor(deal);
  const handleSelectVendor = (vendorCompanyId: number): void => {
    setSelectedId(vendorCompanyId);
    handleOpenConfirm();
  };
  const handleAcceptSelect = async (): Promise<void> => {
    if (selectedId === null) {
      return;
    }
    const result = await postDealVendorCompany(deal.id, selectedId);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      handleClose();
      handleCloseConfirm();
      toast.success('依頼しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  const vendorSelection: VendorSelectOption[] = vendorPartners.map(
    (v: VendorPartner) => {
      if (v.partnerVendorCompany === null) {
        throw new Error();
      }
      return {
        id: v.partnerVendorCompany.id,
        name: v.partnerVendorCompany.name,
        address: v.partnerVendorCompany.address,
        avatarUrl: '/assets/Avatar.svg',
        isSelf: false
      };
    }
  );
  const selfVendor: VendorSelectOption = {
    id: session.vendorCompany.id,
    name: session.vendorCompany.name,
    address: session.vendorCompany.address,
    avatarUrl: '/assets/Avatar.svg',
    isSelf: true
  };
  return (
    <>
      {subVendor === null ? (
        <div className={Styles.UnSelectedSubVendorCard}>
          <div className={Styles.SubVendorBlock}>
            <div>
              <p className={Styles.SubVendorName}>実施会社を選択してください</p>
              <p className={Styles.Description}>
                依頼せず直接実施する場合は自社を選択してください
              </p>
            </div>
            <button
              className={Styles.AssignSubVendorButton}
              onClick={handleOpen}
              type="button"
            >
              実施会社を選択する
            </button>
          </div>
        </div>
      ) : (
        <div className={Styles.SubVendorCard}>
          <div className={Styles.SubVendorBlock}>
            <img
              alt="avatar"
              className={Styles.AvatarIcon}
              src="/assets/Avatar.svg"
            />
            {subVendor.id === session.vendorCompany.id ? (
              <div className={Styles.SubVendorName}>
                {session.vendorCompany.name} で実施
              </div>
            ) : (
              <div className={Styles.SubVendorName}>
                {subVendor.name} に依頼中
              </div>
            )}
            <button
              className={Styles.AssignSubVendorButton}
              onClick={handleOpen}
              type="button"
            >
              実施会社を変更する
            </button>
          </div>
        </div>
      )}
      {isOpen && (
        <VendorSelectModal
          handleAccept={handleSelectVendor}
          handleClose={handleClose}
          selectedId={subVendor?.id ?? null}
          selfVendor={selfVendor}
          vendors={vendorSelection}
        />
      )}
      <ConfirmModal
        actionText="決定"
        confirmType="save"
        handleAccept={handleAcceptSelect}
        handleCancel={handleCloseConfirm}
        isOpen={isOpenConfirm}
      >
        {getConfirmMessage()}
      </ConfirmModal>
    </>
  );
};
