import React from 'react';
import { Accordion } from '@itandi/itandi-bb-ui';
import Styles from './AccordionHtml.module.scss';

type Props = DeepReadonly<{
  html: string;
  maxHeightForClosed: number;
}>;

export const AccordionHtml: React.FC<Props> = ({
  html,
  maxHeightForClosed
}: Props) => (
  <Accordion maxHeightForClosed={maxHeightForClosed}>
    <div
      className={Styles.HtmlContent}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </Accordion>
);
