import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/settings/company_vendors/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/settings/company_vendors';

const Home: NextPage = () => (
  <>
    <NskHead title="施工会社一覧" />
    <Component />
  </>
);

export default Home;
