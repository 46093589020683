import React from 'react';
import { useStatuses } from '~/hooks/useStatuses';
import { useSubtypes } from '~/hooks/useSubtypes';
import { NotificationList } from '~/components/common/notifications/settings/NotificationList';
import { Notification, NotificationEdit } from '~/models/Notification';
import { NskResponse } from '~/models/Api';
import { create, destroy, update } from '~/utils/api/CompanyNotification';
import { useCompanyNotifications } from '~/hooks/useCompanyNotifications';

export const NotificationSetting: React.FC = () => {
  const { data: notifications, mutate: mutateNotifications } =
    useCompanyNotifications().index();
  const { data: statuses } = useStatuses().index();
  const { data: subtypes } = useSubtypes().useIndex();
  const handleCreate = React.useCallback(
    async (notification: NotificationEdit): Promise<NskResponse<void>> =>
      create(notification),
    []
  );
  const handleUpdate = React.useCallback(
    async (
      notification: NotificationEdit,
      notificationId: number
    ): Promise<NskResponse<void>> => update(notification, notificationId),
    []
  );
  const handleDestroy = React.useCallback(
    async (notification: Notification): Promise<NskResponse<void>> =>
      destroy(notification),
    []
  );
  const handleMutate = React.useCallback(async () => {
    mutateNotifications && (await mutateNotifications());
  }, [mutateNotifications]);
  if (notifications === null || statuses === null) {
    return null;
  }
  return (
    <NotificationList
      handleCreate={handleCreate}
      handleDestroy={handleDestroy}
      handleMutate={handleMutate}
      handleUpdate={handleUpdate}
      notifications={notifications}
      statuses={statuses}
      subtypes={subtypes}
      type="CompanyNotification"
    />
  );
};
