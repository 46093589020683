import React from 'react';

type Debounce = (fn: () => void) => void;

/**
 * Debounceにより、delay値以内での変更でイベントを発生させないためのカスタムフック
 * InputのonChangeイベントでAPIコールしたい時等に利用してください
 *
 * @param delay
 */
export const useDebounce = (delay: number): Debounce => {
  const timer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  return React.useCallback(
    (fn: () => void): void => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout((): void => {
        fn();
      }, delay);
    },
    [delay]
  );
};
