import React from 'react';
import { useSearchParams } from 'next/navigation';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import SettingStyles from '../../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { PATH } from '~/pages/settings/imports';
import {
  convertImportType,
  importTypeLabels,
  isImportType
} from '~/models/ImportLog';
import Styles from './Page.module.scss';
import { ImportLogTable } from '~/components/specifics/settings/imports/[importType]/ImportLogTable';
import { useImportLogs } from '~/hooks/admin/useImportLogs';
import { ImportFileUploadPanel } from '~/components/specifics/settings/imports/[importType]/ImportFileUploadPanel';
import { ImportConversionPanel } from '~/components/specifics/settings/imports/[importType]/ImportConversionPanel';

export const Page: React.FC = () => {
  const { data: importLogByTypes, mutate: mutateLogs } =
    useImportLogs().useIndex();
  const searchParams = useSearchParams();
  const importType = searchParams.get('importType');
  const handleMutate = React.useCallback(async () => {
    mutateLogs && (await mutateLogs());
  }, [mutateLogs]);
  if (
    importType === null ||
    !isImportType(importType) ||
    importLogByTypes === null
  ) {
    return null;
  }
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <div className={SettingStyles.BreadcrumbsBlock}>
          <Breadcrumbs>
            <BreadcrumbsItem href={PATH}>過去データ取込</BreadcrumbsItem>
            <BreadcrumbsItem isText>
              {importTypeLabels[importType]}
            </BreadcrumbsItem>
          </Breadcrumbs>
        </div>
        <h2 className={SettingStyles.SettingTitle}>
          {importTypeLabels[importType]}のデータ取込
        </h2>
        <div className={Styles.TopArea}>
          <div className={Styles.UploadPanelWrapper}>
            <h3 className={Styles.SubTitle}>ファイルアップロード</h3>
            <ImportFileUploadPanel
              handleMutate={handleMutate}
              importType={importType}
            />
          </div>
          <ImportConversionPanel importType={importType} />
        </div>
        <div className={Styles.TablePanelWrapper}>
          <h3 className={Styles.SubTitle}>過去の取込履歴</h3>
          <ImportLogTable
            importLogs={importLogByTypes[convertImportType(importType)].logs}
          />
        </div>
      </ClientSettingLayout>
    </DefaultV2>
  );
};
