import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';

const paths = {
  update: '/property_deal_statuses/:id'
};

export const update = async (
  propertyDealId: number
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(propertyDealId)));
