import { object, string } from 'yup';

export type DealTodo = {
  id: number;
  completed: boolean;
  dueDate: string | null;
  completedAt: string | null;
  title: string;
  memo: string | null;
};

export type DealTodoEdit = Omit<DealTodo, 'id' | 'completedAt'>;
export const buildInitialDealTodoEdit = (): DealTodoEdit => ({
  completed: false,
  dueDate: null,
  title: '',
  memo: ''
});

export const validationSchema = object().shape({
  title: string().required('入力してください')
});
