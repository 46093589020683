import useSWR from 'swr';
import { Property } from '~/models/kensaku_kun/property';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/kensaku_kun/rooms'
} as const;

type TIndex = (buildingPropertyId: number) => SwrReturnType<Property<'room'>[]>;

type ReturnType = {
  index: TIndex;
};

export const useRooms = (): ReturnType => {
  const index: TIndex = (buildingPropertyId: number) => {
    const { data, mutate, error } = useSWR({
      url: paths.index,
      params: { buildingPropertyId }
    });
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
