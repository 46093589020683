import React from 'react';
import Styles from './TopArea.module.scss';
import { UploadPanel } from '~/components/common/upload/UploadPanel';

type Props = {
  handleDrop: (files: File[]) => Promise<void>;
};

export const TopArea: React.FC<Props> = ({ handleDrop }: Props) => (
  <div className={Styles.TopArea}>
    <div className={Styles.Uploading}>
      <UploadPanel accept={'image/*'} onDrop={handleDrop} />
    </div>
  </div>
);
