import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { RiAddFill, RiEdit2Line } from 'react-icons/ri';
import {
  Button,
  FormControl,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import Styles from './DealFileCard.module.scss';
import {
  DealUploadFile,
  DealUploadFileUpdateParam
} from '~/models/DealUploadFile';
import { isSuccess } from '~/utils/api/api_handler';
import { DeleteMenuButton } from '~/components/common/parts/button/DeleteMenuButton';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { NskResponse } from '~/models/Api';
import { Deal } from '~/models/Deal';
import { UploadButton } from '~/components/common/upload/UploadButton';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { formatDate } from '~/utils/DateUtil';
import { VendorSession } from '~/models/VendorSession';

type Props = {
  fileType: string;
  files: ReadonlyArray<DealUploadFile>;
  description?: string | null;
  handleGetFilePath: (file: DealUploadFile) => string;
  deal: Deal;
  handleUpload?: ((file: File) => Promise<NskResponse<void>>) | null;
  handleUpdate?:
    | ((
        dealOtherParam: DealUploadFileUpdateParam
      ) => Promise<NskResponse<void>>)
    | null;
  handleDestroy?: ((file: DealUploadFile) => Promise<NskResponse<void>>) | null;
  handleMutate?: (() => Promise<void>) | null;
  isVendor?: boolean;
  vendorSession?: VendorSession | null;
};

export const DealFileCard: React.FC<Props> = ({
  fileType,
  files,
  deal,
  handleGetFilePath,
  description = null,
  handleUpload = null,
  handleUpdate = null,
  handleDestroy = null,
  handleMutate = null,
  isVendor = false,
  vendorSession = null
}: Props) => {
  const [deleteFile, setDeleteFile] = React.useState<DealUploadFile | null>(
    null
  );
  const [editFile, setEditFile] = React.useState<DealUploadFile | null>(null);
  const [optionalFilename, setOptionalFilename] = React.useState<string | null>(
    null
  );
  const onClickUpload = async (file: File): Promise<void> => {
    if (handleUpload === null) {
      return;
    }
    const result = await handleUpload(file);
    if (isSuccess(result)) {
      handleMutate && (await handleMutate());
      toast.success('登録しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  const acceptDestroy = async (): Promise<void> => {
    if (handleDestroy === null || deleteFile === null) {
      return;
    }
    const result = await handleDestroy(deleteFile);
    if (isSuccess(result)) {
      handleMutate && (await handleMutate());
      setDeleteFile(null);
      toast.success('削除しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  const acceptUpdate = async (): Promise<void> => {
    if (handleUpdate === null || editFile === null) {
      return;
    }
    const result = await handleUpdate({
      id: editFile.id,
      optionalFilename
    });
    if (isSuccess(result)) {
      handleMutate && (await handleMutate());
      setEditFile(null);
      toast.success('変更しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  const clearEditFile = (): void => {
    setEditFile(null);
  };
  const onChangeOptionalFilename = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setOptionalFilename(e.target.value.length === 0 ? null : e.target.value);
    },
    [setOptionalFilename]
  );
  const renderRow = (file: DealUploadFile, index: number): ReactNode => {
    const extension = file.fileName.split('.').pop();
    const fileAddName = ((): string | null => {
      if (file.staff !== null) {
        return `${file.staff.name}`;
      }
      if (file.vendorUser !== null) {
        return `${file.vendorUser.name}`;
      }
      return null;
    })();
    const displayFilename = ((): string => {
      if (file.optionalFilename !== null) {
        return `${index + 1}_${file.optionalFilename}`;
      }
      if (file.originalFilename !== null) {
        return `${index + 1}_${file.originalFilename}`;
      }
      return `${index + 1}_${deal.name}_${
        deal.roomNumber
      }_${fileType}.${extension}`;
    })();
    const arrowEdit = ((): boolean => {
      if (isVendor === false) {
        return true;
      }
      if (
        vendorSession !== null &&
        vendorSession.vendorUser?.id === file.vendorUser?.id
      ) {
        return true;
      }
      return false;
    })();
    return (
      <div key={file.id} className={Styles.FileRow}>
        <div className={Styles.FileContent}>
          <a
            className={Styles.FileLink}
            href={handleGetFilePath(file)}
            rel="noreferrer"
            target="_blank"
          >
            {displayFilename}
          </a>
          {handleUpdate !== null && arrowEdit && (
            <div className={Styles.FileButtons}>
              <EditMenuButton onClick={(): void => setEditFile(file)}>
                ファイル名を変更する
              </EditMenuButton>
            </div>
          )}
          {handleDestroy !== null && (
            <div className={Styles.FileButtons}>
              <DeleteMenuButton onClick={(): void => setDeleteFile(file)}>
                削除
              </DeleteMenuButton>
            </div>
          )}
        </div>
        <div className={Styles.Annotation}>
          {formatDate(file.createdAt, true)}に
          {fileAddName && `${fileAddName}が`}登録
        </div>
      </div>
    );
  };
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        {fileType}
        {handleUpload !== null && (
          <div className={Styles.AddButtonWrapper}>
            <UploadButton onClick={onClickUpload} size="sm">
              <RiAddFill className={Styles.Icon} />
              追加
            </UploadButton>
          </div>
        )}
      </h3>
      {description !== null && (
        <div className={Styles.Description}>{description}</div>
      )}
      <div className={Styles.FileList}>
        {files.map(renderRow)}
        {files.length === 0 && (
          <div className={Styles.NoFile}>ファイル登録なし</div>
        )}
      </div>
      <ConfirmModal
        confirmType="delete"
        handleAccept={(): Promise<void> => acceptDestroy()}
        handleCancel={(): void => setDeleteFile(null)}
        isOpen={deleteFile !== null}
      >
        ファイルを削除してよろしいですか？
      </ConfirmModal>
      <Modalv2 handleClose={clearEditFile} isOpen={editFile !== null}>
        <ModalHeader icon={RiEdit2Line} title="表示用ファイル名の変更" />
        <ModalBody>
          <FormControl chip="任意" label="表示用ファイル名" variant="Vertical">
            <Input
              defaultValue={
                editFile?.optionalFilename ? editFile.optionalFilename : ''
              }
              onChange={onChangeOptionalFilename}
              placeholder={
                editFile?.originalFilename ? editFile.originalFilename : ''
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter
          leftArea={
            <Button onClick={clearEditFile} variant="secondary">
              変更せず閉じる
            </Button>
          }
          rightArea={
            <Button onClick={acceptUpdate} variant="primary">
              変更
            </Button>
          }
        />
      </Modalv2>
    </div>
  );
};
