import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { Staff } from '~/models/Staff';

const paths = {
  index: '/staffs',
  show: '/staffs/:id'
};

type TIndex = () => SwrReturnType<Staff[]>;
type TShow = (id: number | null) => SwrReturnType<Staff>;
type ReturnType = {
  index: TIndex;
  show: TShow;
};

export const useStaffs = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<Staff[]>(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  const show: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<Staff>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index, show };
};
