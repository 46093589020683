import React, { ReactNode } from 'react';
import { VendorWithoutAuthHeader } from '../header/VendorWithoutAuthHeader';

type Props = {
  children: ReactNode;
};

export const VendorWithoutAuthLayout: React.FC<Props> = ({
  children
}: Props) => (
  <>
    <VendorWithoutAuthHeader />
    <main>{children}</main>
  </>
);
