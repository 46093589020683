import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { BillingType, DealBillingContext } from '~/models/DealBilling';

const paths = {
  index: '/deals/:deal_id/deal_billings'
};
type TIndex = (
  id: number | null,
  billingType: BillingType
) => SwrReturnType<DealBillingContext>;
type ReturnType = {
  index: TIndex;
};

export const useDealBillings = (): ReturnType => {
  const index: TIndex = (dealId: number | null, billingType: BillingType) => {
    const url: string = paths.index.replace(':deal_id', String(dealId));
    const { data, mutate, error } = useSWR<DealBillingContext>(
      dealId === null ? null : { url, params: { billingType } }
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
