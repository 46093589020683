import React from 'react';
import ReactModal from 'react-modal';
import cx from 'clsx';

import { RiCloseLine } from 'react-icons/ri';
import Styles from './Modal.module.scss';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  handleClose?: () => void;
  title: string;
  footers?: React.ReactNode;
  size?: 'lg' | 'md';
  shouldCloseOnOverlayClick?: boolean;
  bodyClassName?: string;
};

ReactModal.setAppElement('body');

const customStyles = {
  overlay: {
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    inset: 0,
    width: '100%',
    padding: '0',
    background: 'none',
    border: 'none',
    overflow: 'hidden'
  }
};

export const Modal: React.FC<Props> = ({
  isOpen,
  children,
  handleClose,
  title,
  footers,
  size = 'md',
  shouldCloseOnOverlayClick = false,
  bodyClassName = ''
}: Props) => {
  const getSizeClass = (): string => {
    switch (size) {
      case 'lg':
        return Styles.LargeSize;
      case 'md':
        return Styles.MediumSize;
      default:
        return Styles.MediumSize;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={customStyles}
    >
      <div className={cx(Styles.ModalDialog, getSizeClass())}>
        <div className={Styles.ModalContent}>
          <header className={Styles.ModalHeader}>
            <div>{title}</div>
            {handleClose !== null && (
              <div className={Styles.ModalClose}>
                <button
                  className={Styles.ModalCloseButton}
                  onClick={handleClose}
                  type="button"
                >
                  <RiCloseLine className={Styles.CloseIcon} />
                </button>
              </div>
            )}
          </header>
          <div className={cx(Styles.ModalBody, bodyClassName)}>{children}</div>
          {footers ? <div className={Styles.ModalFooter}>{footers}</div> : null}
        </div>
      </div>
    </ReactModal>
  );
};
