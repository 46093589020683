import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/staffs/profiles/edit/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (staffId: number): string =>
  `/settings/staffs/${staffId}/profiles`;
const Home: NextPage = () => (
  <>
    <NskHead title="担当者編集" />
    <Component />
  </>
);

export default Home;
