import React, { ReactNode } from 'react';
import Link from 'next/link';
import Styles from './Breadcrumbs.module.scss';

export type BreadcrumbItem = {
  path: string | null;
  caption: string;
  active: boolean;
};
type Props = DeepReadonly<{
  breadcrumbItems: BreadcrumbItem[];
}>;

const Breadcrumbs: React.FC<Props> = ({ breadcrumbItems }: Props) => {
  if (breadcrumbItems.length === 0) {
    return <></>;
  }

  const renderItem = (item: BreadcrumbItem, idx: number): ReactNode => (
    <li key={`breadcrumb-item-${idx}`} className={Styles.BreadcrumbItem}>
      {item.active ? (
        <span>{item.caption}</span>
      ) : (
        item.path && <Link href={item.path}>{item.caption}</Link>
      )}
    </li>
  );
  return (
    <ol className={Styles.Breadcrumb}>
      {breadcrumbItems.map((item: BreadcrumbItem, idx: number) =>
        renderItem(item, idx)
      )}
    </ol>
  );
};

export default Breadcrumbs;
