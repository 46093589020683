import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import Styles from './CompanySettingEditModal.module.scss';
import { ModalFooter } from '~/components/common/parts/modal/ModalFooter';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { Modal } from '~/components/common/parts/modal/Modal';
import { CompanySetting } from '~/models/CompanySetting';
import { update } from '~/utils/api/admin/CompanySetting';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
  companySetting: CompanySetting;
  handleMutate: () => Promise<void>;
}>;

export const CompanySettingEditModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  companySetting,
  handleMutate
}: Props) => {
  const [antisocialCheckId, setAntisocialCheckId] = React.useState<
    string | null
  >(companySetting.antisocialCheckId);
  const handleSubmit = React.useCallback(async (): Promise<void> => {
    const response = await update({ ...companySetting, antisocialCheckId });
    if (isSuccess(response)) {
      await handleMutate();
      handleClose();
      toast.success('設定しました');
      return;
    }
    toast.error('エラーが発生しました');
  }, [companySetting, antisocialCheckId, handleMutate, handleClose]);
  const onChangeId = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      setAntisocialCheckId(value === '' ? null : value);
    },
    [setAntisocialCheckId]
  );
  return (
    <Modal handleClose={handleClose} isOpen={isOpen} title="反社チェックID入力">
      <div className={Styles.InputForm}>
        <FormLabel>反社チェックID</FormLabel>
        <Input onChange={onChangeId} value={antisocialCheckId ?? ''} />
      </div>
      <ModalFooter onClickAccept={handleSubmit} onClickCancel={handleClose} />
    </Modal>
  );
};
