import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { Message } from '~/models/Message';

const paths = {
  index: '/deals/:deal_id/messages'
};
type TIndex = (id: number | null) => SwrReturnType<Message[]>;
type ReturnType = {
  index: TIndex;
};

export const useMessages = (): ReturnType => {
  const index: TIndex = (dealId: number | null) => {
    const { data, mutate, error } = useSWR<Message[]>(
      dealId === null ? null : paths.index.replace(':deal_id', String(dealId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
