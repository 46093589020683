import { VENDOR_API_PREFIX } from '~/constants/AppConst';

export const isVendor = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.location.pathname.startsWith('/vendors');
};

export const getApiUrl = (url: string): string =>
  `${isVendor() ? VENDOR_API_PREFIX : ''}${url}`;
