import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/imports/[importType]/[id]/import_log_errors/Page';
import { NskHead } from '~/components/common/head/NskHead';
import { ImportType } from '~/models/ImportLog';

export const PATH = (importType: ImportType, importLogId: number): string =>
  `/settings/imports/${importType}/${importLogId}/import_log_errors`;

const Home: NextPage = () => (
  <>
    <NskHead title="過去データ取込エラーログ" />
    <Component />
  </>
);

export default Home;
