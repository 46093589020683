import React from 'react';
import Loader from '~/components/common/parts/loading/Loading';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import Styles from './Page.module.scss';
import { DealCard } from '~/components/common/deals/show/deal_card/DealCard';
import { DealInspectionClientCard } from '~/components/common/deals/show/inspection_card/DealInspectionClientCard';
import { DealConstructionScheduleClientCard } from '~/components/common/deals/show/construction_card/DealConstructionScheduleClientCard';
import { DealMain } from '~/components/common/deals/show/main/DealMain';
import { useDeals } from '~/hooks/useDeals';
import { DealOriginVendorClientCard } from '~/components/common/deals/show/vendor_card/DealOriginVendorClientCard';
import { useMessages } from '~/hooks/deals/useMessages';
import { ChatPopContainer } from '~/components/common/chat/ChatPopContainer';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { DealRepairCard } from '~/components/common/deals/show/repair_card/DealRepairCard';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { isOpen, handleClose, handleOpen } = useOpenHandler();
  const { data: deal, mutate: mutateDeal, isLoading } = useDeals().show(id);
  const { data: messages, mutate: mutateMessages } = useMessages().index(id);
  const handleToggleChat = React.useCallback(() => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [isOpen, handleClose, handleOpen]);
  const handleMutate = React.useCallback(async () => {
    mutateDeal && (await mutateDeal());
  }, [mutateDeal]);
  const handleMutateMessage = React.useCallback(async () => {
    mutateMessages && (await mutateMessages());
  }, [mutateMessages]);
  if (isLoading || deal === null) {
    return <Loader isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: null, caption: '案件詳細', active: true }
  ];
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
        noBackground
      >
        <div className={Styles.Container}>
          <div className={Styles.LeftCol}>
            <DealOriginVendorClientCard handleToggleChat={handleToggleChat} />
            <DealCard
              deal={deal}
              handleMutate={handleMutate}
              isVendor={false}
            />
            {deal.dealType === 'repair' && (
              <DealRepairCard
                companyType="client"
                deal={deal}
                handleMutate={handleMutate}
              />
            )}
            <DealInspectionClientCard />
            <DealConstructionScheduleClientCard />
          </div>
          <DealMain deal={deal} isVendor={false} />
        </div>
      </DealLayout>
      {messages !== null && (
        <ChatPopContainer
          deal={deal}
          handleClose={handleClose}
          handleMutate={handleMutateMessage}
          isOpen={isOpen}
          messages={messages}
          speaker="maintenance_company"
        />
      )}
    </DefaultV2>
  );
};
