import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Styles from './CompanyStoreProxyLoginModal.module.scss';
import { Modal } from '~/components/common/parts/modal/Modal';
import { CompanySettingWithStore } from '~/models/CompanySetting';
import { CompanyStoreSetting } from '~/models/CompanyStoreSetting';
import { useCompanySettings } from '~/hooks/useCompanySettings';
import useItandiAccount from '~/hooks/useItandiAccount';
import { PATH as DEALS_PATH } from '~/pages/deals';

type Props = DeepReadonly<{
  companySetting: CompanySettingWithStore;
  handleClose: () => void;
}>;

export const CompanyStoreProxyLoginModal: React.FC<Props> = ({
  companySetting,
  handleClose
}: Props) => {
  const router = useRouter();
  const { mutate } = useItandiAccount().currentAccount();
  const companySettingsApi = useCompanySettings();
  const onClickStore = React.useCallback(
    async (
      e: React.MouseEvent,
      companyStoreSetting: CompanyStoreSetting
    ): Promise<void> => {
      e.preventDefault();
      await companySettingsApi.proxyLogin(
        companyStoreSetting.companyMasterId,
        companyStoreSetting.authId
      );
      mutate != null && (await mutate());
      router.push(DEALS_PATH).then();
    },
    [companySettingsApi, mutate, router]
  );
  const renderCompanyStore = React.useCallback(
    (companyStoreSetting: CompanyStoreSetting): ReactNode => (
      <div key={companyStoreSetting.authId} className={Styles.StoreRow}>
        <a
          className={Styles.StoreLink}
          href="#"
          onClick={(e: React.MouseEvent): void => {
            onClickStore(e, companyStoreSetting).then();
          }}
        >
          {companyStoreSetting.name}
          {companyStoreSetting.masterAccount && (
            <span className={Styles.MasterLabel}>特権アカウント</span>
          )}
        </a>
      </div>
    ),
    [onClickStore]
  );
  return (
    <Modal handleClose={handleClose} isOpen title="店舗に代理ログイン">
      <p className={Styles.Description}>
        代理ログインする店舗を選択してください
      </p>
      <div className={Styles.StoreList}>
        {companySetting.companyStoreSettings.map(renderCompanyStore)}
      </div>
    </Modal>
  );
};
