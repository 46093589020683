import React from 'react';
import clsx from 'clsx';
import { RiExternalLinkFill } from 'react-icons/ri';
import ReactLinkify from 'react-linkify';
import Styles from './ChatMessageRow.module.scss';
import {
  getSpeakerName,
  isShowReadMark,
  Message,
  MessageSpeaker
} from '~/models/Message';
import { formatDate } from '~/utils/DateUtil';
import { getShowImagePath as getShowImagePathForCompany } from '~/utils/api/deals/Message';
import { getShowImagePath as getShowImagePathForVendor } from '~/utils/api/vendors/deals/Message';
import { Deal } from '~/models/Deal';

type Props = DeepReadonly<{
  deal: Deal;
  message: Message;
  speaker: MessageSpeaker;
}>;

export const ChatMessageRow: React.FC<Props> = ({
  deal,
  message,
  speaker
}: Props) => {
  const speakerStyle = React.useMemo(
    () => (message.speaker === speaker ? Styles.Self : Styles.Partner),
    [speaker, message]
  );
  const titleName = React.useMemo(() => getSpeakerName(message), [message]);
  const showReadMark = React.useMemo(
    () => isShowReadMark(message, speaker),
    [message, speaker]
  );
  const showImagePath = React.useMemo(() => {
    const getMethod =
      speaker === 'maintenance_company'
        ? getShowImagePathForCompany
        : getShowImagePathForVendor;
    return getMethod(deal.id, message.id);
  }, [deal, message, speaker]);
  const componentDecorator = React.useCallback(
    (
      decoratedHref: string,
      decoratedText: string,
      key: number
    ): React.ReactNode => (
      <a
        key={key}
        className={Styles.Link}
        href={decoratedHref}
        rel="noreferrer noopener"
        target="blank"
      >
        {decoratedText}
      </a>
    ),
    []
  );
  return (
    <div className={clsx(Styles.MessageRow, speakerStyle)}>
      <div className={Styles.MessageArea}>
        {titleName !== null && (
          <div className={Styles.MessageTitle}>{titleName}</div>
        )}
        <div className={clsx(Styles.Message, speakerStyle)}>
          {message.messageType === 'text' && (
            <span>
              <ReactLinkify componentDecorator={componentDecorator}>
                {message.text}
              </ReactLinkify>
            </span>
          )}
          {message.messageType === 'file' && (
            <span>
              <a
                className={Styles.FileLink}
                href={showImagePath}
                rel="noreferrer"
                target="_blank"
              >
                {message.text}
              </a>
              <RiExternalLinkFill className={Styles.LinkIcon} />
            </span>
          )}
        </div>
        <div className={Styles.MessageFooter}>
          {showReadMark && <span>既読</span>}
          <span className={Styles.Datetime}>
            {formatDate(message.createdAt, true)}
          </span>
        </div>
      </div>
    </div>
  );
};
