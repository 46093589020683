import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/vendors/login/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/vendors/login';

const Login: NextPage = () => (
  <>
    <NskHead title="TOP" />
    <Component />
  </>
);

export default Login;
