import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { CompanyStoreSetting } from '~/models/CompanyStoreSetting';

const paths = {
  index: '/company_store_settings'
};

type TIndex = () => SwrReturnType<CompanyStoreSetting[]>;
type ReturnType = {
  index: TIndex;
};

export const useCompanyStoreSettings = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<CompanyStoreSetting[]>(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
