import { NskResponse } from '~/models/Api';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/property_deals/:property_deal_id/lease_contract_files/:file_number',
  destroy: '/property_deals/:property_deal_id/lease_contract_files/:file_number'
};

export const create = async (
  file: File,
  propertyDealId: number,
  fileNumber: number
): Promise<NskResponse<void>> => {
  const params = new FormData();
  params.append('file', file);
  return postRequest(
    paths.create
      .replace(':property_deal_id', String(propertyDealId))
      .replace(':file_number', String(fileNumber)),
    params
  );
};

export const destroy = async (
  propertyDealId: number,
  fileNumber: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':property_deal_id', String(propertyDealId))
      .replace(':file_number', String(fileNumber))
  );
