import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Page.module.scss';
import { CompanyForm } from '~/components/specifics/markets/company_settings/new/forms/CompanyForm';
import { StaffForm } from '~/components/specifics/markets/company_settings/new/forms/StaffForm';
import { TermForm } from '~/components/specifics/markets/company_settings/new/forms/TermForm';
import { MarketCompanySetting } from '~/models/CompanySetting';
import { PlanForm } from '~/components/specifics/markets/company_settings/new/forms/PlanForm';
import { CompanyStoreForm } from './forms/CompanyStoreForm';

type Props = DeepReadonly<{
  handleNextPage: () => void;
}>;

export const FormInputPage: React.FC<Props> = ({ handleNextPage }: Props) => {
  const { watch, handleSubmit, formState } =
    useFormContext<MarketCompanySetting>();
  const onClickSubmit = React.useCallback(() => {
    handleSubmit(
      () => {
        handleNextPage();
      },
      () => {
        const errorValues = Object.values(formState.errors);
        if (errorValues.length > 0) {
          const inputRef: HTMLInputElement | null =
            errorValues[0].ref instanceof HTMLInputElement
              ? errorValues[0].ref
              : null;
          if (inputRef !== null) {
            inputRef.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    )();
  }, [handleSubmit, handleNextPage, formState.errors]);
  const marketPlan = watch('marketPlan');
  return (
    <div className={Styles.InnerPageContainer}>
      <PlanForm />
      <CompanyForm />
      <CompanyStoreForm />
      <StaffForm />
      {marketPlan === 'single' && <TermForm />}
      <div className={Styles.OneButtonArea}>
        <Button
          disabled={!watch('checkTerm')}
          onClick={onClickSubmit}
          variant="primary"
          width="fill"
        >
          入力内容の確認
        </Button>
      </div>
    </div>
  );
};
