import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/subtypes/restoration/todo_templates/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = `/settings/subtypes/restoration/todo_templates`;

const Home: NextPage = () => (
  <>
    <NskHead title="TODOテンプレート" />
    <Component />
  </>
);

export default Home;
