import { array, object, string } from 'yup';

export type CompanyStoreMail = {
  id: number;
  email: string;
};

export type MailsCreateParams = {
  companyStoreMails: MailCreateParam[];
};

export type MailCreateParam = {
  key: string;
  email: string;
};

export const validationSchemaCompanyStoreMail = object().shape({
  companyStoreMails: array().of(
    object().shape({
      email: string()
        .email('メールアドレスの形式を確認してください')
        .required('メールアドレスは必須です')
        .nullable()
    })
  )
});
