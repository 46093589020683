import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import {
  ImportLogWithCount,
  ImportType,
  importTypeLabels
} from '~/models/ImportLog';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { formatDate } from '~/utils/DateUtil';
import { PATH } from '~/pages/settings/imports/[importType]';

type Props = {
  importType: ImportType;
  importLogWithCount: ImportLogWithCount;
};

export const ImportTypeRow: React.FC<Props> = ({
  importType,
  importLogWithCount
}: Props) => {
  const router = useRouter();
  const handleClick = React.useCallback(() => {
    router.push(PATH(importType)).then();
  }, [router, importType]);
  return (
    <TableRow key={importType}>
      <td>{importTypeLabels[importType]}</td>
      <td>
        <Button height="small" onClick={handleClick}>
          取込画面
        </Button>
      </td>
      <td>{importLogWithCount.count}</td>
      <td>
        {importLogWithCount.logs.length === 0 ? (
          <span>-</span>
        ) : (
          <span>{formatDate(importLogWithCount.logs[0].createdAt, true)}</span>
        )}
      </td>
    </TableRow>
  );
};
