export const summaryFileTemplateTypes = [
  'property_deal_billing_owner',
  'property_deal_billing_rentee'
] as const;
export type SummaryFileTemplateType = (typeof summaryFileTemplateTypes)[number];
export type SummaryFileTemplate = {
  id: number;
  templateType: SummaryFileTemplateType;
  fileName: string;
};

export const summaryFileTemplateLabels = {
  property_deal_billing_owner: 'オーナー宛の請求書',
  property_deal_billing_rentee: '入居者宛の請求書'
} as const satisfies {
  [key in SummaryFileTemplateType]: string;
};
