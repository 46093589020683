import { ItandiAccount } from './ItandiAccount';

export type StaffAuthorization = {
  id: number;
  enableSetting: boolean;
  enableStatus: boolean;
  enableCompleteStatus: boolean;
  enableCancel: boolean;
};

export type StaffAuthParam = Omit<StaffAuthorization, 'id'>;

export const buildDefaultStaffAuth = (): StaffAuthParam => ({
  enableStatus: true,
  enableCancel: true,
  enableSetting: true,
  enableCompleteStatus: true
});

type AuthorizationType =
  | 'enableStatus'
  | 'enableCancel'
  | 'enableSetting'
  | 'enableCompleteStatus';

export const AUTH_NAME = {
  setting: 'staffAuthorization.enableSetting',
  status: 'staffAuthorization.enableStatus',
  completeStatus: 'staffAuthorization.enableCompleteStatus',
  cancel: 'staffAuthorization.enableCancel'
} as const satisfies {
  [key in 'setting' | 'status' | 'completeStatus' | 'cancel']: string;
};

export const hasAuthorization = (
  account: ItandiAccount,
  authType: AuthorizationType
): boolean =>
  (account.isAdmin || account.staff?.staffAuthorization[authType]) ?? false;
