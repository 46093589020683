import React, { ReactNode } from 'react';
import Styles from './ScheduleInputForm.module.scss';
import {
  CONSTRUCTION_NUMBERS,
  CONSTRUCTION_SCHEDULE_LABELS,
  ConstructionScheduleType,
  DealConstructionScheduleEdit,
  isInvalidSchedule
} from '~/models/DealConstructionSchedule';
import { formatDate, toDate } from '~/utils/DateUtil';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';

type Props = {
  schedules: DealConstructionScheduleEdit[];
  handleChange: (newSchedules: DealConstructionScheduleEdit[]) => void;
  touchSubmit: boolean;
};

export const ScheduleInputForm: React.FC<Props> = ({
  schedules,
  handleChange,
  touchSubmit
}: Props) => {
  const renderFormGroup = (typeNumber: ConstructionScheduleType): ReactNode => {
    const schedule =
      schedules.find(
        (s: DealConstructionScheduleEdit) =>
          s.constructionScheduleType === typeNumber
      ) ?? null;
    const onChange = (dates: [Date, Date]): void => {
      const [startDate, endDate] = dates;
      const newSchedules = [...schedules];
      const index = newSchedules.findIndex(
        (s) => s.constructionScheduleType === typeNumber
      );
      const newSchedule = {
        ...schedule,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        constructionScheduleType: typeNumber
      };
      newSchedules.splice(index, 1, newSchedule);
      handleChange(newSchedules);
    };
    if (schedule === null) {
      return null;
    }
    return (
      <FormGroup key={typeNumber}>
        <FormLabel>{CONSTRUCTION_SCHEDULE_LABELS[typeNumber]}</FormLabel>
        <Datepicker
          endDate={toDate(schedule.endDate)}
          isClearable
          onChange={onChange}
          selectsRange
          startDate={toDate(schedule.startDate)}
        />
        {touchSubmit && isInvalidSchedule(schedule) && (
          <div className={Styles.InvalidMessage}>
            開始日と終了日を入力してください
          </div>
        )}
      </FormGroup>
    );
  };
  return <div>{CONSTRUCTION_NUMBERS.map(renderFormGroup)}</div>;
};
