import React from 'react';
import cx from 'clsx';
import Styles from './ConstructionList.module.scss';
import { ConstructionListRows } from '~/components/common/constructions/list/ConstructionListRows';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';
import {
  DealConstruction,
  DealConstructionContext,
  DealConstructionEdit
} from '~/models/DealConstruction';

type Props = {
  readonly: boolean;
  constructionContext: DealConstructionContext;
  constructionItems: ConstructionItem[];
  handleMutateConstructions: () => Promise<void>;
  companyType: 'client' | 'vendor' | 'partner';
  docType: 'billing' | 'estimation';
  handleUpdate?:
    | ((
        submitValue: DealConstructionEdit,
        estimationId: number
      ) => Promise<NskResponse<void>>)
    | null;
  handleDestroy?:
    | ((deleteConstruction: DealConstruction) => Promise<NskResponse<void>>)
    | null;
  handleUpdateSeq?:
    | ((constructions: DealConstruction[]) => Promise<void>)
    | null;
  handleUpdateEnabled?:
    | ((construction: DealConstruction) => Promise<void>)
    | null;
  target?: 'owner' | 'rentee' | null;
};
export const ConstructionList: React.FC<Props> = ({
  readonly,
  constructionItems,
  constructionContext,
  handleMutateConstructions,
  companyType,
  docType,
  handleUpdate = null,
  handleDestroy = null,
  handleUpdateSeq = null,
  handleUpdateEnabled = null,
  target = null
}: Props) => (
  <div>
    <table className={Styles.List}>
      <thead>
        <tr className={cx(Styles.ListRow, Styles.Header)}>
          <th className={Styles.OrderHandle}>
            {handleUpdateSeq !== null && <span>並替</span>}
          </th>
          {handleUpdateEnabled !== null && (
            <th className={Styles.SwitchEnable}>工事対象</th>
          )}
          <th className={Styles.Estimation}>施工項目</th>
          <th className={Styles.UnitAmount}>数量 * 単価</th>
          <th className={Styles.ChargePer}>負担割合</th>
          {target !== null && (
            <th className={Styles.ForAmount}>
              {target === 'rentee' && '入居者請求額'}
              {target === 'owner' && 'オーナー請求額'}
            </th>
          )}
          <th className={Styles.Amount}>価格</th>
          {!readonly && (
            <th className={Styles.Edit}>
              {handleUpdate !== null ||
                (handleDestroy !== null && <span>編集</span>)}
            </th>
          )}
        </tr>
      </thead>
      <ConstructionListRows
        companyType={companyType}
        constructionContext={constructionContext}
        constructionItems={constructionItems}
        docType={docType}
        handleDestroy={handleDestroy}
        handleMutateConstructions={handleMutateConstructions}
        handleUpdate={handleUpdate}
        handleUpdateEnabled={handleUpdateEnabled}
        handleUpdateSeq={handleUpdateSeq}
        readonly={readonly}
        target={target}
      />
    </table>
  </div>
);
