import React from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import SettingStyles from '../../../SettingStyle.module.scss';
import { PATH as STAFFS_PATH } from '~/pages/settings/staffs';
import { FormInputPage } from '../FormInputPage';
import { useStaffs } from '~/hooks/useStaffs';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const id = useIdQueryHandler('staffId');
  const { data: staff, mutate: mutateStaff } = useStaffs().show(id);
  const handleMutate = React.useCallback(async (): Promise<void> => {
    mutateStaff && mutateStaff();
  }, [mutateStaff]);
  if (staff === null) return null;
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <div className={SettingStyles.BreadcrumbsBlock}>
          <Breadcrumbs>
            <BreadcrumbsItem href={STAFFS_PATH}>担当者一覧</BreadcrumbsItem>
            <BreadcrumbsItem isText>担当者編集</BreadcrumbsItem>
          </Breadcrumbs>
        </div>
        <h2 className={SettingStyles.SettingTitle}>担当者編集</h2>
        <FormInputPage handleMutate={handleMutate} staff={staff} />
      </ClientSettingLayout>
    </DefaultV2>
  );
};
