import React from 'react';
import { RiAddBoxLine } from 'react-icons/ri';
import Styles from './ConstructionTop.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealConstructionContext } from '~/models/DealConstruction';

type Props = {
  constructionContext: DealConstructionContext;
  handleClickShowAddModal?: (() => void) | null;
  handleClickPreview: () => void;
  handleClickChangeAmount?: (() => void) | null;
  readonly: boolean;
};

export const ConstructionTop: React.FC<Props> = ({
  constructionContext,
  handleClickShowAddModal = null,
  handleClickChangeAmount = null,
  handleClickPreview,
  readonly
}: Props) => (
  <div className={Styles.EstimateTop}>
    <div className={Styles.Amounts}>
      <span className={Styles.TotalAmount}>
        総額：¥{toThousandSeparator(constructionContext.totalAmountWithTax)}
      </span>
      <span className={Styles.TotalAmountWithoutTax}>
        税抜：¥{toThousandSeparator(constructionContext.totalAmount)}
      </span>
    </div>
    <div className={Styles.ButtonArea}>
      {handleClickChangeAmount !== null && (
        <button
          className={Styles.ChangeAmountButton}
          onClick={handleClickChangeAmount}
          type="button"
        >
          単価を一括で変更する
        </button>
      )}
      <button
        className={Styles.PreviewButton}
        onClick={handleClickPreview}
        type="button"
      >
        .xlsxダウンロード
      </button>
      {handleClickShowAddModal !== null && !readonly && (
        <button
          className={Styles.AddButton}
          onClick={handleClickShowAddModal}
          type="button"
        >
          <RiAddBoxLine className={Styles.Icon} />
          項目追加
        </button>
      )}
    </div>
  </div>
);
