import React, { ReactNode } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Alert,
  InputRadio,
  InputCheckBox,
  Tooltip,
  Modalv2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from '@itandi/itandi-bb-ui';
import { RiAddBoxLine } from 'react-icons/ri';
import {
  CompanyVendor,
  CompanyVendorEdit,
  validationSchemaCompanyVendor
} from '~/models/CompanyVendor';
import Styles from './CompanyVendorList.module.scss';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { create, update } from '~/utils/api/CompanyVendor';
import { isSuccess } from '~/utils/api/api_handler';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import { VENDOR_COMPANY_ITANDI_ID } from '~/models/VendorCompany';

type Props = {
  companyVendor?: CompanyVendor | null;
  isOpen: boolean;
  handleClose: () => void;
};

export const CompanyVendorModal: React.FC<Props> = ({
  companyVendor = null,
  isOpen,
  handleClose
}: Props) => {
  const [invite, setInvite] = React.useState<boolean>(false);
  const { mutate: mutateCompanyVendors } = useCompanyVendors().index();
  const initialValue: CompanyVendorEdit = companyVendor ?? {
    name: '',
    email: '',
    vendorKey: '',
    vendorCompany: null,
    showTenantDefault: true,
    skipEstimationDefault: false,
    defaultDealManagementType: 'with_vendor',
    completionReportImageRequired: true
  };
  if (companyVendor?.vendorEstimationRequired) {
    initialValue.skipEstimationDefault = false;
  }
  const handleChangeInvite = React.useCallback(() => {
    setInvite(!invite);
  }, [invite]);
  const handleSave = async (submitValue: CompanyVendorEdit): Promise<void> => {
    const result =
      companyVendor === null
        ? await create(submitValue, invite)
        : await update(submitValue, companyVendor.id);
    if (isSuccess(result)) {
      mutateCompanyVendors && (await mutateCompanyVendors());
      handleClose();
      toast.success('保存しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  return (
    <Formik
      initialValues={initialValue}
      onSubmit={handleSave}
      validationSchema={validationSchemaCompanyVendor}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        isSubmitting
      }: FormikProps<CompanyVendorEdit>): ReactNode => (
        <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
          <ModalHeader
            icon={RiAddBoxLine}
            title={companyVendor === null ? '施工会社追加' : '施工会社編集'}
          />
          <ModalBody>
            <div>
              <FormGroup>
                <FormLabel>施工会社名</FormLabel>
                <FormControl
                  isInvalid={!!(touched.name && errors.name)}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>代表者メールアドレス</FormLabel>
                <FormControl
                  isInvalid={!!(touched.email && errors.email)}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>施工会社管理番号</FormLabel>
                <FormControl
                  isInvalid={!!(touched.vendorKey && errors.vendorKey)}
                  name="vendorKey"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vendorKey ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.vendorKey}
                </Form.Control.Feedback>
              </FormGroup>
              {companyVendor?.vendorCompany?.id !==
                VENDOR_COMPANY_ITANDI_ID && (
                <FormGroup>
                  <FormLabel>デフォルトの個人情報公開</FormLabel>
                  <InputCheckBox
                    checked={values.showTenantDefault}
                    labelText="依頼時にデフォルトで個人情報を公開する"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      setFieldValue('showTenantDefault', e.target.checked);
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <FormLabel>デフォルトの見積入力スキップ</FormLabel>
                <InputCheckBox
                  checked={values.skipEstimationDefault}
                  disabled={companyVendor?.vendorEstimationRequired}
                  labelText="依頼時にデフォルトで見積入力をスキップする"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('skipEstimationDefault', e.target.checked);
                  }}
                />
                {companyVendor?.vendorEstimationRequired && (
                  <Tooltip>
                    この施工会社はシステム上見積入力が必須に設定されています
                  </Tooltip>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>案件の管理タイプ</FormLabel>
                <InputCheckBox
                  checked={values.defaultDealManagementType === 'own'}
                  disabled={companyVendor?.managementRequiredByVendor}
                  labelText="アカウント連携している会社との案件を非共有にする"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(
                      'defaultDealManagementType',
                      e.target.checked ? 'own' : 'with_vendor'
                    );
                  }}
                />
                {companyVendor?.managementRequiredByVendor && (
                  <Tooltip>
                    この施工会社は案件を非共有として登録することを許可していません
                  </Tooltip>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>完了報告画像の登録</FormLabel>
                <InputCheckBox
                  checked={values.completionReportImageRequired}
                  disabled={
                    companyVendor?.completionReportImageRequiredByVendor
                  }
                  labelText="アカウント連携している施工会社の完了報告画像の登録を必須にする"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(
                      'completionReportImageRequired',
                      e.target.checked
                    );
                  }}
                />
                {companyVendor?.completionReportImageRequiredByVendor && (
                  <Tooltip>
                    この施工会社は完了報告画像の登録を任意とすることを許可していません
                  </Tooltip>
                )}
              </FormGroup>
              {companyVendor === null && (
                <div>
                  <FormLabel>システム招待</FormLabel>
                  <div className={Styles.InputRadioGroup}>
                    <InputRadio
                      defaultChecked
                      label="今はしない"
                      name="invite"
                      onChange={handleChangeInvite}
                    />
                    <InputRadio
                      label="施工会社をシステムに招待する"
                      name="invite"
                      onChange={handleChangeInvite}
                    />
                  </div>
                  <div className={Styles.Invite}>
                    <p className={Styles.inviteDescription}>
                      連携すると、確認写真や見積書、チャットによるコミュニケーションをシステム上で行なえます。
                    </p>
                  </div>
                  <Alert variant="info">
                    システム連携には、施工会社様によるアカウント作成が必要です。（招待メールが送られます。）
                  </Alert>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter
            leftArea={
              <Button onClick={handleClose} variant="secondary">
                保存せず閉じる
              </Button>
            }
            rightArea={
              <Button
                disabled={isSubmitting}
                onClick={(): void => {
                  handleSubmit();
                }}
                variant="primary"
              >
                保存
              </Button>
            }
          />
        </Modalv2>
      )}
    </Formik>
  );
};
