import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@itandi/itandi-bb-ui';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { useDeals } from '~/hooks/useDeals';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import Styles from './Page.module.scss';
import { PATH } from '~/pages/deals/[id]';
import Loading from '~/components/common/parts/loading/Loading';
import { getDownloadPath } from '~/utils/api/deals/DealEstimationFile';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import { NskResponse } from '~/models/Api';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { ConstructionAddForm } from '~/components/common/constructions/adding/ConstructionAddForm';
import { ConstructionAddModal } from '~/components/common/constructions/adding/ConstructionAddModal';
import { OWN_IN_ESTIMATION } from '~/models/Status';
import {
  update as updateDealEstimation,
  updateSeq,
  destroy as destroyDealEstimation,
  create as postDealEstimation
} from '~/utils/api/deals/DealOwnEstimation';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { useConstructionItems } from '~/hooks/useConstructionItems';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getOriginDealVendor } from '~/models/DealVendorCompany';

export const Page: React.FC = () => {
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const router = useRouter();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: estimationContext, mutate: mutateEstimations } =
    useDealEstimations().index(id);
  const { data: constructionItems } = useConstructionItems().index();
  if (
    id === null ||
    deal === null ||
    estimationContext === null ||
    constructionItems === null
  ) {
    return <Loading isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '見積り作成', active: true }
  ];
  const handleClickPreview = (): void => {
    const path = getDownloadPath(id);
    window.open(path);
  };
  const handleClickShowAddModal = (): void => {
    setShowAddModal(true);
  };
  const handleMutateEstimations = async (): Promise<void> => {
    mutateEstimations && (await mutateEstimations());
  };
  const handleCreate = async (
    submitValue: DealConstructionEdit
  ): Promise<NskResponse<void>> => postDealEstimation(submitValue, deal.id);
  const handleUpdate = async (
    submitValue: DealConstructionEdit,
    estimationId: number
  ): Promise<NskResponse<void>> =>
    updateDealEstimation(submitValue, estimationId, deal.id);
  const handleDestroy = async (
    deleteEstimation: DealConstruction
  ): Promise<NskResponse<void>> => {
    if (deleteEstimation.type !== 'DealEstimation') {
      throw new Error(`Illegal construction type: ${deleteEstimation.type}`);
    }
    return destroyDealEstimation(deleteEstimation, deal.id);
  };
  const handleUpdateSeq = async (
    newEstimations: DealConstruction[]
  ): Promise<void> => {
    await updateSeq(newEstimations, deal.id);
  };
  const handleMutateConstructions = async (): Promise<void> => {
    mutateEstimations && (await mutateEstimations());
  };
  const isReadonly =
    deal.status !== OWN_IN_ESTIMATION &&
    getOriginDealVendor(deal)?.skipEstimation !== true;
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
      >
        <h2 className={Styles.EstimateTitle}>見積り作成</h2>
        <ConstructionTop
          constructionContext={estimationContext}
          handleClickPreview={handleClickPreview}
          handleClickShowAddModal={handleClickShowAddModal}
          readonly={isReadonly}
        />
        <div className={Styles.Container}>
          {!isReadonly && (
            <div className={Styles.EstimateAddForm}>
              <ConstructionAddForm
                constructionItems={constructionItems}
                handleCreate={handleCreate}
                mutateConstructions={handleMutateConstructions}
              />
            </div>
          )}
          <div className={Styles.EstimateList}>
            <ConstructionList
              companyType="client"
              constructionContext={estimationContext}
              constructionItems={constructionItems}
              docType="estimation"
              handleDestroy={handleDestroy}
              handleMutateConstructions={handleMutateEstimations}
              handleUpdate={handleUpdate}
              handleUpdateSeq={handleUpdateSeq}
              readonly={isReadonly}
            />
            <div className={Styles.CompleteButtonArea}>
              <Button
                className={Styles.CompleteButton}
                onClick={(): void => {
                  router.push(PATH(deal.id)).then();
                }}
                variant="primary"
              >
                完了して戻る
              </Button>
            </div>
          </div>
        </div>
        {showAddModal && (
          <ConstructionAddModal
            constructionItems={constructionItems}
            handleClose={(): void => {
              setShowAddModal(false);
            }}
            handleCreate={handleCreate}
            mutateConstructions={handleMutateEstimations}
          />
        )}
      </DealLayout>
    </DefaultV2>
  );
};
