import React from 'react';
import { InputRadio } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { SurveyAnswers, DealSurveyOption } from '~/models/Survey';

type Props = {
  surveyIndex: number;
  surveyOption: DealSurveyOption;
};
export const SurveyRadio: React.FC<Props> = ({
  surveyIndex,
  surveyOption
}: Props) => {
  const { register, formState } = useFormContext<SurveyAnswers>();
  const surveyOptionIdError =
    formState.errors.answers?.[surveyIndex]?.surveyOptionId || null;
  return (
    <InputRadio
      isInvalid={surveyOptionIdError !== null}
      label={surveyOption.text}
      value={surveyOption.id}
      {...register(`answers.${surveyIndex}.surveyOptionId`)}
    />
  );
};
