import React from 'react';
import cx from 'clsx';
import { toast } from 'react-toastify';
import Styles from './NotificationList.module.scss';
import {
  DEAL_STATE_LABELS,
  DEAL_TYPE_LABELS,
  MAIL_SEND_TO_LABELS,
  Notification,
  NotificationEdit,
  NOTIFY_TYPE_LABELS,
  SEND_TYPE_LABELS,
  TRIGGER_DATE_TYPE_LABELS,
  TRIGGER_TYPE_LABELS
} from '~/models/Notification';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { NotificationModal } from '~/components/common/notifications/settings/NotificationModal';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { Status } from '~/models/Status';
import { Subtype } from '~/models/Subtype';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  statuses: Status[];
  subtypes: Subtype[] | null;
  notification: Notification;
  handleMutate: () => Promise<void>;
  handleUpdate: (
    notification: NotificationEdit,
    notificationId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (notification: Notification) => Promise<NskResponse<void>>;
  usePartner: boolean;
}>;

const getNotifyReservationDaysLabel = (days: number): string => {
  if (days === 0) {
    return '当日';
  }
  if (days < 0) {
    return `${days * -1}日前`;
  }
  return `${days}日後`;
};

const isBlank = (text: string | null): boolean => {
  if (text === null) {
    return true;
  }
  return text.trim() === '';
};

export const NotificationListRow: React.FC<Props> = ({
  type,
  statuses,
  subtypes,
  notification,
  handleMutate,
  handleUpdate,
  handleDestroy,
  usePartner
}: Props) => {
  const {
    isOpen: isOpenEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit
  } = useOpenHandler();
  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const statusLabel = React.useMemo(() => {
    if (statuses === null || notification.triggerType !== 'status') {
      return '';
    }
    const status =
      statuses.find((s) => s.id === notification.triggerStatusId) ?? null;
    return status === null ? '' : status.name;
  }, [statuses, notification]);
  const handleUpdateAccept = React.useCallback(
    async (notificationEdit: NotificationEdit): Promise<void> => {
      const response = await handleUpdate(notificationEdit, notification.id);
      if (isSuccess(response)) {
        await handleMutate();
        handleCloseEdit();
        toast.success('通知設定を追加しました。');
        return;
      }
      toast.error('通知設定の追加に失敗しました。');
    },
    [handleMutate, handleCloseEdit, notification, handleUpdate]
  );
  const handleDeleteAccept = React.useCallback(async () => {
    const response = await handleDestroy(notification);
    if (isSuccess(response)) {
      await handleMutate();
      handleCloseDelete();
      toast.success('通知設定を削除しました');
      return;
    }
    toast.error('エラーが発生しました');
  }, [notification, handleMutate, handleCloseDelete, handleDestroy]);
  const subtype = subtypes?.find((s) => s.id === notification.dealSubtypeId);
  if (statuses === null) {
    return null;
  }
  const {
    triggerType,
    triggerDateType,
    notifyReservationDays,
    dealEstimationState,
    dealInspectionReportState,
    dealCompletionReportState,
    dealConstructionScheduleState
  } = notification;
  return (
    <tr className={Styles.ListRow}>
      <td className={Styles.ListCol}>
        <div>{DEAL_TYPE_LABELS[notification.dealType]}</div>
        {notification.dealType === 'other' &&
          type === 'CompanyNotification' && (
            <div className={Styles.SubLabel}>{subtype && subtype.name}</div>
          )}
      </td>
      <td className={Styles.ListCol}>
        {triggerType && <div>{TRIGGER_TYPE_LABELS[triggerType]}</div>}
        <div className={Styles.SubLabel}>
          {triggerType === 'status' && statusLabel}
          {triggerType === 'specific_date' &&
            triggerDateType &&
            TRIGGER_DATE_TYPE_LABELS[triggerDateType]}
        </div>
      </td>
      <td className={Styles.ListCol}>
        {dealEstimationState === 'no_setting' &&
          dealInspectionReportState === 'no_setting' &&
          dealCompletionReportState === 'no_setting' &&
          dealConstructionScheduleState === 'no_setting' && (
            <div>登録状況に関わらず通知</div>
          )}
        {dealEstimationState !== 'no_setting' && (
          <div className={Styles.DealStateLabel}>
            見積り: {DEAL_STATE_LABELS[dealEstimationState]}
          </div>
        )}
        {dealInspectionReportState !== 'no_setting' && (
          <div className={Styles.DealStateLabel}>
            立会報告画像: {DEAL_STATE_LABELS[dealInspectionReportState]}
          </div>
        )}
        {dealCompletionReportState !== 'no_setting' && (
          <div className={Styles.DealStateLabel}>
            完了報告画像: {DEAL_STATE_LABELS[dealCompletionReportState]}
          </div>
        )}
        {dealConstructionScheduleState !== 'no_setting' && (
          <div className={Styles.DealStateLabel}>
            工事日程の入力状況:
            {DEAL_STATE_LABELS[dealConstructionScheduleState]}
          </div>
        )}
      </td>
      <td className={Styles.ListCol}>
        <div>{NOTIFY_TYPE_LABELS[notification.notifyType]}</div>
        {notification.notifyType === 'reservation' && notifyReservationDays && (
          <div className={Styles.SubLabel}>
            {getNotifyReservationDaysLabel(notifyReservationDays)}
          </div>
        )}
      </td>
      <td className={Styles.ListCol}>
        <div>{SEND_TYPE_LABELS[notification.sendType]}</div>
        {notification.sendType === 'mail' &&
          notification.mailSendTo !== null && (
            <div className={Styles.SubLabel}>
              {`${MAIL_SEND_TO_LABELS[notification.mailSendTo]}宛`}
            </div>
          )}
      </td>
      <td className={cx(Styles.ListCol, Styles.Content)}>
        {isBlank(notification.title)
          ? notification.content
          : notification.title}
      </td>
      <td className={cx(Styles.ListCol, Styles.Edit)}>
        <a className={Styles.EditLink} href="#" onClick={handleOpenEdit}>
          編集
        </a>
        <a className={Styles.DeleteLink} href="#" onClick={handleOpenDelete}>
          削除
        </a>
        <NotificationModal
          handleAcceptSubmit={handleUpdateAccept}
          handleClose={handleCloseEdit}
          isOpen={isOpenEdit}
          notification={notification}
          statuses={statuses}
          subtypes={subtypes}
          type={type}
          usePartner={usePartner}
        />
        {isOpenDelete && (
          <ConfirmModal
            confirmType="delete"
            handleAccept={handleDeleteAccept}
            handleCancel={handleCloseDelete}
            isOpen={isOpenDelete}
          >
            通知を削除してよろしいですか？
          </ConfirmModal>
        )}
      </td>
    </tr>
  );
};
