import React, { ReactNode } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { RiAddBoxLine } from 'react-icons/ri';
import {
  VendorCompanyCreateParams,
  vendorCompanyCreateValidationSchema
} from '~/models/VendorCompany';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { create } from '~/utils/api/admin/VendorCompany';
import { isSuccess } from '~/utils/api/api_handler';
import { useVendorCompanies } from '~/hooks/admin/useVendorCompanies';
import Styles from './VendorCompanyModal.module.scss';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const VendorCompanyModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  const { mutate: mutateVendorCompanies } = useVendorCompanies().index();
  const initialValue = {
    name: '',
    loginEmail: '',
    password: '',
    ceoLastName: '',
    ceoFirstName: '',
    postalCode: '',
    address: ''
  };
  const handleAccept = async (
    submitValue: VendorCompanyCreateParams
  ): Promise<void> => {
    const result = await create(submitValue);
    if (isSuccess(result)) {
      mutateVendorCompanies != null && (await mutateVendorCompanies());
      toast.success('作成しました');
      handleClose();
      return;
    }
    toast.error(result.message ?? 'エラーが発生しました');
  };
  return (
    <Formik
      initialValues={initialValue}
      onSubmit={handleAccept}
      validationSchema={vendorCompanyCreateValidationSchema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        handleSubmit,
        touched
      }: FormikProps<VendorCompanyCreateParams>): ReactNode => (
        <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
          <ModalHeader icon={RiAddBoxLine} title="施工会社を追加" />
          <ModalBody>
            <div className={Styles.Forms}>
              <FormGroup>
                <FormLabel>施工会社名</FormLabel>
                <FormControl
                  isInvalid={!!(touched.name && errors.name)}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="例）イタンジ株式会社"
                  value={values.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>ログインメールアドレス</FormLabel>
                <FormControl
                  isInvalid={!!(touched.loginEmail && errors.loginEmail)}
                  name="loginEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="メールアドレス"
                  value={values.loginEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.loginEmail}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>初期パスワード</FormLabel>
                <FormControl
                  isInvalid={!!(touched.password && errors.password)}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="パスワード"
                  value={values.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>代表者氏名</FormLabel>
                <div className={Styles.FormRow}>
                  <div className={Styles.FormCol}>
                    <FormControl
                      isInvalid={touched.ceoLastName && !!errors.ceoLastName}
                      name="ceoLastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="例）内装"
                      type="text"
                      value={values.ceoLastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ceoLastName}
                    </Form.Control.Feedback>
                  </div>
                  <div className={Styles.FormCol}>
                    <FormControl
                      isInvalid={touched.ceoFirstName && !!errors.ceoFirstName}
                      name="ceoFirstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="例）太郎"
                      type="text"
                      value={values.ceoFirstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ceoFirstName}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <FormLabel>郵便番号</FormLabel>
                <FormControl
                  isInvalid={touched.postalCode && !!errors.postalCode}
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="例）1234567"
                  type="text"
                  value={values.postalCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>住所</FormLabel>
                <FormControl
                  isInvalid={touched.address && !!errors.address}
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="例）東京都港区六本木3丁目2-1 六本木グランドタワー42階"
                  type="text"
                  value={values.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter
            leftArea={
              <Button onClick={handleClose} variant="secondary">
                保存せず閉じる
              </Button>
            }
            rightArea={
              <Button
                onClick={(): void => {
                  handleSubmit();
                }}
                variant="primary"
              >
                追加
              </Button>
            }
          />
        </Modalv2>
      )}
    </Formik>
  );
};
