import React from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import SettingStyles from '../../../SettingStyle.module.scss';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { TodoTemplateList } from '~/components/common/todo_templates/TodoTemplateList';
import { PATH } from '~/pages/settings/subtypes';

export const Page: React.FC = () => (
  <DefaultV2>
    <ClientSettingLayout>
      <div className={SettingStyles.BreadcrumbsBlock}>
        <Breadcrumbs>
          <BreadcrumbsItem href={PATH}>案件タイプ設定</BreadcrumbsItem>
          <BreadcrumbsItem isText>TODOテンプレート設定</BreadcrumbsItem>
        </Breadcrumbs>
      </div>
      <h2 className={SettingStyles.SettingTitle}>
        原状回復 TODOテンプレート設定
      </h2>
      <TodoTemplateList dealType="restoration" />
    </ClientSettingLayout>
  </DefaultV2>
);
