import { NskResponse } from '~/models/Api';
import { CompanyNotificationSetting } from '~/models/CompanyNotificationSetting';
import { putRequest } from '~/utils/api/api_handler';

const paths = {
  update: '/company_notification_setting'
};
export const update = async (
  companyNotificationSetting: CompanyNotificationSetting
): Promise<NskResponse<void>> =>
  putRequest(paths.update, { companyNotificationSetting });
