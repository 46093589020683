import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/deals/[id]/inspection_reports/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string =>
  `/vendors/deals/${id}/inspection_reports`;

const InspectionReports: NextPage = () => (
  <>
    <NskHead title="立会報告画像の登録" />
    <Component />
  </>
);

export default InspectionReports;
