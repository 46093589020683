import { VendorCompany, VendorToCompanySetting } from '~/models/VendorCompany';
import { VendorUser } from '~/models/VendorUser';
import { RelatedVendorPartner } from '~/models/VendorPartner';
import { Deal } from '~/models/Deal';
import { getOriginVendor, isOriginVendor } from '~/models/DealVendorCompany';

export type VendorSession = {
  vendorCompany: VendorCompany & VendorToCompanySetting;
  vendorUser: VendorUser | null;
  admin: boolean;
  /**
   * Partner視点で、協力している一次請けの施工会社一覧
   */
  relatedVendorPartners: RelatedVendorPartner[];
};

/**
 * 一次請けの施工会社に管理会社とのチャット許可を受けているかどうか
 * @param session
 * @param vendorCompanyId
 */
export const allowMessageByVendorCompany = (
  session: VendorSession,
  vendorCompanyId: number
): boolean =>
  session.relatedVendorPartners.some(
    (relatedVendorPartner) =>
      relatedVendorPartner.vendorCompany.id === vendorCompanyId &&
      relatedVendorPartner.allowMessage
  );

/**
 * ログインsessionから、dealに対してメッセージ可能かどうかを返す
 *
 * @param session
 * @param deal
 */
export const canMessage = (session: VendorSession, deal: Deal): boolean => {
  if (isOriginVendor(deal, session.vendorCompany.id)) {
    // originは常にメッセージ可能
    return true;
  }
  const originVendor = getOriginVendor(deal);
  if (originVendor === null) {
    return false;
  }
  return allowMessageByVendorCompany(session, originVendor.id);
};
