import React, { ReactNode, useContext } from 'react';

import { usePopper } from 'react-popper';
import cx from 'clsx';
import { Portal } from 'react-overlays';
import { RiArrowDownSFill } from 'react-icons/ri';
import Styles from './Dropdown.module.scss';
import { useClickAway } from '~/hooks/useClickAway';

type Props = {
  title: string | ReactNode;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
  size?: 'md' | 'sm';
  variant?: 'primary' | 'secondary' | 'normal' | 'link';
  noBorder?: boolean;
  useCaret?: boolean;
};

type DropdownState = {
  show: boolean;
  setShow?: (show: boolean) => void;
};
export const DropdownContext = React.createContext<DropdownState>({
  show: false,
  setShow: undefined
});

export const useDropdownContext = (): DropdownState =>
  useContext<DropdownState>(DropdownContext);

export const Dropdown: React.FC<Props> = ({
  title,
  children,
  className = '',
  disabled = false,
  placement = 'bottom-end',
  size = 'md',
  variant = 'primary',
  noBorder = false,
  useCaret = true
}: Props) => {
  const [show, setShow] = React.useState<boolean>(false);
  const referenceRef = React.useRef<HTMLButtonElement | null>(null);
  const popperRef = React.useRef<HTMLDivElement | null>(null);
  useClickAway(popperRef, referenceRef, (): void => {
    setShow(false);
  });
  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement
    }
  );
  React.useEffect(() => {
    if (update) update();
  }, [show, update]);
  const onClickToggle = (): void => {
    setShow(!show);
  };

  const variantStyle = ((): string => {
    switch (variant) {
      case 'primary':
        return Styles.Primary;
      case 'secondary':
        return Styles.Secondary;
      case 'normal':
        return Styles.Normal;
      case 'link':
        return Styles.Link;
      default:
        return '';
    }
  })();
  const sizeStyle = ((): string => {
    switch (size) {
      case 'md':
        return Styles.Medium;
      case 'sm':
        return Styles.Small;
      default:
        return '';
    }
  })();
  const container = ((): HTMLElement | null => {
    if (typeof document === 'undefined') {
      return null;
    }
    return document.body;
  })();
  const providerValue = React.useMemo(
    () => ({ show, setShow }),
    [show, setShow]
  );
  return (
    <DropdownContext.Provider value={providerValue}>
      <button
        ref={referenceRef}
        aria-expanded="false"
        aria-haspopup="true"
        className={cx(
          className,
          Styles.DropdownToggle,
          variantStyle,
          sizeStyle,
          show ? Styles.ShowStyle : '',
          noBorder ? Styles.NoBorder : ''
        )}
        data-toggle="dropdown"
        disabled={disabled}
        onClick={onClickToggle}
        type="button"
      >
        <span className={Styles.DropdownTitle}>{title}</span>
        {useCaret && <RiArrowDownSFill />}
      </button>
      <Portal container={container}>
        <div
          ref={popperRef}
          className={cx(Styles.DropdownPopper, show ? '' : Styles.Hide)}
          style={styles.popper}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes.popper}
        >
          <div className={show ? '' : Styles.Hide}>{children}</div>
        </div>
      </Portal>
    </DropdownContext.Provider>
  );
};
