import React from 'react';
import { toast } from 'react-toastify';
import Styles from './statuses.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { create as postCancelBack } from '~/utils/api/deals/DealCancelBack';
import { isSuccess } from '~/utils/api/api_handler';
import useItandiAccount from '~/hooks/useItandiAccount';
import { useDeals } from '~/hooks/useDeals';
import { canOperatingDeal } from '~/models/Staff';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Canceled: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const dealId = useIdQueryHandler();
  const {
    isOpen: confirmBackOpen,
    handleOpen: handleConfirmBackOpen,
    handleClose: handleConfirmBackClose
  } = useOpenHandler();

  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data } = useItandiAccount().currentAccount();
  if (deal === null || data === null) {
    return null;
  }
  const { dealCancel } = deal;
  const handleDeleteCancel = async (): Promise<void> => {
    const result = await postCancelBack(deal.id);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      toast.success('取り下げた案件を元に戻しました');
      handleConfirmBackClose();
      return;
    }
    toast.error('エラーが発生しました');
    setIsSubmitting(false);
  };
  return (
    <div>
      <h3 className={Styles.Title}>この案件は取り下げられました</h3>
      {dealCancel !== null && (
        <div>
          <div className={Styles.CancelReasonLabel}>取り下げ理由</div>
          <div className={Styles.CancelReason}>{dealCancel.reason}</div>
        </div>
      )}
      <div className={Styles.StatusHandleButtonRow}>
        <div className={Styles.RightButtons}>
          <button
            className={Styles.BackwardButton}
            disabled={!canOperatingDeal(data)}
            onClick={handleConfirmBackOpen}
            type="button"
          >
            案件を元に戻す
          </button>
        </div>
        <ConfirmModal
          actionText="元に戻す"
          confirmType="save"
          disabled={isSubmitting}
          handleAccept={handleDeleteCancel}
          handleCancel={handleConfirmBackClose}
          isOpen={confirmBackOpen}
        >
          取り下げた案件を元に戻しますか？
        </ConfirmModal>
      </div>
    </div>
  );
};
