import useSWR from 'swr';
import { Property } from '~/models/kensaku_kun/property';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/kensaku_kun/buildings'
} as const;

type TIndex = (name: string) => SwrReturnType<Property<'building'>[]>;

type ReturnType = {
  index: TIndex;
};

export const useBuildings = (): ReturnType => {
  const index: TIndex = (name: string) => {
    const { data, mutate, error } = useSWR({
      url: paths.index,
      params: { name }
    });
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
