import React, { ReactNode } from 'react';
import { DealHistory } from '~/models/DealHistory';
import { DealHistoryRow } from '~/components/common/deals/show/main/histories/DealHistoryRow';
import { useDealHistories } from '~/hooks/vendors/deals/useDealHistories';

type Props = {
  dealId: number;
};

const renderRow = (history: DealHistory): ReactNode => (
  <DealHistoryRow key={history.id} dealHistory={history} />
);

/**
 *
 * @param dealId
 * @constructor
 * @for vendor
 */
export const DealHistoryVendorList: React.FC<Props> = ({ dealId }: Props) => {
  const { data: histories } = useDealHistories().useIndex(dealId);
  if (histories === null) {
    return null;
  }
  return <div>{histories.map(renderRow)}</div>;
};
