import React, { ReactNode } from 'react';
import Styles from './ConfirmModal.module.scss';
import { Modal } from '~/components/common/parts/modal/Modal';
import { CancelButton } from '~/components/common/parts/button/CancelButton';
import { Button } from '~/components/common/parts/button/Button';

type ConfirmType = 'delete' | 'save';
type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  handleCancel: () => void;
  handleAccept: () => void | Promise<void>;
  title?: string;
  actionText?: string;
  cancelText?: string;
  confirmType: ConfirmType;
  disabled?: boolean;
};

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  children,
  handleCancel,
  handleAccept,
  title = '確認',
  confirmType,
  actionText,
  cancelText = 'キャンセル',
  disabled = false
}: Props) => {
  const footer = ((): ReactNode => {
    if (confirmType === 'save') {
      return (
        <>
          <CancelButton
            className={Styles.Button}
            label={cancelText}
            onClick={handleCancel}
          />
          <Button
            className={Styles.AcceptButton}
            disabled={disabled}
            onClick={handleAccept}
            variant="primary"
          >
            {actionText ?? '保存'}
          </Button>
        </>
      );
    }
    return (
      <>
        <CancelButton label={cancelText} onClick={handleCancel} />
        <Button
          className={Styles.AcceptButton}
          disabled={disabled}
          onClick={handleAccept}
          variant="danger"
        >
          {actionText ?? '削除'}
        </Button>
      </>
    );
  })();
  return (
    <Modal
      footers={footer}
      handleClose={handleCancel}
      isOpen={isOpen}
      title={title}
    >
      {children}
    </Modal>
  );
};
