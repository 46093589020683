import React, { ReactNode } from 'react';
import Styles from './Container.module.scss';

type Props = DeepReadonly<{
  title: string;
  children: ReactNode;
}>;

export const Container: React.FC<Props> = ({ title, children }: Props) => (
  <div className={Styles.Container}>
    <h1 className={Styles.Title}>{title}</h1>
    {children}
  </div>
);
