import React, { ReactNode } from 'react';
import { RiPaintBrushLine } from 'react-icons/ri';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { Deal, matchDealType } from '~/models/Deal';
import Styles from '../DealCard.module.scss';
import { formatDate } from '~/utils/DateUtil';
import {
  getFloorMapPath as getFloorMapVendorPath,
  getLeaseContractFilePath as getLeaseContractFileVendorPath
} from '~/utils/api/vendors/Deal';
import {
  getFloorMapPath as getFloorMapClientPath,
  getLeaseContractFilePath as getLeaseContractFileClientPath
} from '~/utils/api/Deal';
import { VendorSession } from '~/models/VendorSession';
import { isOriginVendor } from '~/models/DealVendorCompany';
import { ProductLinkList } from '~/components/common/products/ProductLinkList';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { DealCardModal } from '~/components/common/deals/show/deal_card/DealCardModal';
import { FINISHED, OWN_FINISHED } from '~/models/Status';

type Props = {
  deal: Deal;
  vendorSession?: VendorSession | null;
  handleMutate?: (() => Promise<void>) | null;
  isVendor?: boolean;
};

const isEmpty = (value: string | unknown): boolean =>
  value == null || value === '';

const renderNotYetInput = (): ReactNode => (
  <span className={Styles.NotYet}>未入力</span>
);

export const DealCard: React.FC<Props> = ({
  deal,
  vendorSession = null,
  isVendor = true,
  handleMutate = null
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiPaintBrushLine className={Styles.TitleIcon} />
        案件情報
        <div className={Styles.EditButtonWrapper}>
          {!isVendor &&
            deal.status !== FINISHED &&
            deal.status !== OWN_FINISHED && (
              <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
            )}
          <DealCardModal
            deal={deal}
            handleClose={handleClose}
            handleMutate={handleMutate}
            isOpen={isOpen}
          />
        </div>
      </h3>
      <div className={Styles.Content}>
        {matchDealType(deal, 'restoration') && (
          <dl className={Styles.ListContent}>
            <dt className={Styles.ListContentCaption}>解約日</dt>
            <dd className={Styles.ListContentValue}>
              {isEmpty(deal.terminationDate) ? (
                <>{renderNotYetInput()}</>
              ) : (
                <>{formatDate(deal.terminationDate)}</>
              )}
            </dd>
          </dl>
        )}
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>入居予定日</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.preferredDate) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>{formatDate(deal.preferredDate)}</>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>入居者</dt>
          <dd className={Styles.ListContentValue}>
            <p>
              {isEmpty(deal.tenantName) ? renderNotYetInput() : deal.tenantName}
            </p>
            {!isEmpty(deal.tenantNameKana) && <p>（{deal.tenantNameKana}）</p>}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>入居者携帯電話番号</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.tenantTel) ? (
              renderNotYetInput()
            ) : (
              <a className={Styles.Link} href={`tel:${deal.tenantTel}`}>
                {deal.tenantTel}
              </a>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>入居者メールアドレス</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.tenantEmail) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <a className={Styles.Link} href={`mailto:${deal.tenantEmail}`}>
                {deal.tenantEmail}
              </a>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>エレベーター</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.enableElevator) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>{deal.enableElevator ? 'あり' : 'なし'}</>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>オートロック</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.enableAutoLock) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>{deal.enableAutoLock ? 'あり' : 'なし'}</>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>鍵置き場</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.placeKeyStoring) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>{deal.placeKeyStoring}</>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>賃貸借契約書</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.leaseContractFile1) &&
            isEmpty(deal.leaseContractFile2) &&
            isEmpty(deal.leaseContractFile3) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>
                {!isEmpty(deal.leaseContractFile1) && (
                  <div>
                    <a
                      className={Styles.Link}
                      href={
                        vendorSession !== null
                          ? getLeaseContractFileVendorPath(deal.id, 1)
                          : getLeaseContractFileClientPath(deal.id, 1)
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      賃貸借契約書1
                      {deal.leaseContractFile1OriginalFilename !== null && (
                        <>_{deal.leaseContractFile1OriginalFilename}</>
                      )}
                    </a>
                  </div>
                )}
                {!isEmpty(deal.leaseContractFile2) && (
                  <div>
                    <a
                      className={Styles.Link}
                      href={
                        vendorSession !== null
                          ? getLeaseContractFileVendorPath(deal.id, 2)
                          : getLeaseContractFileClientPath(deal.id, 2)
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      賃貸借契約書2
                      {deal.leaseContractFile2OriginalFilename !== null && (
                        <>_{deal.leaseContractFile2OriginalFilename}</>
                      )}
                    </a>
                  </div>
                )}
                {!isEmpty(deal.leaseContractFile3) && (
                  <div>
                    <a
                      className={Styles.Link}
                      href={
                        vendorSession !== null
                          ? getLeaseContractFileVendorPath(deal.id, 3)
                          : getLeaseContractFileClientPath(deal.id, 3)
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      賃貸借契約書3
                      {deal.leaseContractFile3OriginalFilename !== null && (
                        <>_{deal.leaseContractFile3OriginalFilename}</>
                      )}
                    </a>
                  </div>
                )}
              </>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>間取り図</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.floorMap) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <a
                className={Styles.Link}
                href={
                  vendorSession !== null
                    ? getFloorMapVendorPath(deal.id)
                    : getFloorMapClientPath(deal.id)
                }
                rel="noreferrer"
                target="_blank"
              >
                ダウンロード
              </a>
            )}
          </dd>
        </dl>
        {(vendorSession === null ||
          isOriginVendor(deal, vendorSession.vendorCompany.id)) && (
          <dl className={Styles.ListContent}>
            <dt className={Styles.ListContentCaption}>管理会社担当者</dt>
            <dd className={Styles.ListContentValue}>
              {isEmpty(deal.staff?.name) ? (
                <>{renderNotYetInput()}</>
              ) : (
                <>
                  <p>{deal.staff.name}</p>
                  {!isEmpty(deal.staff.email) && (
                    <p>
                      <a
                        className={Styles.Link}
                        href={`mailto:${deal.staff.email}`}
                      >
                        {deal.staff.email}
                      </a>
                    </p>
                  )}
                </>
              )}
            </dd>
          </dl>
        )}
        {(vendorSession === null ||
          isOriginVendor(deal, vendorSession.vendorCompany.id)) && (
          <dl className={Styles.ListContent}>
            <dt className={Styles.ListContentCaption}>発注書請書の宛名</dt>
            <dd className={Styles.ListContentValue}>
              {isEmpty(deal.estimateAddressName) ? (
                <>{renderNotYetInput()}</>
              ) : (
                <>
                  {!isEmpty(deal.estimateAddressName) && (
                    <p>{deal.estimateAddressName}</p>
                  )}
                </>
              )}
            </dd>
          </dl>
        )}

        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>駐車場の場所</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.freeParking) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <p className={Styles.longSentence}>{deal.freeParking}</p>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>その他要望</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.note) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <p className={Styles.longSentence}>{deal.note}</p>
            )}
          </dd>
        </dl>
      </div>
      {vendorSession === null && <ProductLinkList deal={deal} />}
    </div>
  );
};
