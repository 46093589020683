import useSwr from 'swr';
import { AxiosPromise } from 'axios';
import { SwrReturnType } from '~/models/Api';
import { CompanySettingWithStore } from '~/models/CompanySetting';
import ApiClient from '~/utils/api/api_client';
import { getApiUrl } from '~/utils/AppUtil';

type TIndex = (
  searchWord: string,
  pageNumber: number
) => SwrReturnType<Pageable<CompanySettingWithStore>>;
type TProxyLogin = (companyMasterId: number, authId: number) => AxiosPromise;
type ReturnType = {
  index: TIndex;
  proxyLogin: TProxyLogin;
};

export const useCompanySettings = (): ReturnType => {
  const index: TIndex = (searchWord: string, pageNumber: number) => {
    const { data, error, mutate, isLoading } = useSwr({
      url: '/admin/company_settings',
      params: { searchWord, pageNumber }
    });
    return {
      data: data ?? null,
      mutate,
      error,
      isLoading
    };
  };
  const proxyLogin: TProxyLogin = (companyMasterId: number, authId: number) =>
    ApiClient.post(
      getApiUrl(`/admin/company_settings/${companyMasterId}/proxy_login`),
      { authId }
    );

  return { index, proxyLogin };
};
