import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { ImportConversionParam } from '~/models/ImportConversion';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import Styles from './AddModalForm.module.scss';
import { FormDescription } from '~/components/common/parts/form/FormDescription';

export const AddModalForm: React.FC = () => {
  const { register, formState } = useFormContext<ImportConversionParam>();
  const { errors } = formState;
  return (
    <div>
      <FormGroup>
        <FormLabel>列</FormLabel>
        <Input
          isInvalid={errors.columnName?.message != null}
          placeholder="例：account_key"
          {...register('columnName')}
        />
        {errors.columnName?.message != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.columnName.message}
          </div>
        )}
        <FormDescription>
          CSVのヘッダーの列名を小文字で入力してください
        </FormDescription>
      </FormGroup>
      <FormGroup>
        <FormLabel>変換前の値</FormLabel>
        <Input
          isInvalid={errors.originalValue?.message != null}
          placeholder="例：渋谷支店"
          {...register('originalValue')}
        />
        {errors.originalValue?.message != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.originalValue.message}
          </div>
        )}
        <FormDescription>
          CSVに入力されている変換したい文言を入力してください
        </FormDescription>
      </FormGroup>
      <FormGroup>
        <FormLabel>変換後の値</FormLabel>
        <Input
          isInvalid={errors.convertedValue?.message != null}
          placeholder="例：100"
          {...register('convertedValue')}
        />
        {errors.convertedValue?.message != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.convertedValue.message}
          </div>
        )}
      </FormGroup>
    </div>
  );
};
