import React from 'react';
import Styles from './Form.module.scss';
import { Switcher } from '~/components/common/parts/switcher/Switcher';

type Props = DeepReadonly<{
  formLabel: string;
  description: string;
  checked?: boolean | undefined;
  defaultChecked?: boolean | undefined;
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}>;

export const AuthorizationForm: React.FC<Props> = ({
  formLabel,
  description,
  checked = undefined,
  defaultChecked = undefined,
  disabled = false,
  handleChange
}: Props) => (
  <div className={Styles.FormList}>
    <div className={Styles.FormBlock}>
      <div className={Styles.FormLabel}>{formLabel}</div>
      <div className={Styles.FormContent}>
        <Switcher
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        <span className={Styles.Description}>{description}</span>
      </div>
    </div>
  </div>
);
