import React from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { useDeals } from '~/hooks/vendors/useDeals';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import Styles from './Page.module.scss';
import { PATH } from '~/pages/vendors/deals/[id]';
import Loading from '~/components/common/parts/loading/Loading';
import { getDownloadPath } from '~/utils/api/vendors/deals/DealEstimationFile';
import { useDealEstimations } from '~/hooks/vendors/deals/useDealEstimations';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import {
  update as updateDealEstimation,
  updateSeq,
  destroy as destroyEstimation,
  create as postDealEstimation
} from '~/utils/api/vendors/deals/DealEstimation';
import { NskResponse } from '~/models/Api';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { ConstructionAddForm } from '~/components/common/constructions/adding/ConstructionAddForm';
import { ConstructionAddModal } from '~/components/common/constructions/adding/ConstructionAddModal';
import { IN_ESTIMATION } from '~/models/Status';
import { create } from '~/utils/api/vendors/deals/DealEstimationComplete';
import { isSuccess } from '~/utils/api/api_handler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { isSubVendor } from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useDealConstructionItems } from '~/hooks/vendors/deals/useDealConstructionItems';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const {
    isOpen: showConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const { data: session } = useSessions().index();
  const router = useRouter();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: estimationContext, mutate: mutateEstimations } =
    useDealEstimations().index(id);
  const { data: dealConstructionItems } = useDealConstructionItems().index(id);
  const vendorCompanyId = session?.vendorCompany.id ?? null;
  if (
    deal === null ||
    estimationContext === null ||
    dealConstructionItems === null ||
    vendorCompanyId === null
  ) {
    return <Loading isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '見積り作成', active: true }
  ];
  const handleClickPreview = (): void => {
    const path = getDownloadPath(deal.id);
    window.open(path);
  };
  const handleClickShowAddModal = (): void => {
    setShowAddModal(true);
  };
  const handleMutateEstimations = async (): Promise<void> => {
    mutateEstimations && (await mutateEstimations());
  };
  const handleCreate = async (
    submitValue: DealConstructionEdit
  ): Promise<NskResponse<void>> => postDealEstimation(submitValue, deal.id);
  const handleUpdate = async (
    submitValue: DealConstructionEdit,
    estimationId: number
  ): Promise<NskResponse<void>> =>
    updateDealEstimation(submitValue, estimationId, deal.id);
  const handleDestroy = async (
    deleteEstimation: DealConstruction
  ): Promise<NskResponse<void>> => {
    if (deleteEstimation.type !== 'DealEstimation') {
      throw new Error(`Illegal construction type: ${deleteEstimation.type}`);
    }
    return destroyEstimation(deleteEstimation, deal.id);
  };
  const handleUpdateSeq = async (
    newEstimations: DealConstruction[]
  ): Promise<void> => {
    await updateSeq(newEstimations, deal.id);
  };
  const handleMutateConstructions = async (): Promise<void> => {
    mutateEstimations && (await mutateEstimations());
  };
  const isReadonly = deal.status !== IN_ESTIMATION;
  const handleSubmitComplete = async (): Promise<void> => {
    const result = await create(deal.id);
    if (isSuccess(result)) {
      await router.push(PATH(deal.id));
      toast.success('完了しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  return (
    <VendorLayoutV2>
      <DealLayout breadcrumbItems={breadcrumbItems} deal={deal} isVendor>
        <h2 className={Styles.EstimateTitle}>見積り作成</h2>
        <ConstructionTop
          constructionContext={estimationContext}
          handleClickPreview={handleClickPreview}
          handleClickShowAddModal={handleClickShowAddModal}
          readonly={isReadonly}
        />
        <div className={Styles.Container}>
          {!isReadonly && (
            <div className={Styles.EstimateAddForm}>
              <ConstructionAddForm
                constructionItems={dealConstructionItems}
                handleCreate={handleCreate}
                mutateConstructions={handleMutateConstructions}
              />
            </div>
          )}
          <div className={Styles.EstimateList}>
            <ConstructionList
              companyType="vendor"
              constructionContext={estimationContext}
              constructionItems={dealConstructionItems}
              docType="estimation"
              handleDestroy={handleDestroy}
              handleMutateConstructions={handleMutateEstimations}
              handleUpdate={handleUpdate}
              handleUpdateSeq={handleUpdateSeq}
              readonly={isReadonly}
            />
            <div className={Styles.CompleteButtonArea}>
              {isSubVendor(deal, vendorCompanyId) ? (
                <button
                  className={Styles.CompleteButton}
                  onClick={handleOpenConfirm}
                  type="button"
                >
                  完了して通知する
                </button>
              ) : (
                <button
                  className={Styles.CompleteButton}
                  onClick={handleSubmitComplete}
                  type="button"
                >
                  完了して戻る
                </button>
              )}
              <ConfirmModal
                actionText="完了"
                confirmType="save"
                handleAccept={handleSubmitComplete}
                handleCancel={handleCloseConfirm}
                isOpen={showConfirm}
              >
                完了してよろしいですか？
              </ConfirmModal>
            </div>
          </div>
        </div>
        {showAddModal && (
          <ConstructionAddModal
            constructionItems={dealConstructionItems}
            handleClose={(): void => {
              setShowAddModal(false);
            }}
            handleCreate={handleCreate}
            mutateConstructions={handleMutateEstimations}
          />
        )}
      </DealLayout>
    </VendorLayoutV2>
  );
};
