import React from 'react';
import { InputCheckBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Form.module.scss';
import { MarketCompanySetting } from '~/models/CompanySetting';

type Props = DeepReadonly<{
  isConfirm?: boolean;
}>;

type CheckBoxStates = [boolean, boolean, boolean];

export const TermForm: React.FC<Props> = ({ isConfirm = false }: Props) => {
  const [checkStates, setCheckStates] = React.useState<CheckBoxStates>([
    false,
    false,
    false
  ]);
  const { setValue } = useFormContext<MarketCompanySetting>();
  React.useEffect(() => {
    const newCheckTerm = checkStates.every((c) => c);
    setValue('checkTerm', newCheckTerm);
  }, [checkStates, setValue]);
  const handleChangeCheck = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
      const newCheckStates: [boolean, boolean, boolean] = [...checkStates];
      newCheckStates[index] = e.target.checked;
      setCheckStates(newCheckStates);
    },
    [checkStates, setCheckStates]
  );
  if (isConfirm) {
    return null;
  }
  return (
    <div className={Styles.FormGroup}>
      <div className={Styles.Checkboxes}>
        <InputCheckBox
          checked={checkStates[0]}
          id="term1"
          labelText={
            <span>
              内装工事くんに関する
              <a
                className={Styles.TermLink}
                href="https://www.itandi.co.jp/kiyaku/itandisystemriyoukiyaku.pdf"
                rel="noreferrer"
                target="_blank"
              >
                ITANDI SYSTEM利用規約
              </a>
              、
              <a
                className={Styles.TermLink}
                href="https://www.itandi.co.jp/kiyaku/naisoukoujikunnomakasepuranntokuyaku20231101.pdf"
                rel="noreferrer"
                target="_blank"
              >
                内装工事くんおまかせプラン特約
              </a>
              、
              <a
                className={Styles.TermLink}
                href="/unit_amounts_single.pdf"
                rel="noreferrer"
                target="_blank"
              >
                単価表
              </a>
              に同意の上、利用申込してください。
            </span>
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChangeCheck(e, 0)
          }
        />
        <InputCheckBox
          checked={checkStates[1]}
          id="term2"
          labelText="本申込者は、本サービス申込みにあたり会社を代表又は代理する権限を有していることを表明し、これを保証します。"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChangeCheck(e, 1)
          }
        />
        <InputCheckBox
          checked={checkStates[2]}
          id="term3"
          labelText="弊社による業務代行サービスは、弊社から送付する契約開始通知書の通知をもって利用できるものとします。"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChangeCheck(e, 2)
          }
        />
      </div>
    </div>
  );
};
