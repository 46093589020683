import React, { ReactNode } from 'react';
import { Button } from '../parts/button/Button';
import Styles from './UploadButton.module.scss';
import useLoader from '~/hooks/useLoader';

type Props = {
  children: ReactNode;
  onClick: (file: File) => Promise<void>;
  className?: string;
  size?: 'lg' | 'md' | 'sm';
  variant?: 'primary' | 'secondary' | 'normal' | 'danger' | 'link' | 'outline';
};

export const UploadButton: React.FC<Props> = ({
  children,
  className = '',
  variant = 'normal',
  size = 'md',
  onClick
}: Props) => {
  const { Loader, showLoader, hideLoader } = useLoader();
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const handlePostFile = (): void => inputFileRef?.current?.click();
  const handleChangeFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    showLoader();
    const { files } = e.target;
    const file = files && files[0];
    if (file !== null) {
      await onClick(file);
      hideLoader();
      if (inputFileRef.current !== null) {
        inputFileRef.current.value = '';
      }
    }
  };
  return (
    <>
      <Loader />
      <input
        ref={inputFileRef}
        accept=".pdf, image/*,"
        className={Styles.HideFilePostInput}
        onChange={handleChangeFile}
        type="file"
      />
      <Button
        className={className}
        onClick={handlePostFile}
        size={size}
        variant={variant}
      >
        {children}
      </Button>
    </>
  );
};
