import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';
import { DealEstimationEdit } from '~/models/DealEstimate';

const paths = {
  update: '/deals/:deal_id/deal_estimations/:id'
};

export const update = async (
  dealEstimation: DealEstimationEdit,
  dealEstimationId: number,
  dealId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':id', String(dealEstimationId))
      .replace(':deal_id', String(dealId)),
    { dealEstimation }
  );
