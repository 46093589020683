import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import Styles from './Switcher.module.scss';
import { generateKey } from '~/utils/RandomKeyGenerator';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  disabled?: boolean;
};

export const Switcher: React.FC<Props> = ({
  className = '',
  disabled = false,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...props
}: Props) => {
  const key = generateKey();
  return (
    <div className={clsx(Styles.Switcher, disabled ? Styles.Disabled : '')}>
      <input
        className={clsx(className, Styles.Input)}
        disabled={disabled}
        id={`switcher_${key}`}
        type="checkbox"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <label className={Styles.Slider} htmlFor={`switcher_${key}`} />
    </div>
  );
};
