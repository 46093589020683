import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { RiEdit2Line, RiFileCopy2Line } from 'react-icons/ri';
import { validationSchema, DealRepairParam } from '~/models/DealRepair';
import { Deal } from '~/models/Deal';
import { onInvalidScroll } from '~/utils/FormUtil';
import { isSuccess } from '~/utils/api/api_handler';
import { DealRepairCardModalForm } from './DealRepairCardModalForm';
import { update } from '~/utils/api/deals/DealRepair';
import { duplicateWithRepair } from '~/utils/api/deals/DealDuplication';
import { PATH } from '~/pages/deals/[id]';

type Props = DeepReadonly<{
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate?: (() => Promise<void>) | null;
  duplicate?: boolean;
}>;

export const DealRepairCardModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  handleMutate = null,
  duplicate = false
}: Props) => {
  const repair = deal.dealRepair;
  const router = useRouter();
  const description = duplicate
    ? '同一物件で、分離発注をする場合はこちらに入力してください。'
    : '';
  const defaultValues = React.useMemo(
    (): DealRepairParam => ({
      requestContent: repair?.requestContent ?? '',
      itemName: repair?.itemName ?? '',
      itemCategory: repair?.itemCategory ?? '',
      manufacturer: repair?.manufacturer ?? '',
      modelNumber: repair?.modelNumber ?? '',
      modelNumberFile: null,
      checkDestroy: false
    }),
    [repair]
  );
  const methods = useForm<DealRepairParam>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const handleModalSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: DealRepairParam) => {
        const response = await update(deal.id, submitValue);
        if (isSuccess(response)) {
          handleMutate && (await handleMutate());
          handleClose();
          toast.success('更新しました');
          return;
        }
        handleClose();
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, deal.id, handleClose, handleMutate]);
  const handleSubmitWithRepairDuplicate = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: DealRepairParam) => {
        const result = await duplicateWithRepair(submitValue, deal.id);
        if (isSuccess(result)) {
          const newId = result.data?.id ?? null;
          if (newId !== null) {
            await router.push(PATH(newId));
            toast.success('関連案件として追加しました');
            return;
          }
        }
        handleClose();
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, deal.id, handleClose, router]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader
        description={description}
        icon={duplicate ? RiFileCopy2Line : RiEdit2Line}
        title="修繕情報の更新"
      />
      <ModalBody>
        <FormProvider {...methods}>
          <DealRepairCardModalForm deal={deal} />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={methods.formState.isSubmitting}
            onClick={
              duplicate ? handleSubmitWithRepairDuplicate : handleModalSubmit
            }
            variant="primary"
          >
            {duplicate ? '追加' : '保存'}
          </Button>
        }
      />
    </Modalv2>
  );
};
