import useSwr from 'swr';
import { SwrReturnType } from '~/models/Api';
import { MarketInitialCompanySetting } from '~/models/CompanySetting';

const paths = {
  show: '/markets/company_settings'
};

type TShow = () => SwrReturnType<MarketInitialCompanySetting>;
type ReturnType = {
  show: TShow;
};

export const useCompanySettings = (): ReturnType => {
  const show: TShow = () => {
    const { data, error } = useSwr(paths.show);
    return {
      data: data ?? null,
      error,
      isLoading: !data && !error
    };
  };
  return { show };
};
