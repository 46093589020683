import { NskResponse } from '~/models/Api';
import { deleteRequest } from '~/utils/api/api_handler';
import { DealUploadFile } from '~/models/DealUploadFile';

const paths = {
  show: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:deal_id/deal_uploaded_files/:id`,
  destroy: '/deals/:deal_id/deal_uploaded_files/:id'
};

export const getFilePath = (dealId: number, file: DealUploadFile): string =>
  paths.show
    .replace(':deal_id', String(dealId))
    .replace(':id', String(file.id));

export const destroy = async (
  dealId: number,
  id: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy.replace(':deal_id', String(dealId)).replace(':id', String(id))
  );
