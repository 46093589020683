import useSWR from 'swr';
import { Deal, DealIndexClient, DealSearchCondition } from '~/models/Deal';
import { SwrReturnType } from '~/models/Api';
import { convertQueryStringUrl } from '~/utils/DealSearchConditionUtils';

const paths = {
  index: '/deals',
  show: '/deals/:id'
};
type TIndex = (
  query: DealSearchCondition
) => SwrReturnType<Pageable<DealIndexClient>>;
type TShow = (id: number | null) => SwrReturnType<Deal>;
type ReturnType = {
  index: TIndex;
  show: TShow;
};

export const useDeals = (): ReturnType => {
  const index: TIndex = (query: DealSearchCondition) => {
    const { data, mutate, error } = useSWR<Pageable<DealIndexClient>>(
      convertQueryStringUrl(paths.index, query)
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  const show: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<Deal>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index, show };
};
