import ApiClient from '~/utils/api/api_client';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

const paths = {
  new: '/itandi_user_sessions/new',
  create: '/itandi_user_sessions',
  destroy: '/itandi_user_sessions'
} as const;

export const getUserLoginUrl = async (): Promise<string> => {
  const { data } = await ApiClient.get<{ url: string }>(paths.new);
  return data.url;
};

export const create = async (
  params: Record<string, string | string[] | undefined>
): Promise<NskResponse> => postRequest(paths.create, params);

export const destroy = async (): Promise<NskResponse<{ url: string }>> =>
  deleteRequest(paths.destroy);
