import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { ConstructionItem } from '~/models/ConstructionItem';

const paths = {
  index: '/construction_items'
};
type TIndex = () => SwrReturnType<ConstructionItem[]>;
type ReturnType = {
  index: TIndex;
};

export const useConstructionItems = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<ConstructionItem[]>(paths.index);
    return {
      data: data ?? [],
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
