import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import Styles from './RelatedDealRow.module.scss';
import { DealWithPrices } from '~/models/Deal';
import { PATH as DEAL_PATH } from '~/pages/deals/[id]';
import { PATH as ESTIMATION_PATH } from '~/pages/deals/[id]/estimations';
import { PATH as BILLING_OWNER_PATH } from '~/pages/deals/[id]/billings/edit';
import { PATH as BILLING_RENTEE_PATH } from '~/pages/deals/[id]/billing_rentees/edit';
import { DealTypeLabel } from '~/components/common/parts/label/DealTypeLabel';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { isCanceled, PARTIALLY_FINISHED } from '~/models/Status';
import { RelatedDealRowSkLink } from '~/components/specifics/property_deals/[id]/RelatedDealRowSkLink';

type Props = {
  deal: DealWithPrices;
};

const getVendorCompanyName = (deal: DeepReadonly<DealWithPrices>): string => {
  if (deal.originDealVendorCompany === null) {
    return '施工会社未選定';
  }
  if (deal.originDealVendorCompany.vendorCompany === null) {
    return deal.originDealVendorCompany.companyVendor?.name ?? '自社管理';
  }
  return deal.originDealVendorCompany.vendorCompany.name;
};

const profitStyles = {
  positive: Styles.ProfitPositive,
  negative: Styles.ProfitNegative,
  zero: Styles.ProfitZero
};

export const RelatedDealRow: React.FC<Props> = ({ deal }: Props) => {
  const notTargetDeal =
    isCanceled(deal.status.id) || deal.status.id === PARTIALLY_FINISHED;
  const profit = React.useMemo(() => {
    if (deal.dealOwnerBillingContext.totalAmount === 0) {
      return null;
    }
    return (
      deal.dealOwnerBillingContext.sumOwnerAmount +
      deal.dealRenteeBillingContext.sumRenteeAmount -
      deal.dealEstimationContext.totalAmount
    );
  }, [deal]);
  const profitRate = React.useMemo(() => {
    if (profit === null || deal.dealOwnerBillingContext.totalAmount === 0) {
      return null;
    }
    return Math.round(
      (profit /
        (deal.dealOwnerBillingContext.sumOwnerAmount +
          deal.dealRenteeBillingContext.sumRenteeAmount)) *
        100
    );
  }, [profit, deal]);
  const profitStyle = React.useMemo(() => {
    if (profitRate === null) {
      return profitStyles.zero;
    }
    if (profitRate > 0) {
      return profitStyles.positive;
    }
    if (profitRate < 0) {
      return profitStyles.negative;
    }
    return profitStyles.zero;
  }, [profitRate]);
  return (
    <div className={Styles.DealRow}>
      <div className={Styles.DealVendorBlock}>
        <div className={Styles.DealTypeBlock}>
          <DealTypeLabel
            dealSubtype={deal.dealSubtype}
            dealType={deal.dealType}
          />
        </div>
        <h4 className={Styles.ContextLabel}>依頼会社</h4>
        <Link className={Styles.DealLink} href={DEAL_PATH(deal.id)}>
          {getVendorCompanyName(deal)}
        </Link>
      </div>
      <div className={Styles.DealStatusBlock}>
        <h4 className={Styles.ContextLabel}>現在の手続きステータス</h4>
        <span className={Styles.ContentText}>{deal.status.name}</span>
      </div>
      <div
        className={clsx(
          Styles.DealEstimationBlock,
          notTargetDeal && Styles.NotTarget
        )}
      >
        <h4 className={Styles.ContextLabel}>施工会社からの見積額</h4>
        {deal.dealEstimationContext.dealConstructions.length > 0 ? (
          <>
            <div>
              <Link
                className={Styles.ContentLink}
                href={ESTIMATION_PATH(deal.id)}
                target="_blank"
              >
                ¥{toThousandSeparator(deal.dealEstimationContext.totalAmount)}
              </Link>
            </div>
            <div className={Styles.SubContentText}>
              税込:¥
              {toThousandSeparator(
                deal.dealEstimationContext.totalAmountWithTax
              )}
            </div>
          </>
        ) : (
          <span className={clsx(Styles.ContentText, Styles.Empty)}>
            未作成
            <div className={Styles.SubContentText}>-</div>
          </span>
        )}
      </div>
      <div
        className={clsx(
          Styles.DealBillingBlock,
          notTargetDeal && Styles.NotTarget
        )}
      >
        <h4 className={Styles.ContextLabel}>入居者への請求額</h4>
        {deal.dealRenteeBillingContext.dealConstructions.length > 0 ? (
          <>
            <div>
              <Link
                className={Styles.ContentLink}
                href={BILLING_RENTEE_PATH(deal.id)}
                target="_blank"
              >
                ¥
                {toThousandSeparator(
                  deal.dealRenteeBillingContext.sumRenteeAmount
                )}
              </Link>
            </div>
            <div className={Styles.SubContentText}>
              税込:¥
              {toThousandSeparator(
                deal.dealRenteeBillingContext.sumRenteeAmountWithTax
              )}
            </div>
          </>
        ) : (
          <span className={clsx(Styles.ContentText, Styles.Empty)}>
            未作成
            <div className={Styles.SubContentText}>-</div>
          </span>
        )}
      </div>
      <div
        className={clsx(
          Styles.DealBillingBlock,
          notTargetDeal && Styles.NotTarget
        )}
      >
        <h4 className={Styles.ContextLabel}>オーナーへの請求額</h4>
        {deal.dealOwnerBillingContext.dealConstructions.length > 0 ? (
          <>
            <div>
              <Link
                className={Styles.ContentLink}
                href={BILLING_OWNER_PATH(deal.id)}
                target="_blank"
              >
                ¥
                {toThousandSeparator(
                  deal.dealOwnerBillingContext.sumOwnerAmount
                )}
              </Link>
            </div>
            <div className={Styles.SubContentText}>
              税込:¥
              {toThousandSeparator(
                deal.dealOwnerBillingContext.sumOwnerAmountWithTax
              )}
            </div>
          </>
        ) : (
          <span className={clsx(Styles.ContentText, Styles.Empty)}>
            未作成
            <div className={Styles.SubContentText}>-</div>
          </span>
        )}
      </div>
      <div
        className={clsx(Styles.ProfitBlock, notTargetDeal && Styles.NotTarget)}
      >
        <h4 className={Styles.ContextLabel}>利益額</h4>
        <div className={Styles.ProfitText}>
          <span className={profitStyle}>
            {profit === null ? '-' : `¥${toThousandSeparator(profit)}`}
          </span>
        </div>
      </div>
      <div
        className={clsx(
          Styles.ProfitRateBlock,
          notTargetDeal && Styles.NotTarget
        )}
      >
        <h4 className={Styles.ContextLabel}>利益率</h4>
        <div className={Styles.ProfitText}>
          <span className={profitStyle}>
            {profitRate === null ? '-' : toThousandSeparator(profitRate)}
          </span>
          <span className={Styles.Unit}>%</span>
        </div>
      </div>
      <RelatedDealRowSkLink deal={deal} notTargetDeal={notTargetDeal} />
    </div>
  );
};
