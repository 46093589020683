import React from 'react';
import {
  Loader,
  Text,
  Breadcrumbs,
  BreadcrumbsItem,
  Grid,
  Box
} from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useDeals } from '~/hooks/useDeals';
import { DealSearchCondition } from '~/models/Deal';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealTable } from '~/components/specifics/deals/index/table/DealTable';
import {
  buildSearchConditionByParams,
  convertQueryStringUrl
} from '~/utils/DealSearchConditionUtils';
import { PATH } from '~/pages/deals';
import { DealSearch } from '~/components/specifics/deals/index/search/DealSearch';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import { MEDIA_QUERY, useMediaQuery } from '~/hooks/useMediaQuery';

export const Page: React.FC = () => {
  const router = useRouter();
  const { match: isSp, isLoaded: windowLoaded } = useMediaQuery(MEDIA_QUERY.sp);
  const [dealSearchCondition, setDealSearchCondition] =
    React.useState<DealSearchCondition>(
      buildSearchConditionByParams(router.query)
    );
  // isLoading中用の総ページ数State
  const [totalPage, setTotalPage] = React.useState<number>(1);
  const {
    data: paginateDeal,
    mutate: mutatePaginateDeal,
    isLoading
  } = useDeals().index(dealSearchCondition);
  const { data: companyVendors, isLoading: isCompanyVendorsLoading } =
    useCompanyVendors().index();
  const handleChangeCondition = React.useCallback(
    async (newCondition: DealSearchCondition): Promise<void> => {
      setDealSearchCondition(newCondition);
      const newUrl = convertQueryStringUrl(PATH, newCondition);
      await router.replace(newUrl);
      setTotalPage(paginateDeal?.sumPageCount || totalPage);
      mutatePaginateDeal && (await mutatePaginateDeal().then());
    },
    [mutatePaginateDeal, paginateDeal, totalPage, router]
  );
  if (!windowLoaded) {
    return null;
  }
  return (
    <DefaultV2>
      <Loader isLoading={isLoading} position="screen" />
      <Box marginTop={8} marginX={16}>
        <Grid gap={8}>
          <Breadcrumbs>
            <BreadcrumbsItem isText>{`${
              dealSearchCondition?.page || 1
            }ページ`}</BreadcrumbsItem>
            <BreadcrumbsItem isText>{`${
              isLoading ? totalPage : paginateDeal?.sumPageCount
            }ページ`}</BreadcrumbsItem>
          </Breadcrumbs>
          <Text color="Default" size="XL" weight="Bold">
            案件一覧
          </Text>
          {companyVendors !== null && !isCompanyVendorsLoading && (
            <DealSearch
              companyVendors={companyVendors}
              dealSearchCondition={dealSearchCondition}
              handleChangeCondition={handleChangeCondition}
              isSp={isSp}
            />
          )}
          {paginateDeal !== null && (
            <DealTable
              deals={paginateDeal.objects}
              dealSearchCondition={dealSearchCondition}
              handleChangeCondition={handleChangeCondition}
              isSp={isSp}
              total={paginateDeal.count}
            />
          )}
        </Grid>
      </Box>
    </DefaultV2>
  );
};
