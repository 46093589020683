import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { RiUploadCloud2Line } from 'react-icons/ri';
import Styles from './UploadPanel.module.scss';

type Props = DropzoneOptions;

export const UploadPanel: React.FC<Props> = (options: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);
  return (
    <div
      className={clsx(Styles.Panel, {
        [Styles.DropActive]: isDragActive
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={Styles.IconWrapper}>
        <RiUploadCloud2Line className={Styles.Icon} />
      </div>
      <p className={Styles.Guide}>
        {isDragActive ? 'ファイルをアップロード' : 'ファイルを選択してください'}
      </p>
    </div>
  );
};
