import React from 'react';
import Link from 'next/link';
import { ImportLog, importStatusLabels } from '~/models/ImportLog';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { formatDate } from '~/utils/DateUtil';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { PATH } from '~/pages/settings/imports/[importType]/[id]/import_log_errors';
import Styles from './Page.module.scss';

type Props = DeepReadonly<{
  importLogs: ImportLog[];
}>;

export const ImportLogTable: React.FC<Props> = ({ importLogs }: Props) => (
  <Table>
    <TableHeader>
      <th>ステータス</th>
      <th>行数</th>
      <th>取込件数</th>
      <th>エラー件数</th>
      <th>取込日時</th>
    </TableHeader>
    <TableBody>
      {importLogs.map((importLog) => (
        <TableRow key={importLog.id}>
          <td>{importStatusLabels[importLog.status]}</td>
          <td>{importLog.rowCount}</td>
          <td>{importLog.importCount}</td>
          <td>
            <Link
              className={Styles.Link}
              href={PATH(importLog.importType, importLog.id)}
            >
              {importLog.errorCount}
            </Link>
          </td>
          <td>{formatDate(importLog.createdAt, true)}</td>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
