import React from 'react';
import { NextPage } from 'next';
import { NskHead } from '~/components/common/head/NskHead';
import { Page } from '~/containers/specifics/admin/Page';

export const PATH = '/admin';

const Admin: NextPage = () => (
  <>
    <NskHead title="Admin" />
    <Page />
  </>
);

export default Admin;
