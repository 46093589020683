import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/vendors/deals/:deal_id/messages',
  create_file: '/vendors/deals/:deal_id/messages/create_file',
  read: '/vendors/deals/:deal_id/messages/read',
  show_image: `${process.env.NEXT_PUBLIC_API_HOST}/api/vendors/deals/:deal_id/messages/:id/show_image`
};

export const create = async (
  dealId: number,
  text: string
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    text
  });

export const createFile = async (
  dealId: number,
  file: File
): Promise<NskResponse<void>> => {
  const formData = new FormData();
  formData.append('file', file);
  return postRequest(
    paths.create_file.replace(':deal_id', String(dealId)),
    formData
  );
};

export const postRead = async (dealId: number): Promise<NskResponse<void>> =>
  postRequest(paths.read.replace(':deal_id', String(dealId)));

export const getShowImagePath = (dealId: number, messageId: number): string =>
  paths.show_image
    .replace(':deal_id', String(dealId))
    .replace(':id', String(messageId));
