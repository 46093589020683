import React from 'react';
import Styles from './Page.module.scss';
import { AdminLayout } from '~/components/common/layouts/AdminLayout';

type Props = {
  message?: string;
};

export const Page: React.FC<Props> = ({ message }: Props) => (
  <AdminLayout>
    <div className={Styles.ErrorContainer}>
      <h1 className={Styles.ErrorTitle}>権限がありません</h1>
      <p className={Styles.ErrorMessage}>{message || ''}</p>
    </div>
  </AdminLayout>
);
