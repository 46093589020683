import React, { ReactNode } from 'react';
import Styles from './DealLayoutFooter.module.scss';

type Props = {
  children: ReactNode;
};

/**
 * DealLayoutで追従式のFooterを提供する。
 * メイン要素の高さが足りない場合にbottom: 0で固定する場合は各自min-heightを確保する必要がある
 *
 * @param children
 * @constructor
 * @for vendor, client
 */
export const DealLayoutFooter: React.FC<Props> = ({ children }: Props) => (
  <div className={Styles.DealLayoutFooter}>{children}</div>
);
