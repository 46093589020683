import { captureException } from '@sentry/nextjs';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ApiClient from '~/utils/api/api_client';
import { NskResponse, NskResponseFail, NskResponseSuccess } from '~/models/Api';

type ApiResponse<T = null> = {
  code?: number;
  message?: string;
  data: T;
};

export const isSuccess = (
  response: NskResponse<unknown>
): response is NskResponseSuccess<unknown> => response.type === 'success';

const parseSuccess = <T>(
  response: AxiosResponse<T>
): NskResponseSuccess<T> => ({
  type: 'success',
  code: response.status ?? 204,
  data: response.data ?? null
});
const parseError = <E>(err: unknown): NskResponseFail<E> => {
  if (axios.isAxiosError(err)) {
    const axiosError: AxiosError<ApiResponse<E>> = err;
    if (axiosError?.response?.data?.message == null) {
      // 予期せぬエラー
      captureException(err);
    }
    return {
      type: 'fail',
      code: axiosError?.response?.data?.code ?? null,
      message: axiosError?.response?.data?.message ?? null,
      data: axiosError?.response?.data?.data ?? null
    };
  }
  throw err;
};

export const postRequest = async <T, E>(
  path: string,
  params?: Record<string, unknown> | FormData
): Promise<NskResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.post(path, params));
  } catch (err) {
    return parseError<E>(err);
  }
};

export const putRequest = async <T, E>(
  path: string,
  params?: Record<string, unknown> | FormData
): Promise<NskResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.put(path, params));
  } catch (err) {
    return parseError<E>(err);
  }
};

export const deleteRequest = async <T, E>(
  path: string,
  params?: Record<string, unknown>
): Promise<NskResponse<T, E>> => {
  try {
    return parseSuccess<T>(await ApiClient.delete(path, params));
  } catch (err) {
    return parseError<E>(err);
  }
};
