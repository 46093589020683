import React, { FC } from 'react';
import Styles from './VendorWithoutAuthHeader.module.scss';

export const VendorWithoutAuthHeader: FC = () => (
  <header className={Styles.BBHeader} id="header">
    <nav className={Styles.FirstNav}>
      <span className={Styles.ItandiBBLogo}>
        <img
          alt="ITANDI BB"
          className={Styles.ItandiBBLogo}
          src="/itandi_bb_plus_NSK.svg"
        />
      </span>
    </nav>
  </header>
);
