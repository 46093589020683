import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { TodoTemplate } from '~/models/TodoTemplate';

const paths = {
  index: '/todo_templates'
};

type TIndex = () => SwrReturnType<TodoTemplate[]>;
type ReturnType = {
  useIndex: TIndex;
};

export const useTodoTemplates = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error } = useSWR<TodoTemplate[]>(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex };
};
