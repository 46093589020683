import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';
import { ImportType } from '~/models/ImportLog';

const paths = {
  create: '/admin/import_logs'
} as const;

export const create = async (
  importFile: File,
  importType: ImportType
): Promise<NskResponse> => {
  const params = new FormData();
  params.append('import_log[import_file]', importFile);
  params.append('import_log[import_type]', importType);
  return postRequest(paths.create, params);
};
