import { NskResponse } from '~/models/Api';
import { putRequest } from '../api_handler';
import { DealConstructionScheduleEdit } from '~/models/DealConstructionSchedule';

const paths = {
  update: '/deals/:deal_id/deal_construction_schedules'
};

export const update = async (
  dealId: number,
  schedules: DealConstructionScheduleEdit[]
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':deal_id', String(dealId)), {
    dealConstructionSchedules: schedules
  });
