import React from 'react';
import { useRouter } from 'next/router';
import { useDealInspectionReportImages } from '~/hooks/vendors/deals/useDealInspectionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH } from '~/pages/vendors/deals/[id]/inspection_reports';

export const InspectionReportImageVendorPanel: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  const reportsApi = useDealInspectionReportImages();
  const { data: reportImages } = reportsApi.index(Number(id));
  return (
    <div>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="詳細画面"
          detailPagePath={PATH(Number(id))}
          reportImages={reportImages}
          title="立会報告画像"
        />
      )}
    </div>
  );
};
