import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  initialValue: string;
  handleClose: () => void;
  handleSubmit: (submitValue: string) => void | Promise<void>;
  as: 'input' | 'textarea';
};

export const HideInputInnerForm: React.FC<Props> = ({
  initialValue,
  handleClose,
  handleSubmit,
  as
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [value, setValue] = React.useState<string>(initialValue);
  React.useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  const onSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    await handleSubmit(value);
    handleClose();
  };
  const onKeyDown = async (e: React.KeyboardEvent): Promise<void> => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };
  const onBlur = async (): Promise<void> => {
    await handleSubmit(value);
    handleClose();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };
  return (
    <Form onSubmit={onSubmit}>
      <Form.Control
        ref={inputRef}
        as={as}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value ?? ''}
      />
    </Form>
  );
};
