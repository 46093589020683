import React, { FC } from 'react';
import Styles from './ErrorMessage.module.scss';

type Props = {
  message?: string;
};
export const ErrorMessage: FC<Props> = ({ message = '' }: Props) => (
  <div className={Styles.ErrorContainer}>
    <h2 className={Styles.ErrorTitle}>エラーが発生しました</h2>
    <div className={Styles.ErrorMessage}>{message}</div>
  </div>
);
