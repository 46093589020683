import React, { FormEvent, ReactNode } from 'react';
import {
  ArrayHelpers,
  FieldArray,
  Formik,
  FormikHelpers,
  FormikProps
} from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Button,
  ModalHeader,
  Modalv2,
  ModalBody,
  ModalFooter
} from '@itandi/itandi-bb-ui';
import { RiAddBoxLine } from 'react-icons/ri';
import { CompanyStoreMailModalRow } from './CompanyStoreMailModalRow';
import { create as createMails } from '~/utils/api/CompanyStoreMails';
import { isSuccess } from '~/utils/api/api_handler';
import {
  MailsCreateParams,
  MailCreateParam,
  validationSchemaCompanyStoreMail
} from '~/models/CompanyStoreMail';
import { useCompanyStoreMails } from '~/hooks/useCompanyStoreMails';
import { generateKey } from '~/utils/RandomKeyGenerator';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const CompanyStoreMailModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  const { mutate: mutateMails } = useCompanyStoreMails().index();
  const keyId = React.useMemo(() => generateKey(), []);
  const handleSave = async (
    value: MailsCreateParams,
    { resetForm }: FormikHelpers<MailsCreateParams>
  ): Promise<void> => {
    const result = await createMails(value);
    if (isSuccess(result)) {
      mutateMails && (await mutateMails());
      toast.success('登録しました');
      handleClose();
      resetForm();
      return;
    }
    toast.error('エラーが発生しました');
  };
  return (
    <Formik
      initialValues={{ companyStoreMails: [{ key: keyId, email: '' }] }}
      onSubmit={handleSave}
      validationSchema={validationSchemaCompanyStoreMail}
    >
      {({
        values,
        errors,
        handleChange,
        handleReset,
        handleSubmit,
        touched,
        handleBlur
      }: FormikProps<MailsCreateParams>): ReactNode => (
        <Modalv2
          handleClose={(): void => {
            handleClose();
            handleReset();
          }}
          isOpen={isOpen}
        >
          <ModalHeader icon={RiAddBoxLine} title="メールアドレス追加" />
          <ModalBody>
            <Form
              onSubmit={(e: FormEvent<HTMLFormElement>): void => {
                e.preventDefault();
              }}
            >
              <FieldArray name="companyStoreMails">
                {({ push, remove }: ArrayHelpers): ReactNode => (
                  <div>
                    {values.companyStoreMails.map(
                      (mail: MailCreateParam, index: number) => {
                        const mailErrors =
                          errors.companyStoreMails != null
                            ? errors.companyStoreMails[index]
                            : null;
                        return (
                          <CompanyStoreMailModalRow
                            key={mail.key}
                            errors={mailErrors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            index={index}
                            remove={remove}
                            touched={touched}
                            values={values}
                          />
                        );
                      }
                    )}
                    <Button
                      height="small"
                      onClick={(e: React.MouseEvent): void => {
                        e.preventDefault();
                        const key = generateKey();
                        push({ key, email: '' });
                      }}
                      variant="secondary"
                    >
                      追加
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Form>
          </ModalBody>
          <ModalFooter
            leftArea={
              <Button onClick={handleClose} variant="secondary">
                保存せず閉じる
              </Button>
            }
            rightArea={
              <Button
                onClick={async (): Promise<void> => handleSubmit()}
                variant="primary"
              >
                保存
              </Button>
            }
          />
        </Modalv2>
      )}
    </Formik>
  );
};
