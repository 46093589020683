import React from 'react';
import Styles from './RelatedDealRow.module.scss';
import { DealWithPrices } from '~/models/Deal';
import { useUsingApplications } from '~/hooks/useUsingApplications';

type Props = {
  targetDeals: DealWithPrices[];
};

export const RelatedDealTotalRowSkLink: React.FC<Props> = ({
  targetDeals
}: Props) => {
  const { useSK } = useUsingApplications();
  if (!useSK) {
    // SKを使用していない場合は表示しない
    return null;
  }
  const linkedToSk = targetDeals.every(
    (deal) => deal.skPartnerDeal?.linked === true
  );
  return (
    <div className={Styles.SkLinkBlock}>
      {linkedToSk ? '連携済' : '一部未連携'}
    </div>
  );
};
