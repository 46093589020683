import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import Styles from './RoomSelectPage.module.scss';
import { useRooms } from '~/hooks/kensaku_kun/useRooms';
import Loading from '~/components/common/parts/loading/Loading';
import { Property } from '~/models/kensaku_kun/property';
import { RoomSelectRow } from '~/components/common/kensaku_kun/PropertySelectModal/RoomSelectRow';

type Props = {
  selectBuilding: Property<'building'>;
  handleBackToBuilding: () => void;
  handleSelect: (property: Property<'room' | 'building'>) => void;
};

export const RoomSelectPage: React.FC<Props> = ({
  selectBuilding,
  handleBackToBuilding,
  handleSelect
}: Props) => {
  const [selectedRoom, setSelectedRoom] =
    React.useState<Property<'room'> | null>(null);
  const { data: rooms } = useRooms().index(selectBuilding?.propertyId);
  const handleSelectRoom = React.useCallback((room: Property<'room'>) => {
    setSelectedRoom(room);
  }, []);
  const onClickBack = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      handleBackToBuilding();
    },
    [handleBackToBuilding]
  );
  const renderRow = React.useCallback(
    (room: Property<'room'>) => (
      <RoomSelectRow
        key={room.propertyId}
        handleSelectRoom={handleSelectRoom}
        isSelected={room.propertyId === selectedRoom?.propertyId}
        room={room}
      />
    ),
    [handleSelectRoom, selectedRoom]
  );
  const onClickBuilding = React.useCallback(() => {
    if (selectBuilding !== null) {
      handleSelect(selectBuilding);
    }
  }, [handleSelect, selectBuilding]);
  const onClickRoom = React.useCallback(() => {
    if (selectedRoom !== null) {
      handleSelect(selectedRoom);
    }
  }, [selectedRoom, handleSelect]);
  if (rooms === null) {
    return <Loading isLoading />;
  }
  return (
    <div>
      <a className={Styles.BackLink} href="#" onClick={onClickBack}>
        棟選択に戻る
      </a>
      <p className={Styles.Description}>部屋を選択してください</p>
      <div className={Styles.RoomList}>
        {rooms.length === 0 && (
          <p className={Styles.Empty}>部屋が登録されていません</p>
        )}
        {rooms.map(renderRow)}
      </div>
      <div className={Styles.ButtonArea}>
        <div className={Styles.Buttons}>
          <Button onClick={onClickBuilding} variant="secondary">
            棟として選択
          </Button>
          <Button
            disabled={selectedRoom === null}
            onClick={onClickRoom}
            variant="primary"
          >
            部屋を選択
          </Button>
        </div>
      </div>
    </div>
  );
};
