import { LoginParams } from '~/models/Auth';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

const paths = {
  create: '/vendors/sessions/vendor_companies',
  destroy: '/vendors/sessions/vendor_companies/logout'
};

export const create = async (params: LoginParams): Promise<NskResponse<void>> =>
  postRequest(paths.create, params);

export const destroy = async (): Promise<NskResponse<void>> =>
  deleteRequest(paths.destroy);
