import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './StatusBar.module.scss';
import { Status } from '~/models/Status';

type Props = {
  statuses: Status[];
  currentId: number | null;
};

export const StatusBar: React.FC<Props> = ({ statuses, currentId }: Props) => {
  const displayStatuses = statuses.filter((s: Status) => s.statusBarDisplay);
  const renderStatus = (status: Status, index: number): ReactNode => (
    <div
      key={status.id}
      className={clsx(
        Styles.Status,
        status.id === currentId ? Styles.Active : '',
        index === 0 ? Styles.First : '',
        index === displayStatuses.length - 1 ? Styles.Last : ''
      )}
    >
      {status.name}
    </div>
  );
  return (
    <div className={Styles.StatusBar}>{displayStatuses.map(renderStatus)}</div>
  );
};
