import React from 'react';
import Styles from './ConstructionList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';

type Props = {
  fee: number;
  showSwitchEnable: boolean;
  feeTitle: string;
};

export const FeeRow: React.FC<Props> = ({
  fee,
  showSwitchEnable,
  feeTitle
}: Props) => (
  <tr className={Styles.ListRow}>
    <td className={Styles.OrderHandle} />
    {showSwitchEnable && <td className={Styles.SwitchEnable} />}
    <td className={Styles.Estimation}>
      <div className={Styles.Point}>{feeTitle}</div>
    </td>
    <td className={Styles.UnitAmount} />
    <td className={Styles.ChargePer} />
    <td className={Styles.Amount}>¥{toThousandSeparator(fee)}</td>
    <td className={Styles.Buttons} />
  </tr>
);
