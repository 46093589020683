import React from 'react';
import { NextPage } from 'next';
import { NskHead } from '~/components/common/head/NskHead';
import { Page } from '~/containers/specifics/admin/vendor_companies/Page';

export const PATH = '/admin/vendor_companies';

const VendorCompanies: NextPage = () => (
  <>
    <NskHead title="Admin" />
    <Page />
  </>
);

export default VendorCompanies;
