import { CompanySetting } from '~/models/CompanySetting';
import { deleteRequest, putRequest } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

const paths = {
  update: '/admin/company_settings/:id',
  destroy: '/admin/company_settings/:id'
};

export const update = async (
  companySetting: CompanySetting
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update.replace(':id', String(companySetting.companyMasterId)),
    { companySetting }
  );

export const destroy = async (
  companySetting: CompanySetting
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy.replace(':id', String(companySetting.companyMasterId))
  );
