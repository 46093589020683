import React from 'react';
import { useDeals } from '~/hooks/vendors/useDeals';
import {
  DealUploadFile,
  DealUploadFileUpdateParam
} from '~/models/DealUploadFile';
import {
  destroy as destroyFile,
  getFilePath as getVendorFilePath
} from '~/utils/api/vendors/deals/DealUploadedFile';
import { DealFileCard } from '~/components/common/deals/show/files/DealFileCard';
import { NskResponse } from '~/models/Api';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { create as postReceiptFile } from '~/utils/api/vendors/deals/DealReceiptFile';
import {
  create as postOtherFile,
  update as putOtherFile
} from '~/utils/api/vendors/deals/DealOtherFile';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { LeaseContractFileCard } from '~/components/common/deals/show/files/LeaseContractFileCard';
import { getLeaseContractFilePath } from '~/utils/api/vendors/Deal';

export const DealFileListVendor: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(Number(id));
  const { data: session } = useSessions().index();
  if (deal === null || session === null) {
    return null;
  }
  const handleGetFilePath = (file: DealUploadFile): string =>
    getVendorFilePath(deal.id, file);
  const handleMutate = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const handleDestroyFile = async (
    file: DealUploadFile
  ): Promise<NskResponse<void>> => destroyFile(deal.id, file.id);
  const handleUploadReceipt = async (file: File): Promise<NskResponse<void>> =>
    postReceiptFile(file, deal.id);
  const handleUploadOther = async (file: File): Promise<NskResponse<void>> =>
    postOtherFile(file, deal.id);
  const handleUpdateOther = async (
    dealOtherParam: DealUploadFileUpdateParam
  ): Promise<NskResponse<void>> => putOtherFile(dealOtherParam, deal.id);
  return (
    <>
      {getOriginDealVendor(deal)?.showTenant && (
        <LeaseContractFileCard
          deal={deal}
          handleGetFile={getLeaseContractFilePath}
          handleMutate={handleMutate}
          isVendor
        />
      )}
      {getOriginDealVendor(deal)?.showTenant && (
        <DealFileCard
          deal={deal}
          files={deal.dealMemorandumFiles}
          fileType="賃借人同意書"
          handleGetFilePath={handleGetFilePath}
          handleMutate={handleMutate}
          isVendor
          vendorSession={session}
        />
      )}
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <DealFileCard
          deal={deal}
          files={deal.dealReceiptFiles}
          fileType="工事発注書・請書"
          handleDestroy={handleDestroyFile}
          handleGetFilePath={handleGetFilePath}
          handleMutate={handleMutate}
          handleUpload={handleUploadReceipt}
          isVendor
          vendorSession={session}
        />
      )}
      <DealFileCard
        deal={deal}
        description="管理会社とファイルが共有されます"
        files={deal.dealOtherFiles}
        fileType="その他ファイル"
        handleDestroy={handleDestroyFile}
        handleGetFilePath={handleGetFilePath}
        handleMutate={handleMutate}
        handleUpdate={handleUpdateOther}
        handleUpload={handleUploadOther}
        isVendor
        vendorSession={session}
      />
    </>
  );
};
