import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { ImportType } from '~/models/ImportLog';
import { useImportConversions } from '~/hooks/admin/useImportConversions';
import Styles from './Page.module.scss';
import { PATH } from '~/pages/settings/imports/[importType]/import_conversions';

type Props = DeepReadonly<{
  importType: ImportType;
}>;

export const ImportConversionPanel: React.FC<Props> = ({
  importType
}: Props) => {
  const router = useRouter();
  const { data: importConversions } =
    useImportConversions().useIndex(importType);
  const onClick = React.useCallback(() => {
    router.push(PATH(importType));
  }, [importType, router]);
  if (importConversions === null) {
    return null;
  }
  return (
    <div className={Styles.ConversionPanelWrapper}>
      <h3 className={Styles.SubTitle}>取込変換設定</h3>
      <Button height="small" onClick={onClick}>
        取込変換設定を表示
      </Button>
    </div>
  );
};
