import React from 'react';
import { useRouter } from 'next/router';
import Styles from './statuses.module.scss';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { FINISHED, IN_CONSTRUCTION } from '~/models/Status';
import { useSessions } from '~/hooks/vendors/useSessions';
import { isOriginVendor } from '~/models/DealVendorCompany';
import { useDeals } from '~/hooks/vendors/useDeals';

export const AwaitingAcceptance: React.FC = () => {
  const router = useRouter();
  const { id: dealId = null } = router.query;
  const { data: deal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  const { data: session } = useSessions().index();
  if (deal === null || session === null) {
    return null;
  }
  return (
    <div>
      <h3 className={Styles.Title}>完了報告を送信しました。</h3>
      <p>検収完了をお待ち下さい</p>
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <StatusHandleVendorButtons
          forwardDisabled
          nextStatusId={FINISHED}
          prevStatusId={IN_CONSTRUCTION}
        />
      )}
    </div>
  );
};
