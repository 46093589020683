import React, { ReactNode } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  ModalBody,
  ModalHeader,
  Modalv2,
  ModalFooter
} from '@itandi/itandi-bb-ui';
import { RiMailOpenLine } from 'react-icons/ri';
import { create as createPayload } from '~/utils/api/CompanyVendorPayload';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import {
  CompanyVendorPayloadCreateParam,
  validationSchemaPayload
} from '~/models/CompanyVendorPayload';
import { isSuccess } from '~/utils/api/api_handler';
import { CompanyVendor } from '~/models/CompanyVendor';
import Styles from './CompanyVendorList.module.scss';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  companyVendor: CompanyVendor;
};

export const InviteCompanyVendorModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  companyVendor
}: Props) => {
  const { mutate: mutateCompanyVendors } = useCompanyVendors().index();
  const initialValue: CompanyVendorPayloadCreateParam = {
    companyVendorId: companyVendor.id,
    email: ''
  };
  const handleSave = async (
    submitValue: CompanyVendorPayloadCreateParam
  ): Promise<void> => {
    const result = await createPayload(submitValue, companyVendor.id);
    if (isSuccess(result)) {
      mutateCompanyVendors && (await mutateCompanyVendors());
      handleClose();
      toast.success('招待しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  return (
    <Formik
      initialValues={initialValue}
      onSubmit={handleSave}
      validationSchema={validationSchemaPayload}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        handleSubmit,
        touched,
        isSubmitting
      }: FormikProps<CompanyVendorPayloadCreateParam>): ReactNode => (
        <Modalv2 handleClose={handleClose} isOpen={isOpen}>
          <ModalHeader icon={RiMailOpenLine} title="施工会社を招待" />
          <ModalBody>
            <div>
              <div className={Styles.Invite}>
                <p className={Styles.inviteDescription}>
                  連携すると、確認写真や見積書、チャットによるコミュニケーションをシステム上で行なえます。
                </p>
              </div>
              <Alert variant="info">
                システム連携には、施工会社様によるアカウント作成が必要です。（招待メールが送られます。）
              </Alert>
              <FormGroup>
                <FormLabel>招待先の代表者メールアドレス</FormLabel>
                <FormControl
                  isInvalid={!!(touched.email && errors.email)}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter
            leftArea={
              <Button onClick={handleClose} variant="secondary">
                保存せず閉じる
              </Button>
            }
            rightArea={
              <Button
                disabled={isSubmitting}
                onClick={(): void => {
                  handleSubmit();
                }}
                variant="primary"
              >
                招待
              </Button>
            }
          />
        </Modalv2>
      )}
    </Formik>
  );
};
