import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/vendors/deals/:deal_id/deal_receipt_files'
};

export const create = async (
  file: File | null,
  dealId: number
): Promise<NskResponse<void>> => {
  const params = new FormData();
  if (file !== null) params.append('file', file);
  return postRequest(paths.create.replace(':deal_id', String(dealId)), params);
};
