import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import Styles from './Page.module.scss';
import { CompanyForm } from '~/components/specifics/markets/company_settings/new/forms/CompanyForm';
import { StaffForm } from '~/components/specifics/markets/company_settings/new/forms/StaffForm';
import { create as postRegistration } from '~/utils/api/markets/CompanySetting';
import { isSuccess } from '~/utils/api/api_handler';
import { MarketCompanySetting } from '~/models/CompanySetting';
import useLoader from '~/hooks/useLoader';
import { PlanForm } from '~/components/specifics/markets/company_settings/new/forms/PlanForm';
import { CompanyStoreForm } from './forms/CompanyStoreForm';

type Props = DeepReadonly<{
  handleNextPage: () => void;
  handlePrevPage: () => void;
}>;

export const FormConfirmPage: React.FC<Props> = ({
  handleNextPage,
  handlePrevPage
}: Props) => {
  const { Loader, showLoader, hideLoader } = useLoader();
  const { getValues } = useFormContext<MarketCompanySetting>();
  const handleSubmit = React.useCallback(async () => {
    showLoader();
    const response = await postRegistration(getValues());
    hideLoader();
    if (isSuccess(response)) {
      handleNextPage();
      return;
    }
    toast.error(`エラーが発生しました。${response.message}`);
  }, [handleNextPage, getValues, hideLoader, showLoader]);
  return (
    <div className={Styles.InnerPageContainer}>
      <Loader />
      <PlanForm isConfirm />
      <CompanyForm isConfirm />
      <CompanyStoreForm isConfirm />
      <StaffForm isConfirm />
      <div className={Styles.TwoButtonsArea}>
        <div>
          <Button onClick={handlePrevPage} variant="secondary" width="fill">
            戻る
          </Button>
        </div>
        <div>
          <Button onClick={handleSubmit} variant="primary" width="fill">
            送信
          </Button>
        </div>
      </div>
    </div>
  );
};
