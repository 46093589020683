import React from 'react';
import {
  AWAITING_ACCEPTANCE,
  CANCELED,
  FINISHED,
  IN_CONSTRUCTION,
  IN_ESTIMATION,
  NOW_ACCEPTING,
  PARTIALLY_FINISHED,
  SEND_ESTIMATE
} from '~/models/Status';
import { InEstimation } from '~/components/specifics/vendors/deals/[id]/statuses/InEstimation';
import { NowAccepting } from '~/components/specifics/vendors/deals/[id]/statuses/NowAccepting';
import { SendEstimate } from '~/components/specifics/vendors/deals/[id]/statuses/SendEstimate';
import { InConstruction } from '~/components/specifics/vendors/deals/[id]/statuses/InConstruction';
import { AwaitingAcceptance } from '~/components/specifics/vendors/deals/[id]/statuses/AwaitingAcceptance';
import { Finished } from '~/components/specifics/vendors/deals/[id]/statuses/Finished';
import { Canceled } from '~/components/specifics/vendors/deals/[id]/statuses/Canceled';
import { PartiallyFinished } from '~/components/specifics/vendors/deals/[id]/statuses/PartiallyFinished';

type Props = {
  statusId: number;
};
type ComponentsType = {
  [key: number]: React.FC;
};

export const StatusSwitcher: React.FC<Props> = ({ statusId }: Props) => {
  const Components: ComponentsType = {
    [NOW_ACCEPTING]: NowAccepting,
    [IN_ESTIMATION]: InEstimation,
    [SEND_ESTIMATE]: SendEstimate,
    [IN_CONSTRUCTION]: InConstruction,
    [AWAITING_ACCEPTANCE]: AwaitingAcceptance,
    [PARTIALLY_FINISHED]: PartiallyFinished,
    [FINISHED]: Finished,
    [CANCELED]: Canceled
  };
  const Component = Components[statusId] ?? null;
  if (Component === null) {
    throw new Error(`Invalid deal status. statusId: ${statusId}`);
  }
  return <Component />;
};
