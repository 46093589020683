import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/settings/company_store_mails/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/settings/company_store_mails';

const Home: NextPage = () => (
  <>
    <NskHead title="通知先メールアドレス設定" />
    <Component />
  </>
);

export default Home;
