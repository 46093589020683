import React from 'react';
import Styles from './DealMainWorkflow.module.scss';
import { StatusBar } from '~/components/common/parts/status/StatusBar';
import { useStatuses } from '~/hooks/useStatuses';
import { StatusSwitcher } from '~/components/specifics/deals/[id]/statuses/StatusSwitcher';
import { DealCancelButtonClient } from '~/components/common/deals/show/main/DealCancelButtonClient';
import { isWithVendorDeal } from '~/models/Deal';
import {
  getOwnStatuses,
  getWithVendorStatuses,
  isCancelApplying,
  isCanceled,
  isCompleted,
  NOW_ACCEPTING
} from '~/models/Status';
import { DealMainWorkflowClientCancel } from '~/components/common/deals/show/main/DealMainWorkflowClientCancel';
import { useDeals } from '~/hooks/useDeals';
import { getOriginDealVendor } from '~/models/DealVendorCompany';
import { DealClientTodoTable } from '~/components/common/deals/show/main/todos/DealClientTodoTable';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { DealSurveyAnswers } from '~/components/specifics/deals/[id]/deal_survey/Answers';
import { useDealSurveyAnswers } from '~/hooks/deals/useDealSurveyAnswers';
import { CompanyVendorSelector } from '~/components/common/company_vendors/CompanyVendorSelector';

export const DealMainWorkflowClient: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: statuses } = useStatuses().index();
  const { data: answers } = useDealSurveyAnswers().index(id);
  if (statuses === null || deal === null) {
    return null;
  }
  if (isCancelApplying(deal)) {
    return <DealMainWorkflowClientCancel />;
  }
  const originVendor = getOriginDealVendor(deal);
  const displayStatuses = isWithVendorDeal(deal)
    ? getWithVendorStatuses(statuses)
    : getOwnStatuses(statuses);
  return (
    <div>
      {!isCanceled(deal.status) && (
        <div className={Styles.StatusCard}>
          <h2 className={Styles.Header}>
            {originVendor === null ? (
              <span className={Styles.NotYet}>
                まだ施工会社が選択されていません
              </span>
            ) : (
              <span>
                {originVendor?.companyVendor?.name}
                {!isCompleted(deal.status) && 'に依頼中'}
              </span>
            )}
            <DealCancelButtonClient />
          </h2>
          <StatusBar currentId={deal.status} statuses={displayStatuses} />
        </div>
      )}
      <div className={Styles.TodoCard}>
        <h3 className={Styles.TodoCardTitle}>TODO</h3>
        <div className={Styles.TodoCardContents}>
          <DealClientTodoTable />
        </div>
      </div>
      {answers !== null && answers.length !== 0 && (
        <div className={Styles.SurveyAnswerCard}>
          <DealSurveyAnswers answers={answers} />
        </div>
      )}
      {deal.status === NOW_ACCEPTING && (
        <div className={Styles.SelectDealVendorCompanyCard}>
          <div>
            <h3 className={Styles.Header}>
              工事を依頼する施工会社を選択してください
            </h3>
            <CompanyVendorSelector />
          </div>
        </div>
      )}
      <div className={Styles.WorkflowCard}>
        <StatusSwitcher statusId={deal.status} />
      </div>
    </div>
  );
};
