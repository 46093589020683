import React from 'react';
import { toast } from 'react-toastify';
import Styles from './DealCancelButton.module.scss';
import { Button } from '~/components/common/parts/button/Button';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { create as postCancel } from '~/utils/api/deals/DealCancel';
import { isSuccess } from '~/utils/api/api_handler';
import { DealCancelApplyModal } from '~/components/common/deals/show/main/DealCancelApplyModal';
import useItandiAccount from '~/hooks/useItandiAccount';
import { useDeals } from '~/hooks/useDeals';
import { canOperatingDeal } from '~/models/Staff';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const DealCancelButtonClient: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data } = useItandiAccount().currentAccount();
  if (deal === null || data === null) {
    return null;
  }
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const handleAccept = async (reason: string): Promise<void> => {
    const result = await postCancel(deal.id, reason);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      toast.success('案件を取り下げました');
      handleClose();
      return;
    }
    toast.error(result.message ?? 'エラーが発生しました。');
  };
  return (
    <span className={Styles.CancelButtonWrapper}>
      <Button
        className={Styles.CancelButton}
        disabled={!canOperatingDeal(data)}
        onClick={handleOpen}
        variant="link"
      >
        案件の取り下げ
      </Button>
      {isOpen && (
        <DealCancelApplyModal
          deal={deal}
          handleAccept={handleAccept}
          handleClose={handleClose}
        />
      )}
    </span>
  );
};
