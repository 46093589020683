import React from 'react';
import Styles from './DealMain.module.scss';
import { DealTabs } from '~/components/common/deals/show/main/DealTabs';
import { EstimationVendorPanel } from '~/components/specifics/deals/parts/EstimationVendorPanel';
import { EstimationClientPanel } from '~/components/specifics/deals/parts/EstimationClientPanel';
import { InspectionReportImageVendorPanel } from '~/components/specifics/deals/parts/InspectionReportImageVendorPanel';
import { InspectionReportImageClientPanel } from '~/components/specifics/deals/parts/InspectionReportImageClientPanel';
import { CompletionReportImageVendorPanel } from '~/components/specifics/deals/parts/CompletionReportImageVendorPanel';
import { CompletionReportImageClientPanel } from '~/components/specifics/deals/parts/CompletionReportImageClientPanel';
import { Deal, matchDealType } from '~/models/Deal';
import { DealMainWorkflowClient } from '~/components/common/deals/show/main/DealMainWorkflowClient';
import { DealMainWorkflowVendor } from '~/components/common/deals/show/main/DealMainWorkflowVendor';
import { DealFileListVendor } from '~/components/common/deals/show/files/DealFileListVendor';
import { DealFileListClient } from '~/components/common/deals/show/files/DealFileListClient';
import { DealMainSpLinksVendor } from '~/components/common/deals/show/main/DealMainSpLinksVendor';
import { DealMainSpLinksClient } from '~/components/common/deals/show/main/DealMainSpLinksClient';
import { IN_CONSTRUCTION } from '~/models/Status';
import { DealHistoryClientList } from '~/components/common/deals/show/main/histories/DealHistoryClientList';
import { DealHistoryVendorList } from '~/components/common/deals/show/main/histories/DealHistoryVendorList';

type Props = {
  isVendor: boolean;
  deal: Deal;
};

export type TabType =
  | 'workflow'
  | 'reports'
  | 'estimation'
  | 'histories'
  | 'files';

export const DealMain: React.FC<Props> = ({ isVendor, deal }: Props) => {
  const [tabType, setTabType] = React.useState<TabType>('workflow');
  const showCompleteImagePanel =
    deal.status >= IN_CONSTRUCTION || matchDealType(deal, 'repair');
  const handleChangeTab = React.useCallback((newTabType: TabType): void => {
    setTabType(newTabType);
  }, []);
  return (
    <div className={Styles.DealMain}>
      {isVendor ? <DealMainSpLinksVendor /> : <DealMainSpLinksClient />}
      <DealTabs handleChangeTab={handleChangeTab} tabType={tabType} />
      <div className={tabType === 'workflow' ? Styles.Show : Styles.Hide}>
        {isVendor ? <DealMainWorkflowVendor /> : <DealMainWorkflowClient />}
      </div>
      <div className={tabType === 'reports' ? Styles.Show : Styles.Hide}>
        {isVendor ? (
          <div className={Styles.Card}>
            <InspectionReportImageVendorPanel />
            {showCompleteImagePanel && <CompletionReportImageVendorPanel />}
          </div>
        ) : (
          <div className={Styles.Card}>
            <InspectionReportImageClientPanel />
            {showCompleteImagePanel && <CompletionReportImageClientPanel />}
          </div>
        )}
      </div>
      {tabType === 'histories' && (
        // NOTE: mutateを実行するためにタブを開く度にrenderingし直す
        <div className={Styles.Show}>
          <div className={Styles.Card}>
            {isVendor ? (
              <DealHistoryVendorList dealId={deal.id} />
            ) : (
              <DealHistoryClientList dealId={deal.id} />
            )}
          </div>
        </div>
      )}
      <div className={tabType === 'estimation' ? Styles.Show : Styles.Hide}>
        <div className={Styles.Card}>
          {isVendor ? (
            <EstimationVendorPanel />
          ) : (
            <EstimationClientPanel deal={deal} />
          )}
        </div>
      </div>
      <div className={tabType === 'files' ? Styles.Show : Styles.Hide}>
        {isVendor ? <DealFileListVendor /> : <DealFileListClient />}
      </div>
    </div>
  );
};
