import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { ImportConversion } from '~/models/ImportConversion';
import { ImportType } from '~/models/ImportLog';

const paths = {
  index: '/admin/import_conversions'
} as const;

type TIndex = (importType: ImportType) => SwrReturnType<ImportConversion[]>;
type ReturnType = {
  useIndex: TIndex;
};

export const useImportConversions = (): ReturnType => {
  const useIndex: TIndex = (importType: ImportType) => {
    const { data, mutate, error, isLoading } = useSWR<ImportConversion[]>({
      url: paths.index,
      params: { importType }
    });
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      error,
      isLoading
    };
  };
  return { useIndex };
};
