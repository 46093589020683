import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiEdit2Line } from 'react-icons/ri';
import { Deal } from '~/models/Deal';
import { onInvalidScroll } from '~/utils/FormUtil';
import { isSuccess } from '~/utils/api/api_handler';
import { update } from '~/utils/api/Deal';
import {
  buildDealUpdateParam,
  DealUpdateParam,
  updateValidationSchema
} from '~/models/DealForm';
import { DealCardModalForm } from '~/components/common/deals/show/deal_card/DealCardModalForm';

type Props = {
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate?: (() => Promise<void>) | null;
};

export const DealCardModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  handleMutate = null
}: Props) => {
  const methods = useForm<DealUpdateParam>({
    mode: 'onBlur',
    resolver: yupResolver(updateValidationSchema),
    defaultValues: buildDealUpdateParam(deal)
  });
  const handleModalSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: DealUpdateParam) => {
        const response = await update(deal.id, submitValue);
        if (isSuccess(response)) {
          handleMutate && (await handleMutate());
          handleClose();
          toast.success('更新しました');
          return;
        }
        handleClose();
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, deal.id, handleClose, handleMutate]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader icon={RiEdit2Line} title="案件情報の更新" />
      <ModalBody>
        <FormProvider {...methods}>
          <DealCardModalForm deal={deal} />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={methods.formState.isSubmitting}
            onClick={handleModalSubmit}
            variant="primary"
          >
            更新
          </Button>
        }
      />
    </Modalv2>
  );
};
