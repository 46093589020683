import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikTouched } from 'formik';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import Styles from './CompanyStoreMailModal.module.scss';
import { MailCreateParam, MailsCreateParams } from '~/models/CompanyStoreMail';

type Props = {
  index: number;
  errors: string | null | FormikErrors<MailCreateParam>;
  remove: (number: number) => void;
  handleBlur: (e: React.FocusEvent) => void;
  touched: FormikTouched<MailsCreateParams>;
  values: MailsCreateParams;
  handleChange: (e: React.ChangeEvent) => void;
};

export const CompanyStoreMailModalRow: React.FC<Props> = ({
  index,
  values,
  remove,
  errors,
  handleBlur,
  handleChange,
  touched
}: Props) => {
  const mailTouched =
    touched?.companyStoreMails != null
      ? touched?.companyStoreMails[index]
      : null;
  const mailValues =
    values?.companyStoreMails != null ? values?.companyStoreMails[index] : null;
  if (typeof errors === 'string') {
    return <>{errors}</>;
  }
  return (
    <FormGroup>
      <div className={Styles.MailCreateRow}>
        <div className={Styles.EmailForm}>
          {index === 0 && <FormLabel>メールアドレス</FormLabel>}
          <Form.Control
            isInvalid={!!(mailTouched?.email && errors?.email)}
            name={`companyStoreMails.${index}.email`}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="メールアドレスを入力してください"
            type="email"
            value={mailValues?.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email}
          </Form.Control.Feedback>
        </div>
        <div className={Styles.DelLink}>
          {index !== 0 && (
            <a
              className={Styles.MailDeleteLink}
              href="#"
              onClick={(e: React.MouseEvent): void => {
                e.preventDefault();
                remove(index);
              }}
            >
              削除
            </a>
          )}
        </div>
      </div>
    </FormGroup>
  );
};
