import React, { ComponentProps } from 'react';
import { SingleComboBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { useCompanyStoreSettings } from '~/hooks/useCompanyStoreSettings';
import { CompanyStoreSetting } from '~/models/CompanyStoreSetting';

type Props = {
  dealSearchCondition: DealSearchCondition;
};

export const CompanyStoreCombobox: React.FC<Props> = ({
  dealSearchCondition
}: Props) => {
  const { data: companyStoreSettings } = useCompanyStoreSettings().index();
  const { setValue } = useFormContext<DealSearchCondition>();
  const selectedCompanyStoreSetting =
    companyStoreSettings?.find(
      ({ authId }) => authId === dealSearchCondition.authId
    ) || null;
  const [selectedCompanyStoreSettingOption] = React.useState<
    ComponentProps<typeof SingleComboBox>['value'] | null
  >(
    selectedCompanyStoreSetting
      ? {
          label: selectedCompanyStoreSetting.name,
          value: String(selectedCompanyStoreSetting.authId)
        }
      : null
  );
  const onChangeCompanyStoreSetting = React.useCallback(
    async (
      option: ComponentProps<typeof SingleComboBox>['value'] | null
    ): Promise<void> => {
      setValue('authId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (companyStoreSettings === null) {
    return null;
  }
  return (
    <SingleComboBox
      defaultValue={selectedCompanyStoreSettingOption || null}
      onChange={onChangeCompanyStoreSetting}
      options={companyStoreSettings.map((c: CompanyStoreSetting) => ({
        label: c.name,
        value: String(c.authId)
      }))}
      placeholder="店舗"
    />
  );
};
