import { deleteRequest, postRequest } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

const paths = {
  create: '/vendors/sessions/vendor_users',
  destroy: '/vendors/sessions/vendor_users/:id'
};

export const create = async (
  vendorUserId: number,
  password: string | null
): Promise<NskResponse<void>> =>
  postRequest(paths.create, { vendorUserId, password });

export const destroy = async (): Promise<NskResponse<void>> =>
  deleteRequest(paths.destroy);
