import React from 'react';
import Styles from './Page.module.scss';
import { useDeals } from '~/hooks/useDeals';
import { useConstructionItems } from '~/hooks/useConstructionItems';
import Loading from '~/components/common/parts/loading/Loading';
import { useDealBillings } from '~/hooks/deals/useDealBillings';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PATH } from '~/pages/deals/[id]';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import { ConstructionAddForm } from '~/components/common/constructions/adding/ConstructionAddForm';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { getDownloadPath } from '~/utils/api/deals/DealBillingFile';
import { NskResponse } from '~/models/Api';
import {
  create as postDealBilling,
  destroy as destroyBilling,
  update as updateDealBilling,
  updateSeq
} from '~/utils/api/deals/DealBilling';
import { ChangeAmountModal } from '~/components/common/constructions/change_amount/ChangeAmountModal';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const [showChangeAmountModal, setShowChangeAmountModal] =
    React.useState<boolean>(false);
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: constructionItems } = useConstructionItems().index();
  const { data: billingContext, mutate: mutateBillings } =
    useDealBillings().index(id, 'rentee');
  if (deal === null || billingContext === null || constructionItems === null) {
    return <Loading isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal?.id), caption: '案件詳細', active: false },
    { path: '', caption: '入居者への請求書作成', active: true }
  ];
  const handleClickPreview = (): void => {
    if (id === null) return;
    const path = getDownloadPath(id, 'deal_billing_rentee');
    window.open(path);
  };
  const handleMutateConstructions = async (): Promise<void> => {
    mutateBillings != null && (await mutateBillings());
  };
  const handleCreate = async (
    submitValue: DealConstructionEdit
  ): Promise<NskResponse<void>> =>
    postDealBilling(submitValue, deal.id, 'rentee');
  const handleUpdate = async (
    submitValue: DealConstructionEdit,
    estimationId: number
  ): Promise<NskResponse<void>> =>
    updateDealBilling(submitValue, estimationId, deal.id);
  const handleDestroy = async (
    deleteBilling: DealConstruction
  ): Promise<NskResponse<void>> => {
    if (deleteBilling.type !== 'DealBilling') {
      throw new Error(`Illegal construction type: ${deleteBilling.type}`);
    }
    if (deleteBilling.billingType !== 'rentee') {
      throw new Error(`Illegal billing type: ${deleteBilling.billingType}`);
    }
    return destroyBilling(deleteBilling, deal.id);
  };
  const handleUpdateSeq = async (
    newBillings: DealConstruction[]
  ): Promise<void> => {
    await updateSeq(newBillings, deal.id);
  };
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
      >
        <h2 className={Styles.EstimateTitle}>入居者への請求書作成</h2>
        <ConstructionTop
          constructionContext={billingContext}
          handleClickChangeAmount={(): void => setShowChangeAmountModal(true)}
          handleClickPreview={handleClickPreview}
          readonly={false}
        />
        {showChangeAmountModal && (
          <ChangeAmountModal
            billingType="rentee"
            handleClose={(): void => setShowChangeAmountModal(false)}
          />
        )}
        <div className={Styles.Container}>
          <div className={Styles.EstimateAddForm}>
            <ConstructionAddForm
              constructionItems={constructionItems}
              handleCreate={handleCreate}
              mutateConstructions={handleMutateConstructions}
            />
          </div>
          <div className={Styles.EstimateList}>
            <ConstructionList
              companyType="client"
              constructionContext={billingContext}
              constructionItems={constructionItems}
              docType="billing"
              handleDestroy={handleDestroy}
              handleMutateConstructions={handleMutateConstructions}
              handleUpdate={handleUpdate}
              handleUpdateSeq={handleUpdateSeq}
              readonly={false}
              target="rentee"
            />
          </div>
        </div>
      </DealLayout>
    </DefaultV2>
  );
};
