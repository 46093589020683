import React from 'react';
import styles from './Loading.module.scss';

type Props = {
  isLoading: boolean;
};

const Loading: React.FC<Props> = ({ isLoading }: Props) => (
  <>
    {isLoading && (
      <div className={styles.LoadingWrapper}>
        <div className={styles.Loading}>loading...</div>
      </div>
    )}
  </>
);

export default Loading;
