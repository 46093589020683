import { ImportLogError } from '~/models/ImportLogError';

export const importTypes = [
  'company_vendor',
  'deal',
  'deal_estimation',
  'deal_billing_owner',
  'deal_billing_rentee'
] as const;
export const isImportType = (value: unknown): value is ImportType =>
  importTypes.some((importType) => importType === value);
export type ImportType = (typeof importTypes)[number];
export type ImportStatus =
  | 'unprocessed'
  | 'processing'
  | 'normal_end'
  | 'error_end';
export type ImportLog = {
  id: number;
  importType: ImportType;
  status: ImportStatus;
  rowCount: number | null;
  importCount: number;
  deleteCount: number;
  errorCount: number;
  createdAt: string;
  importLogErrors: ImportLogError[];
};
export type ImportLogWithCount = {
  logs: ImportLog[];
  count: number;
};
export type ImportLogsByType = {
  companyVendor: ImportLogWithCount;
  deal: ImportLogWithCount;
  dealEstimation: ImportLogWithCount;
  dealBillingOwner: ImportLogWithCount;
  dealBillingRentee: ImportLogWithCount;
};

const converter = {
  company_vendor: 'companyVendor',
  deal: 'deal',
  deal_estimation: 'dealEstimation',
  deal_billing_owner: 'dealBillingOwner',
  deal_billing_rentee: 'dealBillingRentee'
} as const satisfies {
  [key in ImportType]: keyof ImportLogsByType;
};
export const convertImportType = (
  importType: ImportType
): keyof ImportLogsByType => converter[importType];

export const importTypeLabels = {
  company_vendor: '施工会社',
  deal: '案件',
  deal_estimation: '施工会社見積',
  deal_billing_owner: '請求情報（オーナー）',
  deal_billing_rentee: '請求情報（入居者）'
} as const satisfies {
  [key in ImportType]: string;
};

export const importStatusLabels = {
  unprocessed: '未処理',
  processing: '処理中',
  normal_end: '正常終了',
  error_end: '異常終了'
} as const satisfies {
  [key in ImportStatus]: string;
};
