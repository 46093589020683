import React from 'react';
import Loader from '~/components/common/parts/loading/Loading';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import Styles from './Page.module.scss';
import { DealCard } from '~/components/common/deals/show/deal_card/DealCard';
import { DealMain } from '~/components/common/deals/show/main/DealMain';
import { DealConstructionScheduleVendorCard } from '~/components/common/deals/show/construction_card/DealConstructionScheduleVendorCard';
import { DealInspectionVendorCard } from '~/components/common/deals/show/inspection_card/DealInspectionVendorCard';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useSessions } from '~/hooks/vendors/useSessions';
import { DealOriginVendorCard } from '~/components/common/deals/show/vendor_card/DealOriginVendorCard';
import { DealSubVendorCard } from '~/components/common/deals/show/vendor_card/DealSubVendorCard';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { DealCompanyCard } from '~/components/common/deals/show/deal_card/DealCompanyCard';
import { ChatPopContainer } from '~/components/common/chat/ChatPopContainer';
import { useMessages } from '~/hooks/vendors/deals/useMessages';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getVendorMessageSpeaker } from '~/models/Message';
import { DealRepairCard } from '~/components/common/deals/show/repair_card/DealRepairCard';
import { DealNoteWithinVendorCard } from '~/components/common/deals/show/vendor_card/DealNoteWithinVendorCard';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, isLoading, mutate: mutateDeal } = useDeals().show(id);
  const { isOpen, handleClose, handleOpen } = useOpenHandler();
  const { data: session } = useSessions().index();
  const { data: messages, mutate: mutateMessages } = useMessages().index(id);
  const handleToggleChat = React.useCallback(() => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [isOpen, handleClose, handleOpen]);
  const handleMutateDeal = React.useCallback(async () => {
    mutateDeal && (await mutateDeal());
  }, [mutateDeal]);
  const handleMutateMessage = React.useCallback(async () => {
    mutateMessages && (await mutateMessages());
  }, [mutateMessages]);
  if (isLoading || deal === null || session === null) {
    return <Loader isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: null, caption: '案件詳細', active: true }
  ];
  return (
    <VendorLayoutV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor
        noBackground
      >
        <div className={Styles.Container}>
          <div className={Styles.LeftCol}>
            <DealCompanyCard deal={deal} handleToggleChat={handleToggleChat} />
            <DealNoteWithinVendorCard
              deal={deal}
              handleMutate={handleMutateDeal}
            />
            <DealCard deal={deal} vendorSession={session} />
            {deal.dealType === 'repair' && (
              <DealRepairCard companyType="vendor" deal={deal} />
            )}
            <DealOriginVendorCard />
            <DealSubVendorCard />
            <DealInspectionVendorCard />
            <DealConstructionScheduleVendorCard />
          </div>
          <DealMain deal={deal} isVendor />
        </div>
      </DealLayout>
      {messages !== null && (
        <ChatPopContainer
          deal={deal}
          handleClose={handleClose}
          handleMutate={handleMutateMessage}
          isOpen={isOpen}
          isVendor
          messages={messages}
          speaker={getVendorMessageSpeaker(deal, session)}
        />
      )}
    </VendorLayoutV2>
  );
};
