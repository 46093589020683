import React from 'react';
import { InputDate } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { formatHyphenDate, toDate as convertToDate } from '~/utils/DateUtil';

type Props = {
  attributeName:
    | 'constructionMinStartDate'
    | 'constructionMaxStartDate'
    | 'constructionMinEndDate'
    | 'constructionMaxEndDate'
    | 'inspectionMinDate'
    | 'inspectionMaxDate'
    | 'registrationMinDate'
    | 'registrationMaxDate';
  defaultValue: string | undefined;
};

export const SearchConditionDate: React.FC<Props> = ({
  attributeName,
  defaultValue
}: Props) => {
  const { setValue } = useFormContext<DealSearchCondition>();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    convertToDate(defaultValue || null)
  );

  const onChangeDate = (value: Date | null): void => {
    setValue(attributeName, formatHyphenDate(value) || undefined);
    setSelectedDate(value);
  };
  return (
    <InputDate
      onChange={onChangeDate}
      placeholder="年/月/日"
      value={selectedDate}
    />
  );
};
