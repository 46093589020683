import React, { ReactNode } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Styles from './AccordionSettingMenu.module.scss';

type SettingMenu = {
  path: string;
  title: string;
};
type Props = {
  title: string | ReactNode;
  menus: SettingMenu[];
  currentPath: string;
};

const isActive = (currentPath: string, targetPath: string): boolean =>
  currentPath.includes(targetPath);

const haveCurrentPath = (menus: SettingMenu[], currentPath: string): boolean =>
  menus.some((menu: SettingMenu) => isActive(currentPath, menu.path));

export const AccordionSettingMenu: React.FC<Props> = ({
  title,
  menus,
  currentPath
}: Props) => {
  const [showDetail, setShowDetail] = React.useState<boolean>(false);
  // 画面開いたときに開いて閉じる動きを抑制 showDetailを使うとmenusを切り替えるたびに閉じる動作になる
  const defaultHeight = haveCurrentPath(menus, currentPath)
    ? menus.length * 53
    : 0;
  const [height, setHeight] = React.useState<number | null>(defaultHeight);
  const detailRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setShowDetail(haveCurrentPath(menus, currentPath));
  }, [currentPath, menus]);

  React.useEffect(() => {
    const newHeight = showDetail
      ? detailRef.current?.getBoundingClientRect().height
      : 0;
    setHeight(newHeight ?? 0);
  }, [showDetail, detailRef]);
  const onClickAccordion = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowDetail(!showDetail);
  };
  const getActiveStyles = (targetPath: string): string =>
    isActive(currentPath, targetPath) ? Styles.ActiveMenu : '';
  return (
    <div className={Styles.AccordionSettingMenu}>
      <a className={Styles.AccordionLink} href="#" onClick={onClickAccordion}>
        <span>{title}</span>
        <RiArrowDropDownLine className={Styles.AccordionLinkIcon} />
      </a>
      <div
        className={Styles.AccordionHeightWrapper}
        style={{ height: `${height || 0}px` }}
      >
        <div ref={detailRef} className={Styles.AccordionDetailWrapper}>
          {menus.map(
            (menu: SettingMenu): ReactNode => (
              <Link
                key={menu.path}
                className={clsx(
                  Styles.SettingChildLink,
                  getActiveStyles(menu.path)
                )}
                href={menu.path}
              >
                {menu.title}
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  );
};
