import React from 'react';
import { useDealEstimations } from '~/hooks/vendors/deals/useDealEstimations';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as ESTIMATIONS_EDIT_PATH } from '~/pages/vendors/deals/[id]/estimations/edit';
import { PATH as ESTIMATIONS_PATH } from '~/pages/vendors/deals/[id]/estimations';
import { IN_ESTIMATION } from '~/models/Status';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const EstimationVendorPanel: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const dealEstimationsApi = useDealEstimations();
  const { data: estimationContext } = dealEstimationsApi.index(id);
  if (estimationContext === null || deal === null) {
    return null;
  }
  return (
    <div>
      {estimationContext.dealConstructions.length > 0 && (
        <ConstructionNavigation
          dealConstructionContext={estimationContext}
          detailPagePath={
            deal.status === IN_ESTIMATION
              ? ESTIMATIONS_EDIT_PATH(Number(id))
              : ESTIMATIONS_PATH(Number(id))
          }
          title="工事見積書"
        />
      )}
    </div>
  );
};
