import { SummaryFileTemplateType } from '~/models/SummaryFileTemplate';
import { NskResponse } from '~/models/Api';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';

const paths = {
  show: (id: number) =>
    `${process.env.NEXT_PUBLIC_API_HOST}/api/summary_file_templates/${id}`,
  create: '/summary_file_templates',
  destroy: (id: number) => `/summary_file_templates/${id}`
} as const;

export const getShowPath = (id: number): string => paths.show(id);

export const create = async (
  templateFile: File,
  templateType: SummaryFileTemplateType
): Promise<NskResponse> => {
  const params = new FormData();
  params.append('summary_file_template[template_file]', templateFile);
  params.append('summary_file_template[templateType]', templateType);
  return postRequest(paths.create, params);
};

export const destroy = async (id: number): Promise<NskResponse> =>
  deleteRequest(paths.destroy(id));
