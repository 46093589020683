import React from 'react';
import cx from 'clsx';
import Styles from './DealTypeLabel.module.scss';
import { Subtype } from '~/models/Subtype';

type Props = {
  dealType: 'restoration' | 'repair' | 'other';
  dealSubtype: Subtype | null;
};
export const DealTypeLabel: React.FC<Props> = ({
  dealType,
  dealSubtype
}: Props) => {
  const typeLabelText = React.useMemo(() => {
    if (dealSubtype !== null) {
      return dealSubtype.name;
    }
    if (dealType === 'restoration') {
      return '原状回復';
    }
    if (dealType === 'repair') {
      return '修繕';
    }
    return 'その他';
  }, [dealType, dealSubtype]);
  return <span className={cx(Styles.Tag)}>{typeLabelText}</span>;
};
