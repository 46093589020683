import { object, string } from 'yup';
import { Deal } from '~/models/Deal';
import { VendorCompany } from '~/models/VendorCompany';
import { VendorUser } from '~/models/VendorUser';
import { CompanyVendor } from '~/models/CompanyVendor';

export type DealVendorCompany = {
  id: number;
  seq: number;
  companyVendor: CompanyVendor | null;
  vendorCompany: VendorCompany | null;
  user: VendorUser | null;
  showTenant: boolean;
  skipEstimation: boolean;
  cautionaryNote: string | null;
  cautionaryNoteHtml: string;
  noteWithinVendor: string | null;
  noteWithinVendorHtml?: string;
};

export type DealVendorCompanyUpdateParam = {
  showTenant: boolean;
};

export type DealVendorCompanyVendorUpdateParam = {
  noteWithinVendor: string;
};

export const ORIGIN_SEQ = 0;
export const SUB_SEQ = 1;

export const getOriginDealVendor = (
  deal: DeepReadonly<Deal>
): DealVendorCompany | null =>
  deal.dealVendorCompanies.find(
    (d: DealVendorCompany) => d.seq === ORIGIN_SEQ
  ) ?? null;

export const getOriginVendor = (
  deal: DeepReadonly<Deal>
): VendorCompany | null => getOriginDealVendor(deal)?.vendorCompany ?? null;

export const isOriginVendor = (deal: Deal, vendorCompanyId: number): boolean =>
  getOriginVendor(deal)?.id === vendorCompanyId;

export const getSubDealVendor = (deal: Deal): DealVendorCompany | null =>
  deal.dealVendorCompanies.find((d: DealVendorCompany) => d.seq === SUB_SEQ) ??
  null;

export const getSubVendor = (deal: Deal): VendorCompany | null =>
  getSubDealVendor(deal)?.vendorCompany ?? null;

export const isSubVendor = (deal: Deal, vendorCompanyId: number): boolean =>
  getSubVendor(deal)?.id === vendorCompanyId;

export const isSameVendor = (deal: Deal): boolean =>
  getOriginVendor(deal)?.id === getSubVendor(deal)?.id;

/** 引数のvendorCompanyIdのDealVendorCompanyを返す */
export const getSelfDealVendor = (
  deal: Deal,
  vendorCompanyId: number
): DealVendorCompany | null => {
  if (isOriginVendor(deal, vendorCompanyId)) {
    return getOriginDealVendor(deal);
  }
  return (
    deal.dealVendorCompanies.find(
      (d: DealVendorCompany) => d.vendorCompany?.id === vendorCompanyId
    ) ?? null
  );
};

export const validationSchema = object().shape({
  noteWithinVendor: string().max(10000, '10000文字以内で設定してください')
});
