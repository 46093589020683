import React from 'react';
import { animateScroll as scroll } from 'react-scroll/modules';
import { Deal } from '~/models/Deal';
import Styles from './ChatPageContainer.module.scss';
import { Message, MessageSpeaker } from '~/models/Message';
import { ChatMessageArea } from '~/components/common/chat/messages/ChatMessageArea';
import { ChatPostArea } from '~/components/common/chat/post/ChatPostArea';

type Props = DeepReadonly<{
  deal: Deal;
  messages: Message[];
  handleMutate: () => Promise<void>;
  speaker?: MessageSpeaker;
}>;

/**
 * スマホなどで画面いっぱいに表示するチャット画面
 *
 * @param deal
 * @param messages
 * @param handleMutate
 * @param speaker 話し手が誰か
 * @constructor
 */
export const ChatPageContainer: React.FC<Props> = ({
  deal,
  messages,
  handleMutate,
  speaker = 'maintenance_company'
}: Props) => {
  React.useEffect(() => {
    if (messages !== null) {
      // メッセージが読み込まれるたびに一番下までスクロール
      // NOTE: ChatMessageArea内のscrollerはスクロール可動域のdomが異なるため動作しない
      scroll.scrollToBottom({
        duration: 0
      });
    }
  }, [messages]);
  return (
    <>
      <div className={Styles.ChatPageContainer}>
        <ChatMessageArea
          deal={deal}
          handleMutate={handleMutate}
          isOpen
          messages={messages}
          speaker={speaker}
        />
      </div>
      <ChatPostArea
        deal={deal}
        handleMutate={handleMutate}
        isVendor={speaker !== 'maintenance_company'}
      />
    </>
  );
};
