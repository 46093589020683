import React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Styles from './ConstructionItemSettingList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { ConstructionItem } from '~/models/ConstructionItem';
import { useConstructionItemSettings } from '~/hooks/useConstructionItemSettings';

type Props = {
  constructionItem: ConstructionItem;
};

export const ConstructionItemSettingListRow: React.FC<Props> = ({
  constructionItem
}: Props) => {
  const router = useRouter();
  const { vendorCompanyId = null } = router.query;
  const { data: constructionItemSettings } =
    useConstructionItemSettings().index(
      vendorCompanyId === null ? null : Number(vendorCompanyId)
    );
  const unitAmount = React.useMemo(
    () =>
      constructionItemSettings?.find(
        (setting) => setting.constructionItemId === constructionItem.id
      )?.unitAmount ?? constructionItem.unitAmount,
    [constructionItem.id, constructionItem.unitAmount, constructionItemSettings]
  );
  return (
    <tr className={clsx(Styles.ListRow)}>
      <td className={clsx(Styles.ListCol)}>{constructionItem.name}</td>
      <td className={clsx(Styles.ListCol, Styles.UnitAMount)}>
        ¥{toThousandSeparator(unitAmount)}
      </td>
    </tr>
  );
};
