import React, { ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import { RiAddFill } from 'react-icons/ri';
import Styles from './DealFileCard.module.scss';
import { isSuccess } from '~/utils/api/api_handler';
import { Deal } from '~/models/Deal';
import { UploadButton } from '~/components/common/upload/UploadButton';
import { create, destroy } from '~/utils/api/property_deals/LeaseContractFile';
import { DeleteMenuButton } from '~/components/common/parts/button/DeleteMenuButton';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';

type Props = {
  handleGetFile: (dealId: number, fileNumber: number) => string;
  deal: Deal;
  handleMutate: () => Promise<void>;
  isVendor?: boolean;
};

export const LeaseContractFileCard: React.FC<Props> = ({
  deal,
  handleGetFile,
  handleMutate,
  isVendor = false
}: Props) => {
  const [deleteFileNumber, setDeleteFileNumber] = useState<number | null>(null);
  const renderRow = (fileNumber: number): ReactNode => {
    const handleUpload = async (uploadFile: File): Promise<void> => {
      if (isVendor) {
        return;
      }
      const result = await create(uploadFile, deal.propertyDealId, fileNumber);
      if (isSuccess(result)) {
        await handleMutate();
        toast.success('登録しました');
        return;
      }
      toast.error('エラーが発生しました');
    };
    const handleDelete = async (): Promise<void> => {
      if (deleteFileNumber === null || isVendor) {
        return;
      }
      const result = await destroy(deal.propertyDealId, deleteFileNumber);
      if (isSuccess(result)) {
        await handleMutate();
        setDeleteFileNumber(null);
        toast.success('削除しました');
        return;
      }
      toast.error('エラーが発生しました');
    };
    let file = null;
    let originalFilename = null;
    // eslint-disable-next-line default-case
    switch (fileNumber) {
      case 1:
        file = deal.leaseContractFile1;
        originalFilename = deal.leaseContractFile1OriginalFilename;
        break;
      case 2:
        file = deal.leaseContractFile2;
        originalFilename = deal.leaseContractFile2OriginalFilename;
        break;
      case 3:
        file = deal.leaseContractFile3;
        originalFilename = deal.leaseContractFile3OriginalFilename;
        break;
    }
    return (
      <div key={fileNumber} className={Styles.FileRow}>
        {file === null && (
          <>
            <div className={Styles.FileContent}>
              <div className={Styles.NoFile}>
                賃貸借契約書{fileNumber}_ファイル登録無し
              </div>
              {!isVendor && (
                <div className={Styles.FileButtons}>
                  <UploadButton onClick={handleUpload} size="sm">
                    <RiAddFill className={Styles.Icon} />
                    登録
                  </UploadButton>
                </div>
              )}
            </div>
          </>
        )}
        {file !== null && (
          <>
            <div className={Styles.FileContent}>
              <a
                className={Styles.FileLink}
                href={handleGetFile(deal.id, fileNumber)}
                rel="noreferrer"
                target="_blank"
              >
                賃貸借契約書{fileNumber}
                {originalFilename !== null && <>_{originalFilename}</>}
              </a>
              {!isVendor && (
                <div className={Styles.FileButtons}>
                  <DeleteMenuButton
                    onClick={(): void => setDeleteFileNumber(fileNumber)}
                  >
                    削除
                  </DeleteMenuButton>
                </div>
              )}
            </div>
          </>
        )}
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleDelete}
          handleCancel={(): void => setDeleteFileNumber(null)}
          isOpen={deleteFileNumber !== null}
        >
          賃貸借契約書{deleteFileNumber}を削除してよろしいですか？
        </ConfirmModal>
      </div>
    );
  };
  return (
    <>
      <div className={Styles.DealCard}>
        <h3 className={Styles.Title}>賃貸借契約書</h3>
        <div className={Styles.FileList}>{[1, 2, 3].map(renderRow)}</div>
      </div>
    </>
  );
};
