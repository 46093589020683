import { object, string } from 'yup';

export type Subtype = {
  id: number;
  name: string;
  foreignKey: string | null;
};

export type SubtypeEdit = Omit<Subtype, 'id'>;

export const buildDefaultSubtype = (): SubtypeEdit => ({
  name: '',
  foreignKey: ''
});

export const validationSchema = object().shape({
  name: string().required('入力してください')
});
