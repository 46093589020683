import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/imports/[importType]/import_conversions/Page';
import { NskHead } from '~/components/common/head/NskHead';
import { ImportType } from '~/models/ImportLog';

export const PATH = (importType: ImportType): string =>
  `/settings/imports/${importType}/import_conversions`;

const Home: NextPage = () => (
  <>
    <NskHead title="過去データ取込" />
    <Component />
  </>
);

export default Home;
