import React from 'react';
import { useRouter } from 'next/router';
import Styles from './statuses.module.scss';
import { useDeals } from '~/hooks/vendors/useDeals';

export const Canceled: React.FC = () => {
  const router = useRouter();
  const { id: dealId = null } = router.query;
  const { data: deal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  if (deal === null) {
    return null;
  }
  const { dealCancel } = deal;
  return (
    <div>
      <h3 className={Styles.Title}>この案件は取り下げられました</h3>
      {dealCancel !== null && (
        <div>
          <div className={Styles.CancelReasonLabel}>取り下げ理由</div>
          <div className={Styles.CancelReason}>{dealCancel.reason}</div>
        </div>
      )}
    </div>
  );
};
