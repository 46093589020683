import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, InputRadio, ItandiChip } from '@itandi/itandi-bb-ui';
import { RiHome2Line } from 'react-icons/ri';
import Styles from './Block.module.scss';
import { DealUpdateParam } from '~/models/DealForm';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';
import { toDate } from '~/utils/DateUtil';
import { onChangeDate } from '~/utils/FormUtil';
import useItandiAccount from '~/hooks/useItandiAccount';
import { Deal } from '~/models/Deal';
import { AmaterasPropertyBlock } from '~/components/common/deals/show/deal_card/block/AmaterasPropertyBlock';

type Props = {
  deal: Deal;
};

export const RoomBlock: React.FC<Props> = ({ deal }: Props) => {
  const { data: session } = useItandiAccount().currentAccount();
  const { register, watch, setValue, formState } =
    useFormContext<DealUpdateParam>();
  const { errors } = formState;
  if (deal === null) return null;
  const preferredDate = watch('preferredDate');
  return (
    <div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormRowLabel}>
          <RiHome2Line className={Styles.BlockIcon} />
          物件情報
        </div>
      </div>
      {session?.company?.useBkkLinkage ? (
        <AmaterasPropertyBlock deal={deal} />
      ) : (
        <>
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabelCol}>
              <span className={Styles.FormLabelText}>物件名</span>
              <span className={Styles.RequiredLabel}>
                <ItandiChip label="必須" />
              </span>
            </div>
            <div className={Styles.FormSettingCol}>
              <div>
                <div>
                  <Input
                    isInvalid={errors.name != null}
                    {...register('name')}
                  />
                </div>
                {errors.name != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.name.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabelCol}>
              <span className={Styles.FormLabelText}>部屋番号</span>
              <span className={Styles.RequiredLabel}>
                <ItandiChip label="必須" />
              </span>
            </div>
            <div className={Styles.FormSettingCol}>
              <div>
                <div>
                  <Input
                    isInvalid={errors.roomNumber != null}
                    {...register('roomNumber')}
                  />
                </div>
                {errors.roomNumber != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.roomNumber.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabelCol}>
              <span className={Styles.FormLabelText}>物件住所</span>
              <span className={Styles.RequiredLabel}>
                <ItandiChip label="必須" />
              </span>
            </div>
            <div className={Styles.FormSettingCol}>
              <div>
                <div>
                  <Input
                    isInvalid={errors.address != null}
                    {...register('address')}
                  />
                </div>
                {errors.address != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.address.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>エレベーターの有無</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.InputRadioGroup}>
            <InputRadio
              isInvalid={errors.enableElevator != null}
              label="なし"
              value="false"
              {...register('enableElevator')}
            />
            <InputRadio
              isInvalid={errors.enableElevator != null}
              label="あり"
              value="true"
              {...register('enableElevator')}
            />
          </div>
          {errors.enableElevator != null && (
            <div className={Styles.ErrorMessage}>
              {errors.enableElevator.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>オートロックの有無</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.InputRadioGroup}>
            <InputRadio
              isInvalid={errors.enableAutoLock != null}
              label="なし"
              value="false"
              {...register('enableAutoLock')}
            />
            <InputRadio
              isInvalid={errors.enableAutoLock != null}
              label="あり"
              value="true"
              {...register('enableAutoLock')}
            />
          </div>
          {errors.enableAutoLock != null && (
            <div className={Styles.ErrorMessage}>
              {errors.enableAutoLock.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>鍵の置き場所</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.placeKeyStoring != null}
              placeholder="例）エントランス横の雨樋（番号：1234）"
              {...register('placeKeyStoring')}
            />
          </div>
          {errors.placeKeyStoring != null && (
            <div className={Styles.ErrorMessage}>
              {errors.placeKeyStoring.message}
            </div>
          )}
          <div className={Styles.Description}>
            下記について教えてください。
            <br />
            空室時：鍵を設置した場所
            <br />
            立会依頼時：鍵設置を希望する場所（希望がない場合は「希望なし」とご記載ください）
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>無償利用が可能な駐車場</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.freeParking != null}
              placeholder="例）エントランス前"
              {...register('freeParking')}
            />
          </div>
          {errors.freeParking != null && (
            <div className={Styles.ErrorMessage}>
              {errors.freeParking.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>入居予定日</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <div>
              <Datepicker
                onChange={(date): void => {
                  onChangeDate(date, 'preferredDate', setValue);
                }}
                selected={toDate(preferredDate)}
              />
            </div>
          </div>
          <div className={Styles.Description}>
            次回の入居予定がある場合は予定日を入力してください。
          </div>
        </div>
      </div>
    </div>
  );
};
