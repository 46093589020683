export const TYPE_MISC_CONSTRUCTION = 10;
export const TYPE_RECOVERING_CONSTRUCTION = 20;
export const TYPE_CLEANING = 30;

export const CONSTRUCTION_SCHEDULE_LABELS = {
  [TYPE_MISC_CONSTRUCTION]: '雑工事',
  [TYPE_RECOVERING_CONSTRUCTION]: 'クロス工事',
  [TYPE_CLEANING]: 'クリーニング'
};

export const OTHER_CONSTRUCTION_ITEM_ID = 9900;
