import useSWR from 'swr';
import { PropertyDealBuilding } from '~/models/PropertyDeal';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/property_deal_buildings'
};
type TIndex = (
  nameCont: string
) => SwrReturnType<ReadonlyArray<PropertyDealBuilding>>;
type ReturnType = {
  index: TIndex;
};

export const usePropertyDealBuildings = (): ReturnType => {
  const index: TIndex = (nameCont: string) => {
    const { data, mutate, isLoading, error } = useSWR<
      ReadonlyArray<PropertyDealBuilding>
    >({
      url: paths.index,
      params: { nameCont }
    });
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading,
      error
    };
  };
  return { index };
};
