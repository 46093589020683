import React from 'react';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import SettingStyles from '../SettingStyle.module.scss';
import { CompanyNotificationSettingList } from './CompanyNotificationSettingList';

export const Page: React.FC = () => (
  <DefaultV2>
    <ClientSettingLayout>
      <h2 className={SettingStyles.SettingTitle}>メール通知設定</h2>
      <CompanyNotificationSettingList />
    </ClientSettingLayout>
  </DefaultV2>
);
