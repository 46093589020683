import { NskResponse } from '~/models/Api';
import { postRequest, putRequest } from '~/utils/api/api_handler';
import { DealVendorCompanyUpdateParam } from '~/models/DealVendorCompany';

const paths = {
  create: '/deals/:deal_id/deal_vendor_companies',
  update: '/deals/:deal_id/deal_vendor_companies/:id'
};

export const create = async (
  dealId: number,
  companyVendorId: number,
  showTenant: boolean,
  skipEstimation: boolean
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    companyVendorId,
    showTenant,
    skipEstimation
  });

export const update = async (
  dealId: number,
  dealVendorCompanyId: number,
  dealVendorCompany: DealVendorCompanyUpdateParam
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':deal_id', String(dealId))
      .replace(':id', String(dealVendorCompanyId)),
    { dealVendorCompany }
  );
