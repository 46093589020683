import { ParsedUrlQuery } from 'querystring';
import queryString, { StringifiableRecord } from 'query-string';
import { camelize, decamelizeKeys } from 'humps';
import {
  DealSearchCondition,
  DealType,
  isNumberCondition
} from '~/models/Deal';

/**
 * URLパラメータから検索条件を組み立てる
 *
 * @param query
 */

type DealSearchConditionWithProperty = DealSearchCondition & {
  [key: string]: string | number;
};

export const buildSearchConditionByParams = (
  query: ParsedUrlQuery
): DealSearchCondition => {
  const conditions: DealSearchConditionWithProperty = {};
  if (query === undefined) {
    return conditions;
  }
  Object.keys(query).forEach((key: string) => {
    // NOTE: next/routerの仕様?でarray bracketがパースされないので配列パラメータは個別に記載
    if (key === 'status_id[]') {
      const queryValue = query[key];
      if (Array.isArray(queryValue)) {
        conditions.statusId = queryValue.map((s: string) => Number(s));
      } else {
        conditions.statusId = [Number(queryValue)];
      }
    } else if (key === 'deal_types[]') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const queryValue = query[key] as DealType | DealType[] | undefined;
      if (Array.isArray(queryValue)) {
        conditions.dealTypes = queryValue;
      } else {
        conditions.dealTypes = queryValue ? [queryValue] : [];
      }
    } else if (key === 'deal_subtype_ids[]') {
      const queryValue = query[key];
      if (Array.isArray(queryValue)) {
        conditions.dealSubtypeIds = queryValue.map((s: string) => Number(s));
      } else {
        conditions.dealSubtypeIds = [Number(queryValue)];
      }
    } else if (typeof query[key] === 'string') {
      const queryKey = query[key]?.toString() ?? null;
      if (queryKey !== null) {
        conditions[camelize(key)] = isNumberCondition(camelize(key))
          ? Number(queryKey)
          : queryKey;
      }
    }
  });
  return conditions;
};

const decamelizeKeyCondition = (
  condition: DealSearchCondition
): StringifiableRecord => {
  const decamelizeCondition: DealSearchCondition = decamelizeKeys(condition);
  if (Array.isArray(decamelizeCondition)) {
    // for type guard
    return {};
  }
  return decamelizeCondition;
};

export const convertQueryStringUrl = (
  basePath: string,
  condition: DealSearchCondition
): string => {
  const url = queryString.stringifyUrl(
    { url: basePath, query: decamelizeKeyCondition(condition) },
    { skipNull: true, skipEmptyString: true, arrayFormat: 'bracket' }
  );
  return url;
};
