import React, { ReactNode } from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSurvey, SurveyAnswers, DealSurveyOption } from '~/models/Survey';
import Styles from './SurveyForm.module.scss';
import { SurveyRadio } from './SurveyRaidio';

type Props = {
  surveyIndex: number;
  survey: DealSurvey;
};
export const SurveyFormRow: React.FC<Props> = ({
  survey,
  surveyIndex
}: Props) => {
  const { register, formState } = useFormContext<SurveyAnswers>();
  const surveyOptionIdError =
    formState.errors.answers?.[surveyIndex]?.surveyOptionId || null;
  const noteError = formState.errors.answers?.[surveyIndex]?.note || null;
  const renderRadio = React.useCallback(
    (surveyOption: DealSurveyOption): ReactNode => (
      <SurveyRadio
        key={surveyOption.id}
        surveyIndex={surveyIndex}
        surveyOption={surveyOption}
      />
    ),
    [surveyIndex]
  );
  return (
    <div className={Styles.FormRow}>
      <div className={Styles.SurveyTitle}>{survey.title}</div>
      <div className={Styles.SurveyDescription}>{survey.description}</div>
      {surveyOptionIdError !== null && (
        <div className={Styles.InvalidFeedback}>
          {surveyOptionIdError.message}
        </div>
      )}
      <div className={Styles.RadioGroup}>
        {survey.surveyOptions.map(renderRadio, surveyIndex)}
      </div>
      {noteError !== null && (
        <div className={Styles.InvalidFeedback}>{noteError.message}</div>
      )}
      {survey.enabledNote && (
        <div className={Styles.Note}>
          <Input
            isInvalid={noteError !== null}
            {...register(`answers.${surveyIndex}.note`)}
          />
        </div>
      )}
    </div>
  );
};
