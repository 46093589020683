import React from 'react';
import { InputRadio } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  ESTIMATION_DESCRIPTIONS,
  REPORT_DESCRIPTIONS,
  DEAL_STATE_LABELS,
  DEAL_STATE_NAMES,
  NotificationEdit,
  NotificationDealStateFormName,
  SCHEDULE_DESCRIPTIONS
} from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';

type Props = DeepReadonly<{
  formName: NotificationDealStateFormName;
}>;

export const DealStateRadio: React.FC<Props> = ({ formName }: Props) => {
  const { register, watch, formState } = useFormContext<NotificationEdit>();
  const state = watch(formName);
  const { errors } = formState;

  return (
    <>
      <div className={Styles.DealStateSubLabel}>
        {DEAL_STATE_NAMES[formName]}
      </div>
      <div className={Styles.RadioGroup}>
        <InputRadio
          isInvalid={errors[formName] != null}
          label={DEAL_STATE_LABELS.no_setting}
          value="no_setting"
          {...register(formName)}
        />
        <InputRadio
          isInvalid={errors[formName] != null}
          label={DEAL_STATE_LABELS.registered}
          value="registered"
          {...register(formName)}
        />
        <InputRadio
          isInvalid={errors[formName] != null}
          label={DEAL_STATE_LABELS.yet}
          value="yet"
          {...register(formName)}
        />
      </div>
      {formName === 'dealEstimationState' &&
        ESTIMATION_DESCRIPTIONS[state] !== '' && (
          <div className={Styles.DealStateDescription}>
            {ESTIMATION_DESCRIPTIONS[state]}
          </div>
        )}
      {(formName === 'dealInspectionReportState' ||
        formName === 'dealCompletionReportState') &&
        REPORT_DESCRIPTIONS[state] !== '' && (
          <div className={Styles.DealStateDescription}>
            {REPORT_DESCRIPTIONS[state]}
          </div>
        )}
      {formName === 'dealConstructionScheduleState' &&
        SCHEDULE_DESCRIPTIONS[state] !== '' && (
          <div className={Styles.DealStateDescription}>
            {SCHEDULE_DESCRIPTIONS[state]}
          </div>
        )}
    </>
  );
};
