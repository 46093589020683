import useSWR from 'swr';
import queryString from 'query-string';
import { SwrReturnType } from '~/models/Api';
import { ConstructionItemSetting } from '~/models/ConstructionItemSetting';

const paths = {
  index: '/construction_item_settings'
};
type TIndex = (
  vendorCompanyId: number | null
) => SwrReturnType<ConstructionItemSetting[]>;
type ReturnType = {
  index: TIndex;
};

export const useConstructionItemSettings = (): ReturnType => {
  const index: TIndex = (vendorCompanyId: number | null) => {
    const { data, mutate, error } = useSWR<ConstructionItemSetting[]>(
      queryString.stringifyUrl({
        url: paths.index,
        query: { vendor_company_id: vendorCompanyId }
      })
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
