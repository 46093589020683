import React from 'react';
import { RiAttachment2 } from 'react-icons/ri';
import { scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import Styles from './ChatPostFileButton.module.scss';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { Deal } from '~/models/Deal';
import { createFile as createFileForCompany } from '~/utils/api/deals/Message';
import { createFile as createFileForVendor } from '~/utils/api/vendors/deals/Message';
import { isSuccess } from '~/utils/api/api_handler';
import useLoader from '~/hooks/useLoader';

type Props = DeepReadonly<{
  deal: Deal;
  isVendor: boolean;
  handleMutate: () => Promise<void>;
}>;

export const ChatPostFileButton: React.FC<Props> = ({
  deal,
  isVendor,
  handleMutate
}: Props) => {
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { Loader, showLoader, hideLoader } = useLoader();
  const handleChangeFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.files === null || inputFileRef.current === null) {
        return;
      }
      setFile(e?.target?.files[0]);
      inputFileRef.current.value = '';
      handleOpen();
    },
    [handleOpen, inputFileRef, setFile]
  );
  const handleClick = React.useCallback((): void => {
    inputFileRef?.current?.click();
  }, [inputFileRef]);
  const handleCancel = React.useCallback(() => {
    handleClose();
    setFile(null);
  }, [handleClose, setFile]);
  const handleSubmit = React.useCallback(async () => {
    showLoader();
    const postMethod = isVendor ? createFileForVendor : createFileForCompany;
    if (file !== null) {
      const response = await postMethod(deal.id, file);
      if (isSuccess(response)) {
        await handleMutate();
        scroller.scrollTo('chat-message-field-bottom', {
          duration: 300,
          smooth: 'easeInQuad',
          containerId: 'chat_messages_field',
          ignoreCancelEvents: true
        });
        handleClose();
        hideLoader();
        return;
      }
      toast.error(`エラーが発生しました: ${response.message}`);
      hideLoader();
    }
  }, [isVendor, deal, file, handleClose, handleMutate, showLoader, hideLoader]);
  return (
    <>
      <Loader />
      <input
        ref={inputFileRef}
        accept="image/*,video/*, .pdf, .xls, .xlsx"
        className={Styles.HideFilePostInput}
        onChange={handleChangeFile}
        type="file"
      />
      <button
        className={Styles.MessageAttachmentButton}
        onClick={handleClick}
        type="button"
      >
        <RiAttachment2 className={Styles.Icon} />
      </button>
      <ConfirmModal
        confirmType="save"
        handleAccept={handleSubmit}
        handleCancel={handleCancel}
        isOpen={isOpen}
      >
        {file?.name}を送信してよろしいですか？
      </ConfirmModal>
    </>
  );
};
