import React from 'react';
import { useFormContext } from 'react-hook-form';
import Styles from './DealEditorBody.module.scss';
import { DealParam } from '~/models/DealForm';
import { DealTypeBlock } from './block/DealTypeBlock';
import { MoveOutBlock } from './block/MoveOutBlock';
import { OtherBlock } from './block/OtherBlock';
import { RepairBlock } from './block/RepairBlock';
import { RoomBlock } from './block/RoomBlock';
import { TenantBlock } from './block/TenantBlock';
import { usePropertyDealRooms } from '~/hooks/usePropertyDealRooms';
import { PropertyDealRoom } from '~/models/PropertyDeal';

export const DealEditorBody: React.FC = () => {
  const { watch, setValue } = useFormContext<DealParam>();
  const DealType = watch('dealType');
  const roomName = watch('roomName');
  const address = watch('address');
  const [currentRoom, setCurrentRoom] = React.useState<PropertyDealRoom | null>(
    null
  );
  const { data: propertyDealRooms } = usePropertyDealRooms().index(
    roomName,
    address,
    null,
    null
  );
  const onChangeRoom = React.useCallback(
    (option: { label: string; value: string } | null): void => {
      if (propertyDealRooms === null) return;
      const value = option?.value ?? '';
      if (value === '') {
        setCurrentRoom(null);
        setValue('roomNumber', null);
        return;
      }
      const selectedRoom =
        propertyDealRooms.find(
          (building) => building.id.toString() === value
        ) ?? null;
      if (selectedRoom !== null) {
        setCurrentRoom(selectedRoom);
        setValue('roomNumber', selectedRoom.roomNumber);
      }
    },
    [propertyDealRooms, setValue]
  );
  return (
    <div className={Styles.DealEditorBody}>
      <DealTypeBlock />
      <RoomBlock
        handleChangeRoom={onChangeRoom}
        propertyDealRooms={propertyDealRooms}
      />
      {DealType === 'repair' && <RepairBlock />}
      <TenantBlock currentRoom={currentRoom} />
      <MoveOutBlock />
      <OtherBlock />
    </div>
  );
};
