import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/deals/[id]/estimations/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string => `/vendors/deals/${id}/estimations`;

const Estimations: NextPage = () => (
  <>
    <NskHead title="見積り確認" />
    <Component />
  </>
);

export default Estimations;
