import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Modal } from '~/components/common/parts/modal/Modal';
import Styles from './DealCancelApplyModal.module.scss';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { Deal } from '~/models/Deal';

type Props = {
  deal: Deal;
  handleClose: () => void;
  handleAccept: (reason: string) => Promise<void>;
};

export const DealCancelApplyModal: React.FC<Props> = ({
  deal,
  handleClose,
  handleAccept
}: Props) => {
  const isAssignedVendor = deal.vendorCompany != null;
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [reason, setReason] = React.useState<string>('');
  const onChangeReason = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setReason(e.target.value);
    },
    []
  );
  const reasonLabel = `取り下げ理由${
    isAssignedVendor ? '（施工会社に通知されます）' : ''
  }`;
  const isInvalid = isAssignedVendor && reason === '';
  const onClickSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    await handleAccept(reason);
    setIsSubmitting(false);
  };
  return (
    <Modal handleClose={handleClose} isOpen title="案件を取り下げる">
      <p className={Styles.Description}>案件を取り下げてよろしいですか？</p>
      <FormGroup>
        <FormLabel>{reasonLabel}</FormLabel>
        <FormControl
          as="textarea"
          onChange={onChangeReason}
          rows={4}
          value={reason}
        />
      </FormGroup>
      <div className={Styles.Buttons}>
        <button
          className={Styles.CancelButton}
          onClick={handleClose}
          type="button"
        >
          キャンセル
        </button>
        <button
          className={Styles.SubmitButton}
          disabled={isInvalid || isSubmitting}
          onClick={onClickSubmit}
          type="button"
        >
          案件を取り下げる
        </button>
      </div>
    </Modal>
  );
};
