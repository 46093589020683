import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { Notification } from '~/models/Notification';

const paths = {
  index: '/company_notifications'
};

type TIndex = () => SwrReturnType<Notification[]>;
type ReturnType = {
  index: TIndex;
};

export const useCompanyNotifications = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<Notification[]>(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
