import React, { ReactNode } from 'react';
import useItandiAccount from '~/hooks/useItandiAccount';
import { SERVER_ERROR_MESSAGE } from '~/constants/AppConst';
import { ErrorMessage } from '~/components/common/errors/ErrorMessage';
import { ItandiHeaderContainer } from '../header/ItandiHeaderContainer';

type Props = {
  children: ReactNode;
};

export const AdminLayout: React.FC<Props> = ({ children }: Props) => {
  const itandiAccountApi = useItandiAccount();
  const { data, isLoading, error } = itandiAccountApi.currentAccount();
  const errorMessage: string | null =
    error != null && error.response?.data?.code === 422
      ? error.response?.data?.message ?? SERVER_ERROR_MESSAGE
      : null;
  if (isLoading || data === null) {
    return null;
  }
  return (
    <>
      <ItandiHeaderContainer />
      <main>
        {errorMessage !== null ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <>{children}</>
        )}
      </main>
    </>
  );
};
