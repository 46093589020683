import { ImportType } from '~/models/ImportLog';
import { NskResponse } from '~/models/Api';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';
import { ImportConversionParam } from '~/models/ImportConversion';

const paths = {
  create: '/admin/import_conversions',
  destroy: (id: number) => `/admin/import_conversions/${id}`
} as const;

export const create = async (
  importType: ImportType,
  importConversion: ImportConversionParam
): Promise<NskResponse> =>
  postRequest(paths.create, { importType, importConversion });

export const destroy = async (
  id: number,
  importType: ImportType
): Promise<NskResponse> => deleteRequest(paths.destroy(id), { importType });
