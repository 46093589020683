import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputRadio } from '@itandi/itandi-bb-ui';
import { MarketCompanySetting } from '~/models/CompanySetting';
import Styles from './Form.module.scss';

type Props = DeepReadonly<{
  isConfirm?: boolean;
}>;

export const PlanForm: React.FC<Props> = ({ isConfirm }: Props) => {
  const { register, getValues, formState } =
    useFormContext<MarketCompanySetting>();
  const [values, setValues] = React.useState<MarketCompanySetting | null>(null);
  React.useEffect(() => {
    if (isConfirm) {
      setValues(getValues());
    }
  }, [isConfirm, getValues]);
  if (isConfirm && values === null) {
    return null;
  }
  const { errors } = formState;
  return (
    <div className={Styles.FormGroup}>
      <div className={Styles.GroupLabel}>利用方法</div>
      <div className={Styles.FormList}>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            プラン選択
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.marketPlan === 'single' && '単発発注'}</span>
            ) : (
              <div className={Styles.RadioGroup}>
                <InputRadio
                  label="単発発注"
                  value="single"
                  {...register('marketPlan')}
                />
              </div>
            )}
            {errors.marketPlan != null && (
              <div className={Styles.ErrorMessage}>
                {errors.marketPlan.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
