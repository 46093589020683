import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './ConstructionItemSettingList.module.scss';
import { useConstructionItems } from '~/hooks/useConstructionItems';
import { ConstructionItem } from '~/models/ConstructionItem';
import { ConstructionItemSettingListRow } from './ConstructionItemSettingListRow';
import SettingStyles from '../../SettingStyle.module.scss';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const ConstructionItemSettingList: React.FC = () => {
  const { data: constructionItems } = useConstructionItems().index();
  const vendorCompanyId = useIdQueryHandler('vendorCompanyId');
  const { data: companyVendors } = useCompanyVendors().index();
  const vendorCompany = React.useMemo(() => {
    if (companyVendors === null || vendorCompanyId === null) {
      return null;
    }
    return (
      companyVendors.find(
        (company) => company.vendorCompany?.id === vendorCompanyId
      )?.vendorCompany ?? null
    );
  }, [companyVendors, vendorCompanyId]);
  const renderItemSettings = React.useCallback(
    (constructionItem: ConstructionItem): ReactNode => (
      <ConstructionItemSettingListRow
        key={constructionItem.id}
        constructionItem={constructionItem}
      />
    ),
    []
  );
  if (constructionItems === null || vendorCompany === null) {
    return null;
  }
  return (
    <div>
      <h1 className={Styles.TitleList}>
        <span className={Styles.vendorCompanyName}>{vendorCompany.name}</span>
        <span className={SettingStyles.SettingTitle}>工事単価一覧</span>
      </h1>
      <table className={Styles.List}>
        <thead>
          <tr className={clsx(Styles.ListRow, Styles.Header)}>
            <th>項目名</th>
            <th>単価</th>
          </tr>
        </thead>
        <tbody>{constructionItems.map(renderItemSettings)}</tbody>
      </table>
    </div>
  );
};
