import React from 'react';
import { ChangerLabels, DealHistory } from '~/models/DealHistory';
import Styles from './DealHistoryRow.module.scss';
import { formatDate } from '~/utils/DateUtil';

type Props = DeepReadonly<{
  dealHistory: DealHistory;
}>;

export const DealHistoryRow: React.FC<Props> = ({ dealHistory }: Props) => (
  <div className={Styles.Row}>
    <div className={Styles.Top}>
      <span className={Styles.Text}>{dealHistory.historyText}</span>
      <span className={Styles.Datetime}>
        {formatDate(dealHistory.createdAt, true)}
      </span>
    </div>
    <div className={Styles.Bottom}>
      <span>{ChangerLabels[dealHistory.changer]}</span>
      {dealHistory.staff !== null && <span>：{dealHistory.staff.name}</span>}
      {dealHistory.vendorUser !== null && (
        <span>：{dealHistory.vendorUser.name}</span>
      )}
    </div>
  </div>
);
