import { ProductParam, ProductCode } from '@itandi/itandi-bb-ui';
import useItandiAccount from '~/hooks/useItandiAccount';

type ReturnType = {
  // NOTE: ひとまずNSKと関わりのあるアプリケーションのみ
  useBKK: boolean;
  useKT: boolean;
  useSK: boolean;
};

/**
 * @for client
 */
export const useUsingApplications = (): ReturnType => {
  const { data: currentAccount } = useItandiAccount().currentAccount();
  const detectProductCode = (productCode: ProductCode): boolean => {
    if (currentAccount === null) {
      return false;
    }
    return currentAccount.productParams.some(
      (param: ProductParam) => param.code === productCode
    );
  };
  return {
    useBKK: detectProductCode('bkk'),
    useKT: detectProductCode('kt'),
    useSK: detectProductCode('sk')
  };
};
