import useSWR from 'swr';
import { DealHistory } from '~/models/DealHistory';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: (dealId: number) => `/vendors/deals/${dealId}/deal_histories`
} as const;

type TIndex = (dealId: number) => SwrReturnType<DealHistory[]>;
type ReturnType = {
  useIndex: TIndex;
};

export const useDealHistories = (): ReturnType => {
  const useIndex: TIndex = (dealId: number | null) => {
    const { data, mutate, error } = useSWR<DealHistory[]>(
      dealId === null ? null : paths.index(dealId)
    );
    return {
      data: data ?? [],
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex };
};
