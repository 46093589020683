import { object, string } from 'yup';
import { VendorCompany } from '~/models/VendorCompany';

export type CompanyVendor = {
  id: number;
  seq: number;
  name: string;
  email: string;
  vendorKey: string;
  showTenantDefault: boolean;
  skipEstimationDefault: boolean;
  defaultDealManagementType: 'with_vendor' | 'own';
  vendorCompany: VendorCompany | null;
  companyVendorPayload: { id: number } | null;
  completionReportImageRequired: boolean;
  vendorEstimationRequired: boolean;
  managementRequiredByVendor: boolean;
  completionReportImageRequiredByVendor: boolean;
  cautionaryNote: string;
  cautionaryNoteHtml: string;
};

export const getCompanyVendorNameWithStatus = (
  companyVendor: CompanyVendor
): string =>
  `${
    (companyVendor.vendorCompany &&
      companyVendor.defaultDealManagementType === 'own') ||
    companyVendor.vendorCompany === null
      ? '非共有：'
      : ''
  }${companyVendor.name}`;

export const convertToComboBoxOption = (
  companyVendor: CompanyVendor
): { label: string; value: string } => ({
  label: companyVendor.name,
  value: String(companyVendor.id)
});

export type CompanyVendorEdit = Omit<
  CompanyVendor,
  | 'id'
  | 'seq'
  | 'companyVendorPayload'
  | 'vendorEstimationRequired'
  | 'managementRequiredByVendor'
  | 'completionReportImageRequiredByVendor'
  | 'cautionaryNote'
  | 'cautionaryNoteHtml'
>;

export const validationSchemaCompanyVendor = object().shape({
  name: string().required('入力してください'),
  email: string()
    .nullable()
    .email('メールアドレスの形式を確認してください')
    .required('入力してください')
});
