import {
  FormState,
  UseFormSetValue,
  UseFormGetValues
} from 'react-hook-form/dist/types/form';
import { formatDate } from '~/utils/DateUtil';

export const onInvalidScroll = (
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types,@typescript-eslint/no-explicit-any
  formState: FormState<Record<string, any>>
): void => {
  const errorValues = Object.values(formState.errors);
  if (errorValues !== undefined && errorValues.length > 0) {
    const inputRef: HTMLInputElement | null =
      errorValues[0]?.ref instanceof HTMLInputElement
        ? errorValues[0].ref
        : null;
    if (inputRef !== null) {
      inputRef.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export const onChangeFile = (
  e: React.ChangeEvent<HTMLInputElement>,
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
): void => {
  const { files } = e.target;
  if (files !== null) {
    const uploadFile: File = files[0];
    setValue(name, uploadFile);
  }
};

export const onClickFileLink = (
  e: React.MouseEvent,
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
): void => {
  const file = getValues(name);
  if (file !== null) {
    e.preventDefault();
    const url = URL.createObjectURL(file);
    window.open(url);
  }
};

// DatePickerで単体の日付を取得する
export const onChangeDate = (
  date: Date | [Date | null, Date | null] | null,
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
): void => {
  if (date === null || Array.isArray(date)) {
    setValue(name, null);
    return;
  }
  const dateStr = formatDate(date);
  setValue(name, dateStr);
};
