import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { VendorCompany } from '~/models/VendorCompany';

const paths = {
  index: '/admin/vendor_companies'
};

type TIndex = () => SwrReturnType<VendorCompany[]>;

type ReturnType = {
  index: TIndex;
};

export const useVendorCompanies = (): ReturnType => {
  const index: TIndex = () => {
    const { data, error, mutate } = useSWR<VendorCompany[]>(paths.index);
    return {
      data: data ?? null,
      error,
      isLoading: !data && !error,
      mutate
    };
  };
  return { index };
};
