import React, { ReactNode } from 'react';
import Styles from './DropdownList.module.scss';

type Props = {
  children: ReactNode;
};

const PDropdownList: React.FC<Props> = ({ children }: Props) => (
  <div className={Styles.DropdownList}>{children}</div>
);

export const DropdownList = React.memo(PDropdownList);
