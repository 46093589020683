import React, { ReactNode } from 'react';
import { VendorHeader } from '~/components/common/header/VendorHeader';
import { useSessions } from '~/hooks/vendors/useSessions';
import Loading from '~/components/common/parts/loading/Loading';
import { UserLoginModal } from '~/components/common/user_login_modal/UserLoginModal';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';
import { NskResponse } from '~/models/Api';
import { create as loginStaff } from '~/utils/api/vendors/sessions/VendorUser';

type Props = {
  children: ReactNode;
  loginRequire?: boolean;
};

export const VendorLayoutV2: React.FC<Props> = ({
  children,
  loginRequire = true
}: Props) => {
  const { data: vendorUsers } = useVendorUsers().index();
  const { data: session, mutate: mutateSession } = useSessions().index();
  if (session === null || vendorUsers === null) {
    return <Loading isLoading />;
  }
  if (loginRequire && !session.admin && session.vendorUser === null) {
    // show login modal
    const handleMutateSession = async (): Promise<void> => {
      mutateSession && (await mutateSession());
    };
    const handleLogin = (
      id: number,
      password: string | null
    ): Promise<NskResponse<void>> => loginStaff(id, password);
    return (
      <>
        <VendorHeader />
        <main>
          <UserLoginModal
            handleLogin={handleLogin}
            loginStaffs={vendorUsers}
            mutateSession={handleMutateSession}
          />
        </main>
      </>
    );
  }
  return (
    <>
      <VendorHeader />
      <main>{children}</main>
    </>
  );
};
