import React from 'react';
import { Input, InputSelect } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { MarketCompanySetting } from '~/models/CompanySetting';
import Styles from './Form.module.scss';

type Props = DeepReadonly<{
  isConfirm?: boolean;
}>;

type Prefecture = {
  id: number;
  name: string;
};

const prefectures: Prefecture[] = [
  { id: 1, name: '東京都' },
  { id: 2, name: '神奈川県' },
  { id: 3, name: '千葉県' },
  { id: 4, name: '埼玉県' },
  { id: 5, name: '大阪府' }
];

export const CompanyStoreForm: React.FC<Props> = ({
  isConfirm = false
}: Props) => {
  const { register, getValues, formState } =
    useFormContext<MarketCompanySetting>();
  const [values, setValues] = React.useState<MarketCompanySetting | null>(null);
  React.useEffect(() => {
    if (isConfirm) {
      setValues(getValues());
    }
  }, [isConfirm, getValues]);
  if (isConfirm && values === null) {
    return null;
  }
  const { errors } = formState;
  return (
    <div className={Styles.FormGroup}>
      <div className={Styles.GroupLabel}>店舗情報</div>
      <div className={Styles.FormList}>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            郵便番号
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.zipcode}</span>
            ) : (
              <Input
                isInvalid={errors.zipcode != null}
                placeholder="例）1234567"
                {...register('zipcode')}
              />
            )}
            {errors.zipcode != null && (
              <div className={Styles.ErrorMessage}>
                {errors.zipcode.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            都道府県
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.state}</span>
            ) : (
              <div>
                <InputSelect {...register('state')}>
                  <option value="">選択してください</option>
                  {prefectures.map((p: Prefecture) => (
                    <option key={p.id} value={p.name}>
                      {p.name}
                    </option>
                  ))}
                </InputSelect>
              </div>
            )}
            {!isConfirm && (
              <p className={Styles.Description}>※エリアは順次拡大予定です。</p>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            市区町村・番地
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.city}</span>
            ) : (
              <Input
                isInvalid={errors.city != null}
                placeholder="例）港区3丁目2-1"
                {...register('city')}
              />
            )}
            {errors.city != null && (
              <div className={Styles.ErrorMessage}>{errors.city.message}</div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            建物・部屋番号
            {!isConfirm && <span className={Styles.OptionalLabel}>任意</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.otherAddress}</span>
            ) : (
              <Input
                isInvalid={errors.otherAddress != null}
                placeholder="例）六本木グランドタワー40階"
                {...register('otherAddress')}
              />
            )}
            {errors.otherAddress != null && (
              <div className={Styles.ErrorMessage}>
                {errors.otherAddress.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            店舗の代表電話番号
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.phoneNumber}</span>
            ) : (
              <Input
                isInvalid={errors.phoneNumber != null}
                placeholder="例）06-0000-0000"
                {...register('phoneNumber')}
              />
            )}
            {errors.phoneNumber != null && (
              <div className={Styles.ErrorMessage}>
                {errors.phoneNumber.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            通知用メールアドレス
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.email}</span>
            ) : (
              <Input
                isInvalid={errors.email != null}
                placeholder="例）notification@example.com"
                {...register('email')}
              />
            )}
            {errors.email != null && (
              <div className={Styles.ErrorMessage}>{errors.email.message}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
