import React from 'react';
import { RiStickyNoteLine } from '@itandi/itandi-bb-ui';
import Styles from '../DealCard.module.scss';
import { Deal } from '~/models/Deal';
import { AccordionHtml } from '~/components/common/accordion_html/AccordionHtml';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { DealNoteWithinVendorModal } from './DealNoteWithinVendorModal';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  deal: Deal;
  handleMutate: () => Promise<void>;
};

/**
 * 施工会社内案件メモCard
 * @for vendor
 */
export const DealNoteWithinVendorCard: React.FC<Props> = ({
  deal,
  handleMutate
}: Props) => {
  const originDealVendor = getOriginDealVendor(deal);
  const { data: session } = useSessions().index();
  const { isOpen, handleClose, handleOpen } = useOpenHandler();
  const noteWithinVendorHtml = originDealVendor?.noteWithinVendorHtml ?? '';

  if (originDealVendor === null || session === null) return null;

  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiStickyNoteLine className={Styles.TitleIcon} />
        社内案件メモ（管理会社に共有されません）
        <div className={Styles.EditButtonWrapper}>
          {isOriginVendor(deal, session.vendorCompany.id) && (
            <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
          )}
          <DealNoteWithinVendorModal
            deal={deal}
            handleClose={handleClose}
            handleMutate={handleMutate}
            isOpen={isOpen}
          />
        </div>
      </h3>
      <div className={Styles.Content}>
        {noteWithinVendorHtml === '' ? (
          <span className={Styles.NotYet}>未入力</span>
        ) : (
          <AccordionHtml html={noteWithinVendorHtml} maxHeightForClosed={170} />
        )}
      </div>
    </div>
  );
};
