import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import {
  DealSurvey,
  SurveyAnswer,
  SurveyAnswers,
  validationSurveyAnswersSchema
} from '~/models/Survey';
import { SurveyForm } from '~/components/common/survey/SurveyForm';
import Styles from './SurveyForm.module.scss';

type Props = {
  handleAccept: ((surveyAnswers: SurveyAnswer[]) => Promise<void>) | null;
  isOpen: boolean;
  handleClose: () => void;
  surveys: DealSurvey[];
};

export const SurveyModal: React.FC<Props> = ({
  handleAccept,
  isOpen,
  handleClose,
  surveys
}: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const methods = useForm<SurveyAnswers>({
    mode: 'onChange',
    resolver: yupResolver(validationSurveyAnswersSchema)
  });

  const onSubmit = methods.handleSubmit((values) => {
    if (handleAccept === null) return;
    setIsSubmitting(true);
    handleAccept(values.answers).then();
    setIsSubmitting(false);
  });

  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader icon={RiCheckboxCircleLine} title="アンケート" />
      <ModalBody>
        <div className={Styles.Forms}>
          <FormProvider {...methods}>
            <SurveyForm surveys={surveys} />
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button disabled={isSubmitting} onClick={onSubmit} variant="primary">
            この工事の全てのステップを完了する
          </Button>
        }
      />
    </Modalv2>
  );
};
