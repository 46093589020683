import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiAddBoxLine, RiEdit2Line } from 'react-icons/ri';
import {
  buildInitialTodoTemplateEdit,
  TodoTemplate,
  TodoTemplateEdit,
  validationSchema
} from '~/models/TodoTemplate';
import { DealType } from '~/models/Deal';
import { TodoTemplateModalForm } from '~/components/common/todo_templates/TodoTemplateModalForm';
import { onInvalidScroll } from '~/utils/FormUtil';
import { create, update } from '~/utils/api/TodoTemplate';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  dealType: DealType;
  subtypeId: number | null;
  todoTemplate?: TodoTemplate | null;
  isOpen: boolean;
  handleMutate: () => Promise<void>;
  handleClose: () => void;
}>;

export const TodoTemplateModal: React.FC<Props> = ({
  dealType,
  subtypeId,
  isOpen,
  handleClose,
  handleMutate,
  todoTemplate = null
}: Props) => {
  const methods = useForm<TodoTemplateEdit>({
    defaultValues:
      todoTemplate ?? buildInitialTodoTemplateEdit(dealType, subtypeId),
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const handleModalSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: TodoTemplateEdit) => {
        if (todoTemplate === null) {
          // create
          const response = await create(submitValue);
          if (isSuccess(response)) {
            await handleMutate();
            handleClose();
            toast.success('作成しました');
            methods.reset();
            return;
          }
          toast.error('エラーが発生しました');
          return;
        }
        // update
        const response = await update(todoTemplate.id, submitValue);
        if (isSuccess(response)) {
          await handleMutate();
          handleClose();
          toast.success('更新しました');
          return;
        }
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, todoTemplate, handleMutate, handleClose]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader
        icon={todoTemplate ? RiEdit2Line : RiAddBoxLine}
        title={
          todoTemplate ? 'TODOテンプレート編集' : 'TODOテンプレート新規作成'
        }
      />
      <ModalBody>
        <FormProvider {...methods}>
          <TodoTemplateModalForm />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button
            onClick={(): void => {
              methods.reset();
              handleClose();
            }}
            variant="secondary"
          >
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={methods.formState.isSubmitting}
            onClick={handleModalSubmit}
            variant="primary"
          >
            保存
          </Button>
        }
      />
    </Modalv2>
  );
};
