import React from 'react';
import Styles from './ChatMessagePane.module.scss';
import { ChatMessagePaneHeader } from '~/components/common/chat/messages/ChatMessagePaneHeader';
import { ChatMessagePaneBodyClient } from '~/components/common/chat/messages/ChatMessagePaneBodyClient';
import { ChatMessagePaneBodyVendor } from '~/components/common/chat/messages/ChatMessagePaneBodyVendor';

type Props = DeepReadonly<{
  isVendor?: boolean;
}>;

export const ChatMessagePane: React.FC<Props> = ({
  isVendor = false
}: Props) => (
  <div className={Styles.ChatMessagePane}>
    <ChatMessagePaneHeader isVendor={isVendor} />
    {isVendor ? <ChatMessagePaneBodyVendor /> : <ChatMessagePaneBodyClient />}
  </div>
);
