import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { MarketCompanySetting } from '~/models/CompanySetting';
import Styles from './Form.module.scss';

type Props = DeepReadonly<{
  isConfirm?: boolean;
}>;

export const StaffForm: React.FC<Props> = ({ isConfirm = false }: Props) => {
  const { register, getValues, formState } =
    useFormContext<MarketCompanySetting>();
  const [values, setValues] = React.useState<MarketCompanySetting | null>(null);
  React.useEffect(() => {
    if (isConfirm) {
      setValues(getValues());
    }
  }, [isConfirm, getValues]);
  if (isConfirm && values === null) {
    return null;
  }
  const { errors } = formState;
  return (
    <div className={Styles.FormGroup}>
      <div className={Styles.GroupLabel}>担当者情報</div>
      <div className={Styles.FormList}>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            氏名
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.staffName}</span>
            ) : (
              <Input
                isInvalid={errors.staffName != null}
                placeholder="例）内装 太郎"
                {...register('staffName')}
              />
            )}
            {errors.staffName != null && (
              <div className={Styles.ErrorMessage}>
                {errors.staffName.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            メールアドレス
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.staffEmail}</span>
            ) : (
              <Input
                isInvalid={errors.staffEmail != null}
                placeholder="例）itandi.tarou@example.com"
                {...register('staffEmail')}
              />
            )}
            {errors.staffEmail != null && (
              <div className={Styles.ErrorMessage}>
                {errors.staffEmail.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            電話番号
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.staffPhoneNumber}</span>
            ) : (
              <Input
                isInvalid={errors.staffPhoneNumber != null}
                placeholder="例）070-1234-5678"
                {...register('staffPhoneNumber')}
              />
            )}
            {errors.staffPhoneNumber != null && (
              <div className={Styles.ErrorMessage}>
                {errors.staffPhoneNumber.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
