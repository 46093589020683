import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import { BillingType, DealBilling } from '~/models/DealBilling';

const paths = {
  create: '/deals/:deal_id/deal_billings',
  update: '/deals/:deal_id/deal_billings/:id',
  destroy: '/deals/:deal_id/deal_billings/:id',
  updateSeq: '/deals/:deal_id/deal_billings/update_seq'
};

export const create = async (
  dealBilling: DealConstructionEdit,
  dealId: number,
  billingType: BillingType
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    dealBilling,
    billingType
  });

export const update = async (
  dealBilling: DealConstructionEdit,
  dealBillingId: number,
  dealId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':id', String(dealBillingId))
      .replace(':deal_id', String(dealId)),
    { dealBilling }
  );

export const destroy = async (
  dealBilling: DealBilling,
  dealId: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':id', String(dealBilling.id))
      .replace(':deal_id', String(dealId))
  );

export const updateSeq = async (
  dealBillings: DealConstruction[],
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeq.replace(':deal_id', String(dealId)), {
    dealBillings
  });
