import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/staffs/profiles/new/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/settings/staffs/new';

const Home: NextPage = () => (
  <>
    <NskHead title="担当者追加" />
    <Component />
  </>
);

export default Home;
