import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Modal } from '~/components/common/parts/modal/Modal';
import Styles from './StatusReasonModal.module.scss';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';

type Props = {
  handleClose: () => void;
  handleAccept: (reason: string) => Promise<void>;
  label: string;
  title: string;
  actionText: string;
  confirmText: string;
};

export const StatusReasonModal: React.FC<Props> = ({
  handleClose,
  handleAccept,
  label,
  title,
  actionText,
  confirmText
}: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [reason, setReason] = React.useState<string>('');
  const onChangeReason = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setReason(e.target.value);
    },
    []
  );

  const onClickSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    await handleAccept(reason);
    setIsSubmitting(false);
  };
  return (
    <Modal handleClose={handleClose} isOpen title={title}>
      <p className={Styles.Description}>{confirmText}</p>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <FormControl
          as="textarea"
          onChange={onChangeReason}
          rows={4}
          value={reason}
        />
      </FormGroup>
      <div className={Styles.Buttons}>
        <button
          className={Styles.CancelButton}
          onClick={handleClose}
          type="button"
        >
          キャンセル
        </button>
        <button
          className={Styles.SubmitButton}
          disabled={isSubmitting}
          onClick={onClickSubmit}
          type="button"
        >
          {actionText}
        </button>
      </div>
    </Modal>
  );
};
