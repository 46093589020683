import React from 'react';
import { toast } from 'react-toastify';
import Styles from './ConstructionAddForm.module.scss';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import { isSuccess } from '~/utils/api/api_handler';
import { DealConstructionEdit } from '~/models/DealConstruction';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';

type Props = {
  handleCreate: (
    submitValue: DealConstructionEdit
  ) => Promise<NskResponse<void>>;
  constructionItems: ConstructionItem[];
  mutateConstructions: () => Promise<void>;
};
export const ConstructionAddForm: React.FC<Props> = ({
  handleCreate,
  constructionItems,
  mutateConstructions
}: Props) => {
  const handleSave = async (
    submitValue: DealConstructionEdit
  ): Promise<boolean> => {
    const response = await handleCreate(submitValue);
    if (isSuccess(response)) {
      await mutateConstructions();
      toast.success('追加しました');
      return true;
    }
    toast.error('エラーが発生しました');
    return false;
  };
  return (
    <div className={Styles.EstimateAddForm}>
      <ConstructionForm
        constructionItems={constructionItems}
        handleSave={handleSave}
        type="add"
      />
    </div>
  );
};
