import React, { ReactNode } from 'react';
import Styles from './TableHeader.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const TableHeader: React.FC<Props> = ({ children }: Props) => (
  <thead>
    <tr className={Styles.TableHeader}>{children}</tr>
  </thead>
);
