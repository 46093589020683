import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Deal } from '~/models/Deal';
import { DealEditorFooter } from '~/components/common/deals/new/DealEditorFooter';
import { buildDealParam, DealParam, validationSchema } from '~/models/DealForm';
import { DealEditorBody } from './DealEditorBody';

type Props = DeepReadonly<{
  deal?: Deal | null;
  useProperty: boolean;
}>;

/**
 * 案件追加・修正
 *
 * @for client
 * @param _deal
 * @param useProperty
 * @constructor
 */
export const DealEditor: React.FC<Props> = ({
  deal: _deal = null,
  useProperty
}: Props) => {
  const methods = useForm<DealParam>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: buildDealParam(useProperty)
  });
  return (
    <div>
      <FormProvider {...methods}>
        <DealEditorBody />
        <DealEditorFooter />
      </FormProvider>
    </div>
  );
};
