import React from 'react';
import { TableRow } from '~/components/common/parts/table/TableRow';
import {
  summaryFileTemplateLabels,
  SummaryFileTemplateType
} from '~/models/SummaryFileTemplate';
import { useSummaryFileTemplates } from '~/hooks/useSummaryFileTemplates';
import { SummaryFileTemplateRowNoSetting } from '~/components/specifics/settings/file_templates/SummaryFileTemplateRowNoSetting';
import { SummaryFileTemplateRowUploaded } from '~/components/specifics/settings/file_templates/SummaryFileTemplateRowUploaded';

type Props = {
  fileType: SummaryFileTemplateType;
};

export const SummaryFileTemplateRow: React.FC<Props> = ({
  fileType
}: Props) => {
  const { data: templates } = useSummaryFileTemplates().useIndex();
  const template = React.useMemo(() => {
    if (templates === null) return null;
    return templates.find((t) => t.templateType === fileType) ?? null;
  }, [fileType, templates]);
  if (templates === null) {
    return null;
  }
  return (
    <TableRow>
      <td>{summaryFileTemplateLabels[fileType]}</td>
      <td>
        {template === null ? (
          <SummaryFileTemplateRowNoSetting fileType={fileType} />
        ) : (
          <SummaryFileTemplateRowUploaded template={template} />
        )}
      </td>
    </TableRow>
  );
};
