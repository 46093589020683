import React from 'react';
import { Deal } from '~/models/Deal';
import { ProductLink } from '~/components/common/products/ProductLink';
import Styles from './ProductLinkList.module.scss';

type Props = {
  deal: Deal;
};

export const ProductLinkList: React.FC<Props> = ({ deal }: Props) => {
  if (deal.propertyType === null && deal.leasingId === null) {
    return null;
  }
  return (
    <div className={Styles.List}>
      {deal.propertyType === 'room' && (
        <ProductLink
          name="部屋"
          productCode="bkk"
          title="物件管理くん"
          url={`${process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_BKK}/rent_rooms/${deal.propertyId}/edit`}
        />
      )}
      {deal.propertyType === 'building' && (
        <ProductLink
          name="建物"
          productCode="bkk"
          title="物件管理くん"
          url={`${process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_BKK}/rent_room_buildings/${deal.propertyId}/edit`}
        />
      )}
      {deal.leasingId !== null && (
        <ProductLink
          name="賃貸借契約"
          productCode="kt"
          title="入居者管理くん"
          url={`${process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_KT}/leasings/${deal.leasingId}`}
        />
      )}
    </div>
  );
};
