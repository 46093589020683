import useSWR from 'swr';
import { useRouter } from 'next/router';
import { AxiosPromise } from 'axios';
import ApiClient from '~/utils/api/api_client';
import { SwrReturnType } from '~/models/Api';
import { ItandiAccount } from '~/models/ItandiAccount';

export const loginApi = async (): Promise<string> => {
  const { data } = await ApiClient.get<{ url: string }>(
    '/itandi_account_sessions/new'
  );
  return data.url;
};

type TLogin = () => void;
type TLoginCallback = (
  params: Record<string, string | string[] | undefined>
) => AxiosPromise;
type TLogout = () => void;
type TCurrentAccount = () => SwrReturnType<ItandiAccount>;

type ReturnType = {
  login: TLogin;
  loginCallback: TLoginCallback;
  logout: TLogout;
  currentAccount: TCurrentAccount;
};

const useItandiAccount = (): ReturnType => {
  const router = useRouter();
  const login: TLogin = () => {
    loginApi().then((url: string): void => {
      router.replace(url).then();
    });
  };

  const loginCallback: TLoginCallback = (params) =>
    ApiClient.get('/itandi_accounts_callback', { params });

  const currentAccount: TCurrentAccount = () => {
    const { data, mutate, error } = useSWR<ItandiAccount>(
      '/itandi_account_sessions/current_account',
      { revalidateOnFocus: true }
    );

    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !error && !data,
      error
    };
  };

  const logout: TLogout = () =>
    ApiClient.delete('logout').then(({ data }: { data: { url: string } }) =>
      router.replace(data.url)
    );

  return { login, loginCallback, logout, currentAccount };
};

export default useItandiAccount;
