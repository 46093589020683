import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import Link from 'next/link';
import Styles from './ChatMessagePaneHeader.module.scss';
import { useMessageSelectedContext } from '~/components/common/chat/ChatPagesContainer';
import { PATH as CLIENT_PATH } from '~/pages/deals/[id]';
import { PATH as VENDOR_PATH } from '~/pages/vendors/deals/[id]';

type Props = DeepReadonly<{
  isVendor?: boolean;
}>;

export const ChatMessagePaneHeader: React.FC<Props> = ({
  isVendor = false
}: Props) => {
  const { selectedSummary, setSelectedSummary } = useMessageSelectedContext();
  const onClickClose = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      setSelectedSummary && setSelectedSummary(null);
    },
    [setSelectedSummary]
  );
  if (selectedSummary === null) {
    return <></>;
  }
  return (
    <div className={Styles.HeaderContainer}>
      <a className={Styles.CloseLink} href="#" onClick={onClickClose}>
        <RiArrowRightSLine className={Styles.CloseIcon} />
      </a>
      <h3 className={Styles.TimelineHeader}>
        <div
          className={Styles.RoomName}
        >{`${selectedSummary.deal.name} ${selectedSummary.deal.roomNumber}`}</div>
        <div className={Styles.PartnerName}>
          {isVendor
            ? selectedSummary.deal.companySetting.name
            : selectedSummary.deal.originVendorCompany?.name}
        </div>
      </h3>
      <div className={Styles.LinkWrapper}>
        <Link
          className={Styles.DealLink}
          href={
            isVendor
              ? VENDOR_PATH(selectedSummary.deal.id)
              : CLIENT_PATH(selectedSummary.deal.id)
          }
          target="_blank"
        >
          案件詳細
        </Link>
      </div>
    </div>
  );
};
