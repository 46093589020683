import { NskResponse } from '~/models/Api';
import { postRequest, putRequest } from '~/utils/api/api_handler';
import { DealParam, DealUpdateParam } from '~/models/DealForm';
import { getKeys } from '../getKeys';

const paths = {
  create: '/deals',
  update: '/deals/:id',
  destroy: '/deals/:id',
  floorMap: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:id/floor_map`,
  leaseContractFile: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:id/lease_contract_file?file_number=:file_number`
};

export const create = async (
  deal: DealParam
): Promise<NskResponse<{ id: number }>> => {
  const formData = new FormData();
  getKeys(deal).forEach((key) => {
    if (deal[key] === null) return;
    if (key === 'staff' || key === 'dealSubtype' || key === 'companyVendor') {
      formData.append(`deal[${key}][id]`, String(deal[key]?.id));
    } else if (key === 'property') {
      formData.append(`deal[${key}][id]`, String(deal[key]?.propertyId));
    } else if (
      key === 'useProperty' ||
      key === 'showTenant' ||
      key === 'skipEstimation'
    ) {
      formData.append(`deal[${key}]`, String(deal[key]));
    } else if (key === 'dealRepair') {
      getKeys(deal[key] ?? {}).forEach((repairKey) => {
        const dealKey = deal[key];
        if (dealKey === null) return;
        formData.append(`deal[${key}][${repairKey}]`, dealKey[repairKey]);
      });
    } else {
      const dealKey = deal[key];
      if (dealKey === null) return;
      formData.append(`deal[${key}]`, dealKey);
    }
  });
  return postRequest(paths.create, formData);
};

export const update = async (
  dealId: number,
  deal: DealUpdateParam
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(dealId)), deal);

export const getFloorMapPath = (dealId: number): string =>
  paths.floorMap.replace(':id', String(dealId));

export const getLeaseContractFilePath = (
  dealId: number,
  fileNumber: number
): string =>
  paths.leaseContractFile
    .replace(':id', String(dealId))
    .replace(':file_number', String(fileNumber));
