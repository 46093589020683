import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { MarketCompanySetting } from '~/models/CompanySetting';
import Styles from './Form.module.scss';

type Props = DeepReadonly<{
  isConfirm?: boolean;
}>;

export const CompanyForm: React.FC<Props> = ({ isConfirm = false }: Props) => {
  const { register, getValues, formState } =
    useFormContext<MarketCompanySetting>();
  const [values, setValues] = React.useState<MarketCompanySetting | null>(null);
  React.useEffect(() => {
    if (isConfirm) {
      setValues(getValues());
    }
  }, [isConfirm, getValues]);
  if (isConfirm && values === null) {
    return null;
  }
  const { errors } = formState;
  return (
    <div className={Styles.FormGroup}>
      <div className={Styles.GroupLabel}>会社情報</div>
      <div className={Styles.FormList}>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            会社名
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.companyName}</span>
            ) : (
              <Input
                isInvalid={errors.companyName != null}
                placeholder="例）サンプル株式会社"
                {...register('companyName')}
              />
            )}
            {errors.companyName != null && (
              <div className={Styles.ErrorMessage}>
                {errors.companyName.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            会社代表者氏名
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{`${values?.ceoLastName} ${values?.ceoFirstName}`}</span>
            ) : (
              <div className={Styles.NameContent}>
                <div>
                  <Input
                    isInvalid={errors.ceoLastName != null}
                    placeholder="例）賃貸"
                    {...register('ceoLastName')}
                  />
                  {errors.ceoLastName != null && (
                    <div className={Styles.ErrorMessage}>
                      {errors.ceoLastName.message}
                    </div>
                  )}
                </div>
                <div>
                  <Input
                    isInvalid={errors.ceoFirstName != null}
                    placeholder="例）太郎"
                    {...register('ceoFirstName')}
                  />
                  {errors.ceoFirstName != null && (
                    <div className={Styles.ErrorMessage}>
                      {errors.ceoFirstName.message}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormBlock}>
          <div className={Styles.FormLabel}>
            居住用管理戸数
            {!isConfirm && <span className={Styles.RequireLabel}>必須</span>}
          </div>
          <div className={Styles.FormContent}>
            {isConfirm ? (
              <span>{values?.managedUnitNumber}</span>
            ) : (
              <Input
                isInvalid={errors.managedUnitNumber != null}
                placeholder="例）500"
                {...register('managedUnitNumber')}
              />
            )}
            {errors.managedUnitNumber != null && (
              <div className={Styles.ErrorMessage}>
                {errors.managedUnitNumber.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
