import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import useItandiAccount from '~/hooks/useItandiAccount';
import { SERVER_ERROR_MESSAGE } from '~/constants/AppConst';
import { ErrorMessage } from '~/components/common/errors/ErrorMessage';
import { UserLoginModal } from '~/components/common/user_login_modal/UserLoginModal';
import { useStaffs } from '~/hooks/useStaffs';
import { NskResponse } from '~/models/Api';
import { login } from '~/utils/api/Staff';
import { PATH as ADMIN_HOME_PATH } from '~/pages/admin';
import { ItandiHeaderContainer } from '~/components/common/header/ItandiHeaderContainer';

type Props = {
  children: ReactNode;
};

export const DefaultV2: React.FC<Props> = ({ children }: Props) => {
  const router = useRouter();
  const itandiAccountApi = useItandiAccount();
  const { data: loginStaffs } = useStaffs().index();
  const {
    data,
    isLoading,
    error,
    mutate: mutateSession
  } = itandiAccountApi.currentAccount();
  const errorMessage: string | null =
    error != null && error.response?.data?.code === 422
      ? error.response?.data?.message ?? SERVER_ERROR_MESSAGE
      : null;
  if (isLoading || loginStaffs === null || data === null) {
    return null;
  }
  if (data.isAdmin && data.company == null) {
    router.replace(ADMIN_HOME_PATH).then();
    return null;
  }
  const handleLoginStaff = (
    id: number,
    password: string | null
  ): Promise<NskResponse<void>> => login(id, password);
  const handleMutateSession = async (): Promise<void> => {
    mutateSession && (await mutateSession());
  };
  return (
    <>
      <ItandiHeaderContainer />
      <main>
        {errorMessage !== null ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          <>
            {data.isAdmin || data.staff ? (
              <>{children}</>
            ) : (
              <UserLoginModal
                handleLogin={handleLoginStaff}
                loginStaffs={loginStaffs}
                mutateSession={handleMutateSession}
              />
            )}
          </>
        )}
      </main>
    </>
  );
};
