import { boolean, object, string } from 'yup';
import { ConstructionItemSetting } from './ConstructionItemSetting';
import { CompanyStoreSetting } from '~/models/CompanyStoreSetting';

export type CompanySetting = {
  companyMasterId: number;
  authId: number;
  name: string;
  baseName: string;
  fromMarket: boolean;
  marketPlan: 'single' | 'multiple' | null;
  antisocialCheckId: string | null;
  ceoLastName: string;
  ceoFirstName: string;
  managedUnitNumber: string;
  useBkkLinkage: boolean;
  useIaStaffLogin: boolean;
  autoFillEstimateAddressType: 'none' | 'company_name';
};

export type CompanySettingWithStore = CompanySetting & {
  companyStoreSettings: CompanyStoreSetting[];
};

// vendorのみcompanySettingからconstructionItemSettingsを表示させたい時
export type CompanySettingWithItemSetting = CompanySetting & {
  constructionItemSettings: ConstructionItemSetting[];
};

export type DealCompanySetting = Pick<
  CompanySetting,
  'companyMasterId' | 'name'
>;

export const getItemSettingLength = (
  itemSettings: DeepReadonly<ConstructionItemSetting[]>,
  vendorCompanyId: number
): number =>
  itemSettings.filter(
    (itemSetting: ConstructionItemSetting) =>
      itemSetting.vendorCompanyId === vendorCompanyId
  ).length;

export const convertToComboBoxOption = (
  companySetting: DealCompanySetting
): { label: string; value: string } => ({
  label: companySetting.name,
  value: String(companySetting.companyMasterId)
});

export type MarketInitialCompanySetting = {
  existSetting: boolean;
  companyName: string;
  ceoLastName: string;
  ceoFirstName: string;
  email: string;
  phoneNumber: string;
  state: string;
  zipcode: string;
  managedUnitNumber: string;
};

export type MarketCompanySetting = {
  marketPlan: 'single' | 'multiple' | null;
  companyName: string;
  ceoLastName: string;
  ceoFirstName: string;
  zipcode: string;
  state: string | null;
  city: string;
  otherAddress: string;
  managedUnitNumber: string;
  phoneNumber: string;
  email: string;
  staffName: string;
  staffEmail: string;
  staffPhoneNumber: string;
  checkTerm: boolean;
};

export const validationSchemaMarketCompanySetting = object().shape({
  marketPlan: string().nullable().required('入力してください'),
  companyName: string().required('入力してください'),
  ceoLastName: string().required('入力してください'),
  ceoFirstName: string().required('入力してください'),
  zipcode: string().required('入力してください'),
  state: string().required('入力してください'),
  city: string().required('入力してください'),
  managedUnitNumber: string().required('入力してください'),
  email: string()
    .email('メールアドレスの形式を確認してください')
    .required('入力してください'),
  staffName: string().required('入力してください'),
  staffEmail: string()
    .email('メールアドレスの形式を確認してください')
    .required('入力してください'),
  staffPhoneNumber: string().required('入力してください'),
  checkTerm: boolean().isTrue('チェックしてください')
});

export type AdminSettingCompanySetting = {
  name: string;
  robotPaymentCode: string;
  fromMarket: boolean;
  useBkkLinkage: boolean;
  useIaStaffLogin: boolean;
  autoFillEstimateAddressType: 'none' | 'company_name';
};

export const validationSchemaAdminCompanySetting = object().shape({
  name: string().required('入力してください')
});
