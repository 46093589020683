import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import Styles from './BuildingSelectRow.module.scss';
import { Property } from '~/models/kensaku_kun/property';

type Props = {
  building: Property<'building'>;
  handleSelectBuilding: (building: Property<'building'>) => void;
};

export const BuildingSelectRow: React.FC<Props> = ({
  building,
  handleSelectBuilding
}: Props) => {
  const onClickRow = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      handleSelectBuilding(building);
    },
    [handleSelectBuilding, building]
  );
  return (
    <a className={Styles.Row} href="#" onClick={onClickRow}>
      <div>
        <div className={Styles.Name}>{building.name}</div>
        <p className={Styles.Address}>{building.address}</p>
      </div>
      <div className={Styles.ArrowIconWrapper}>
        <RiArrowRightSLine />
      </div>
    </a>
  );
};
