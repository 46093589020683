import React, { ReactNode } from 'react';
import { RiCalendarEventLine } from 'react-icons/ri';
import { Form } from 'react-bootstrap';
import Styles from './ScheduleInputForm.module.scss';
import { Deal, matchDealType } from '~/models/Deal';
import { InspectionCandidateDate } from '~/models/InspectionCandidateDate';
import { formatDate } from '~/utils/DateUtil';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { VendorUser } from '~/models/VendorUser';

type Props = {
  deal: Deal;
  values: ScheduleInputFormObject;
  handleChange: (newValues: ScheduleInputFormObject) => void;
  vendorUsers?: VendorUser[] | null;
};

export type ScheduleInputFormObject = {
  inspectionDate: Date | null;
  userId: number | null;
};

/**
 * 立会日程入力フォーム
 * 実施会社の場合は担当者も入力する
 *
 * @for vendors, clients
 */
export const ScheduleInputForm: React.FC<Props> = ({
  deal,
  values,
  handleChange,
  vendorUsers = null
}: Props) => {
  const { dealInspectionCandidateDates: candidateDates } = deal;
  const onChangeInspectionDate = (date: Date | null): void => {
    handleChange({ ...values, inspectionDate: date });
  };
  const onChangeUserId = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    handleChange({ ...values, userId: Number(value) });
  };
  return (
    <div>
      {candidateDates.length > 0 && (
        <div className={Styles.InspectionCandidateDates}>
          <h3 className={Styles.CandidateTitle}>
            <RiCalendarEventLine className={Styles.Icon} />
            {` 入居者からの${
              matchDealType(deal, 'repair') ? '現地調査' : ' 立会'
            }希望日時`}
          </h3>
          {candidateDates.map(
            (candidateDate: InspectionCandidateDate): ReactNode => (
              <div key={candidateDate.id} className={Styles.CandidateDateRow}>
                <span className={Styles.Title}>第{candidateDate.sort}希望</span>
                <span className={Styles.Date}>
                  {formatDate(candidateDate.date)}
                </span>
                {candidateDate.time}
              </div>
            )
          )}
        </div>
      )}
      <FormGroup>
        <FormLabel>
          {`${matchDealType(deal, 'repair') ? '現地調査' : ' 立会'}実施日時`}
        </FormLabel>
        <Datepicker
          onChange={onChangeInspectionDate}
          selected={values.inspectionDate}
          showTimeSelect
        />
      </FormGroup>
      {vendorUsers !== null && (
        <FormGroup>
          <FormLabel>案件担当者</FormLabel>
          <Form.Control
            as="select"
            onChange={onChangeUserId}
            value={values.userId ?? ''}
          >
            <option key={-1} value="">
              選択してください
            </option>
            {vendorUsers.map(
              (user: VendorUser): ReactNode => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              )
            )}
          </Form.Control>
        </FormGroup>
      )}
    </div>
  );
};
