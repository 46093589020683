import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { DealCompanySetting } from '~/models/CompanySetting';

const paths = {
  index: '/vendors/deal_company_settings'
};

type TIndex = (searchWord: string) => SwrReturnType<DealCompanySetting[]>;
type ReturnType = {
  index: TIndex;
};
export const useDealCompanySettings = (): ReturnType => {
  const index: TIndex = (searchWord: string) => {
    const { data, mutate, error, isLoading } = useSWR({
      url: paths.index,
      params: { searchWord }
    });
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  return { index };
};
