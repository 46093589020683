import React from 'react';
import Styles from './FormDescription.module.scss';

type Props = {
  children: string;
};

export const FormDescription: React.FC<Props> = ({ children }: Props) => (
  <p className={Styles.FormDescription}>{children}</p>
);
