import { NskResponse } from '~/models/Api';
import { MailsCreateParams } from '~/models/CompanyStoreMail';
import { deleteRequest, postRequest } from './api_handler';

const paths = {
  create: '/company_store_mails',
  destroy: '/company_store_mails/destroy_mails'
};
export const create = async (
  companyStoreMails: MailsCreateParams
): Promise<NskResponse<void>> => postRequest(paths.create, companyStoreMails);

export const destroy = async (
  deleteIds: number[]
): Promise<NskResponse<void>> =>
  deleteRequest(paths.destroy, { data: { deleteIds } });
