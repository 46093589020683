import React, { ReactNode } from 'react';
import Styles from './FormGroup.module.scss';

type Props = {
  children: ReactNode;
};

export const FormGroup: React.FC<Props> = ({ children }: Props) => (
  <div className={Styles.FormGroup}>{children}</div>
);
