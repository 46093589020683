import { ReactNode } from 'react';
import { Deal, matchDealType } from './Deal';

export const NUMBER_OF_UPLOAD_AT_ONCE = 3;
export const NUMBER_OF_DELETE_AT_ONCE = 10;

export type DealReportImage = {
  type: 'DealInspectionReportImage' | 'DealCompletionReportImage';
  id: number;
  dealId: number;
  seq: number;
  imageUrl: string;
  thumbnailImageUrl: string | null;
  point: string | null;
  note: string | null;
  shared: boolean;
  outputPdfImage: boolean;
  outputPdfNote: boolean;
  construction: 'unspecified' | 'targeted';
  createdAt: string;
};

export type DealInspectionReportImage = DealReportImage & {
  type: 'DealInspectionReportImage';
};

export type DealCompletionReportImage = DealReportImage & {
  type: 'DealCompletionReportImage';
};

export type DealReportBulkUpdateParams = {
  shared?: boolean;
  outputPdfImage?: boolean;
  outputPdfNote?: boolean;
  construction?: 'unspecified' | 'targeted';
};

export const navigationTitle = (deal: Deal): string =>
  `${
    matchDealType(deal, 'repair') ? '現地調査の' : '立会報告'
  }画像を登録してください`;

export type ReportImageBulkAction = {
  children: string;
  danger?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  onClick: () => void | Promise<void>;
};
