import React from 'react';
import cx from 'clsx';
import Styles from './CompanyNotificationSettingList.module.scss';
import { useCompanyNotificationSetting } from '~/hooks/useCompanyNotificationSetting';
import { Switcher } from '~/components/common/parts/switcher/Switcher';
import { update as updateNotification } from '~/utils/api/CompanyNotificationSetting';

export const CompanyNotificationSettingList: React.FC = () => {
  const notificationApi = useCompanyNotificationSetting();
  const { data: notification, mutate: handleMutateNotification } =
    notificationApi.show();
  const handleUpdateInspectionDate = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const newNotification = {
      ...notification,
      inputInspectionDate: e.target.checked
    };
    await updateNotification(newNotification);
    handleMutateNotification && (await handleMutateNotification());
  };
  return (
    <div>
      <table className={Styles.List}>
        <thead>
          <tr className={cx(Styles.ListRow, Styles.Header)}>
            <th />
            <th>通知メール</th>
          </tr>
        </thead>
        <tbody>
          <tr className={Styles.ListRow}>
            {notification !== null && (
              <td className={Styles.SwitchEnable}>
                <Switcher
                  checked={notification.inputInspectionDate}
                  onChange={handleUpdateInspectionDate}
                />
              </td>
            )}
            <td>
              <div className={Styles.Notification}>
                <div className={Styles.Title}>立会日程通知メール</div>
                <div className={Styles.Description}>
                  施工会社が立会日程を入力した際に通知されるメールです
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
