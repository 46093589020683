import { BillingType } from '~/models/DealBilling';

const paths = {
  index: (propertyDealId: number, billingType: BillingType) =>
    `${process.env.NEXT_PUBLIC_API_HOST}/api/property_deals/${propertyDealId}/deal_billing_files?billing_type=${billingType}`
} as const;

export const getDownloadPath = (
  propertyDealId: number,
  billingType: BillingType
): string => paths.index(propertyDealId, billingType);
