import React, { ReactNode } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RiArrowDownSFill } from 'react-icons/ri';
import Styles from './VendorHeader.module.scss';
import { GlobalHeaderSecondNavVendor } from '~/components/common/header/GlobalHeaderSecondNavVendor';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { destroy as logout } from '~/utils/api/vendors/sessions/VendorCompany';
import { PATH } from '~/pages/vendors/login';
import { useSessions } from '~/hooks/vendors/useSessions';

export const VendorHeader: React.FC = () => {
  const router = useRouter();
  const { data: session } = useSessions().index();
  const handleVendorLogout = async (): Promise<void> => {
    await logout();
    await router.push(PATH);
  };
  if (session === null) {
    return null;
  }
  const dropdownTitleDom = (): ReactNode => {
    if (session.admin) {
      return (
        <span className={Styles.DropdownTitle}>
          <span className={Styles.AdminBadge}>Admin</span>
          <span className={Styles.CompanyName}>
            {session.vendorCompany.name}
          </span>
          <RiArrowDownSFill className={Styles.Caret} />
        </span>
      );
    }
    return (
      <span className={Styles.DropdownTitle}>
        {session.vendorUser?.name}
        <img
          alt="avatar"
          className={Styles.AvatarIcon}
          src="/assets/Avatar.svg"
        />
        <RiArrowDownSFill className={Styles.Caret} />
      </span>
    );
  };
  return (
    <header className={Styles.BBHeader} id="header">
      <nav className={Styles.FirstNav}>
        <Link className={Styles.ItandiBBLogoLink} href="/vendors/deals">
          <img
            alt="ITANDI BB"
            className={Styles.ItandiBBLogo}
            src="/itandi_bb_plus_NSK.svg"
          />
        </Link>
        {session !== null && (
          <>
            <div className={Styles.AccountBlock}>
              <SimpleDropdown placement="bottom-end" title={dropdownTitleDom()}>
                <DropdownList>
                  <DropdownMenu onClick={handleVendorLogout}>
                    ログアウト
                  </DropdownMenu>
                </DropdownList>
              </SimpleDropdown>
            </div>
          </>
        )}
      </nav>
      <GlobalHeaderSecondNavVendor isAdmin={session.admin} />
    </header>
  );
};
