import { number, object, string } from 'yup';
import { DealType } from '~/models/Deal';
import { NOW_ACCEPTING } from '~/models/Status';

export type TodoTemplate = {
  id: number;
  dealType: DealType;
  subtypeId: number | null;
  statusId: number;
  title: string;
  memo: string | null;
};

export type TodoTemplateEdit = Omit<TodoTemplate, 'id'>;

export const buildInitialTodoTemplateEdit = (
  dealType: DealType,
  subtypeId: number | null
): TodoTemplateEdit => ({
  dealType,
  subtypeId,
  statusId: NOW_ACCEPTING,
  title: '',
  memo: ''
});

export const validationSchema = object().shape({
  statusId: number().required('入力してください'),
  title: string().required('入力してください')
});

export const filterTodoTemplatesByDealType = (
  dealType: DealType,
  subtypeId: number | null,
  todoTemplates: TodoTemplate[] | null
): TodoTemplate[] =>
  todoTemplates === null
    ? []
    : todoTemplates.filter(
        (todoTemplate) =>
          todoTemplate.dealType === dealType &&
          todoTemplate.subtypeId === subtypeId
      );
