import React, { ReactNode } from 'react';
import { Element, scroller } from 'react-scroll';
import Styles from './ChatMessageArea.module.scss';
import { Message, MessageSpeaker } from '~/models/Message';
import { ChatMessageRow } from '~/components/common/chat/messages/ChatMessageRow';
import { Deal } from '~/models/Deal';
import { postRead as postReadForClient } from '~/utils/api/deals/Message';
import { postRead as postReadForVendor } from '~/utils/api/vendors/deals/Message';
import { useDebounce } from '~/hooks/useDebounce';

type Props = DeepReadonly<{
  isOpen: boolean;
  deal: Deal;
  messages: Message[];
  speaker: MessageSpeaker;
  handleMutate: () => Promise<void>;
}>;

export const ChatMessageArea: React.FC<Props> = ({
  isOpen,
  deal,
  messages,
  speaker,
  handleMutate
}: Props) => {
  const debounce = useDebounce(2000);
  React.useEffect(() => {
    const f = async (): Promise<void> => {
      debounce(async () => {
        if (isOpen) {
          const readMethod =
            speaker === 'maintenance_company'
              ? postReadForClient
              : postReadForVendor;
          await readMethod(deal.id);
          await handleMutate();
        }
      });
      await handleMutate();
      scroller.scrollTo('chat-message-field-bottom', {
        duration: 0,
        containerId: 'chat_messages_field',
        ignoreCancelEvents: true
      });
    };
    f().then();
  }, [isOpen, handleMutate, deal, speaker, debounce]);
  const renderRow = React.useCallback(
    (message: DeepReadonly<Message>): ReactNode => (
      <ChatMessageRow
        key={message.id}
        deal={deal}
        message={message}
        speaker={speaker}
      />
    ),
    [speaker, deal]
  );
  return (
    <div className={Styles.Messages} id="chat_messages_field">
      {messages.map(renderRow)}
      <Element name="chat-message-field-bottom" />
    </div>
  );
};
