import React, { ReactNode } from 'react';
import Styles from './HideInputForm.module.scss';
import { HideInputInnerForm } from '~/components/common/parts/input/HideInputInnerForm';

type Props = {
  viewComponent: ReactNode;
  value: string;
  handleSubmit: (submitValue: string) => void | Promise<void>;
  as?: 'input' | 'textarea';
};

/**
 * 普段はTextで、クリックすると入力フォームになるInput
 *
 * @constructor
 */
export const HideInputForm: React.FC<Props> = ({
  viewComponent,
  value,
  handleSubmit,
  as = 'input'
}: Props) => {
  const [showInput, setShowInput] = React.useState<boolean>(false);
  const handleOpen = React.useCallback(() => setShowInput(true), []);
  const handleClose = React.useCallback(() => setShowInput(false), []);
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      if (!showInput) {
        setShowInput(true);
      }
    }
  };
  return (
    <div>
      {showInput ? (
        <HideInputInnerForm
          as={as}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          initialValue={value}
        />
      ) : (
        <div
          className={Styles.ViewComponentWrapper}
          onClick={handleOpen}
          onKeyDown={onKeyDown}
          role="button"
          tabIndex={0}
        >
          {viewComponent}
        </div>
      )}
    </div>
  );
};
