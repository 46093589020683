import React from 'react';
import { DealSurveyAnswer } from '~/models/Survey';
import Styles from './Answers.module.scss';

type Props = {
  answers: ReadonlyArray<DealSurveyAnswer>;
};

export const DealSurveyAnswers: React.FC<Props> = ({ answers }: Props) => {
  if (answers.length === 0) {
    return null;
  }
  return (
    <>
      <h3 className={Styles.title}>アンケート回答結果</h3>
      <table className={Styles.AnswerTable}>
        <thead>
          <tr className={Styles.AnswerTableHeaderRow}>
            <th>設問</th>
            <th>回答</th>
            <th>備考</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer: DealSurveyAnswer) => (
            <tr key={answer.id} className={Styles.AnswerTableRow}>
              <td className={Styles.Question}>{answer.title}</td>
              <td className={Styles.Option}>{answer.text}</td>
              <td className={Styles.Note}>
                {answer.note !== null && answer.note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
