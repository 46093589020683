import React, { ReactNode } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { Portal } from 'react-overlays';
import { RiCalendarLine } from 'react-icons/ri';
import Styles from './Datepicker.module.scss';

registerLocale('ja', ja);
type Props = ReactDatePickerProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CalenderContainer = ({ children }: any): ReactNode => {
  if (typeof document !== 'undefined') {
    const el = document.body;
    return <Portal container={el}>{children}</Portal>;
  }
  return <></>;
};

export const Datepicker: React.FC<Props> = (props: Props) => {
  const { showTimeSelect } = props;
  return (
    <div className={Styles.DatePickerWrapper}>
      <RiCalendarLine className={Styles.Icon} />
      <DatePicker
        dateFormat={`yyyy/MM/dd${showTimeSelect ? ' HH:mm' : ''}`}
        locale="ja"
        popperClassName={Styles.DatePicker}
        popperContainer={CalenderContainer}
        {...props}
      />
    </div>
  );
};
