const paths = {
  floorMap: `${process.env.NEXT_PUBLIC_API_HOST}/api/vendors/deals/:id/floor_map`,
  leaseContractFile: `${process.env.NEXT_PUBLIC_API_HOST}/api/vendors/deals/:id/lease_contract_file?file_number=:file_number`
};

export const getFloorMapPath = (dealId: number): string =>
  paths.floorMap.replace(':id', String(dealId));

export const getLeaseContractFilePath = (
  dealId: number,
  fileNumber: number
): string =>
  paths.leaseContractFile
    .replace(':id', String(dealId))
    .replace(':file_number', String(fileNumber));
