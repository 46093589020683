import React from 'react';
import { useSearchParams } from 'next/navigation';
import { Breadcrumbs, BreadcrumbsItem, Button } from '@itandi/itandi-bb-ui';
import SettingStyles from '../../../../SettingStyle.module.scss';
import Styles from './Page.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { PATH } from '~/pages/settings/imports';
import { importTypeLabels, isImportType } from '~/models/ImportLog';
import { useImportLogs } from '~/hooks/admin/useImportLogs';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { getDownloadPath } from '~/utils/api/ImportLogErrorDownload';

export const Page: React.FC = () => {
  const searchParams = useSearchParams();
  const importType = searchParams.get('importType');
  const importLogId = useIdQueryHandler();
  const { data: importLog } = useImportLogs().useShow(importLogId);
  const onClickDownload = React.useCallback(() => {
    if (importLogId === null) {
      return;
    }
    window.open(getDownloadPath(importLogId));
  }, [importLogId]);
  if (importLog === null || !isImportType(importType)) {
    return null;
  }
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <div className={SettingStyles.BreadcrumbsBlock}>
          <Breadcrumbs>
            <BreadcrumbsItem href={PATH}>過去データ取込</BreadcrumbsItem>
            <BreadcrumbsItem href={PATH}>
              {importTypeLabels[importType]}
            </BreadcrumbsItem>
            <BreadcrumbsItem isText>エラーログ</BreadcrumbsItem>
          </Breadcrumbs>
        </div>
        <h2 className={SettingStyles.SettingTitle}>
          {importTypeLabels[importType]}の取込エラーログ
        </h2>
        <div className={Styles.DownloadButtonArea}>
          <Button height="small" onClick={onClickDownload}>
            CSVダウンロード
          </Button>
        </div>
        <Table>
          <TableHeader>
            <th>行番号</th>
            <th>エラーキー</th>
            <th>エラー内容</th>
          </TableHeader>
          <TableBody>
            {importLog.importLogErrors.map((error) => (
              <TableRow key={error.id}>
                <td>{error.rowNum}</td>
                <td>{error.errorKey}</td>
                <td>{error.errorMessage}</td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ClientSettingLayout>
    </DefaultV2>
  );
};
