import { number, object, string } from 'yup';
import { ConstructionItem } from '~/models/ConstructionItem';
import { DealEstimation, DealEstimationContext } from '~/models/DealEstimate';
import { DealBilling, DealBillingContext } from '~/models/DealBilling';

export type DealConstructionBase = {
  id: number;
  seq: number;
  constructionItem: ConstructionItem | null;
  otherName: string;
  point: string;
  unit: number;
  unitAmount: number;
  totalAmount: number;
  renteeChargePer: number;
  renteeChargeWithPercentage: string;
  ownerChargePer: number;
  ownerChargeWithPercentage: string;
  companyChargePer: number;
  companyChargeWithPercentage: string;
  renteeAmount: number;
  ownerAmount: number;
  companyAmount: number;
  note: string;
  enabled: boolean;
};
export type DealConstructionEdit = Omit<
  DealConstruction,
  | 'id'
  | 'seq'
  | 'renteeChargeWithPercentage'
  | 'ownerChargeWithPercentage'
  | 'companyChargeWithPercentage'
>;
export type DealConstruction = DealEstimation | DealBilling;

export type DealConstructionContextBase = {
  totalAmount: number;
  totalAmountWithTax: number;
  constructionTotalAmount: number;
  constructionTotalAmountWithTax: number;
  sumOwnerAmount: number;
  sumOwnerAmountWithTax: number;
  sumRenteeAmount: number;
  sumRenteeAmountWithTax: number;
  dealConstructions: DealConstruction[];
};
export type DealConstructionContext =
  | DealEstimationContext
  | DealBillingContext;

export const validationSchemaConstruction = object().shape({
  constructionItem: object().nullable().required('工事項目を設定してください'),
  point: string().nullable(),
  unit: number().min(1, '1以上で設定してください').required('入力してください'),
  unitAmount: number().required('入力してください'),
  renteeChargePer: number()
    .min(0, '0以上で設定して下さい')
    .test(
      'hundredPer',
      '合計が100になるように入力してください',
      function testValidation(_value: unknown): boolean {
        const { ownerChargePer, renteeChargePer, companyChargePer } =
          this.parent;
        const sum = ownerChargePer + renteeChargePer + companyChargePer;
        return sum === 100;
      }
    )
});

export const buildDefaultConstruction = (
  type: 'DealEstimation' | 'DealBilling'
): DealConstructionEdit => ({
  constructionItem: null,
  otherName: '',
  point: '',
  unit: 1,
  unitAmount: 0,
  totalAmount: 0,
  renteeChargePer: 0,
  ownerChargePer: 100,
  companyChargePer: 0,
  renteeAmount: 0,
  ownerAmount: 0,
  companyAmount: 0,
  note: '',
  enabled: true,
  type
});
