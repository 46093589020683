import React from 'react';
import { ItandiChip, Input, SingleComboBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import {
  DealParam,
  resetBuildingValues,
  setBuildingValues
} from '~/models/DealForm';
import { useDebounce } from '~/hooks/useDebounce';
import { usePropertyDealBuildings } from '~/hooks/usePropertyDealBuildings';
import { PropertyDealBuilding, PropertyDealRoom } from '~/models/PropertyDeal';

type Props = {
  handleChangeRoom: (option: { label: string; value: string } | null) => void;
  propertyDealRooms: ReadonlyArray<PropertyDealRoom> | null;
};
export const RoomBlockPropertyInput: React.FC<Props> = ({
  handleChangeRoom,
  propertyDealRooms = null
}: Props) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<DealParam>();
  const debounce = useDebounce(200);
  const roomName = watch('roomName') ?? '';
  const { data: propertyDealBuildings } =
    usePropertyDealBuildings().index(roomName);
  const buildingValue = React.useCallback(
    (building: PropertyDealBuilding | null): string =>
      `${building?.name} ${building?.address}`,
    []
  );
  const handleInputChange = React.useCallback(
    (inputText: string): void => {
      debounce(() => {
        setValue('roomName', inputText);
      });
    },
    [debounce, setValue]
  );
  const onChangeBuilding = React.useCallback(
    (option: { label: string; value: string } | null): void => {
      if (propertyDealBuildings === null) return;
      const value = option?.value ?? '';
      if (value === '') {
        resetBuildingValues(setValue);
        setValue('roomName', null);
        setValue('address', null);
        return;
      }
      const selectedBuilding =
        propertyDealBuildings.find(
          (building) => buildingValue(building) === value
        ) ?? null;
      if (selectedBuilding !== null) {
        setBuildingValues(setValue, selectedBuilding);
        setValue('roomName', selectedBuilding.name);
        setValue('address', selectedBuilding.address);
      }
    },
    [buildingValue, propertyDealBuildings, setValue]
  );
  return (
    <>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>物件名</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.InputWrapper}>
            <SingleComboBox
              isInvalid={errors.roomName != null}
              keepInputValue
              onChange={onChangeBuilding}
              onInputChange={handleInputChange}
              options={
                propertyDealBuildings?.map((building) => ({
                  label: building.name,
                  description: building.address,
                  value: buildingValue(building)
                })) ?? []
              }
              placeholder="例）サンプルマンション"
            />
          </div>
          {errors.roomName != null && (
            <div className={Styles.ErrorMessage}>{errors.roomName.message}</div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>部屋番号</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.InputWrapper}>
            <SingleComboBox
              isInvalid={errors.roomNumber != null}
              keepInputValue
              onChange={handleChangeRoom}
              onInputChange={(value): void => setValue('roomNumber', value)}
              options={
                propertyDealRooms?.map((building) => ({
                  label: building.roomNumber,
                  value: `${building.id}`
                })) || []
              }
              placeholder="例）101"
            />
          </div>
          {errors.roomNumber != null && (
            <div className={Styles.ErrorMessage}>
              {errors.roomNumber.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>物件住所</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.InputWrapper}>
            <Input
              isInvalid={errors.address != null}
              placeholder="例）東京都港区六本木1-2-3"
              {...register('address')}
            />
          </div>
          {errors.address != null && (
            <div className={Styles.ErrorMessage}>{errors.address.message}</div>
          )}
        </div>
      </div>
    </>
  );
};
