import React from 'react';
import Styles from './statuses.module.scss';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { IN_ESTIMATION } from '~/models/Status';

export const NowAccepting: React.FC = () => (
  <div>
    <h3 className={Styles.Title}>案件が登録されました</h3>
    <p>
      見積作成中ステータスに変更するまで、管理会社にて依頼先の変更が可能です。
    </p>
    <StatusHandleVendorButtons backwardDisabled nextStatusId={IN_ESTIMATION} />
  </div>
);
