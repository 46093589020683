import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { RiAddBoxLine } from 'react-icons/ri';
import { ImportType } from '~/models/ImportLog';
import { buildDefault, validationSchema } from '~/models/ImportConversion';
import { AddModalForm } from '~/components/specifics/settings/imports/[importType]/import_conversions/AddModalForm';
import { create } from '~/utils/api/ImportConversion';
import { isSuccess } from '~/utils/api/api_handler';
import { useImportConversions } from '~/hooks/admin/useImportConversions';

type Props = {
  importType: ImportType;
  isOpen: boolean;
  handleClose: () => void;
};

export const AddModal: React.FC<Props> = ({
  importType,
  isOpen,
  handleClose
}: Props) => {
  const { mutate } = useImportConversions().useIndex(importType);
  const methods = useForm({
    defaultValues: buildDefault(importType),
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const onClickSubmit = React.useCallback(() => {
    methods.handleSubmit(async (values) => {
      const response = await create(importType, values);
      if (isSuccess(response)) {
        mutate != null && (await mutate());
        handleClose();
        toast.success('取込変換設定を追加しました。');
        return;
      }
      toast.error('取込変換設定の追加に失敗しました。');
    })();
  }, [handleClose, importType, methods, mutate]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader icon={RiAddBoxLine} title="取込変換設定の追加" />
      <ModalBody>
        <FormProvider {...methods}>
          <AddModalForm />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button onClick={onClickSubmit} variant="primary">
            保存
          </Button>
        }
      />
    </Modalv2>
  );
};
