import { NskResponse } from '~/models/Api';
import { postRequest } from '../api_handler';
import { DealConstructionScheduleEdit } from '~/models/DealConstructionSchedule';

const paths = {
  create: '/vendors/deals/:deal_id/deal_construction_schedules'
};

export const create = async (
  dealId: number,
  schedules: DealConstructionScheduleEdit[]
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    dealConstructionSchedules: schedules
  });
