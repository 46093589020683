import React from 'react';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as COMPANY_VENDORS_PATH } from '~/pages/settings/company_vendors';
import { ConstructionItemSettingList } from './ConstructionItemSettingList';

export const Page: React.FC = () => {
  const breadcrumbItems: BreadcrumbItem[] = [
    {
      path: COMPANY_VENDORS_PATH,
      caption: '提携中の施工会社一覧',
      active: false
    },
    { path: null, caption: '工事単価一覧', active: true }
  ];
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
        <ConstructionItemSettingList />
      </ClientSettingLayout>
    </DefaultV2>
  );
};
