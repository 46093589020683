import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/settings/imports/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/settings/imports';

const Home: NextPage = () => (
  <>
    <NskHead title="過去データ取込" />
    <Component />
  </>
);

export default Home;
