import React, { ComponentProps } from 'react';
import { SingleComboBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { useVendorPartners } from '~/hooks/vendors/useVendorPartners';
import { VendorPartner, convertToComboBoxOption } from '~/models/VendorPartner';

type Props = {
  dealSearchCondition: DealSearchCondition;
};

export const VendorPartnerCombobox: React.FC<Props> = ({
  dealSearchCondition
}: Props) => {
  const { data: vendorPartners } = useVendorPartners().index();
  const { setValue } = useFormContext<DealSearchCondition>();
  const noVendorPartnerOption: ComponentProps<typeof SingleComboBox>['value'] =
    {
      label: '実施会社未指定',
      value: String(-1)
    };
  const vendorPartnerOptions: ComponentProps<typeof SingleComboBox>['options'] =
    [noVendorPartnerOption].concat(
      vendorPartners?.map((vp: VendorPartner) => convertToComboBoxOption(vp)) ||
        []
    );
  const [selectedVendorPartnerOption, setSelectedVendorPartnerOption] =
    React.useState<ComponentProps<typeof SingleComboBox>['value']>(
      vendorPartnerOptions.find(
        ({ value }) => value === String(dealSearchCondition.partnerVendorId)
      ) || null
    );
  const onChangeVendorPartner = React.useCallback(
    async (
      option: ComponentProps<typeof SingleComboBox>['value'] | null
    ): Promise<void> => {
      setSelectedVendorPartnerOption(option);
      setValue('partnerVendorId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (vendorPartners === null) {
    return null;
  }
  return (
    <>
      <SingleComboBox
        defaultValue={selectedVendorPartnerOption}
        onChange={onChangeVendorPartner}
        options={vendorPartnerOptions}
        placeholder="実施会社"
        value={selectedVendorPartnerOption}
      />
    </>
  );
};
