import React from 'react';
import { toast } from 'react-toastify';
import Styles from './DealMainWorkflow.module.scss';
import { StatusBar } from '~/components/common/parts/status/StatusBar';
import { useStatuses } from '~/hooks/useStatuses';
import {
  getOwnStatuses,
  getWithVendorStatuses,
  isCanceled
} from '~/models/Status';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { destroy as destroyCancel } from '~/utils/api/deals/DealCancel';
import { isSuccess } from '~/utils/api/api_handler';
import { useDeals } from '~/hooks/useDeals';
import { isWithVendorDeal } from '~/models/Deal';
import useItandiAccount from '~/hooks/useItandiAccount';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { canOperatingDeal } from '~/models/Staff';

export const DealMainWorkflowClientCancel: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const {
    isOpen: confirmOpen,
    handleOpen: handleConfirmOpen,
    handleClose: handleConfirmClose
  } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(id);
  const { data: statuses } = useStatuses().index();
  const { data } = useItandiAccount().currentAccount();
  if (data === null || deal === null || statuses === null) {
    return null;
  }
  const handleAcceptDestroy = async (): Promise<void> => {
    if (deal.dealCancel !== null) {
      const result = await destroyCancel(deal.id, deal.dealCancel.id);
      if (isSuccess(result)) {
        mutateDeal && (await mutateDeal());
        toast.success('案件の取り下げを撤回しました');
        handleConfirmClose();
        return;
      }
      toast.error('エラーが発生しました');
      setIsSubmitting(false);
    }
  };
  const displayStatuses = isWithVendorDeal(deal)
    ? getWithVendorStatuses(statuses)
    : getOwnStatuses(statuses);
  return (
    <div>
      {!isCanceled(deal.status) && (
        <div className={Styles.StatusCard}>
          <h2 className={Styles.Header}>
            <span>{deal.vendorCompany.name}に依頼中</span>
          </h2>
          <StatusBar currentId={null} statuses={displayStatuses} />
        </div>
      )}
      <div className={Styles.WorkflowCard}>
        <h3 className={Styles.CancelTitle}>
          {isCanceled(deal.status)
            ? 'この案件は取り下げられました'
            : 'この案件は取り下げ申請中です'}
        </h3>
        <div>
          <div className={Styles.CancelReasonLabel}>取り下げ理由</div>
          <div className={Styles.CancelReason}>{deal.dealCancel?.reason}</div>
          {!isCanceled(deal.status) && (
            <div className={Styles.StatusHandleButtonRow}>
              <button
                className={Styles.BackCancelButton}
                disabled={!canOperatingDeal(data)}
                onClick={handleConfirmOpen}
                type="button"
              >
                取り下げを撤回する
              </button>
              <ConfirmModal
                actionText="取り下げを撤回"
                cancelText="中止"
                confirmType="delete"
                disabled={isSubmitting}
                handleAccept={handleAcceptDestroy}
                handleCancel={handleConfirmClose}
                isOpen={confirmOpen}
              >
                案件の取り下げを撤回してよろしいですか？
              </ConfirmModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
