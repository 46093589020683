import React, { ReactNode } from 'react';
import cx from 'clsx';
import Link from 'next/link';
import Styles from './StaffList.module.scss';
import { StaffListRow } from '~/components/specifics/settings/staffs/StaffListRow';
import { useStaffs } from '~/hooks/useStaffs';
import { Staff } from '~/models/Staff';
import useItandiAccount from '~/hooks/useItandiAccount';
import { PATH } from '~/pages/settings/staffs/new';

export const StaffList: React.FC = () => {
  const { data: staffs } = useStaffs().index();
  const { data: currentAccount } = useItandiAccount().currentAccount();
  const renderStaff = React.useCallback(
    (staff: Staff): ReactNode => <StaffListRow key={staff.id} staff={staff} />,
    []
  );
  if (
    staffs === null ||
    currentAccount === null ||
    currentAccount.company === null
  ) {
    return null;
  }
  // @note マケプレ経由でかつitandi_account_userを利用していない会社のみ追加できる
  const isAddible =
    currentAccount.company.fromMarket &&
    !currentAccount.company.useIaStaffLogin;
  return (
    <div>
      <table className={Styles.List}>
        <thead>
          <tr className={cx(Styles.ListRow, Styles.Header)}>
            <th>担当者</th>
            <th>メールアドレス</th>
            <th>電話番号</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>{staffs.map(renderStaff)}</tbody>
      </table>
      {isAddible && (
        <div className={Styles.ButtonArea}>
          <Link className={Styles.Button} href={PATH}>
            担当者の追加
          </Link>
        </div>
      )}
    </div>
  );
};
