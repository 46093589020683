import React, { ReactNode } from 'react';
import { Alert } from '@itandi/itandi-bb-ui';
import { DealSurvey } from '~/models/Survey';
import { SurveyFormRow } from '~/components/common/survey/SurveyFormRow';
import Styles from './SurveyForm.module.scss';

type Props = {
  surveys: DealSurvey[];
};
export const SurveyForm: React.FC<Props> = ({ surveys }: Props) => {
  const renderRow = React.useCallback(
    (survey: DealSurvey, index: number): ReactNode => (
      <SurveyFormRow key={survey.id} survey={survey} surveyIndex={index} />
    ),
    []
  );
  if (surveys === null || surveys.length === 0) return null;

  return (
    <div>
      <Alert variant="info">
        今回の工事の評価をお願いします。
        <br />
        ※評価の内容は工事会社に共有されます。
      </Alert>
      <div className={Styles.Forms}>{surveys.map(renderRow)}</div>
    </div>
  );
};
