import React from 'react';
import { toast } from 'react-toastify';
import { ImportType } from '~/models/ImportLog';
import { ImportConversion } from '~/models/ImportConversion';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { DeleteMenuButton } from '~/components/common/parts/button/DeleteMenuButton';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { destroy } from '~/utils/api/ImportConversion';
import { useImportConversions } from '~/hooks/admin/useImportConversions';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  importType: ImportType;
  importConversion: ImportConversion;
};

export const ImportConversionTableRow: React.FC<Props> = ({
  importType,
  importConversion
}: Props) => {
  const { mutate } = useImportConversions().useIndex(importType);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const handleAccept = React.useCallback(async () => {
    const response = await destroy(importConversion.id, importType);
    if (isSuccess(response)) {
      mutate != null && (await mutate());
      toast.success('削除しました');
      handleClose();
      return;
    }
    toast.error('削除に失敗しました');
  }, [handleClose, importConversion.id, importType, mutate]);
  return (
    <TableRow>
      <td>{importConversion.columnName}</td>
      <td>{importConversion.originalValue}</td>
      <td>{importConversion.convertedValue}</td>
      <td>
        <DeleteMenuButton onClick={handleOpen}>削除</DeleteMenuButton>
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleAccept}
          handleCancel={handleClose}
          isOpen={isOpen}
        >
          削除してよろしいですか？
        </ConfirmModal>
      </td>
    </TableRow>
  );
};
