import React from 'react';
import { useRouter } from 'next/router';
import { useDeals } from '~/hooks/vendors/useDeals';
import Styles from './statuses.module.scss';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { IN_ESTIMATION, SEND_ESTIMATE } from '~/models/Status';
import { isOriginVendor } from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';

export const SendEstimate: React.FC = () => {
  const router = useRouter();
  const { id: dealId = null } = router.query;
  const { data: deal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  const { data: session } = useSessions().index();
  if (deal === null || session === null) {
    return null;
  }
  return (
    <div>
      <h3 className={Styles.Title}>見積りを送付しました。</h3>
      <p>工事項目確定をお待ち下さい</p>
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <StatusHandleVendorButtons
          forwardDisabled
          nextStatusId={SEND_ESTIMATE}
          prevStatusId={IN_ESTIMATION}
        />
      )}
    </div>
  );
};
