import { VendorUserWithCompany } from '~/models/VendorUser';
import { StaffWithCompany } from '~/models/Staff';
import { Deal } from '~/models/Deal';
import { VendorSession } from '~/models/VendorSession';
import { isOriginVendor } from '~/models/DealVendorCompany';

export type MessageSpeaker =
  | 'maintenance_company'
  | 'vendor_company'
  | 'partner_vendor_company';
export type Message = {
  id: number;
  speaker: MessageSpeaker;
  messageType: 'text' | 'file';
  text: string;
  readAt: string | null;
  vendorReadAt: string | null;
  partnerReadAt: string | null;
  vendorUser: VendorUserWithCompany | null;
  staff: StaffWithCompany | null;
  createdAt: string;
  updatedAt: string;
};

/**
 * dealとsessionから、話し手としての位置を返す
 */
export const getVendorMessageSpeaker = (
  deal: Deal,
  session: VendorSession
): MessageSpeaker =>
  isOriginVendor(deal, session.vendorCompany.id)
    ? 'vendor_company'
    : 'partner_vendor_company';

export const getSpeakerName = (
  message: DeepReadonly<Message>
): string | null => {
  if (
    ['vendor_company', 'partner_vendor_company'].includes(message.speaker) &&
    message.vendorUser !== null
  ) {
    return `${message.vendorUser.vendorCompany.name} ${message.vendorUser.name}`;
  }
  if (message.speaker === 'maintenance_company' && message.staff !== null) {
    return `${message.staff.companySetting.name} ${message.staff.name}`;
  }
  return null;
};

const isRead = (message: Message, speaker: MessageSpeaker): boolean => {
  if (speaker === 'maintenance_company') {
    // 管理会社は、どちらかが既読したら既読とする
    return message.vendorReadAt !== null || message.partnerReadAt !== null;
  }
  if (speaker === 'vendor_company') {
    // 施工会社は、管理会社が既読したら既読とする
    return message.readAt !== null;
  }
  // 実施会社は、管理会社が既読したら既読とする
  return message.readAt !== null;
};

const isUnread = (message: Message, speaker: MessageSpeaker): boolean => {
  if (speaker === 'maintenance_company') {
    return message.readAt === null;
  }
  if (speaker === 'vendor_company') {
    return message.vendorReadAt === null;
  }
  return message.partnerReadAt === null;
};

/**
 * 既読を表示するか否かを返す
 *
 * @param message
 * @param speaker 視点の話し手
 */
export const isShowReadMark = (
  message: DeepReadonly<Message>,
  speaker: MessageSpeaker
): boolean => {
  if (message.speaker !== speaker) {
    // 自分の発言以外には既読を付けない
    return false;
  }
  return isRead(message, speaker);
};

export const getUnreadCount = (
  messages: DeepReadonly<Message[]>,
  speaker: MessageSpeaker
): number =>
  messages.filter(
    (message: Message) =>
      message.speaker !== speaker && isUnread(message, speaker)
  ).length;
