import { useCallback, useEffect, useState } from 'react';

export const MEDIA_QUERY = {
  pc: '768px <= width',
  sp: 'width < 768px'
};

export const useMediaQuery = (
  query: string
): { match: boolean; isLoaded: boolean } => {
  const formattedQuery = `(${query})`;
  const [match, setMatch] = useState<boolean | null>(null);
  const handleSetMatch = useCallback((): void => {
    setMatch(window.matchMedia(formattedQuery).matches);
  }, [formattedQuery]);

  useEffect(() => {
    handleSetMatch();
    matchMedia(formattedQuery).onchange = handleSetMatch;
    window.addEventListener('load', handleSetMatch);
    window.addEventListener('change', handleSetMatch);
    return (): void => {
      window.removeEventListener('load', handleSetMatch);
      window.removeEventListener('change', handleSetMatch);
    };
  }, [formattedQuery, handleSetMatch]);

  return { match: match || false, isLoaded: match !== null };
};
