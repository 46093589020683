import React from 'react';
import { useDeals } from '~/hooks/useDeals';
import {
  DealUploadFile,
  DealUploadFileUpdateParam
} from '~/models/DealUploadFile';
import {
  destroy as destroyFile,
  getFilePath as getClientFilePath
} from '~/utils/api/deals/DealUploadedFile';
import { DealFileCard } from '~/components/common/deals/show/files/DealFileCard';
import { LeaseContractFileCard } from '~/components/common/deals/show/files/LeaseContractFileCard';
import { NskResponse } from '~/models/Api';
import {
  create as postOtherFile,
  update as putOtherFile
} from '~/utils/api/deals/DealOtherFile';
import {
  create as postPrivateFile,
  update as putPrivateFile
} from '~/utils/api/deals/DealCompanyPrivateFile';
import { isWithVendorDeal } from '~/models/Deal';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getLeaseContractFilePath } from '~/utils/api/Deal';

export const DealFileListClient: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(Number(id));
  if (deal === null) {
    return null;
  }
  const handleGetFilePath = (file: DealUploadFile): string =>
    getClientFilePath(deal.id, file);
  const handleMutate = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const handleDestroyFile = async (
    file: DealUploadFile
  ): Promise<NskResponse<void>> => destroyFile(deal.id, file.id);
  const handleUploadOther = async (file: File): Promise<NskResponse<void>> =>
    postOtherFile(file, deal.id);
  const handleUpdateOther = async (
    dealOtherParam: DealUploadFileUpdateParam
  ): Promise<NskResponse<void>> => putOtherFile(dealOtherParam, deal.id);
  const handleUploadPrivateFile = async (
    file: File
  ): Promise<NskResponse<void>> => postPrivateFile(file, deal.id);
  const handleUpdatePrivateFile = async (
    params: DealUploadFileUpdateParam
  ): Promise<NskResponse<void>> => putPrivateFile(deal.id, params);
  return (
    <>
      <LeaseContractFileCard
        deal={deal}
        handleGetFile={getLeaseContractFilePath}
        handleMutate={handleMutate}
      />
      {isWithVendorDeal(deal) && (
        <DealFileCard
          deal={deal}
          files={deal.dealMemorandumFiles}
          fileType="賃借人同意書"
          handleGetFilePath={handleGetFilePath}
          handleMutate={handleMutate}
        />
      )}
      {isWithVendorDeal(deal) && (
        <DealFileCard
          deal={deal}
          files={deal.dealReceiptFiles}
          fileType="工事発注書・請書"
          handleGetFilePath={handleGetFilePath}
          handleMutate={handleMutate}
        />
      )}
      <DealFileCard
        deal={deal}
        description="施工会社とファイルが共有されます"
        files={deal.dealOtherFiles}
        fileType="その他ファイル（共有用）"
        handleDestroy={handleDestroyFile}
        handleGetFilePath={handleGetFilePath}
        handleMutate={handleMutate}
        handleUpdate={handleUpdateOther}
        handleUpload={handleUploadOther}
      />
      <DealFileCard
        deal={deal}
        description="施工会社とファイルが共有されません"
        files={deal.dealCompanyPrivateFiles ?? []}
        fileType="その他ファイル（社内用）"
        handleDestroy={handleDestroyFile}
        handleGetFilePath={handleGetFilePath}
        handleMutate={handleMutate}
        handleUpdate={handleUpdatePrivateFile}
        handleUpload={handleUploadPrivateFile}
      />
    </>
  );
};
