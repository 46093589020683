import React, { ReactNode } from 'react';
import { RiCameraLine } from 'react-icons/ri';
import Link from 'next/link';
import Styles from './ReportImageNavigation.module.scss';
import { DealReportImage } from '~/models/DealReportImage';

type Props = {
  title: string;
  detailPagePath: string;
  detailPageButtonLabel: string;
  reportImages: DealReportImage[];
  optionalLabel?: boolean;
};

export const ReportImageNavigation: React.FC<Props> = ({
  title,
  detailPagePath,
  detailPageButtonLabel,
  reportImages,
  optionalLabel = false
}: Props) => {
  const thumbnailImages = reportImages.filter(
    (i: DealReportImage) => i.thumbnailImageUrl !== null
  );
  // 4枚までサムネイルに表示
  const thumbnailCount =
    thumbnailImages.length > 3 ? 3 : thumbnailImages.length;
  const additionalImageCount = reportImages.length - thumbnailCount;
  return (
    <div className={Styles.NavigationBlock}>
      <div className={Styles.IconWrapper}>
        <RiCameraLine className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>
            {title}
            {optionalLabel && (
              <span className={Styles.OptionalLabel}>任意</span>
            )}
          </h4>
          <div className={Styles.SpImageCount}>
            {reportImages.length}件の写真が登録されています
          </div>
          {thumbnailImages.length > 0 && (
            <div className={Styles.ThumbnailImageList}>
              {[...Array(thumbnailCount)].map(
                (_num: number, i: number): ReactNode => (
                  <img
                    key={thumbnailImages[i].id}
                    alt="thumbnail"
                    className={Styles.ThumbnailImage}
                    src={thumbnailImages[i].thumbnailImageUrl ?? ''}
                  />
                )
              )}
              {additionalImageCount > 0 && (
                <div className={Styles.AdditionalImageCountBlock}>
                  +{additionalImageCount}
                </div>
              )}
            </div>
          )}
          {reportImages.length === 0 && (
            <div className={Styles.NotYet}>画像は登録されていません</div>
          )}
        </div>
        <div className={Styles.DetailLinkBlock}>
          <Link className={Styles.DetailLink} href={detailPagePath}>
            {detailPageButtonLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};
