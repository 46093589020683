import React, { ReactNode } from 'react';
import cx from 'clsx';
import Styles from './DropdownMenu.module.scss';
import { useDropdownContext } from '~/components/common/parts/dropdown/Dropdown';

type Props = {
  children: ReactNode;
  className?: string;
  onClick: () => void | Promise<void> | boolean | Promise<boolean>;
};

export const DropdownMenu: React.FC<Props> = ({
  children,
  className = '',
  onClick
}: Props) => {
  const { setShow } = useDropdownContext();
  const onClickMenu = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    // Router.push等でComponentがDestructされる場合はtrueを返してもらう
    const notNeedRestore = await onClick();
    if (notNeedRestore !== true) {
      setShow && setShow(false);
    }
  };
  return (
    <div className={cx(Styles.DropdownMenu, className)}>
      <a className={Styles.DropdownMenuLink} href="#" onClick={onClickMenu}>
        {children}
      </a>
    </div>
  );
};
