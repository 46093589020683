import React, { RefObject, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

export const useClickAway = <E extends Event = Event>(
  ref: RefObject<HTMLElement | null>,
  triggerRef: RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  events: string[] = defaultEvents
): void => {
  const savedCallback = useRef<(event: E) => void>(onClickAway);
  React.useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (event: any): void => {
      const { current: el } = ref;
      const { current: trigEl } = triggerRef;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (el && !el.contains(event.target) && !trigEl?.contains(event.target)) {
        savedCallback.current(event);
      }
    };
    events.forEach((eventName: string): void => {
      document.addEventListener(eventName, handler);
    });
    return (): void => {
      events.forEach((eventName: string): void => {
        document.removeEventListener(eventName, handler);
      });
    };
  }, [events, ref, triggerRef]);
};
