import React from 'react';
import SettingStyles from '../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { NotificationSetting } from '~/components/specifics/settings/notifications/NotificationSetting';

export const Page: React.FC = () => (
  <DefaultV2>
    <ClientSettingLayout>
      <h2 className={SettingStyles.SettingTitle}>通知設定</h2>
      <NotificationSetting />
    </ClientSettingLayout>
  </DefaultV2>
);
