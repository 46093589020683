import React, { ReactNode } from 'react';
import { FormControl } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { InputCheckBox } from '@itandi/itandi-bb-ui';
import Styles from './UserLoginModalPassword.module.scss';
import { isSuccess } from '~/utils/api/api_handler';
import { LoginStaffModel } from '~/components/common/user_login_modal/UserLoginModal';
import { NskResponse } from '~/models/Api';

type Props = {
  loginUser: LoginStaffModel;
  handleLogin: (
    id: number,
    password: string | null
  ) => Promise<NskResponse<void>>;
  mutateSession: () => Promise<void>;
  handleClose: () => void;
};

type PasswordObject = {
  password: string;
};

export const UserLoginModalPassword: React.FC<Props> = ({
  loginUser,
  handleLogin,
  mutateSession,
  handleClose
}: Props) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const onClickBackward = (e: React.MouseEvent): void => {
    e.preventDefault();
    handleClose();
  };
  const handleSubmitLogin = async ({
    password
  }: PasswordObject): Promise<void> => {
    const result = await handleLogin(loginUser.id, password);
    if (isSuccess(result)) {
      await mutateSession();
      toast.success('ログインしました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <div className={Styles.LoginPasswordPanel}>
      <a className={Styles.BackwardLink} href="#" onClick={onClickBackward}>
        担当者の選択に戻る
      </a>
      <h3 className={Styles.LoginTitle}>{loginUser.name}でログイン</h3>
      <Formik initialValues={{ password: '' }} onSubmit={handleSubmitLogin}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit
        }: FormikProps<PasswordObject>): ReactNode => (
          <form onSubmit={(e: React.FormEvent): void => e.preventDefault()}>
            <div className={Styles.PasswordPanel}>
              <label className={Styles.Label}>
                パスワードを入力してください
              </label>
              <FormControl
                ref={inputRef}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                value={values.password ?? ''}
              />
              <div className={Styles.ShowPasswordBlock}>
                <InputCheckBox
                  checked={showPassword}
                  labelText="パスワードを表示する"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setShowPassword(e.target.checked);
                  }}
                />
              </div>
            </div>
            <div className={Styles.ButtonArea}>
              <button
                className={Styles.SubmitButton}
                onClick={(): void => handleSubmit()}
                type="submit"
              >
                ログイン
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
