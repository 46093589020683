import React, { ReactNode } from 'react';
import Styles from './Table.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const Table: React.FC<Props> = ({ children }: Props) => (
  <table className={Styles.Table}>{children}</table>
);
