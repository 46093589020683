import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiAddBoxLine, RiEdit2Line } from 'react-icons/ri';
import {
  buildDefaultSubtype,
  Subtype,
  SubtypeEdit,
  validationSchema
} from '~/models/Subtype';
import { SubtypeModalForm } from '~/components/specifics/settings/subtypes/SubtypeModalForm';
import { create, update } from '~/utils/api/Subtype';
import { isSuccess } from '~/utils/api/api_handler';
import { onInvalidScroll } from '~/utils/FormUtil';

type Props = DeepReadonly<{
  subtype?: Subtype | null;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate: () => Promise<void>;
}>;

export const SubtypeModal: React.FC<Props> = ({
  subtype = null,
  isOpen,
  handleClose,
  handleMutate
}: Props) => {
  const methods = useForm<SubtypeEdit>({
    defaultValues: subtype ?? buildDefaultSubtype(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const handleModalSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: SubtypeEdit) => {
        if (subtype === null) {
          // create
          const response = await create(submitValue);
          if (isSuccess(response)) {
            await handleMutate();
            handleClose();
            toast.success('作成しました');
            return;
          }
          toast.error('エラーが発生しました');
          return;
        }
        // update
        const response = await update(subtype.id, submitValue);
        if (isSuccess(response)) {
          await handleMutate();
          handleClose();
          toast.success('更新しました');
          return;
        }
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, subtype, handleMutate, handleClose]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader
        icon={subtype ? RiEdit2Line : RiAddBoxLine}
        title={subtype ? '案件タイプ編集' : '案件タイプ新規作成'}
      />
      <ModalBody>
        <FormProvider {...methods}>
          <SubtypeModalForm />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button onClick={handleModalSubmit} variant="primary">
            保存
          </Button>
        }
      />
    </Modalv2>
  );
};
