import React from 'react';
import clsx from 'clsx';
import { RiCloseFill } from 'react-icons/ri';
import Styles from './ChatPopContainer.module.scss';
import { ChatPostArea } from '~/components/common/chat/post/ChatPostArea';
import { Deal } from '~/models/Deal';
import { Message, MessageSpeaker } from '~/models/Message';
import { ChatMessageArea } from '~/components/common/chat/messages/ChatMessageArea';

type Props = {
  deal: Deal;
  speaker: MessageSpeaker;
  isOpen: boolean;
  handleClose: () => void;
  isVendor?: boolean;
  handleMutate: () => Promise<void>;
  messages: Message[];
};

/**
 * 案件詳細でポップアップするチャット画面
 *
 * @param deal
 * @param speaker
 * @param isOpen
 * @param handleClose
 * @param isVendor
 * @param handleMutate
 * @param messages
 * @constructor
 */
export const ChatPopContainer: React.FC<Props> = ({
  deal,
  speaker,
  isOpen,
  handleClose,
  isVendor = false,
  handleMutate,
  messages
}: Props) => (
  <div
    className={clsx(Styles.ChatContainer, isOpen ? Styles.Show : Styles.Hide)}
  >
    <div className={Styles.Header}>
      {isVendor ? '管理会社とチャット' : '施工会社とチャット'}
      <div className={Styles.CloseButtonWrapper}>
        <button
          className={Styles.CloseButton}
          onClick={handleClose}
          type="button"
        >
          <RiCloseFill className={Styles.CloseIcon} />
        </button>
      </div>
    </div>
    <ChatMessageArea
      deal={deal}
      handleMutate={handleMutate}
      isOpen={isOpen}
      messages={messages}
      speaker={speaker}
    />
    <ChatPostArea deal={deal} handleMutate={handleMutate} isVendor={isVendor} />
  </div>
);
