import useSWR from 'swr';
import { Subtype } from '~/models/Subtype';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/subtypes',
  show: '/subtypes/:id'
};

type TIndex = () => SwrReturnType<Subtype[]>;
type TShow = (id: number | null) => SwrReturnType<Subtype>;
type ReturnType = {
  useIndex: TIndex;
  useShow: TShow;
};

export const useSubtypes = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error } = useSWR<Subtype[]>(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  const useShow: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<Subtype>(
      id === null ? null : paths.show.replace(':id', id.toString())
    );
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex, useShow };
};
