import useSWR from 'swr';
import { SummaryFileTemplate } from '~/models/SummaryFileTemplate';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/summary_file_templates'
} as const;

type TIndex = () => SwrReturnType<SummaryFileTemplate[]>;
type ReturnType = {
  useIndex: TIndex;
};

export const useSummaryFileTemplates = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error } = useSWR<SummaryFileTemplate[]>(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex };
};
