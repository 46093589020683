import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/settings/company_vendors/construction_item_settings/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (vendorCompanyId: number): string =>
  `/settings/company_vendors/${vendorCompanyId}/construction_item_settings`;

const VendorCompanies: NextPage = () => (
  <>
    <NskHead title="単価一覧" />
    <Component />
  </>
);

export default VendorCompanies;
