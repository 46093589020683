import { MarketCompanySetting } from '~/models/CompanySetting';
import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/markets/company_settings'
};

export const create = async (
  marketCompanySetting: MarketCompanySetting
): Promise<NskResponse<void>> =>
  postRequest(paths.create, { marketCompanySetting });
