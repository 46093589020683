import React, { ReactNode } from 'react';
import { RiFileLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Styles from './UploadFileNavigation.module.scss';
import { DealUploadFile } from '~/models/DealUploadFile';
import { NskResponse } from '~/models/Api';
import { DeleteMenuButton } from '~/components/common/parts/button/DeleteMenuButton';
import { UploadButton } from '~/components/common/upload/UploadButton';
import { isSuccess } from '~/utils/api/api_handler';
import { Deal } from '~/models/Deal';
import useLoader from '~/hooks/useLoader';

type Props = {
  title: string;
  deal: Deal;
  fileType: string;
  files: DealUploadFile[];
  handleGetFilePath: (file: DealUploadFile) => string;
  CreateSubmitLabel?: string | null;
  handleMutate?: (() => Promise<void>) | null;
  handleUpload?: ((file: File) => Promise<NskResponse<void>>) | null;
  handleCreate?: (() => Promise<NskResponse<void>>) | null;
  handleDestroy?: ((file: DealUploadFile) => Promise<NskResponse<void>>) | null;
};

export const UploadFileNavigation: React.FC<Props> = ({
  title,
  deal,
  fileType,
  files,
  handleGetFilePath,
  CreateSubmitLabel = null,
  handleMutate = null,
  handleUpload = null,
  handleCreate = null,
  handleDestroy = null
}: Props) => {
  const { Loader, showLoader, hideLoader } = useLoader();
  const onClickUpload = async (file: File): Promise<void> => {
    if (handleUpload === null) {
      return;
    }
    const result = await handleUpload(file);
    if (isSuccess(result)) {
      handleMutate && (await handleMutate());
      toast.success('登録しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  const handleAcceptCreate = async (): Promise<void> => {
    showLoader();
    if (handleCreate === null) return;
    const result = await handleCreate();
    if (isSuccess(result)) {
      handleMutate && (await handleMutate());
      hideLoader();
      toast.success('作成しました');
      return;
    }
    hideLoader();
    toast.error(result.message ?? 'エラーが発生しました');
  };
  const renderRow = (file: DealUploadFile, index: number): ReactNode => {
    const extension = file.fileName.split('.').pop();
    const onClickDestroy = async (): Promise<void> => {
      if (handleDestroy === null) {
        return;
      }
      showLoader();
      const result = await handleDestroy(file);
      if (isSuccess(result)) {
        handleMutate && (await handleMutate());
        hideLoader();
        toast.success('削除しました');
        return;
      }
      hideLoader();
      toast.error('エラーが発生しました');
    };
    return (
      <div key={file.id} className={Styles.FileRow}>
        <div>
          <a
            className={Styles.FileLink}
            href={handleGetFilePath(file)}
            rel="noreferrer"
            target="_blank"
          >
            {index + 1}_{deal.name}_{deal.roomNumber}_{fileType}.{extension}
          </a>
        </div>
        {handleDestroy !== null && (
          <div className={Styles.FileButtons}>
            <DeleteMenuButton onClick={onClickDestroy}>削除</DeleteMenuButton>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={Styles.NavigationBlock}>
      <Loader />
      <div className={Styles.IconWrapper}>
        <RiFileLine className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>
            {title}
            <span className={Styles.OptionalLabel}>任意</span>
          </h4>
          {files.map(renderRow)}
        </div>
      </div>
      <div className={Styles.ButtonArea}>
        {handleCreate !== null && (
          <div className={Styles.DetailLinkBlock}>
            <button
              className={Styles.CreateButton}
              onClick={handleAcceptCreate}
              type="button"
            >
              {CreateSubmitLabel}
            </button>
          </div>
        )}
        {handleUpload !== null && (
          <div className={Styles.DetailLinkBlock}>
            <UploadButton
              className={Styles.DetailLinkButton}
              onClick={onClickUpload}
              variant="normal"
            >
              ファイルを登録
            </UploadButton>
          </div>
        )}
      </div>
    </div>
  );
};
