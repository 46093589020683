import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { VendorPartner } from '~/models/VendorPartner';

const paths = {
  index: '/vendors/vendor_partners'
};

type TIndex = () => SwrReturnType<VendorPartner[]>;
type ReturnType = {
  index: TIndex;
};

export const useVendorPartners = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<VendorPartner[]>(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
