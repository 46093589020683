import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import Styles from './Page.module.scss';
import { PATH } from '~/pages/deals/new';

export const FormCompletePage: React.FC = () => {
  const router = useRouter();
  const handleClick = React.useCallback(() => {
    router.push(PATH).then();
  }, [router]);
  return (
    <div className={Styles.InnerPageContainer}>
      <h2 className={Styles.CompleteTitle}>登録が完了しました</h2>
      <p className={Styles.CompleteText}>
        ご登録ありがとうございます。
        <br />
        案件依頼は下のボタンからお願いします。
      </p>
      <div className={Styles.OneButtonArea}>
        <Button onClick={handleClick} variant="primary" width="fill">
          案件依頼をする
        </Button>
      </div>
    </div>
  );
};
