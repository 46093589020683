import React from 'react';
import { scroller } from 'react-scroll';
import Styles from './ChatPostArea.module.scss';
import { create as createMessageForCompany } from '~/utils/api/deals/Message';
import { create as createMessageForVendor } from '~/utils/api/vendors/deals/Message';
import { Deal } from '~/models/Deal';
import { ChatPostFileButton } from '~/components/common/chat/post/ChatPostFileButton';

type Props = DeepReadonly<{
  deal: Deal;
  isVendor?: boolean;
  handleMutate: () => Promise<void>;
}>;

export const ChatPostArea: React.FC<Props> = ({
  deal,
  isVendor = false,
  handleMutate
}: Props) => {
  const [text, setText] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const onChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
    },
    [setText]
  );
  const rows = React.useMemo((): number => {
    const textRows: string[] | null = text.match(/\r\n|\n/g);
    if (textRows !== null) {
      if (textRows.length <= 5) {
        return 5;
      }
      return textRows.length >= 30 ? 30 : textRows.length;
    }
    return 5;
  }, [text]);
  const handlePostMessage = React.useCallback(async (): Promise<void> => {
    const postMethod = isVendor
      ? createMessageForVendor
      : createMessageForCompany;
    await postMethod(deal.id, text);
    await handleMutate();
    scroller.scrollTo('chat-message-field-bottom', {
      duration: 300,
      smooth: 'easeInQuad',
      containerId: 'chat_messages_field',
      ignoreCancelEvents: true
    });
    setText('');
  }, [handleMutate, deal, text, isVendor]);
  const onClickSubmit = React.useCallback(async (): Promise<void> => {
    setIsSubmitting(true);
    await handlePostMessage();
    setIsSubmitting(false);
  }, [handlePostMessage]);
  const onKeyDownText = React.useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>): Promise<void> => {
      if (
        e.key === 'Enter' &&
        ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) &&
        text.length !== 0
      ) {
        await handlePostMessage();
      }
    },
    [text, handlePostMessage]
  );
  return (
    <div className={Styles.PostArea}>
      <span className={Styles.InputLabel}>本文</span>
      <textarea
        className={Styles.TextArea}
        onChange={onChangeText}
        onKeyDown={onKeyDownText}
        rows={rows}
        value={text}
      />
      <div className={Styles.SubmitArea}>
        <ChatPostFileButton
          deal={deal}
          handleMutate={handleMutate}
          isVendor={isVendor}
        />
        <button
          className={Styles.SubmitButton}
          disabled={text === '' || isSubmitting}
          onClick={onClickSubmit}
          type="button"
        >
          送信
        </button>
      </div>
    </div>
  );
};
