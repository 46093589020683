import { object, string } from 'yup';

export type DealRepair = {
  id: number;
  requestContent: string;
  itemName: string;
  itemCategory: string;
  manufacturer: string;
  modelNumber: string;
  modelNumberFile: string | null;
};

export type DealRepairParam = {
  requestContent: string;
  itemName: string;
  itemCategory: string;
  manufacturer: string;
  modelNumber: string;
  modelNumberFile: File | null;
  checkDestroy: boolean;
};

export type DealFormRepairParam = Omit<DealRepairParam, 'checkDestroy'>;

export const validationSchema = object().shape({
  requestContent: string().required('入力してください'),
  itemCategory: string().required('入力してください')
});
