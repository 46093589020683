import React from 'react';
import { Th, Tr, PaginationWithCounter } from '@itandi/itandi-bb-ui';
import Styles from './DealTable.module.scss';
import { DealSearchCondition } from '~/models/Deal';

type Props = {
  total: number;
  dealSearchCondition: DealSearchCondition;
  handleChangeCondition: (newCondition: DealSearchCondition) => Promise<void>;
};
export const DealTableSPFooter: React.FC<Props> = ({
  total,
  dealSearchCondition,
  handleChangeCondition
}: Props) => {
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(
    dealSearchCondition.page || 1
  );
  const handleChangePage = async (newPageNumber: number): Promise<void> => {
    const newCondition: DealSearchCondition = {
      ...dealSearchCondition,
      page: newPageNumber
    };
    setCurrentPageNum(newPageNumber);
    await handleChangeCondition(newCondition);
  };
  return (
    <Tr>
      <Th colSpan={2}>
        <div className={Styles.PaginationContainer}>
          <PaginationWithCounter
            currentPageNum={currentPageNum}
            handleChangePage={handleChangePage}
            itemsPerPage={50}
            totalItems={total}
          />
        </div>
      </Th>
    </Tr>
  );
};
