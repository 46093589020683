import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/deals/[id]/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string => `/vendors/deals/${id}`;

const DealsDetail: NextPage = () => (
  <>
    <NskHead title="案件詳細" />
    <Component />
  </>
);

export default DealsDetail;
