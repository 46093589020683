import React from 'react';
import { FormControl, Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealInspectionCandidateDateParam } from '~/models/InspectionCandidateDate';
import Styles from './ScheduleInputForm.module.scss';
import { Datepicker } from '../parts/datepicker/Datepicker';
import { toDate } from '~/utils/DateUtil';
import { onChangeDate } from '~/utils/FormUtil';

/**
 * 立会希望日入力フォーム
 *
 * @for clients
 */
export const ScheduleInputForm: React.FC = () => {
  const { register, setValue, watch } =
    useFormContext<DealInspectionCandidateDateParam>();
  const inspectionCandidateDate1 = watch('inspectionCandidateDate1');
  const inspectionCandidateDate2 = watch('inspectionCandidateDate2');
  const inspectionCandidateDate3 = watch('inspectionCandidateDate3');
  return (
    <div>
      <FormControl chip="任意" label="立会希望日1" variant="Horizontal">
        <div className={Styles.ScheduleInputArea}>
          <Datepicker
            onChange={(date): void => {
              onChangeDate(date, 'inspectionCandidateDate1', setValue);
            }}
            selected={toDate(inspectionCandidateDate1)}
          />
          <Input
            placeholder="例）午前中"
            {...register('inspectionCandidateDateTime1')}
          />
        </div>
      </FormControl>
      <FormControl chip="任意" label="立会希望日2" variant="Horizontal">
        <div className={Styles.ScheduleInputArea}>
          <Datepicker
            onChange={(date): void => {
              onChangeDate(date, 'inspectionCandidateDate2', setValue);
            }}
            selected={toDate(inspectionCandidateDate2)}
          />
          <Input
            placeholder="例）12時-14時"
            {...register('inspectionCandidateDateTime2')}
          />
        </div>
      </FormControl>
      <FormControl chip="任意" label="立会希望日3" variant="Horizontal">
        <div className={Styles.ScheduleInputArea}>
          <Datepicker
            onChange={(date): void => {
              onChangeDate(date, 'inspectionCandidateDate3', setValue);
            }}
            selected={toDate(inspectionCandidateDate3)}
          />
          <Input
            placeholder="例）午後"
            {...register('inspectionCandidateDateTime3')}
          />
        </div>
      </FormControl>
    </div>
  );
};
