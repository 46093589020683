import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Modal } from '~/components/common/parts/modal/Modal';
import Styles from './VendorSelectModal.module.scss';

export type VendorSelectOption = {
  id: number;
  name: string;
  address?: string | null;
  avatarUrl?: string | null;
  isSelf: boolean;
};

type Props = {
  handleClose: () => void;
  handleAccept: (id: number) => void;
  selfVendor?: VendorSelectOption | null;
  vendors: VendorSelectOption[];
  selectedId: number | null;
};

/* 今はvendorの実施会社選択用になっている */
export const VendorSelectModal: React.FC<Props> = ({
  handleClose,
  handleAccept,
  selfVendor = null,
  vendors,
  selectedId
}: Props) => {
  const renderVendor = (vendor: VendorSelectOption): ReactNode => {
    const onClickVendor = (e: React.MouseEvent): void => {
      e.preventDefault();
      handleAccept(vendor.id);
      handleClose();
    };
    const isActive = selectedId === vendor.id;
    const isSelf = selfVendor !== null && selfVendor.id === vendor.id;
    return (
      <div
        key={vendor.id}
        className={clsx(Styles.VendorRow, isActive ? Styles.Active : '')}
      >
        <a className={Styles.VendorLink} href="#" onClick={onClickVendor}>
          <div className={Styles.IconWrapper}>
            {!vendor.isSelf && vendor.avatarUrl !== null && (
              <img
                alt="avatar"
                className={Styles.Icon}
                src={vendor.avatarUrl}
              />
            )}
          </div>
          <div>
            <div className={Styles.VendorNameRow}>
              <span className={Styles.VendorName}>{vendor.name}</span>
              {isSelf && (
                <span className={Styles.VendorNameExtra}>
                  （実施会社に依頼しない）
                </span>
              )}
            </div>
            {vendor.address !== null && (
              <div className={Styles.VendorAddress}>{vendor.address}</div>
            )}
          </div>
          <div className={Styles.ArrowIconWrapper}>
            <RiArrowRightSLine className={Styles.ArrowIcon} />
          </div>
        </a>
      </div>
    );
  };
  return (
    <Modal handleClose={handleClose} isOpen title="実施会社を選択">
      {selfVendor !== null && <>{renderVendor(selfVendor)} </>}
      {vendors.map(renderVendor)}
    </Modal>
  );
};
