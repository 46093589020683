import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Pagination } from '@itandi/itandi-bb-ui';
import Styles from './Page.module.scss';
import { useCompanySettings } from '~/hooks/useCompanySettings';
import { CompanySettingWithStore } from '~/models/CompanySetting';
import { CompanyRow } from '~/components/specifics/admin/CompanyRow';
import { AdminLayout } from '~/components/common/layouts/AdminLayout';
import { Container } from '~/components/common/container/Container';
import { SearchInput } from '~/components/common/parts/search/SearchInput';

export const Page: React.FC = () => {
  const [pageNum, setPageNum] = React.useState<number>(1);
  const [searchText, setSearchText] = React.useState<string>('');
  const { data, mutate } = useCompanySettings().index(searchText, pageNum);
  const handleMutate = React.useCallback(async (): Promise<void> => {
    mutate && (await mutate());
  }, [mutate]);
  const searchInputRef = React.useRef(null);
  const handleChangeSearchText = React.useCallback(
    (searchWord: string) => {
      setSearchText(searchWord);
      setPageNum(1);
    },
    [setSearchText]
  );
  const handleChangePage = React.useCallback(
    (newPageNumber: number) => {
      setPageNum(newPageNumber);
    },
    [setPageNum]
  );
  const renderCompanyRow = React.useCallback(
    (companySetting: CompanySettingWithStore): ReactNode => (
      <CompanyRow
        key={companySetting.companyMasterId}
        companySetting={companySetting}
        handleMutate={handleMutate}
      />
    ),
    [handleMutate]
  );

  return (
    <AdminLayout>
      <Container title="管理会社一覧">
        <div className={Styles.TableTopArea}>
          <div className={Styles.SearchArea}>
            <SearchInput
              ref={searchInputRef}
              handleChange={handleChangeSearchText}
              placeholder="管理会社名で検索"
            />
          </div>
        </div>
        <div className={clsx(Styles.TableContainer)}>
          <table className={Styles.CompanyTable}>
            <thead>
              <tr className={Styles.CompanyRow}>
                <th className={Styles.IdCell}>
                  <div>company_master_id</div>
                  <div>auth_id</div>
                </th>
                <th className={Styles.NameCell}>管理会社名</th>
                <th className={Styles.ProxyLoginCell}>操作</th>
              </tr>
            </thead>
            <tbody>{data !== null && data.objects.map(renderCompanyRow)}</tbody>
          </table>
        </div>
        {data !== null && (
          <div className={Styles.PaginationArea}>
            <Pagination
              currentPageNum={pageNum}
              handleChangePage={handleChangePage}
              maxPageNum={data.sumPageCount}
            />
          </div>
        )}
      </Container>
    </AdminLayout>
  );
};
