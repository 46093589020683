import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/deals/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/vendors/deals';

const DealsIndex: NextPage = () => (
  <>
    <NskHead title="案件一覧" />
    <Component />
  </>
);

export default DealsIndex;
