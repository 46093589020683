import React from 'react';
import Link from 'next/link';
import Styles from './DealMainSpLinks.module.scss';
import { PATH as DEAL_DETAIL_PATH } from '~/pages/deals/[id]/detail';
import { PATH as DEAL_MESSAGES_PATH } from '~/pages/deals/[id]/messages';
import { useDeals } from '~/hooks/useDeals';
import { isWithVendorDeal } from '~/models/Deal';
import { getOriginVendor } from '~/models/DealVendorCompany';
import { useMessages } from '~/hooks/deals/useMessages';
import { getUnreadCount } from '~/models/Message';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const DealMainSpLinksClient: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: messages } = useMessages().index(id);
  const unreadMessageCount = React.useMemo((): number => {
    if (messages === null) {
      return 0;
    }
    return getUnreadCount(messages, 'maintenance_company');
  }, [messages]);
  if (deal === null) {
    return null;
  }
  return (
    <div className={Styles.SPDealDetail}>
      <Link
        className={Styles.SPDealDetailLink}
        href={DEAL_DETAIL_PATH(deal.id)}
      >
        案件内容
      </Link>
      {isWithVendorDeal(deal) && getOriginVendor(deal) !== null && (
        <Link
          className={Styles.SPDealDetailLink}
          href={DEAL_MESSAGES_PATH(deal.id)}
        >
          施工会社とチャット
          {unreadMessageCount !== 0 && (
            <span className={Styles.UnreadCountBadge}>
              {unreadMessageCount}
            </span>
          )}
        </Link>
      )}
    </div>
  );
};
