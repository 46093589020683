// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_NSK_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://6e99a45dae374cbeb54a1d58109e72d4@o72281.ingest.sentry.io/6189536',
    environment: process.env.NEXT_PUBLIC_NSK_ENV,
    release: process.env.NEXT_PUBLIC_RELEASE_NO,
    normalizeDepth: 10,
    ignoreErrors: [
      'Request failed with status code 40',
      'Request failed with status code 50',
      'Network Error',
      'timeout of 0ms exceeded ',
      'Request aborted'
    ]
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
