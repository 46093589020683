import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { RiCheckLine } from 'react-icons/ri';
import Styles from './ProgressIndicator.module.scss';

export type IndicatorItem = {
  label: string;
};
type Props = DeepReadonly<{
  items: IndicatorItem[];
  currentIndex: number;
}>;
type ItemProps = DeepReadonly<{
  item: IndicatorItem;
  itemsLength: number;
  index: number;
  currentIndex: number;
}>;

type Status = 'yet' | 'current' | 'done';

const statusStyles: { [field in Status]: string } = {
  yet: Styles.Yet,
  current: Styles.Current,
  done: Styles.Done
};

type Position = 'first' | 'between' | 'last';

const positionStyles: { [field in Position]: string } = {
  first: Styles.First,
  between: Styles.Between,
  last: Styles.Last
};

const ProgressIndicatorItem: React.FC<ItemProps> = ({
  item,
  index,
  itemsLength,
  currentIndex
}: ItemProps) => {
  const status = React.useMemo((): Status => {
    if (currentIndex === itemsLength - 1) {
      // 最後のステータスに到達したら完了扱い
      return 'done';
    }
    if (currentIndex === index) {
      return 'current';
    }
    if (currentIndex > index) {
      return 'done';
    }
    return 'yet';
  }, [currentIndex, index, itemsLength]);
  const position = React.useMemo((): Position => {
    if (index === 0) {
      return 'first';
    }
    if (index === itemsLength - 1) {
      return 'last';
    }
    return 'between';
  }, [index, itemsLength]);
  return (
    <div
      className={clsx(
        Styles.ProgressIndicatorItem,
        positionStyles[position],
        statusStyles[status]
      )}
    >
      <div className={Styles.IndicatorItem}>
        <span
          className={clsx(
            Styles.Line,
            Styles.Left,
            positionStyles[position],
            statusStyles[status]
          )}
        />
        <span className={Styles.Icon}>
          {status === 'done' ? (
            <RiCheckLine className={Styles.CheckIcon} />
          ) : (
            <>{index + 1}</>
          )}
        </span>
        <span
          className={clsx(
            Styles.Line,
            Styles.Right,
            positionStyles[position],
            statusStyles[status]
          )}
        />
      </div>
      <div className={Styles.Label}>{item.label}</div>
    </div>
  );
};

export const ProgressIndicator: React.FC<Props> = ({
  items,
  currentIndex
}: Props) => (
  <div className={Styles.ProgressIndicator}>
    {items.map(
      (item: IndicatorItem, index: number): ReactNode => (
        <ProgressIndicatorItem
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${item.label}`}
          currentIndex={currentIndex}
          index={index}
          item={item}
          itemsLength={items.length}
        />
      )
    )}
  </div>
);
