import React, { ReactNode } from 'react';
import cx from 'clsx';
import { Portal } from 'react-overlays';
import { usePopper } from 'react-popper';
import { DOMContainer } from 'react-overlays/useWaitForDOMRef';
import { DropdownContext } from '~/components/common/parts/dropdown/Dropdown';
import { useClickAway } from '~/hooks/useClickAway';
import Styles from './SimpleDropdown.module.scss';

type Props = {
  title: string | ReactNode;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  openClassName?: string;
  openLock?: boolean; // clickAwayで閉じない
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
};

const InnerSimpleDropdown: React.FC<Props> = ({
  title,
  children,
  disabled,
  className = '',
  openClassName = '',
  openLock = false,
  placement
}: Props) => {
  const [show, setShow] = React.useState<boolean>(false);
  const referenceRef = React.useRef<HTMLButtonElement | null>(null);
  const popperRef = React.useRef<HTMLDivElement | null>(null);
  useClickAway(popperRef, referenceRef, (): void => {
    if (!openLock) {
      setShow(false);
    }
  });
  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement
    }
  );
  React.useEffect(() => {
    if (update) update().then();
  }, [show, update]);
  const onClickToggle = (): void => {
    setShow(!show);
  };
  const providerValue = React.useMemo(
    () => ({ show, setShow }),
    [show, setShow]
  );
  return (
    <DropdownContext.Provider value={providerValue}>
      <button
        ref={referenceRef}
        aria-expanded="false"
        aria-haspopup="true"
        className={cx(className, Styles.DropdownToggle, show && openClassName)}
        data-toggle="dropdown"
        disabled={disabled}
        onClick={onClickToggle}
        type="button"
      >
        <span className={Styles.DropdownTitle}>{title}</span>
      </button>
      <Portal container={document.body}>
        <div
          ref={popperRef}
          className={cx(Styles.DropdownPopper, !show && Styles.Hide)}
          style={styles.popper}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes.popper}
        >
          <div className={show ? '' : Styles.Hide}>{children}</div>
        </div>
      </Portal>
    </DropdownContext.Provider>
  );
};

export const SimpleDropdown: React.FC<Props> = ({
  title,
  children,
  disabled,
  className = '',
  openClassName = '',
  openLock = false,
  placement
}: Props) => {
  const [container, setContainer] = React.useState<DOMContainer | null>(null);
  React.useEffect(() => {
    setContainer(document.body);
  }, []);
  if (container === null) {
    return null;
  }
  return (
    <InnerSimpleDropdown
      className={className}
      disabled={disabled}
      openClassName={openClassName}
      openLock={openLock}
      placement={placement}
      title={title}
    >
      {children}
    </InnerSimpleDropdown>
  );
};
