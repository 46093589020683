import React from 'react';
import Styles from './statuses.module.scss';
import {
  AWAITING_ACCEPTANCE,
  FINISHED,
  IN_CONSTRUCTION,
  statusMovingValidation
} from '~/models/Status';
import { StatusHandleClientButtons } from '~/components/common/statuses/StatusHandleClientButtons';
import { useDealCompletionReportImages } from '~/hooks/deals/useDealCompletionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as COMPLETION_REPORT_PATH } from '~/pages/deals/[id]/completion_reports';
import { useDeals } from '~/hooks/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import useItandiAccount from '~/hooks/useItandiAccount';

export const AwaitingAcceptance: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal } = useDeals().show(dealId);
  const reportsApi = useDealCompletionReportImages();
  const { data: reportImages } = reportsApi.index(dealId);
  const { data: account } = useItandiAccount().currentAccount();
  const forwardStatusDisabled = React.useMemo(() => {
    if (account === null) return false;
    return statusMovingValidation[AWAITING_ACCEPTANCE](account);
  }, [account]);
  if (deal === null) {
    return null;
  }
  return (
    <div>
      <h3 className={Styles.Title}>工事が完了しました</h3>
      <p className={Styles.Description}>
        完了報告画像を確認いただき、問題なければステータスを進めてください。
      </p>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="画像確認画面"
          detailPagePath={COMPLETION_REPORT_PATH(deal.id)}
          reportImages={reportImages}
          title="完了報告画像を確認してください"
        />
      )}
      <StatusHandleClientButtons
        forwardDisabled={!forwardStatusDisabled}
        nextStatusId={FINISHED}
        prevStatusId={IN_CONSTRUCTION}
      />
    </div>
  );
};
