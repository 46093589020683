import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { ConstructionListRow } from '~/components/common/constructions/list/ConstructionListRow';
import { ConstructionListFooterRow } from '~/components/common/constructions/list/ConstructionListFooterRow';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';
import { FeeRow } from '~/components/common/constructions/list/FeeRow';
import {
  DealConstruction,
  DealConstructionContext,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { ConstructionAmountRow } from './ConstructionAmountRow';

type Props = {
  readonly: boolean;
  constructionContext: DealConstructionContext;
  constructionItems: ConstructionItem[];
  handleMutateConstructions: () => Promise<void>;
  handleUpdate:
    | ((
        submitValue: DealConstructionEdit,
        estimationId: number
      ) => Promise<NskResponse<void>>)
    | null;
  handleDestroy:
    | ((deleteConstruction: DealConstruction) => Promise<NskResponse<void>>)
    | null;
  handleUpdateSeq:
    | ((constructions: DealConstruction[]) => Promise<void>)
    | null;
  handleUpdateEnabled:
    | ((constructions: DealConstruction) => Promise<void>)
    | null;
  target: 'owner' | 'rentee' | null;
  companyType: 'client' | 'vendor' | 'partner';
  docType: 'billing' | 'estimation';
};

export const ConstructionListRows: React.FC<Props> = ({
  readonly,
  constructionContext,
  constructionItems,
  handleMutateConstructions,
  handleUpdate,
  handleDestroy,
  handleUpdateSeq,
  handleUpdateEnabled,
  target,
  companyType,
  docType
}: Props) => {
  const { dealConstructions } = constructionContext;

  // 並び替えしたときにmutate前に画面に反映するためのstate
  const [showConstructions, setShowConstructions] = React.useState<
    DealConstruction[]
  >([]);
  React.useEffect(() => {
    setShowConstructions(dealConstructions);
  }, [dealConstructions]);

  const renderRow = (
    dealConstruction: DealConstruction,
    index: number
  ): ReactNode => (
    <ConstructionListRow
      key={dealConstruction.id}
      constructionItems={constructionItems}
      dealConstruction={dealConstruction}
      handleDestroy={handleDestroy}
      handleMutateConstructions={handleMutateConstructions}
      handleUpdate={handleUpdate}
      handleUpdateEnabled={handleUpdateEnabled}
      handleUpdateSeq={handleUpdateSeq}
      index={index}
      readonly={readonly}
      target={target}
    />
  );
  const handleDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const moveConstruction =
      dealConstructions.find(
        (e: DealConstruction) => String(e.id) === draggableId
      ) ?? null;
    const newConstruction: DealConstruction[] = [];
    const wkConstruction = [...dealConstructions];
    wkConstruction.splice(source.index, 1);
    if (moveConstruction !== null) {
      wkConstruction.splice(destination.index, 0, moveConstruction);
    }
    wkConstruction.forEach((e: DealConstruction, i: number) => {
      newConstruction.push({ ...e, seq: i + 1 });
    });
    setShowConstructions(newConstruction);
    handleUpdateSeq && (await handleUpdateSeq(newConstruction));
    handleMutateConstructions().then();
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided): React.ReactElement<HTMLElement> => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {showConstructions.map(renderRow)}
              {provided.placeholder}
              {dealConstructions.length > 0 &&
                constructionContext.type === 'DealEstimationContext' &&
                constructionContext.managementFee !== 0 && (
                  <FeeRow
                    fee={constructionContext.managementFee}
                    feeTitle="諸経費（工事管理費）"
                    showSwitchEnable={handleUpdateEnabled !== null}
                  />
                )}
              {companyType === 'vendor' &&
                constructionContext.type === 'DealEstimationContext' &&
                constructionContext.vendorFee !== 0 && (
                  <ConstructionAmountRow
                    constructionContext={constructionContext}
                  />
                )}
              {dealConstructions.length > 0 &&
                companyType !== 'client' &&
                constructionContext.type === 'DealEstimationContext' &&
                constructionContext.vendorFee !== 0 && (
                  <FeeRow
                    fee={constructionContext.vendorFee}
                    feeTitle="送客手数料（管理会社には表示されません）"
                    showSwitchEnable={handleUpdateEnabled !== null}
                  />
                )}
              <ConstructionListFooterRow
                companyType={companyType}
                constructionContext={constructionContext}
                docType={docType}
                showButtons={!readonly}
                showSwitchEnable={handleUpdateEnabled !== null}
                target={target}
              />
            </>
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};
