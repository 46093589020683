import { CompanyVendor, CompanyVendorEdit } from '~/models/CompanyVendor';
import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';

const paths = {
  create: '/company_vendors',
  update: '/company_vendors/:id',
  destroy: '/company_vendors/:id',
  updateSeq: '/company_vendors/update_seq'
};

export const create = async (
  companyVendor: CompanyVendorEdit,
  invite: boolean
): Promise<NskResponse<void>> =>
  postRequest(paths.create, { companyVendor, invite });

export const update = async (
  companyVendor: CompanyVendorEdit,
  companyVendorId: number
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(companyVendorId)), {
    companyVendor
  });

export const destroy = async (
  companyVendor: CompanyVendor
): Promise<NskResponse<void>> =>
  deleteRequest(paths.destroy.replace(':id', String(companyVendor.id)));

export const updateSeq = async (
  companyVendors: CompanyVendor[]
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeq, { companyVendors });
