import React from 'react';
import {
  ItandiChip,
  Input,
  InputText,
  InputCheckBox
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './DealClientTodoModalForm.module.scss';
import { DealTodoEdit } from '~/models/DealTodo';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';
import { toDate } from '~/utils/DateUtil';

export const DealClientTodoModalForm: React.FC = () => {
  const {
    setValue,
    register,
    watch,
    formState: { errors }
  } = useFormContext<DealTodoEdit>();
  return (
    <div className={Styles.Forms}>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>完了</div>
        <div className={Styles.FormInputCol}>
          <InputCheckBox
            checked={watch('completed')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setValue('completed', e.target.checked);
            }}
          />
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          タイトル
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormInputCol}>
          <Input isInvalid={errors.title != null} {...register('title')} />
          {errors.title != null && (
            <div className={Styles.InvalidFeedback}>{errors.title.message}</div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          対応期限
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormInputCol}>
          <Datepicker
            onChange={(date: Date | null): void => {
              if (date === null) {
                setValue('dueDate', null);
                return;
              }
              setValue('dueDate', date.toISOString());
            }}
            selected={watch('dueDate') ? toDate(watch('dueDate')) : null}
          />
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          メモ
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormInputCol}>
          <InputText isInvalid={errors.memo != null} {...register('memo')} />
          {errors.memo != null && (
            <div className={Styles.InvalidFeedback}>{errors.memo.message}</div>
          )}
        </div>
      </div>
    </div>
  );
};
