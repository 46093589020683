import React, { ForwardedRef, MutableRefObject, RefAttributes } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { Input } from '@itandi/itandi-bb-ui';
import Styles from './SearchInput.module.scss';
import { useDebounce } from '~/hooks/useDebounce';

type Props = {
  placeholder?: string;
  debounceDelay?: number;
  defaultValue?: string;
  handleChange: (searchWord: string) => void | Promise<void>; // useCallbackでWrapすること
};

export const SearchInput: React.FC<Props & RefAttributes<HTMLInputElement>> =
  React.forwardRef(
    (
      {
        placeholder,
        debounceDelay = 200,
        defaultValue = '',
        handleChange
      }: Props,
      ref: MutableRefObject<HTMLInputElement> | ForwardedRef<HTMLInputElement>
    ) => {
      const [searchWord, setSearchWord] = React.useState<string>(defaultValue);
      const debounce = useDebounce(debounceDelay);
      const onChangeWord = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;
        setSearchWord(inputValue);
        debounce(() => {
          handleChange(inputValue);
        });
      };
      return (
        <div className={Styles.SearchArea}>
          <RiSearchLine className={Styles.SearchIcon} />
          <Input
            ref={ref}
            className={Styles.SearchInput}
            onChange={onChangeWord}
            placeholder={placeholder}
            type="text"
            value={searchWord}
          />
        </div>
      );
    }
  );
