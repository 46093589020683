import {
  TYPE_CLEANING,
  TYPE_MISC_CONSTRUCTION,
  TYPE_RECOVERING_CONSTRUCTION
} from '~/constants/DealConstructionConst';
import { Deal } from '~/models/Deal';

export type OldDealConstructionSchedule = {
  id: number;
  constructionScheduleType: number;
  startDate: Date;
  endDate: Date;
};

export type ConstructionScheduleType =
  | typeof TYPE_MISC_CONSTRUCTION
  | typeof TYPE_RECOVERING_CONSTRUCTION
  | typeof TYPE_CLEANING;

export type DealConstructionSchedule = {
  id: number;
  constructionScheduleType: ConstructionScheduleType;
  startDate: string;
  endDate: string;
};
export type DealConstructionScheduleEdit = {
  id?: number;
  constructionScheduleType: ConstructionScheduleType;
  startDate: string | null;
  endDate: string | null;
};

/** @deprecated */
export type DealConstructionSchedules = Array<OldDealConstructionSchedule>;

export const CONSTRUCTION_SCHEDULE_LABELS = {
  [TYPE_MISC_CONSTRUCTION]: '雑工事',
  [TYPE_RECOVERING_CONSTRUCTION]: 'クロス工事',
  [TYPE_CLEANING]: 'クリーニング'
};

export const CONSTRUCTION_NUMBERS: ConstructionScheduleType[] = [
  TYPE_MISC_CONSTRUCTION,
  TYPE_RECOVERING_CONSTRUCTION,
  TYPE_CLEANING
];

export const isInvalidSchedule = (
  schedule: DealConstructionScheduleEdit
): boolean =>
  // どちらかのみが入力されているものをinvalidに
  (schedule.startDate !== null && schedule.endDate === null) ||
  (schedule.startDate === null && schedule.endDate !== null);

export const buildInitialSchedules = (
  deal: Deal
): DealConstructionScheduleEdit[] => {
  const { dealConstructionSchedules: schedules } = deal;
  return CONSTRUCTION_NUMBERS.map(
    (num: ConstructionScheduleType): DealConstructionScheduleEdit => {
      const schedule =
        schedules.find(
          (s: DealConstructionSchedule) => s.constructionScheduleType === num
        ) ?? null;
      if (schedule !== null) {
        return schedule;
      }
      return {
        constructionScheduleType: num,
        startDate: null,
        endDate: null
      };
    }
  );
};
