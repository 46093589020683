import React from 'react';
import { toast } from 'react-toastify';
import { Modal } from '~/components/common/parts/modal/Modal';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { ConstructionItem } from '~/models/ConstructionItem';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';

type Props = {
  dealConstruction: DealConstruction;
  constructionItems: ConstructionItem[];
  handleMutateConstructions: () => Promise<void>;
  handleClose: () => void;
  handleUpdate: (
    submitValue: DealConstructionEdit,
    estimationId: number
  ) => Promise<NskResponse<void>>;
};

export const ConstructionEditModal: React.FC<Props> = ({
  dealConstruction,
  handleMutateConstructions,
  constructionItems,
  handleClose,
  handleUpdate
}: Props) => {
  const handleSave = async (
    submitValue: DealConstructionEdit
  ): Promise<boolean> => {
    const result = await handleUpdate(submitValue, dealConstruction.id);
    if (isSuccess(result)) {
      await handleMutateConstructions();
      handleClose();
      toast.success('見積りを更新しました');
      return true;
    }
    toast.error('エラーが発生しました');
    return false;
  };
  return (
    <Modal handleClose={handleClose} isOpen title="見積りの修正">
      <ConstructionForm
        constructionItems={constructionItems}
        dealConstruction={dealConstruction}
        handleSave={handleSave}
        type="edit"
      />
    </Modal>
  );
};
