import React from 'react';
import Styles from './Page.module.scss';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import { PATH } from '~/pages/vendors/deals/[id]';
import { useDeals } from '~/hooks/vendors/useDeals';
import Loading from '~/components/common/parts/loading/Loading';
import { useDealEstimations } from '~/hooks/vendors/deals/useDealEstimations';
import { getDownloadPath } from '~/utils/api/vendors/deals/DealEstimationFile';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: estimationContext, mutate: mutateEstimations } =
    useDealEstimations().index(id);
  if (deal === null || estimationContext === null) {
    return <Loading isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '見積り作成', active: true }
  ];
  const handleClickPreview = (): void => {
    const path = getDownloadPath(deal.id);
    window.open(path);
  };
  return (
    <VendorLayoutV2>
      <DealLayout breadcrumbItems={breadcrumbItems} deal={deal} isVendor>
        <h2 className={Styles.EstimateTitle}>見積り確認</h2>
        <ConstructionTop
          constructionContext={estimationContext}
          handleClickPreview={handleClickPreview}
          readonly
        />
        <div className={Styles.EstimateList}>
          <ConstructionList
            companyType="vendor"
            constructionContext={estimationContext}
            constructionItems={[]}
            docType="estimation"
            handleMutateConstructions={async (): Promise<void> => {
              mutateEstimations != null && (await mutateEstimations());
            }}
            readonly
          />
        </div>
      </DealLayout>
    </VendorLayoutV2>
  );
};
