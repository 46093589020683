import React from 'react';
import cx from 'clsx';
import Styles from './ModalFooter.module.scss';

type Props = {
  isSubmitting?: boolean;
  submitInvalid?: boolean;
  onClickCancel: () => void;
  onClickAccept: () => void | boolean | Promise<boolean> | Promise<void>;
  acceptLabel?: string;
  bottomSticky?: boolean;
};

export const ModalFooter: React.FC<Props> = ({
  isSubmitting = false,
  submitInvalid = false,
  onClickCancel,
  onClickAccept,
  acceptLabel = '保存',
  bottomSticky = true
}: Props) => (
  <div
    className={cx(Styles.ModalFooter, bottomSticky ? Styles.BottomSticky : '')}
  >
    <button
      className={Styles.CancelButton}
      disabled={isSubmitting}
      onClick={onClickCancel}
      type="button"
    >
      キャンセル
    </button>
    <button
      className={Styles.SubmitButton}
      disabled={isSubmitting || submitInvalid}
      onClick={onClickAccept}
      type="button"
    >
      {acceptLabel}
    </button>
  </div>
);
