import React, { ReactNode } from 'react';
import { useMessageSummaries } from '~/hooks/useMessageSummaries';
import { isUnreadForClient, MessageSummary } from '~/models/MessageSummary';
import { ChatDealListRow } from '~/components/common/chat/deal_list/ChatDealListRow';
import useItandiAccount from '~/hooks/useItandiAccount';

/**
 * チャットしている案件一覧
 *
 * @for client
 * @constructor
 */
export const ChatDealListRowsClient: React.FC = () => {
  const { data: session } = useItandiAccount().currentAccount();
  const { data: messageSummaries } = useMessageSummaries().index();
  const getUnread = React.useCallback(
    (messageSummary: MessageSummary): boolean => {
      if (session === null || session.company === null) {
        return false;
      }
      return isUnreadForClient(messageSummary, session.company.authId);
    },
    [session]
  );
  const renderMessageSummary = React.useCallback(
    (messageSummary: MessageSummary): ReactNode => (
      <ChatDealListRow
        key={messageSummary.id}
        messageSummary={messageSummary}
        unread={getUnread(messageSummary)}
      />
    ),
    [getUnread]
  );
  if (messageSummaries === null) {
    return null;
  }
  return <div>{messageSummaries.map(renderMessageSummary)}</div>;
};
