import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { MessageSummary } from '~/models/MessageSummary';

const paths = {
  index: '/vendors/message_summaries'
};
type TIndex = () => SwrReturnType<MessageSummary[]>;
type ReturnType = {
  index: TIndex;
};

export const useMessageSummaries = (): ReturnType => {
  const index = (): SwrReturnType<MessageSummary[]> => {
    const { data, mutate, error } = useSWR<MessageSummary[]>(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: !error && !data,
      error
    };
  };
  return { index };
};
