import React from 'react';
import { useRouter } from 'next/router';
import { PATH as STAFFS_PATH } from '~/pages/settings/staffs';

export const Page: React.FC = () => {
  const router = useRouter();
  if (router.isReady) {
    router.replace(STAFFS_PATH).then();
  }
  return <></>;
};
