import React from 'react';
import clsx from 'clsx';
import Styles from './ChatPagesContainer.module.scss';
import { ChatDealListPane } from '~/components/common/chat/deal_list/ChatDealListPane';
import { ChatMessagePane } from '~/components/common/chat/messages/ChatMessagePane';
import { MessageSummary } from '~/models/MessageSummary';

type Props = DeepReadonly<{
  isVendor?: boolean;
}>;

type SelectedState = {
  selectedSummary: MessageSummary | null;
  setSelectedSummary?: (summary: MessageSummary | null) => void;
};
const SelectedContext = React.createContext<SelectedState>({
  selectedSummary: null,
  setSelectedSummary: undefined
});
export const useMessageSelectedContext = (): SelectedState =>
  React.useContext<SelectedState>(SelectedContext);

/**
 * 案件を横断してやり取りを管理できるチャット画面
 *
 * @param isVendor
 * @constructor
 */
export const ChatPagesContainer: React.FC<Props> = ({
  isVendor = false
}: Props) => {
  const [selectedSummary, setSelectedSummary] =
    React.useState<MessageSummary | null>(null);
  const providerValue = React.useMemo(
    () => ({ selectedSummary, setSelectedSummary }),
    [selectedSummary]
  );
  return (
    <div className={clsx(Styles.Container, !isVendor && Styles.Client)}>
      <SelectedContext.Provider value={providerValue}>
        <div className={Styles.MainArea}>
          <ChatDealListPane isVendor={isVendor} />
          {selectedSummary !== null && <ChatMessagePane isVendor={isVendor} />}
        </div>
      </SelectedContext.Provider>
    </div>
  );
};
