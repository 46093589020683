import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Button } from '@itandi/itandi-bb-ui';
import Styles from './Page.module.scss';
import { AdminLayout } from '~/components/common/layouts/AdminLayout';
import { useVendorCompanies } from '~/hooks/admin/useVendorCompanies';
import { VendorCompany } from '~/models/VendorCompany';
import { VendorCompanyRow } from '~/components/specifics/admin/vendor_companies/VendorCompanyRow';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { VendorCompanyModal } from '~/components/specifics/admin/vendor_companies/VendorCompanyModal';

export const Page: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { data } = useVendorCompanies().index();
  const renderCompanyRow = (vendorCompany: VendorCompany): ReactNode => (
    <VendorCompanyRow key={vendorCompany.id} vendorCompany={vendorCompany} />
  );
  if (data === null) {
    return <></>;
  }
  return (
    <AdminLayout>
      <div className={Styles.Container}>
        <h1 className={Styles.Header}>施工会社一覧</h1>
        <div className={clsx(Styles.TableContainer)}>
          <div className={Styles.TableTop}>
            <Button
              className={Styles.AddButton}
              height="small"
              onClick={handleOpen}
              variant="primary"
            >
              施工会社を追加
            </Button>
          </div>
          <table className={Styles.CompanyTable}>
            <thead>
              <tr className={Styles.CompanyRow}>
                <th className={Styles.IdCell}>ID</th>
                <th className={Styles.NameCell}>施工会社名</th>
                <th className={Styles.NameCell}>ログインメールアドレス</th>
                <th className={Styles.ProxyLoginCell} />
              </tr>
            </thead>
            <tbody>{data.map(renderCompanyRow)}</tbody>
          </table>
        </div>
        <VendorCompanyModal handleClose={handleClose} isOpen={isOpen} />
      </div>
    </AdminLayout>
  );
};
