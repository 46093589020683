import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useUsingApplications } from '~/hooks/useUsingApplications';
import Styles from './RelatedDealRow.module.scss';
import { DealWithPrices } from '~/models/Deal';
import { getSkFullPath, PATH } from '~/utils/SeisanKanriPath';

type Props = {
  deal: DealWithPrices;
  notTargetDeal: boolean;
};

export const RelatedDealRowSkLink: React.FC<Props> = ({
  deal,
  notTargetDeal
}: Props) => {
  const router = useRouter();
  const { useSK } = useUsingApplications();
  if (!useSK) {
    return null;
  }
  const onClickSkLink = React.useCallback(() => {
    router
      .push({
        pathname: getSkFullPath(PATH.partnerDealsNew),
        query: { nskDealId: deal.id }
      })
      .then();
  }, [router, deal]);
  return (
    <div
      className={clsx(Styles.SkLinkBlock, notTargetDeal && Styles.NotTarget)}
    >
      <h4 className={Styles.ContextLabel}>精算管理連携</h4>
      {deal.skPartnerDeal?.linked ? (
        <Link
          className={Styles.SkLink}
          href={getSkFullPath(PATH.partnerDeal(deal.skPartnerDeal.id))}
        >
          連携済
        </Link>
      ) : (
        <div>
          <p className={Styles.SkLinkText}>未連携</p>
          {!notTargetDeal && (
            <Button height="small" onClick={onClickSkLink} variant="tertiary">
              連携する
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
