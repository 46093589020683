import React from 'react';
import {
  InputCheckBox,
  Modalv2,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import { RiEdit2Line } from 'react-icons/ri';
import Styles from './DealOriginVendorClientChangeModal.module.scss';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useDeals } from '~/hooks/useDeals';
import { getOriginDealVendor } from '~/models/DealVendorCompany';
import { update } from '~/utils/api/deals/DealVendorCompany';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
}>;

/**
 * 管理会社がoriginのDealVendorCompanyを更新するためのModal
 *
 * @constructor
 * @for client
 */
export const DealOriginVendorClientChangeModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(id);
  const [checked, setChecked] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (deal !== null) {
      setChecked(getOriginDealVendor(deal)?.showTenant ?? false);
    }
  }, [deal]);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    if (deal === null || id === null) {
      return;
    }
    const dealVendorCompanyId = getOriginDealVendor(deal)?.id;
    if (!dealVendorCompanyId) {
      return;
    }
    const response = await update(id, dealVendorCompanyId, {
      showTenant: checked
    });
    if (isSuccess(response)) {
      mutateDeal && (await mutateDeal());
      toast.success('更新しました');
      handleClose();
      return;
    }
    toast.error('エラーが発生しました');
  }, [checked, deal, handleClose, id, mutateDeal]);

  if (deal === null) {
    return null;
  }
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader icon={RiEdit2Line} title="施工会社情報変更" />
      <ModalBody>
        <div className={Styles.Forms}>
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabelCol}>個人情報公開</div>
            <div className={Styles.FormSettingCol}>
              <InputCheckBox
                checked={checked}
                labelText="施工会社が入居者情報を参照できるようにする"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button onClick={handleSubmit} variant="primary">
            保存
          </Button>
        }
      />
    </Modalv2>
  );
};
