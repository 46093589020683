import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { VendorSession } from '~/models/VendorSession';

const paths = {
  index: '/vendors/sessions'
};

type TIndex = () => SwrReturnType<VendorSession>;
type ReturnType = {
  index: TIndex;
};

export const useSessions = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<VendorSession>(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
