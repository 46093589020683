import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

export const getTodayISO8601 = (): string => dayjs().toISOString();

export const toDate = (dateStr: string | null): Date | null => {
  if (dateStr == null) {
    return null;
  }
  return dayjs(dateStr).toDate();
};

export const formatDate = (
  date: Date | string | null,
  withTime = false
): string | null => {
  if (date === null) {
    return null;
  }
  return dayjs(date).format(`YYYY/MM/DD${withTime ? ' HH:mm' : ''}`);
};

export const formatHyphenDate = (date: Date | string | null): string | null => {
  if (date === null) {
    return null;
  }
  return dayjs(date).format(`YYYY-MM-DD`);
};

export const formatMonthAndDay = (
  date: Date | string | null
): string | null => {
  if (date === null) {
    return null;
  }
  return dayjs(date).format(`MM/DD`);
};

export const formatHoursAndMinutes = (
  date: Date | string | null
): string | null => {
  if (date === null) {
    return null;
  }
  return dayjs(date).format(`HH:mm`);
};

export const parseJst = (date: Date | string): Date =>
  dayjs(date).tz().toDate();

/**
 * Messageのやり取りで表示する時間
 *
 * @param dateStr
 */
export const getMessageDate = (dateStr: string): string => {
  if (dateStr == null) {
    return '';
  }
  const dayjsDate = dayjs(dateStr);

  let format = 'H時m分';
  const isToday =
    dayjs().format('YYYY-MM-DD') === dayjsDate.format('YYYY-MM-DD');
  if (isToday) {
    return dayjsDate.format(`今日 ${format}`);
  }
  format = `M月D日(ddd) ${format}`;
  const isThisYear = dayjs().year() === dayjsDate.year();
  if (!isThisYear) {
    format = `YYYY年${format}`;
  }
  return dayjsDate.format(format);
};
