import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';
import { DealInspectionCandidateDateParam } from '~/models/InspectionCandidateDate';

const paths = {
  update: '/deals/:deal_id/deal_inspection_candidate_dates'
};

export const update = async (
  dealId: number,
  dealInspectionCandidateDates: DealInspectionCandidateDateParam
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':deal_id', String(dealId)), {
    dealInspectionCandidateDates
  });
