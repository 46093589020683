import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { RiCalendarEventLine } from 'react-icons/ri';
import { FormProvider, useForm } from 'react-hook-form';
import { Deal } from '~/models/Deal';
import { isSuccess } from '~/utils/api/api_handler';
import { ScheduleInputForm } from './ScheduleInputForm';
import {
  DealInspectionCandidateDateParam,
  buildInspectionCandidateDate
} from '~/models/InspectionCandidateDate';
import { update } from '~/utils/api/deals/DealInspectionCandidateDate';

type Props = {
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate: () => Promise<void>;
};

export const ScheduleInputModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  handleMutate
}: Props) => {
  const methods = useForm<DealInspectionCandidateDateParam>({
    mode: 'onBlur',
    defaultValues: deal ? buildInspectionCandidateDate(deal) : {}
  });
  const onSubmitSchedule = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: DealInspectionCandidateDateParam) => {
        const response = await update(deal.id, submitValue);
        if (isSuccess(response)) {
          await handleMutate();
          handleClose();
          toast.success('更新しました');
          return;
        }
        toast.error('エラーが発生しました');
      }
    )();
  }, [methods, deal, handleMutate, handleClose]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader icon={RiCalendarEventLine} title="立会希望日の更新" />
      <ModalBody>
        <FormProvider {...methods}>
          <ScheduleInputForm />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            キャンセル
          </Button>
        }
        rightArea={
          <Button
            disabled={methods.formState.isSubmitting}
            onClick={onSubmitSchedule}
            variant="primary"
          >
            確定
          </Button>
        }
      />
    </Modalv2>
  );
};
