import { SubtypeEdit } from '~/models/Subtype';
import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';

const paths = {
  create: '/subtypes',
  update: '/subtypes/:id',
  destroy: '/subtypes/:id'
};

export const create = async (subtype: SubtypeEdit): Promise<NskResponse> =>
  postRequest(paths.create, { subtype });

export const update = async (
  subtypeId: number,
  subtype: SubtypeEdit
): Promise<NskResponse> =>
  putRequest(paths.update.replace(':id', subtypeId.toString()), { subtype });

export const destroy = async (subtypeId: number): Promise<NskResponse> =>
  deleteRequest(paths.destroy.replace(':id', subtypeId.toString()));
