import { DealTodoEdit } from '~/models/DealTodo';
import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';

const paths = {
  create: '/deals/:deal_id/deal_todos',
  update: '/deals/:deal_id/deal_todos/:id',
  destroy: '/deals/:deal_id/deal_todos/:id'
};

export const create = async (
  dealId: number,
  dealTodo: DealTodoEdit
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    dealTodo
  });

export const update = async (
  dealId: number,
  dealTodoId: number,
  dealTodo: DealTodoEdit
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':deal_id', String(dealId))
      .replace(':id', String(dealTodoId)),
    { dealTodo }
  );

export const destroy = async (
  dealId: number,
  dealTodoId: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':deal_id', String(dealId))
      .replace(':id', String(dealTodoId))
  );
