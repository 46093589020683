import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import { Notification, NotificationEdit } from '~/models/Notification';

const paths = {
  create: '/company_notifications',
  update: '/company_notifications/:id',
  destroy: '/company_notifications/:id'
};

export const create = async (
  notification: NotificationEdit
): Promise<NskResponse<void>> => postRequest(paths.create, { notification });

export const update = async (
  notification: NotificationEdit,
  notificationId: number
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(notificationId)), {
    notification
  });

export const destroy = async (
  notification: Notification
): Promise<NskResponse<void>> =>
  deleteRequest(paths.destroy.replace(':id', String(notification.id)));
