import React from 'react';
import { RiMapPinLine } from 'react-icons/ri';
import cx from 'clsx';
import Styles from './DealLayoutHeader.module.scss';
import { Deal } from '~/models/Deal';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { DealTypeLabel } from '~/components/common/parts/label/DealTypeLabel';

type Props = {
  deal: Deal;
  breadcrumbItems: BreadcrumbItem[];
};

export const DealLayoutHeaderVendor: React.FC<Props> = ({
  deal,
  breadcrumbItems
}: Props) => (
  <div className={Styles.DealHeader} id="deal_header">
    <div className={Styles.HeaderLeftContent}>
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      <div className={Styles.HeaderElements}>
        <div className={Styles.AlignCenter}>
          <DealTypeLabel
            dealSubtype={deal.dealSubtype}
            dealType={deal.dealType}
          />
        </div>
        <div>
          <h1 className={Styles.RoomName}>
            {deal.name} {deal.roomNumber}
          </h1>
        </div>
        <div className={cx(Styles.AlignCenter, Styles.DealAddress)}>
          <RiMapPinLine className={Styles.Icon} />
          {deal.address}
        </div>
      </div>
    </div>
  </div>
);
