import React from 'react';
import { useBuildings } from '~/hooks/kensaku_kun/useBuildings';
import { SearchInput } from '~/components/common/parts/search/SearchInput';
import { Property } from '~/models/kensaku_kun/property';
import { BuildingSelectRow } from '~/components/common/kensaku_kun/PropertySelectModal/BuildingSelectRow';
import Styles from './BuildingSelectPage.module.scss';

type Props = {
  handleSelectBuilding: (building: Property<'building'>) => void;
};

export const BuildingSelectPage: React.FC<Props> = ({
  handleSelectBuilding
}: Props) => {
  const [searchWord, setSearchWord] = React.useState<string>('');
  const { data: buildings } = useBuildings().index(searchWord);

  const handleChangeSearchWord = React.useCallback((newWord: string) => {
    setSearchWord(newWord);
  }, []);
  const renderRow = React.useCallback(
    (building: Property<'building'>) => (
      <BuildingSelectRow
        key={building.propertyId}
        building={building}
        handleSelectBuilding={handleSelectBuilding}
      />
    ),
    [handleSelectBuilding]
  );
  return (
    <div>
      <SearchInput
        handleChange={handleChangeSearchWord}
        placeholder="物件名を入力してください"
      />
      <div className={Styles.List}>{buildings?.map(renderRow)}</div>
    </div>
  );
};
