import React from 'react';
import { useSearchParams } from 'next/navigation';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import { ImportConversionTable } from '~/components/specifics/settings/imports/[importType]/import_conversions/ImportConversionTable';
import { importTypeLabels, isImportType } from '~/models/ImportLog';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import SettingStyles from '../../../SettingStyle.module.scss';
import { PATH as IMPORTS_PATH } from '~/pages/settings/imports';
import { PATH as IMPORT_PATH } from '~/pages/settings/imports/[importType]';
import { ImportConversionTop } from '~/components/specifics/settings/imports/[importType]/import_conversions/ImportConversionTop';

export const Page: React.FC = () => {
  const searchParams = useSearchParams();
  const importType = searchParams.get('importType');
  if (importType === null || !isImportType(importType)) {
    return null;
  }
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <div className={SettingStyles.BreadcrumbsBlock}>
          <Breadcrumbs>
            <BreadcrumbsItem href={IMPORTS_PATH}>
              過去データ取込
            </BreadcrumbsItem>
            <BreadcrumbsItem href={IMPORT_PATH(importType)}>
              {importTypeLabels[importType]}
            </BreadcrumbsItem>
            <BreadcrumbsItem isText>取込変換設定</BreadcrumbsItem>
          </Breadcrumbs>
        </div>
        <h2 className={SettingStyles.SettingTitle}>
          {importTypeLabels[importType]}の取込変換設定
        </h2>
        <ImportConversionTop importType={importType} />
        <ImportConversionTable importType={importType} />
      </ClientSettingLayout>
    </DefaultV2>
  );
};
