import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import Styles from './SummaryFileTemplateRow.module.scss';
import { SummaryFileTemplateType } from '~/models/SummaryFileTemplate';
import { create } from '~/utils/api/SummaryFileTemplate';
import { useSummaryFileTemplates } from '~/hooks/useSummaryFileTemplates';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  fileType: SummaryFileTemplateType;
};

export const SummaryFileTemplateRowNoSetting: React.FC<Props> = ({
  fileType
}: Props) => {
  const { mutate } = useSummaryFileTemplates().useIndex();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const handleChangeFile = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files === null || inputFileRef.current === null) {
        return;
      }
      const response = await create(e.target.files[0], fileType);
      inputFileRef.current.value = '';
      if (isSuccess(response)) {
        mutate != null && (await mutate());
        toast.success('アップロードしました');
        return;
      }
      toast.error(`エラーが発生しました: ${response.message}`);
    },
    [fileType, mutate]
  );
  const onClickUpload = React.useCallback(() => {
    inputFileRef?.current?.click();
  }, [inputFileRef]);
  return (
    <div className={Styles.SettingContents}>
      <span className={clsx(Styles.SettingLabel, Styles.NoSetting)}>
        未設定
      </span>
      <input
        ref={inputFileRef}
        accept=".xlsx"
        className={Styles.HideFilePostInput}
        onChange={handleChangeFile}
        type="file"
      />
      <Button height="small" onClick={onClickUpload}>
        アップロード
      </Button>
    </div>
  );
};
