import React, { ReactNode } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import Styles from './DeleteMenuButton.module.scss';
import { Button } from '~/components/common/parts/button/Button';

type Props = {
  children: ReactNode;
  onClick: () => void | Promise<void>;
};

export const DeleteMenuButton: React.FC<Props> = ({
  children,
  onClick
}: Props) => (
  <Button onClick={onClick} size="sm" variant="normal">
    <RiDeleteBinLine className={Styles.Icon} />
    {children}
  </Button>
);
