import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/components/specifics/markets/company_settings/new/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/markets/company_settings/new';

const Home: NextPage = () => (
  <>
    <NskHead title="内装工事くんおまかせプラン利用開始" />
    <Component />
  </>
);

export default Home;
