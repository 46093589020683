import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Box, Flex, FormControl, Input } from '@itandi/itandi-bb-ui';
import { DealSearchCondition } from '~/models/Deal';
import { DealTypeCheckBox } from './detail/DealTypeCheckBox';
import { SearchConditionDate } from './detail/SearchConditionDate';
import { StatusCombobox } from './detail/StatusCombobox';
import { useStatuses } from '~/hooks/vendors/useStatuses';
import { getWithVendorStatuses } from '~/models/Status';
import { MaintenanceCompanyCombobox } from '~/components/specifics/vendors/deals/index/search/detail/MaintenanceCompanyCombobox';
import { DealCompanySetting } from '~/models/CompanySetting';
import { VendorUserCombobox } from '~/components/specifics/vendors/deals/index/search/detail/VendorUserCombobox';
import { VendorPartnerCombobox } from '~/components/specifics/vendors/deals/index/search/detail/VendorPartnerCombobox';

type Props = {
  handleChangeMaintenanceCompanyInput: (inputText: string) => void;
  dealSearchCondition: DealSearchCondition;
  maintenanceCompanies: DealCompanySetting[] | null;
};

export const DealSearchDetailTab: React.FC<Props> = ({
  dealSearchCondition,
  maintenanceCompanies,
  handleChangeMaintenanceCompanyInput
}: Props) => {
  const { register, setValue } = useFormContext<DealSearchCondition>();
  const { data: tmpStatuses } = useStatuses().index();
  const onChangeCompanyMasterId = React.useCallback(
    async (companyMasterId: number | undefined): Promise<void> => {
      setValue('companyMasterId', companyMasterId);
    },
    [setValue]
  );
  if (tmpStatuses === null) {
    return null;
  }
  const statuses = getWithVendorStatuses(tmpStatuses);
  return (
    <Box margin={16}>
      <Grid gap={8}>
        <FormControl label="ステータス" variant="Vertical">
          <StatusCombobox
            dealSearchCondition={dealSearchCondition}
            statuses={statuses}
          />
        </FormControl>
        <FormControl label="案件タイプ" variant="Vertical">
          <Flex marginY={8} wrap="wrap">
            <DealTypeCheckBox
              key="restoration"
              dealSearchCondition={dealSearchCondition}
              dealType="restoration"
              labelText="原状回復"
            />
            <DealTypeCheckBox
              key="repair"
              dealSearchCondition={dealSearchCondition}
              dealType="repair"
              labelText="修繕"
            />
            <DealTypeCheckBox
              key="other"
              dealSearchCondition={dealSearchCondition}
              dealType="other"
              labelText="その他"
            />
          </Flex>
        </FormControl>
        <FormControl label="案件名" variant="Vertical">
          <Input {...register('name')} placeholder="案件名" />
        </FormControl>
        <FormControl label="所在地" variant="Vertical">
          <Input {...register('address')} placeholder="所在地" />
        </FormControl>
        <FormControl label="入居者" variant="Vertical">
          <Input {...register('tenantName')} placeholder="入居者" />
        </FormControl>
        <FormControl label="担当者" variant="Vertical">
          <VendorUserCombobox dealSearchCondition={dealSearchCondition} />
        </FormControl>
        <FormControl label="管理会社" variant="Vertical">
          <MaintenanceCompanyCombobox
            handleChangeMaintenanceCompanyInput={
              handleChangeMaintenanceCompanyInput
            }
            maintenanceCompanies={maintenanceCompanies}
            onChangeCompanyMasterId={onChangeCompanyMasterId}
            selectedCompanyMasterId={dealSearchCondition.companyMasterId}
          />
        </FormControl>
        <FormControl label="実施会社" variant="Vertical">
          <VendorPartnerCombobox dealSearchCondition={dealSearchCondition} />
        </FormControl>
        <FormControl label="立会日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="inspectionMinDate"
              defaultValue={dealSearchCondition.inspectionMinDate}
            />
            〜
            <SearchConditionDate
              attributeName="inspectionMaxDate"
              defaultValue={dealSearchCondition.inspectionMaxDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="工期開始日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="constructionMinStartDate"
              defaultValue={dealSearchCondition.constructionMinStartDate}
            />
            〜
            <SearchConditionDate
              attributeName="constructionMaxStartDate"
              defaultValue={dealSearchCondition.constructionMaxStartDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="工期終了日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="constructionMinEndDate"
              defaultValue={dealSearchCondition.constructionMinEndDate}
            />
            〜
            <SearchConditionDate
              attributeName="constructionMaxEndDate"
              defaultValue={dealSearchCondition.constructionMaxEndDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="登録日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="registrationMinDate"
              defaultValue={dealSearchCondition.registrationMinDate}
            />
            〜
            <SearchConditionDate
              attributeName="registrationMaxDate"
              defaultValue={dealSearchCondition.registrationMaxDate}
            />
          </Flex>
        </FormControl>
      </Grid>
    </Box>
  );
};
