import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Styles from './PropertyDealLayout.module.scss';
import { usePropertyDeals } from '~/hooks/usePropertyDeals';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';

type Props = DeepReadonly<{
  children: ReactNode;
  breadcrumbItems: BreadcrumbItem[];
}>;

/**
 * @for client
 * @constructor
 */
export const PropertyDealLayout: React.FC<Props> = ({
  children,
  breadcrumbItems
}: Props) => {
  const { id = null } = useRouter().query;
  const { data: propertyDeal } = usePropertyDeals().show(
    id === null ? null : Number(id)
  );
  if (propertyDeal === null) {
    return null;
  }
  return (
    <div>
      <div className={Styles.Header}>
        <div>
          <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          <div className={Styles.HeaderElements}>
            <h1 className={Styles.RoomName}>
              {propertyDeal.name} {propertyDeal.roomNumber}
            </h1>
          </div>
        </div>
      </div>
      <div className={Styles.Container}>{children}</div>
    </div>
  );
};
