import React, { ReactNode } from 'react';
import { RiCommunityLine } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { Deal } from '~/models/Deal';
import { isOriginVendor } from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useMessages } from '~/hooks/vendors/deals/useMessages';
import { getUnreadCount } from '~/models/Message';
import { canMessage } from '~/models/VendorSession';

type Props = {
  deal: Deal;
  handleToggleChat: () => void;
};

const isEmpty = (value: string | unknown): boolean =>
  value == null || value === '';

const renderNotYetInput = (): ReactNode => (
  <span className={Styles.NotYet}>未入力</span>
);

/**
 * 管理会社情報Card
 * @for vendor
 */
export const DealCompanyCard: React.FC<Props> = ({
  deal,
  handleToggleChat
}: Props) => {
  const { data: session } = useSessions().index();
  const { data: messages } = useMessages().index(deal.id);
  const unreadMessageCount = React.useMemo((): number => {
    if (messages === null || session === null) {
      return 0;
    }
    return getUnreadCount(
      messages,
      isOriginVendor(deal, session.vendorCompany.id)
        ? 'vendor_company'
        : 'partner_vendor_company'
    );
  }, [deal, session, messages]);
  const showCompany = React.useMemo(() => {
    if (session === null) {
      return false;
    }
    return canMessage(session, deal);
  }, [deal, session]);
  if (!showCompany) {
    return null;
  }
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCommunityLine className={Styles.TitleIcon} />
        管理会社情報
      </h3>
      <div className={Styles.Content}>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>会社名</dt>
          <dd className={Styles.ListContentValue}>
            {deal.companySetting.name}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>管理会社担当者</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.staff?.name) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>
                <p>{deal.staff.name}</p>
                {!isEmpty(deal.staff.email) && (
                  <p>
                    <a
                      className={Styles.Link}
                      href={`mailto:${deal.staff.email}`}
                    >
                      {deal.staff.email}
                    </a>
                  </p>
                )}
              </>
            )}
          </dd>
        </dl>
      </div>
      <div className={Styles.ButtonArea}>
        <button
          className={Styles.Button}
          onClick={handleToggleChat}
          type="button"
        >
          管理会社とチャット
          {unreadMessageCount > 0 && (
            <span className={Styles.UnreadCountBadge}>
              {unreadMessageCount}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};
