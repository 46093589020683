import React from 'react';
import Styles from './StatusReasonCard.module.scss';
import { getDealStatusBackReasons } from '~/models/DealStatusReason';
import { Deal } from '~/models/Deal';

type Props = {
  deal: Deal;
};

export const StatusReasonCard: React.FC<Props> = ({ deal }: Props) => {
  const dealStatusReasons = getDealStatusBackReasons(deal);
  const isEmpty = dealStatusReasons.length === 0;

  return (
    <>
      {!isEmpty && (
        <div className={Styles.StatusCard}>
          <h1 className={Styles.BackReasonTitle}>ステータスが戻されました</h1>
          <h2 className={Styles.CancelReasonLabel}>理由</h2>
          {dealStatusReasons.map((s) => (
            <div key={s.id}>
              <p className={Styles.BackReason}>{s.reason}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
