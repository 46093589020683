import React from 'react';
import { useRouter } from 'next/router';
import Styles from './statuses.module.scss';
import { StatusHandleClientButtons } from '~/components/common/statuses/StatusHandleClientButtons';
import { SEND_ESTIMATE } from '~/models/Status';
import { useDeals } from '~/hooks/useDeals';
import { matchDealType } from '~/models/Deal';

export const InEstimation: React.FC = () => {
  const router = useRouter();
  const { id: dealId = null } = router.query;
  const { data: deal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  if (deal === null) {
    return null;
  }
  const inspectionName = matchDealType(deal, 'restoration')
    ? '退去の立会'
    : '立会';
  return (
    <div>
      <h3 className={Styles.Title}>{inspectionName}を依頼しました</h3>
      <p>{inspectionName}が完了して、見積りが提出されるまでお待ち下さい</p>
      <StatusHandleClientButtons forwardDisabled nextStatusId={SEND_ESTIMATE} />
    </div>
  );
};
