import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2
} from '@itandi/itandi-bb-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { RiAddBoxLine, RiEdit2Line } from 'react-icons/ri';
import {
  buildInitialDealTodoEdit,
  DealTodo,
  DealTodoEdit,
  validationSchema
} from '~/models/DealTodo';
import { DealClientTodoModalForm } from '~/components/common/deals/show/main/todos/DealClientTodoModalForm';
import { create, update } from '~/utils/api/deals/DealTodo';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  todo?: DealTodo | null;
  isOpen: boolean;
  handleMutate: () => Promise<void>;
  handleClose: () => void;
}>;

export const DealClientTodoModal: React.FC<Props> = ({
  todo = null,
  isOpen,
  handleMutate,
  handleClose
}: Props) => {
  const id = useIdQueryHandler();
  const methods = useForm<DealTodoEdit>({
    defaultValues: todo ?? buildInitialDealTodoEdit(),
    resolver: yupResolver(validationSchema)
  });
  const handleModalSubmit = React.useCallback(() => {
    if (id === null) {
      return;
    }
    methods.handleSubmit(
      async (submitValue: DealTodoEdit) => {
        if (todo === null) {
          const response = await create(id, submitValue);
          if (isSuccess(response)) {
            await handleMutate();
            handleClose();
            toast.success('TODOを追加しました');
            return;
          }
          toast.error('エラーが発生しました');
          return;
        }
        const response = await update(id, todo.id, submitValue);
        if (isSuccess(response)) {
          await handleMutate();
          handleClose();
          toast.success('TODOを更新しました');
          return;
        }
        toast.error('エラーが発生しました');
      },
      () => {
        const errorValues = Object.values(methods.formState.errors);
        if (errorValues.length > 0) {
          const inputRef: HTMLInputElement | null =
            errorValues[0]?.ref instanceof HTMLInputElement
              ? errorValues[0].ref
              : null;
          if (inputRef !== null) {
            inputRef.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    )();
  }, [id, methods, todo, handleMutate, handleClose]);
  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen}>
      <ModalHeader
        icon={todo ? RiEdit2Line : RiAddBoxLine}
        title={todo ? 'TODOを編集' : 'TODOを追加'}
      />
      <ModalBody>
        <FormProvider {...methods}>
          <DealClientTodoModalForm />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={methods.formState.isSubmitting}
            onClick={handleModalSubmit}
            variant="primary"
          >
            保存
          </Button>
        }
      />
    </Modalv2>
  );
};
