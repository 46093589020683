import React from 'react';
import Styles from './Page.module.scss';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';

type Props = {
  message?: string;
};

export const Page: React.FC<Props> = ({ message }: Props) => (
  <VendorLayoutV2>
    <div className={Styles.ErrorContainer}>
      <h1 className={Styles.ErrorTitle}>権限がありません</h1>
      <p className={Styles.ErrorMessage}>{message || ''}</p>
    </div>
  </VendorLayoutV2>
);
