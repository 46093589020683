import React from 'react';
import { useMessageSelectedContext } from '~/components/common/chat/ChatPagesContainer';
import { useMessages } from '~/hooks/deals/useMessages';
import { ChatMessageArea } from '~/components/common/chat/messages/ChatMessageArea';
import { useDeals } from '~/hooks/useDeals';
import { useMessageSummaries } from '~/hooks/useMessageSummaries';
import { ChatPostArea } from '~/components/common/chat/post/ChatPostArea';

/**
 * チャット画面のメッセージ一覧
 *
 * @for client
 * @constructor
 */
export const ChatMessagePaneBodyClient: React.FC = () => {
  const { selectedSummary } = useMessageSelectedContext();
  const { mutate: mutateMessageSummaries } = useMessageSummaries().index();
  const { data: messages, mutate: mutateMessages } = useMessages().index(
    selectedSummary?.deal.id ?? null
  );
  const { data: deal, mutate: mutateDeal } = useDeals().show(
    selectedSummary?.deal.id ?? null
  );

  // const debounce = useDebounce(2000);
  React.useEffect((): void => {
    if (selectedSummary == null) {
      return;
    }
    // debounce(() => {
    mutateDeal && mutateDeal().then();
    mutateMessages && mutateMessages().then();
    // });
  }, [selectedSummary, mutateMessages, mutateDeal]);
  const handleMutate = React.useCallback(async (): Promise<void> => {
    mutateDeal && mutateDeal().then();
    mutateMessages && mutateMessages().then();
    mutateMessageSummaries && mutateMessageSummaries().then();
  }, [mutateMessages, mutateDeal, mutateMessageSummaries]);
  if (messages === null || deal === null) {
    return null;
  }
  return (
    <>
      <ChatMessageArea
        deal={deal}
        handleMutate={handleMutate}
        isOpen
        messages={messages}
        speaker="maintenance_company"
      />
      <ChatPostArea deal={deal} handleMutate={handleMutate} />
    </>
  );
};
