import { object, array, string, number } from 'yup';

export const MAX_NUMBER_OF_ACTIVE_SURVEYS = 5;
export const MAX_NUMBER_OF_OPTIONS = 5;

export type DealSurvey = {
  id: number;
  title: string;
  description: string | null;
  enabledNote: boolean;
  surveyOptions: SurveyOption[];
};

export type DealSurveyOption = {
  id: number;
  text: string;
  value: number;
};

export type SurveyAnswers = {
  answers: SurveyAnswer[];
};

export type SurveyAnswer = {
  surveyOptionId: number;
  note: string | null;
};

export type Survey = {
  id: number;
  title: string;
  description: string | null;
  enabledNote: boolean;
  surveyOptions: SurveyOption[];
  enabled: boolean;
  activatedAt: string | null;
  seq: number;
};

export type SurveyOption = {
  id: number;
  text: string;
  value: number;
  seq: number;
};

export type SurveySetting = {
  id: number | null;
  title: string;
  description: string | null;
  enabledNote: boolean;
  surveyOptions: SurveyOptionSetting[];
  enabled: boolean;
  activatedAt: string | null;
  seq: number | null;
};

export type SurveyOptionSetting = {
  id: number | null;
  text: string;
  value: number;
  seq: number | null;
};

export type DealSurveyAnswer = {
  id: number;
  title: string;
  text: string;
  note: string | null;
};

export const validationSurveyAnswersSchema = object().shape({
  answers: array().of(
    object().shape({
      surveyOptionId: number().nullable().required('選択してください'),
      note: string().nullable().max(255, '255文字以内で設定してください')
    })
  )
});

export const validationSurveySchema = object().shape({
  title: string()
    .nullable()
    .required('入力してください')
    .max(255, '255文字以内で設定してください'),
  description: string().nullable().max(255, '255文字以内で設定してください'),
  surveyOptions: array()
    .of(
      object().shape({
        text: string()
          .nullable()
          .required('入力してください')
          .max(255, '255文字以内で設定してください'),
        value: number()
          .max(5, '5以下で入力してください')
          .min(-5, '-5以上で入力してください')
      })
    )
    .test({
      message: '選択肢は最低2つ作成してください',
      test: (surveyOptions) =>
        surveyOptions !== undefined && surveyOptions.length >= 2
    })
});

export const buildInitialSurveySetting: SurveySetting = {
  id: null,
  title: '',
  description: '',
  enabledNote: false,
  surveyOptions: [
    { id: null, text: '', value: 0, seq: null },
    { id: null, text: '', value: 0, seq: null }
  ],
  enabled: true,
  activatedAt: null,
  seq: null
};
