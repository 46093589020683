import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import clsx from 'clsx';
import Link from 'next/link';
import { MessageSummary } from '~/models/MessageSummary';
import Styles from './ChatDealListRow.module.scss';
import { useMessageSelectedContext } from '~/components/common/chat/ChatPagesContainer';
import { getMessageDate } from '~/utils/DateUtil';
import { PATH as CLIENT_DEAL_PATH } from '~/pages/deals/[id]';
import { PATH as VENDOR_DEAL_PATH } from '~/pages/vendors/deals/[id]';

type Props = {
  messageSummary: MessageSummary;
  isVendor?: boolean;
  unread: boolean;
};

export const ChatDealListRow: React.FC<Props> = ({
  messageSummary,
  isVendor = false,
  unread
}: Props) => {
  const { selectedSummary, setSelectedSummary } = useMessageSelectedContext();
  const handleClickRow = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      setSelectedSummary && setSelectedSummary(messageSummary);
    },
    [messageSummary, setSelectedSummary]
  );
  const isActive = React.useMemo(
    (): boolean => selectedSummary?.id === messageSummary.id,
    [selectedSummary, messageSummary]
  );

  const InnerLinkComponent = (
    <>
      <div className={Styles.DealContents}>
        <div className={Styles.RoomDisplayName}>
          <span className={Styles.RoomName}> {messageSummary.deal.name} </span>
          <span className={Styles.RoomNumber}>
            {messageSummary.deal.roomNumber}
          </span>
        </div>
        {isVendor ? (
          <div className={Styles.PartnerName}>
            {messageSummary.deal.companySetting.name}
          </div>
        ) : (
          <div className={Styles.PartnerName}>
            {messageSummary.deal.originVendorCompany?.name}
          </div>
        )}
      </div>
      <div className={Styles.LastMessage}>
        {messageSummary.lastSendAt !== null && (
          <>
            {getMessageDate(messageSummary.lastSendAt)}・{' '}
            {messageSummary.lastMessage}
          </>
        )}
      </div>
      <div className={Styles.Tags}>
        {/* {!isVendor && isUnreadForClient(messageSummary) && ( */}
        {/*  <span className={Styles.UnreadTag}>未読</span> */}
        {/* )} */}
        {/* {isVendor && messageSummary.vendorUnread && ( */}
        {/*  <span className={Styles.UnreadTag}>未読</span> */}
        {/* )} */}
        {unread && <span className={Styles.UnreadTag}>未読</span>}
      </div>
      <div className={Styles.ArrowIconWrapper}>
        <RiArrowRightSLine className={Styles.ArrowIcon} />
      </div>
    </>
  );

  return (
    <div className={Styles.ChatDealListRow}>
      <a
        className={clsx(
          Styles.ChatDealListRowLink,
          Styles.PC,
          isActive ? Styles.Active : ''
        )}
        href="#"
        onClick={handleClickRow}
      >
        {InnerLinkComponent}
      </a>
      <Link
        className={clsx(Styles.ChatDealListRowLink, Styles.SP)}
        href={
          isVendor
            ? VENDOR_DEAL_PATH(messageSummary.deal.id)
            : CLIENT_DEAL_PATH(messageSummary.deal.id)
        }
      >
        {InnerLinkComponent}
      </Link>
    </div>
  );
};
