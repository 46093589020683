import { NskResponse } from '~/models/Api';
import { postRequest, putRequest } from '~/utils/api/api_handler';
import { DealUploadFileUpdateParam } from '~/models/DealUploadFile';

const paths = {
  create: '/vendors/deals/:deal_id/deal_other_files',
  update: '/vendors/deals/:deal_id/deal_other_files/:id'
};

export const create = async (
  file: File,
  dealId: number
): Promise<NskResponse<void>> => {
  const params = new FormData();
  params.append('file', file);
  return postRequest(paths.create.replace(':deal_id', String(dealId)), params);
};

export const update = async (
  params: DealUploadFileUpdateParam,
  dealId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':deal_id', String(dealId))
      .replace(':id', String(params.id)),
    params
  );
