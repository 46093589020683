import React from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { update as updateStatus } from '~/utils/api/DealStatus';
import { isSuccess } from '~/utils/api/api_handler';
import useLoader from '~/hooks/useLoader';
import { StatusHandleReasonButtons } from './StatusHandleReasonButtons';
import { useDeals } from '~/hooks/useDeals';
import { SurveyAnswer } from '~/models/Survey';
import { PARTIALLY_FINISHED } from '~/models/Status';

type Props = {
  nextStatusId: number;
  prevStatusId?: number | null;
  forwardDisabled?: boolean;
  alertText?: string | null;
  allowPartiallyFinish?: boolean;
};

export const StatusHandleClientButtons: React.FC<Props> = ({
  nextStatusId,
  prevStatusId = null,
  forwardDisabled = false,
  alertText = null,
  allowPartiallyFinish = false
}: Props) => {
  const router = useRouter();
  const { id: dealId = null } = router.query;
  const { data: deal, mutate: mutateDeal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  const { Loader, showLoader, hideLoader } = useLoader();
  if (deal === null) {
    return null;
  }
  const handleUpdateStatus = async (
    statusId: number,
    reason: string | null,
    surveyAnswers: SurveyAnswer[] | null
  ): Promise<void> => {
    showLoader();
    const result = await updateStatus(deal.id, statusId, reason, surveyAnswers);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      hideLoader();
      toast.success('更新しました');
      return;
    }
    hideLoader();
    toast.error(result.message ?? 'エラーが発生しました。');
  };

  const handleForwardStatus = async (): Promise<void> => {
    await handleUpdateStatus(nextStatusId, null, null);
  };
  const handleBackwardStatus = async (reason: string): Promise<void> => {
    if (prevStatusId !== null) {
      await handleUpdateStatus(prevStatusId, reason, null);
    }
  };
  const handleForwardStatusWithSurvey = async (
    surveyAnswers: SurveyAnswer[] | null
  ): Promise<void> => {
    await handleUpdateStatus(nextStatusId, null, surveyAnswers);
  };
  const handlePartiallyFinish = async (): Promise<void> =>
    handleUpdateStatus(PARTIALLY_FINISHED, null, null);

  return (
    <>
      <Loader />
      <StatusHandleReasonButtons
        alertText={alertText}
        canBackward={prevStatusId !== null}
        deal={deal}
        forwardDisabled={forwardDisabled}
        handleBackwardStatus={handleBackwardStatus}
        handleForwardStatus={handleForwardStatus}
        handleForwardStatusWithSurvey={handleForwardStatusWithSurvey}
        handlePartiallyFinish={
          allowPartiallyFinish ? handlePartiallyFinish : null
        }
        nextStatusId={nextStatusId}
      />
    </>
  );
};
