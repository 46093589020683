import { NskResponse } from '~/models/Api';
import { deleteRequest, postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/deals/:deal_id/deal_cancels',
  destroy: '/deals/:deal_id/deal_cancels/:id'
};

export const create = async (
  dealId: number,
  reason: string
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    dealCancel: { reason }
  });

export const destroy = async (
  dealId: number,
  dealCancelId: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':deal_id', String(dealId))
      .replace(':id', String(dealCancelId))
  );
