import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/deals/[id]/completion_reports/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = (id: number): string =>
  `/vendors/deals/${id}/completion_reports`;

const CompletionReports: NextPage = () => (
  <>
    <NskHead title="完了報告画像の登録" />
    <Component />
  </>
);

export default CompletionReports;
