import React from 'react';
import ReactModal from 'react-modal';
import { RiCloseCircleLine } from 'react-icons/ri';
import Styles from './ImagePreviewModal.module.scss';
import { DealReportImage } from '~/models/DealReportImage';

type Props = {
  isOpen: boolean;
  reportImage: DealReportImage;
  handleClose: () => void;
};

const customStyles = {
  overlay: {
    zIndex: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    inset: 0,
    width: '100%',
    padding: '0',
    background: 'none',
    border: 'none',
    overflow: 'auto'
  }
};

ReactModal.setAppElement('body');

export const ImagePreviewModal: React.FC<Props> = ({
  isOpen,
  reportImage,
  handleClose
}: Props) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={handleClose}
    shouldCloseOnOverlayClick // TODO: customStylesのせいで効かない
    style={customStyles}
  >
    <div className={Styles.ModalDialog}>
      <img alt="写真" className={Styles.Image} src={reportImage.imageUrl} />
      <div className={Styles.ModalClose}>
        <button
          className={Styles.ModalCloseButton}
          onClick={handleClose}
          type="button"
        >
          <RiCloseCircleLine className={Styles.CloseIcon} />
        </button>
      </div>
    </div>
  </ReactModal>
);
