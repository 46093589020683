import React from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '@itandi/itandi-bb-ui';
import Styles from './Page.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealEditor } from '~/components/common/deals/new/DealEditor';
import { PATH } from '~/pages/deals';
import useItandiAccount from '~/hooks/useItandiAccount';

export const Page: React.FC = () => {
  const { data: account } = useItandiAccount().currentAccount();
  return (
    <DefaultV2>
      <div className={Styles.PageTop}>
        <Breadcrumbs>
          <BreadcrumbsItem href={PATH}>案件一覧</BreadcrumbsItem>
          <BreadcrumbsItem isText>案件追加</BreadcrumbsItem>
        </Breadcrumbs>
        <h1 className={Styles.Title}>案件追加</h1>
      </div>
      {account != null && (
        <DealEditor useProperty={account.company?.useBkkLinkage ?? false} />
      )}
    </DefaultV2>
  );
};
