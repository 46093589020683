import { object, string } from 'yup';
import { VendorCompany } from '~/models/VendorCompany';

export type VendorUser = {
  id: number;
  seq: number;
  name: string;
  tel: string;
  email: string;
  hasPassword: boolean;
};
export type VendorUserWithCompany = VendorUser & {
  vendorCompany: VendorCompany;
};

export type VendorUserSelfUpdateParam = {
  name: string;
  tel: string;
  email: string;
};

export type VendorUserSettingParam = VendorUserSelfUpdateParam & {
  // password: string;
};

export type VendorUserUpdatePasswordParam = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const vendorUserValidationScheme = object().shape({
  email: string().email('メールアドレスの形式を確認してください'),
  name: string().required('アカウント名を入力してください'),
  tel: string().required('電話番号を入力してください')
});

export const vendorUserPasswordValidationSchema = object().shape({
  newPassword: string().max(255, '255文字以内で設定してください'),
  confirmPassword: string()
    .test(
      'passwordConfirm',
      'パスワードが一致しません',
      function testConfirmPassword(value: string | undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (this.parent.newPassword === '' || this.parent.newPassword == null) {
          return true;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return value === this.parent.newPassword;
      }
    )
    .max(255, '255文字以内で設定してください')
});

export const convertToComboBoxOption = (
  vendorUser: VendorUser
): { label: string; value: string } => ({
  label: vendorUser.name,
  value: String(vendorUser.id)
});
