import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { RiSaveLine } from 'react-icons/ri';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import Styles from './DealEditorFooter.module.scss';
import { DealParam } from '~/models/DealForm';
import { create } from '~/utils/api/Deal';
import { isSuccess } from '~/utils/api/api_handler';
import { PATH } from '~/pages/deals/[id]';
import useLoader from '~/hooks/useLoader';
import { onInvalidScroll } from '~/utils/FormUtil';

export const DealEditorFooter: React.FC = () => {
  const router = useRouter();
  const { getValues, handleSubmit, formState } = useFormContext<DealParam>();
  const { Loader, showLoader, hideLoader } = useLoader();
  const onClickSubmit = React.useCallback(() => {
    handleSubmit(
      async () => {
        showLoader();
        const response = await create(getValues());
        if (isSuccess(response) && response.data?.id != null) {
          await router.push(PATH(response.data.id));
          hideLoader();
          toast.success('作成しました');
          return;
        }
        hideLoader();
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(formState)
    )();
  }, [handleSubmit, showLoader, getValues, hideLoader, router, formState]);
  return (
    <div className={Styles.DealEditorFooter}>
      <Loader />
      <Button
        className={Styles.SaveButton}
        onClick={onClickSubmit}
        startIcon={<RiSaveLine className={Styles.Icon} />}
        variant="primary"
      >
        保存
      </Button>
    </div>
  );
};
