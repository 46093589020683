import React from 'react';
import { toast } from 'react-toastify';
import { ImportType } from '~/models/ImportLog';
import { UploadPanel } from '~/components/common/upload/UploadPanel';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import useLoader from '~/hooks/useLoader';
import { create } from '~/utils/api/ImportLog';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  importType: ImportType;
  handleMutate: () => Promise<void>;
}>;

export const ImportFileUploadPanel: React.FC<Props> = ({
  importType,
  handleMutate
}: Props) => {
  const { Loader, showLoader, hideLoader } = useLoader();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const [file, setFile] = React.useState<File | null>(null);

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);
      handleOpen();
    },
    [handleOpen]
  );

  const handleAccept = React.useCallback(async () => {
    if (file === null) {
      return;
    }
    handleClose();
    showLoader();
    const response = await create(file, importType);
    if (isSuccess(response)) {
      await handleMutate();
      hideLoader();
      toast.success('取込を完了しました。');
      return;
    }
    toast.error(response.message ?? 'エラーが発生しました。');
    hideLoader();
  }, [file, handleClose, handleMutate, hideLoader, importType, showLoader]);

  const handleCancel = React.useCallback(() => {
    setFile(null);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Loader />
      <UploadPanel accept=".csv" multiple={false} onDrop={handleDrop} />
      <ConfirmModal
        actionText="開始"
        confirmType="save"
        handleAccept={handleAccept}
        handleCancel={handleCancel}
        isOpen={isOpen}
      >
        取込を開始しますか？
      </ConfirmModal>
    </>
  );
};
