import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { DealInspectionReportImage } from '~/models/DealReportImage';

const paths = {
  index: '/vendors/deals/:deal_id/deal_inspection_report_images'
};

type TIndex = (id: number | null) => SwrReturnType<DealInspectionReportImage[]>;
type ReturnType = {
  index: TIndex;
};

export const useDealInspectionReportImages = (): ReturnType => {
  const index: TIndex = (dealId: number | null) => {
    const { data, mutate, error } = useSWR<DealInspectionReportImage[]>(
      dealId === null ? null : paths.index.replace(':deal_id', String(dealId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
