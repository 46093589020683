import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Styles from './Page.module.scss';
import {
  MarketCompanySetting,
  validationSchemaMarketCompanySetting
} from '~/models/CompanySetting';
import { MarketRegisterLayout } from '~/components/common/layouts/MarketRegisterLayout';
import { FormInputPage } from '~/components/specifics/markets/company_settings/new/FormInputPage';
import { FormConfirmPage } from '~/components/specifics/markets/company_settings/new/FormConfirmPage';
import { FormCompletePage } from '~/components/specifics/markets/company_settings/new/FormCompletePage';
import {
  IndicatorItem,
  ProgressIndicator
} from '~/components/specifics/markets/company_settings/new/progress_indicator/ProgressIndicator';
import { useCompanySettings } from '~/hooks/markets/useCompanySettings';

const indicatorItems: IndicatorItem[] = [
  { label: '登録情報の入力' },
  { label: '内容確認' },
  { label: '完了' }
];

export const Page: React.FC = () => {
  const { data: initialCompanySetting } = useCompanySettings().show();
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const methods = useForm<MarketCompanySetting>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaMarketCompanySetting)
  });
  const handleNextPage = React.useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);
  const handlePrevPage = React.useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);
  React.useEffect(() => {
    if (initialCompanySetting === null) {
      return;
    }
    if (initialCompanySetting.existSetting) {
      setPageNumber(2);
      return;
    }
    methods.setValue('companyName', initialCompanySetting.companyName);
    methods.setValue('ceoLastName', initialCompanySetting.ceoLastName);
    methods.setValue('ceoFirstName', initialCompanySetting.ceoFirstName);
    methods.setValue(
      'managedUnitNumber',
      initialCompanySetting.managedUnitNumber
    );
    methods.setValue('phoneNumber', initialCompanySetting.phoneNumber);
    methods.setValue('zipcode', initialCompanySetting.zipcode);
    methods.setValue('state', initialCompanySetting.state);
    methods.setValue('email', initialCompanySetting.email);
  }, [methods, initialCompanySetting]);
  if (initialCompanySetting === null) {
    return null;
  }
  if (initialCompanySetting.existSetting && pageNumber < 2) {
    return null;
  }
  return (
    <MarketRegisterLayout>
      <div className={Styles.Container}>
        <h1 className={Styles.Title}>内装工事くんおまかせプラン 利用開始</h1>
        <div className={Styles.Indicator}>
          <div className={Styles.IndicatorWrapper}>
            <ProgressIndicator
              currentIndex={pageNumber}
              items={indicatorItems}
            />
          </div>
        </div>
        <FormProvider {...methods}>
          {pageNumber === 0 && (
            <FormInputPage handleNextPage={handleNextPage} />
          )}
          {pageNumber === 1 && (
            <FormConfirmPage
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          )}
          {pageNumber === 2 && <FormCompletePage />}
        </FormProvider>
      </div>
    </MarketRegisterLayout>
  );
};
