import { Deal } from './Deal';

type Mover = 'company' | 'vendor_company';

export type DealStatusReason = {
  id: string;
  dealId: number;
  statusId: number;
  originStatusId: number;
  mover: Mover;
  reason: string;
  processed: boolean;
};

export const getDealStatusBackReasons = (deal: Deal): DealStatusReason[] =>
  deal?.dealStatusReasons.filter(
    (r: DealStatusReason) => r.processed === false
  );
