import React from 'react';
import { NextPage } from 'next';
import { NskHead } from '~/components/common/head/NskHead';
import { Page } from '~/containers/specifics/deals/[id]/inspection_reports/Page';

export const PATH = (id: number): string => `/deals/${id}/inspection_reports`;

const InspectionReports: NextPage = () => (
  <>
    <NskHead title="立会報告画像" />
    <Page />
  </>
);

export default InspectionReports;
