import React from 'react';
import { Input, ItandiChip } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './SubtypeModalForm.module.scss';
import { SubtypeEdit } from '~/models/Subtype';

export const SubtypeModalForm: React.FC = () => {
  const {
    formState: { errors },
    register
  } = useFormContext<SubtypeEdit>();
  return (
    <div className={Styles.Forms}>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          案件タイプ名
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <Input
            isInvalid={errors.name != null}
            placeholder="例）原状回復"
            {...register('name')}
          />
          {errors.name != null && (
            <div className={Styles.InvalidFeedback}>{errors.name.message}</div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          取り込みキー
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <Input
            isInvalid={errors.foreignKey != null}
            placeholder="例）restoration"
            {...register('foreignKey')}
          />
          {errors.foreignKey != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.foreignKey.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
