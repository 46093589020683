import { stringify } from 'querystring';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { loginApi } from '~/hooks/useItandiAccount';
import { PATH as ERROR_403_PATH } from '~/pages/403';
import { PATH as ERROR_VENDOR_403_PATH } from '~/pages/vendors//403';
import { PATH as VENDOR_LOGIN_PATH } from '~/pages/vendors/login';
import { NskSwrErrorResponse } from '~/models/Api';
import {
  savePurposeUrl,
  saveVendorPurposeUrl
} from '~/utils/AuthenticateRenderer';
import { PATH as MARKET_REGISTRY_PATH } from '~/pages/markets/company_settings/new';
import { getUserLoginUrl } from '~/utils/api/ItandiUserSessions';

const ApiClient = applyCaseMiddleware(
  Axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_HOST}/api`,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
  }),
  { ignoreHeaders: true }
);

ApiClient.interceptors.response.use(
  (response: AxiosResponse<unknown>): AxiosResponse<unknown> => response,
  (error: AxiosError<NskSwrErrorResponse>): Promise<never> => {
    // internal server error（または予期せぬエラー）
    if (!error.response || error.response?.status >= 500) {
      // window.location.href = SERVER_ERROR_PATH;
    }
    // forbidden
    if (error.response?.status === 403) {
      const forbiddenMessage = stringify({
        message: error.response?.data?.message || ''
      });
      const url = error.response?.config?.url;
      if (url != null && url.startsWith('/vendors/')) {
        window.location.href = `${ERROR_VENDOR_403_PATH}?${forbiddenMessage}`;
      } else {
        window.location.href = `${ERROR_403_PATH}?${forbiddenMessage}`;
      }
    }
    if (error.response?.status === 401) {
      const ignoreAuthPath: string[] = ['/vendors/sessions/vendor_companies'];
      const url = error.response?.config?.url;
      if (url != null && !ignoreAuthPath.includes(url)) {
        const isVendor = url.startsWith('/vendors/');
        if (isVendor && error.response?.data?.code === 'vendor_company') {
          // 施工会社未ログイン
          saveVendorPurposeUrl(window.location.pathname);
          window.location.href = VENDOR_LOGIN_PATH;
        } else if (error.response?.data?.code === 'company') {
          // 管理会社未ログイン
          savePurposeUrl(window.location.pathname);
          loginApi().then((callbackUrl: string): void =>
            window.location.replace(callbackUrl)
          );
        } else if (error.response?.data?.code === 'ia_user') {
          // IAの担当者ログインを利用していて担当者未ログイン
          savePurposeUrl(window.location.pathname);
          getUserLoginUrl().then((callbackUrl: string): void =>
            window.location.replace(callbackUrl)
          );
        } else if (error.response?.data?.code === 'company_setting') {
          // 管理会社ログイン済み設定なし
          window.location.href = MARKET_REGISTRY_PATH;
        }
      }
    }
    return Promise.reject(error);
  }
);

export default ApiClient;
