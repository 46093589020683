import React from 'react';
import { useRouter } from 'next/router';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH } from '~/pages/deals/[id]/completion_reports';
import { useDealCompletionReportImages } from '~/hooks/deals/useDealCompletionReportImages';

export const CompletionReportImageClientPanel: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  const reportsApi = useDealCompletionReportImages();
  const { data: reportImages } = reportsApi.index(Number(id));
  return (
    <div>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="詳細画面"
          detailPagePath={PATH(Number(id))}
          reportImages={reportImages}
          title="完了報告画像"
        />
      )}
    </div>
  );
};
