import { NskResponse } from '~/models/Api';
import { CompanyVendorPayloadCreateParam } from '~/models/CompanyVendorPayload';
import { postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/company_vendor_payload'
};

export const create = async (
  companyVendorPayload: CompanyVendorPayloadCreateParam,
  companyVendorId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.create, { companyVendorPayload, companyVendorId });
