import React from 'react';
import Styles from './statuses.module.scss';
import { StatusHandleClientButtons } from '~/components/common/statuses/StatusHandleClientButtons';
import {
  IN_ESTIMATION,
  OWN_IN_ESTIMATION,
  SEND_ESTIMATE
} from '~/models/Status';
import { useDeals } from '~/hooks/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const NowAccepting: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  if (deal === null) {
    return null;
  }
  return (
    <div>
      {deal.dealVendorCompanies.length === 0 ? (
        <>
          <h3 className={Styles.Title}>施工会社を選択してください</h3>
          <StatusHandleClientButtons
            forwardDisabled
            nextStatusId={SEND_ESTIMATE}
          />
        </>
      ) : (
        <>
          {deal.dealVendorCompanies[0].vendorCompany === null ||
          deal.dealVendorCompanies[0].skipEstimation ? (
            <>
              <h3 className={Styles.Title}>ステータスを進めてください</h3>
              <StatusHandleClientButtons
                nextStatusId={
                  deal.dealVendorCompanies[0].vendorCompany === null
                    ? OWN_IN_ESTIMATION
                    : SEND_ESTIMATE
                }
              />
            </>
          ) : (
            <>
              <h3 className={Styles.Title}>
                施工会社がステータスを進めるまでお待ち下さい
              </h3>
              <StatusHandleClientButtons
                forwardDisabled
                nextStatusId={IN_ESTIMATION}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
