import { object, string } from 'yup';

export type CompanyVendorPayloadCreateParam = {
  companyVendorId: number;
  email: string;
};

export type CompanyVendorPayloadUpdateParam = {
  token: string;
  email: string;
  password: string;
};

export const validationSchemaPayload = object().shape({
  email: string()
    .email('メールアドレスの形式を確認してください')
    .required('入力してください')
});
