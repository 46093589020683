import { Deal } from './Deal';

export type InspectionCandidateDate = {
  id: number;
  date: string;
  time: string;
  sort: number;
};

export type DealInspectionCandidateDateParam = {
  inspectionCandidateDate1: string | null;
  inspectionCandidateDateTime1: string | null;
  inspectionCandidateDate2: string | null;
  inspectionCandidateDateTime2: string | null;
  inspectionCandidateDate3: string | null;
  inspectionCandidateDateTime3: string | null;
};

const findCandidateDate = (
  deal: Deal,
  sort: 1 | 2 | 3
): InspectionCandidateDate | null =>
  deal.dealInspectionCandidateDates.find((d) => d.sort === sort) ?? null;

export const buildInspectionCandidateDate = (
  deal: Deal
): DealInspectionCandidateDateParam => {
  const candidateDate1 = findCandidateDate(deal, 1);
  const candidateDate2 = findCandidateDate(deal, 2);
  const candidateDate3 = findCandidateDate(deal, 3);
  return {
    inspectionCandidateDate1: candidateDate1?.date ?? null,
    inspectionCandidateDateTime1: candidateDate1?.time ?? null,
    inspectionCandidateDate2: candidateDate2?.date ?? null,
    inspectionCandidateDateTime2: candidateDate2?.time ?? null,
    inspectionCandidateDate3: candidateDate3?.date ?? null,
    inspectionCandidateDateTime3: candidateDate3?.time ?? null
  };
};

/** @deprecated */
export type InspectionCandidateDates = Array<InspectionCandidateDate>;
