import { useState, useCallback } from 'react';

type ReturnType = (defaultState?: boolean) => {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

export const useOpenHandler: ReturnType = (defaultState = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  return { isOpen, handleOpen, handleClose };
};
