import React from 'react';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as ESTIMATIONS_PATH } from '~/pages/deals/[id]/estimations';
import { PATH as ESTIMATIONS_EDIT_PATH } from '~/pages/deals/[id]/estimations/edit';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { useDealBillings } from '~/hooks/deals/useDealBillings';
import { BillingCreateNavigation } from '~/components/common/constructions/navigation/BillingCreateNavigation';
import { Deal } from '~/models/Deal';
import { OWN_IN_ESTIMATION, SEND_ESTIMATE } from '~/models/Status';
import { getOriginDealVendor } from '~/models/DealVendorCompany';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

type Props = {
  deal: Deal;
};

export const EstimationClientPanel: React.FC<Props> = ({ deal }: Props) => {
  const id = useIdQueryHandler();
  const dealEstimationsApi = useDealEstimations();
  const { data: estimationContext } = dealEstimationsApi.index(id);
  const dealBillingsApi = useDealBillings();
  const { data: ownerBillingContext, mutate: mutateOwnerBillings } =
    dealBillingsApi.index(id, 'owner');
  const { data: renteeBillingContext, mutate: mutateRenteeBillings } =
    dealBillingsApi.index(id, 'rentee');
  const mutateBillings = React.useCallback(async () => {
    mutateOwnerBillings && (await mutateOwnerBillings());
    mutateRenteeBillings && (await mutateRenteeBillings());
  }, [mutateOwnerBillings, mutateRenteeBillings]);
  if (
    estimationContext === null ||
    ownerBillingContext === null ||
    renteeBillingContext === null ||
    id === null
  ) {
    return null;
  }
  const isReadonly =
    deal.status !== OWN_IN_ESTIMATION &&
    getOriginDealVendor(deal)?.skipEstimation !== true;
  return (
    <div>
      {deal.status >= SEND_ESTIMATE && (
        <>
          <ConstructionNavigation
            dealConstructionContext={estimationContext}
            detailPagePath={
              isReadonly ? ESTIMATIONS_PATH(id) : ESTIMATIONS_EDIT_PATH(id)
            }
            title="工事見積書"
          />
          <BillingCreateNavigation
            dealId={id}
            estimationContext={estimationContext}
            mutateBillings={mutateBillings}
            ownerBillingContext={ownerBillingContext}
            renteeBillingContext={renteeBillingContext}
            title="工事請求書"
          />
        </>
      )}
    </div>
  );
};
