import React, { ReactNode } from 'react';
import { useMessageSummaries } from '~/hooks/vendors/useMessageSummaries';
import { isUnreadForVendor, MessageSummary } from '~/models/MessageSummary';
import { ChatDealListRow } from '~/components/common/chat/deal_list/ChatDealListRow';
import { useSessions } from '~/hooks/vendors/useSessions';

/**
 * チャットしている案件一覧
 *
 * @for vendor
 * @constructor
 */
export const ChatDealListRowsVendor: React.FC = () => {
  const { data: session } = useSessions().index();
  const { data: messageSummaries } = useMessageSummaries().index();
  const getUnread = React.useCallback(
    (messageSummary: MessageSummary): boolean => {
      if (session === null || session.vendorCompany === null) {
        return false;
      }
      return isUnreadForVendor(messageSummary, session.vendorCompany.id);
    },
    [session]
  );
  const renderMessageSummary = React.useCallback(
    (messageSummary: MessageSummary): ReactNode => (
      <ChatDealListRow
        key={messageSummary.id}
        isVendor
        messageSummary={messageSummary}
        unread={getUnread(messageSummary)}
      />
    ),
    [getUnread]
  );
  if (messageSummaries === null) {
    return null;
  }
  return <div>{messageSummaries.map(renderMessageSummary)}</div>;
};
