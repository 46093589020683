import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCheckBox, Box } from '@itandi/itandi-bb-ui';
import { DealSearchCondition, DealType } from '~/models/Deal';

type Props = {
  labelText: string;
  dealType: DealType;
  dealSubtypeId?: number;
  dealSearchCondition: DealSearchCondition;
};

export const DealTypeCheckBox: React.FC<Props> = ({
  dealType,
  dealSubtypeId,
  labelText,
  dealSearchCondition
}: Props) => {
  const { setValue, getValues } = useFormContext<DealSearchCondition>();
  const {
    dealTypes: dealTypesFromParam,
    dealSubtypeIds: dealSubtypeIdsFromParam
  } = dealSearchCondition;
  const defaultCheck = (): boolean => {
    if (dealTypesFromParam) {
      if (['restoration', 'repair'].includes(dealType)) {
        return dealTypesFromParam.includes(dealType);
      }
      return (
        dealTypesFromParam.includes(dealType) &&
        !!dealSubtypeId &&
        (dealSubtypeIdsFromParam?.includes(dealSubtypeId) || false)
      );
    }
    return false;
  };
  const [checked, setChecked] = useState<boolean>(defaultCheck);
  const onChangeDealType = React.useCallback((): void => {
    const dealTypes = getValues('dealTypes') || [];
    const dealSubtypeIds = getValues('dealSubtypeIds') || [];
    if (!checked) {
      // 追加
      if (!dealTypes.includes(dealType)) {
        dealTypes.push(dealType);
      }
      if (dealSubtypeId && !dealSubtypeIds.includes(dealSubtypeId)) {
        dealSubtypeIds.push(dealSubtypeId);
      }
    } else {
      // 削除
      if (dealSubtypeId && dealSubtypeIds.includes(dealSubtypeId)) {
        dealSubtypeIds.splice(dealSubtypeIds.indexOf(dealSubtypeId), 1);
      }
      if (dealTypes.includes(dealType)) {
        if (dealType === 'other') {
          if (dealSubtypeIds.length === 0) {
            dealTypes.splice(dealTypes.indexOf(dealType), 1);
          }
        } else {
          dealTypes.splice(dealTypes.indexOf(dealType), 1);
        }
      }
    }
    setValue('dealTypes', dealTypes);
    setValue('dealSubtypeIds', dealSubtypeIds);
    setChecked(!checked);
  }, [checked, dealSubtypeId, dealType, getValues, setValue]);
  return (
    <Box margin={4} minWidth={0}>
      <InputCheckBox
        checked={checked}
        labelText={labelText}
        onChange={onChangeDealType}
      />
    </Box>
  );
};
