import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import Styles from './StaffList.module.scss';
import { Staff } from '~/models/Staff';
import { PATH as STAFF_PROFILE_PATH } from '~/pages/settings/staffs/[staffId]/profiles';

type Props = {
  staff: Staff;
};

export const StaffListRow: React.FC<Props> = ({ staff }: Props) => (
  <tr className={clsx(Styles.ListRow)}>
    <td className={clsx(Styles.ListCol, Styles.SpCol)}>
      <div className={Styles.Name}>{staff.name}</div>
    </td>
    <td className={clsx(Styles.ListCol, Styles.PcCol)}>{staff.email}</td>
    <td className={clsx(Styles.ListCol, Styles.PcCol)}>{staff.phoneNumber}</td>
    <td className={clsx(Styles.ListCol, Styles.Edit)}>
      <Link className={Styles.EditLink} href={STAFF_PROFILE_PATH(staff.id)}>
        編集
      </Link>
    </td>
  </tr>
);
