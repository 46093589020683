import React, { ComponentProps } from 'react';
import { SingleComboBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';
import { VendorUser, convertToComboBoxOption } from '~/models/VendorUser';

type Props = {
  dealSearchCondition: DealSearchCondition;
};

export const VendorUserCombobox: React.FC<Props> = ({
  dealSearchCondition
}: Props) => {
  const { data: vendorUsers } = useVendorUsers().index();
  const { setValue } = useFormContext<DealSearchCondition>();
  const selectedVendorUser =
    vendorUsers?.find(({ id }) => id === dealSearchCondition.userId) || null;
  const [selectedVendorUserOption, setSelectedVendorUserOption] =
    React.useState<ComponentProps<typeof SingleComboBox>['value'] | null>(
      selectedVendorUser ? convertToComboBoxOption(selectedVendorUser) : null
    );
  const onChangeVendorUser = React.useCallback(
    async (
      option: ComponentProps<typeof SingleComboBox>['value'] | null
    ): Promise<void> => {
      setSelectedVendorUserOption(option);
      setValue('userId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (vendorUsers === null) {
    return null;
  }
  return (
    <>
      <SingleComboBox
        defaultValue={selectedVendorUserOption}
        onChange={onChangeVendorUser}
        options={vendorUsers.map((vendorUser: VendorUser) =>
          convertToComboBoxOption(vendorUser)
        )}
        placeholder="担当者"
        value={selectedVendorUserOption}
      />
    </>
  );
};
