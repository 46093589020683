import React from 'react';
import Styles from './FormLabel.module.scss';

type Props = {
  children: string;
};

export const FormLabel: React.FC<Props> = ({ children }: Props) => (
  <div className={Styles.FormLabel}>{children}</div>
);
