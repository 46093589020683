import React from 'react';
import { useRouter } from 'next/router';
import Styles from './Page.module.scss';
import { VendorCompany } from '~/models/VendorCompany';
import { PATH as VENDOR_DEALS_PATH } from '~/pages/vendors/deals';
import { proxyLogin } from '~/utils/api/admin/VendorCompany';

type Props = {
  vendorCompany: VendorCompany;
};

export const VendorCompanyRow: React.FC<Props> = ({ vendorCompany }: Props) => {
  const router = useRouter();
  const onClickProxyLogin = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    await proxyLogin(vendorCompany.id);
    router.push(VENDOR_DEALS_PATH).then();
  };
  return (
    <tr className={Styles.CompanyRow}>
      <td>{vendorCompany.id}</td>
      <td>{vendorCompany.name}</td>
      <td>{vendorCompany.loginEmail}</td>
      <td>
        <a
          className={Styles.ProxyLoginLink}
          href="#"
          onClick={onClickProxyLogin}
        >
          代理ログイン
        </a>
      </td>
    </tr>
  );
};
