import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';
import { SurveyAnswer } from '~/models/Survey';

const paths = {
  update: '/deal_statuses/:deal_id'
};

export const update = async (
  dealId: number,
  statusId: number,
  reason: string | null,
  surveyAnswers: SurveyAnswer[] | null
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':deal_id', String(dealId)), {
    statusId,
    reason,
    surveyAnswers
  });
