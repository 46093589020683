import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import {
  DealReportBulkUpdateParams,
  DealReportImage
} from '~/models/DealReportImage';

const paths = {
  create: '/deals/:deal_id/deal_inspection_report_images',
  update: '/deals/:deal_id/deal_inspection_report_images/:id',
  bulkUpdate: '/deals/:deal_id/deal_inspection_report_images/bulk_update',
  destroy: '/deals/:deal_id/deal_inspection_report_images/:id',
  bulkDelete: '/deals/:deal_id/deal_inspection_report_images/bulk_delete',
  updateSeq: '/deals/:deal_id/deal_inspection_report_images/update_seq',
  get: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:deal_id/deal_inspection_report_images/download_zip`,
  show: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:deal_id/deal_inspection_report_images/:id`
};

export const create = async (
  files: File[],
  dealId: number
): Promise<NskResponse<void>> => {
  const formData = new FormData();
  files.forEach((file: File): void => {
    formData.append('images[]', file);
  });
  return postRequest(
    paths.create.replace(':deal_id', String(dealId)),
    formData
  );
};

export const update = async (
  reportImage: DealReportImage
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':deal_id', String(reportImage.dealId))
      .replace(':id', String(reportImage.id)),
    { deal_inspection_report_image: reportImage }
  );

export const bulkUpdate = async (
  dealId: number,
  imageIds: ReadonlyArray<number>,
  params: DealReportBulkUpdateParams
): Promise<NskResponse<void>> =>
  putRequest(paths.bulkUpdate.replace(':deal_id', String(dealId)), {
    ...params,
    ...{ ids: imageIds }
  });

export const getDownloadZipPath = (
  dealId: number,
  imageIds: ReadonlyArray<number> = []
): string => {
  const path = paths.get.replace(':deal_id', String(dealId));
  if (imageIds.length === 0) {
    return path;
  }
  const urlParamsString = new URLSearchParams(
    imageIds.map((imageId) => ['ids[]', imageId.toString()])
  ).toString();
  return `${path}?${urlParamsString}`;
};

export const updateSeq = async (
  reportImages: DealReportImage[],
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeq.replace(':deal_id', String(dealId)), {
    deal_inspection_report_images: reportImages
  });

export const destroy = async (
  reportImage: DealReportImage
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':deal_id', String(reportImage.dealId))
      .replace(':id', String(reportImage.id))
  );

export const bulkDelete = async (
  dealId: number,
  imageIds: ReadonlyArray<number>
): Promise<NskResponse<void>> => {
  const urlParamsString = new URLSearchParams(
    imageIds.map((imageId) => ['ids[]', imageId.toString()])
  ).toString();
  return deleteRequest(
    `${paths.bulkDelete.replace(':deal_id', String(dealId))}?${urlParamsString}`
  );
};

export const getDownloadPath = (reportImage: DealReportImage): string =>
  paths.show
    .replace(':deal_id', String(reportImage.dealId))
    .replace(':id', String(reportImage.id));
