import React from 'react';
import { NextPage } from 'next';
import { NskHead } from '~/components/common/head/NskHead';
import { Page } from '~/containers/specifics/deals/[id]/completion_reports/Page';

export const PATH = (id: number): string => `/deals/${id}/completion_reports`;

const CompletionReports: NextPage = () => (
  <>
    <NskHead title="完了報告画像" />
    <Page />
  </>
);

export default CompletionReports;
