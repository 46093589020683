import React, { ReactNode } from 'react';
import Styles from './ReportImagesAreaBlockRemarks.module.scss';
import { DealReportImage } from '~/models/DealReportImage';
import { NskResponse } from '~/models/Api';
import { HideInputForm } from '~/components/common/parts/input/HideInputForm';

type Props = {
  reportImage: DealReportImage;
  // handleMutateReportImages: () => Promise<void>;
  handleUpdate:
    | ((submitValue: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  handleDestroy:
    | ((reportImage: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  companyType: 'management' | 'own_management' | 'vendor';
};

const renderView = (content: string | null | undefined): ReactNode => (
  <div className={Styles.ContentView}>
    {content == null || content === '' ? (
      <span className={Styles.YetInput}>未入力</span>
    ) : (
      <span>{content}</span>
    )}
  </div>
);

export const ReportImagesAreaBlockRemarks: React.FC<Props> = ({
  reportImage,
  // handleMutateReportImages,
  handleUpdate,
  handleDestroy: _handleDestroy,
  companyType
}: Props) => {
  const isVendor = companyType === 'vendor';
  const isManagement = companyType === 'management';
  const [viewReportImage, setViewReportImage] =
    React.useState<DealReportImage>(reportImage);
  React.useEffect(() => {
    setViewReportImage(reportImage);
  }, [reportImage]);
  const submitPoint = async (submitValue: string): Promise<void> => {
    const newReportImage = { ...viewReportImage, point: submitValue };
    setViewReportImage(newReportImage);
    handleUpdate && (await handleUpdate(newReportImage));
    // await handleMutateReportImages() 画面がガタついてしまう
  };
  const submitNote = async (submitValue: string): Promise<void> => {
    const newReportImage = { ...viewReportImage, note: submitValue };
    setViewReportImage(newReportImage);
    handleUpdate && (await handleUpdate(newReportImage));
    // await handleMutateReportImages() 画面がガタついてしまう
  };
  const isOutputPdfImage = viewReportImage.outputPdfImage;
  const isOutputPdfNote = viewReportImage.outputPdfNote;
  return (
    <div className={Styles.Remarks}>
      {reportImage.construction === 'targeted' && (
        <div className={Styles.RemarkRow}>
          {reportImage.type === 'DealInspectionReportImage' ? (
            <div className={Styles.ConstructionState}>施工推奨箇所</div>
          ) : (
            <div className={Styles.ConstructionState}>施工対象箇所</div>
          )}
        </div>
      )}
      <div className={Styles.RemarkRow}>
        <div className={Styles.Label}>撮影場所</div>
        <div className={Styles.Content}>
          {!isManagement ? (
            <>
              <HideInputForm
                handleSubmit={submitPoint}
                value={viewReportImage.point ?? ''}
                viewComponent={renderView(viewReportImage.point)}
              />
            </>
          ) : (
            <>{renderView(viewReportImage.point)}</>
          )}
        </div>
      </div>
      <div className={Styles.RemarkRow}>
        <div className={Styles.Label}>備考</div>
        <div className={Styles.Content}>
          {!isManagement ? (
            <>
              <HideInputForm
                as="textarea"
                handleSubmit={submitNote}
                value={viewReportImage.note ?? ''}
                viewComponent={renderView(viewReportImage.note)}
              />
            </>
          ) : (
            <>{renderView(viewReportImage.note)}</>
          )}
          {!isVendor && isOutputPdfImage && !isOutputPdfNote && (
            <p className={Styles.NotOutPutPdfNote}>
              備考は報告書に出力されません
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
