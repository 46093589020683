import useSWR from 'swr';
import { PropertyDealRoom } from '~/models/PropertyDeal';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/property_deal_rooms'
};
type TIndex = (
  name: string | null,
  address: string | null,
  amaterasPropertyId: number | null,
  limit: number | null
) => SwrReturnType<ReadonlyArray<PropertyDealRoom>>;
type ReturnType = {
  index: TIndex;
};

export const usePropertyDealRooms = (): ReturnType => {
  const index: TIndex = (
    name: string | null,
    address: string | null,
    amaterasPropertyId: number | null,
    limit: number | null
  ) => {
    const { data, mutate, isLoading, error } = useSWR<
      ReadonlyArray<PropertyDealRoom>
    >(
      (name === null || name === '') &&
        (address === null || address === '') &&
        amaterasPropertyId === null
        ? null
        : {
            url: paths.index,
            params: { name, address, amaterasPropertyId, limit }
          }
    );
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading,
      error
    };
  };
  return { index };
};
