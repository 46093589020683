import React from 'react';
import { ProductCode, ItandiProductIcon } from '@itandi/itandi-bb-ui';
import { RiExternalLinkLine } from 'react-icons/ri';
import Styles from './ProductLink.module.scss';

type Props = {
  url: string;
  productCode: ProductCode;
  title: string;
  name: string;
};

export const ProductLink: React.FC<Props> = ({
  url,
  productCode,
  title,
  name
}: Props) => (
  <a
    className={Styles.ProductLink}
    href={url}
    rel="noreferrer noopener"
    target="_blank"
  >
    <div>
      <ItandiProductIcon
        height="2.5rem"
        productCode={productCode}
        width="2.5rem"
      />
    </div>
    <div>
      <div className={Styles.ProductTitle}>
        <span className={Styles.Text}>{title}</span>
        <RiExternalLinkLine className={Styles.LinkIcon} />
      </div>
      <div className={Styles.ProductName}>{name}</div>
    </div>
  </a>
);
