import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import { Modal } from '~/components/common/parts/modal/Modal';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { CompanySetting } from '~/models/CompanySetting';
import Styles from './CompanyDetailModal.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { destroy } from '~/utils/api/admin/CompanySetting';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
  companySetting: CompanySetting;
  handleMutate: () => Promise<void>;
}>;

export const CompanyDetailModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  companySetting,
  handleMutate
}: Props) => {
  const {
    isOpen: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const handleAcceptDelete = React.useCallback(async (): Promise<void> => {
    const response = await destroy(companySetting);
    if (isSuccess(response)) {
      await handleMutate();
      handleClose();
      toast.success('削除しました');
      return;
    }
    toast.error(`エラーが発生しました: ${response.message}`);
  }, [handleMutate, companySetting, handleClose]);
  return (
    <Modal handleClose={handleClose} isOpen={isOpen} title="会社申込情報">
      <FormGroup>
        <FormLabel>プラン</FormLabel>
        {companySetting.marketPlan === 'single' && '単発発注'}
        {companySetting.marketPlan === 'multiple' && 'まとめて発注割引'}
      </FormGroup>
      <FormGroup>
        <FormLabel>会社名</FormLabel>
        {companySetting.name}
      </FormGroup>
      <FormGroup>
        <FormLabel>代表者名</FormLabel>
        {`${companySetting.ceoLastName} ${companySetting.ceoFirstName}`}
      </FormGroup>
      <FormGroup>
        <FormLabel>管理戸数</FormLabel>
        {companySetting.managedUnitNumber}
      </FormGroup>
      <div className={Styles.DeleteButtonArea}>
        <Button height="small" onClick={handleOpenConfirm} variant="danger">
          アカウントを削除
        </Button>
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleAcceptDelete}
          handleCancel={handleCloseConfirm}
          isOpen={openConfirm}
        >
          アカウントを削除してよろしいですか？
        </ConfirmModal>
      </div>
    </Modal>
  );
};
