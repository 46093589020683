import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { RiCalendarLine, RiCalendarTodoLine } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { useDeals } from '~/hooks/useDeals';
import { DealConstructionSchedule } from '~/models/DealConstructionSchedule';
import { CONSTRUCTION_SCHEDULE_LABELS } from '~/constants/DealConstructionConst';
import { formatDate } from '~/utils/DateUtil';

export const DealConstructionScheduleClientCard: React.FC = () => {
  const router = useRouter();
  const { id = null } = router.query;
  const { data: deal } = useDeals().show(id === null ? null : Number(id));
  if (deal === null || deal.dealConstructionSchedules.length === 0) {
    return null;
  }
  const renderSchedule = (schedule: DealConstructionSchedule): ReactNode => (
    <dl key={schedule.id} className={Styles.ListContent}>
      <dt className={Styles.ListContentCaption}>
        {CONSTRUCTION_SCHEDULE_LABELS[schedule.constructionScheduleType]}
      </dt>
      <dd className={Styles.CalendarContents}>
        <RiCalendarLine className={Styles.CalendarIcon} />
        {formatDate(schedule.startDate)}
        <span className={Styles.CalendarDelimiter}>-</span>
        <RiCalendarLine className={Styles.CalendarIcon} />
        {formatDate(schedule.endDate)}
      </dd>
    </dl>
  );
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCalendarTodoLine className={Styles.TitleIcon} />
        工事日程
      </h3>
      {deal.dealConstructionSchedules.map(renderSchedule)}
    </div>
  );
};
