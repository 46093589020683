import { NskResponse } from '~/models/Api';
import { DealRepairParam } from '~/models/DealRepair';
import { putRequest } from '~/utils/api/api_handler';
import { getKeys } from '~/utils/getKeys';

const paths = {
  create: '/deals/:deal_id/deal_repair',
  update: '/deals/:deal_id/deal_repair',
  show: `${process.env.NEXT_PUBLIC_API_HOST}/api/deals/:deal_id/deal_repair`
};

export const appendToFormData = (dealRepair: DealRepairParam): FormData => {
  const formData = new FormData();
  getKeys(dealRepair).forEach((key) => {
    if (key === 'modelNumberFile' && dealRepair[key]) {
      const fileKey = dealRepair[key];
      if (fileKey === null) return;
      formData.append(`dealRepair[${key}]`, fileKey);
    } else {
      const repairKey = dealRepair[key];
      if (repairKey === null) return;
      formData.append(`dealRepair[${key}]`, String(repairKey));
    }
  });
  return formData;
};

export const update = async (
  dealId: number,
  dealRepair: DealRepairParam
): Promise<NskResponse> =>
  putRequest(
    paths.update.replace(':deal_id', String(dealId)),
    appendToFormData(dealRepair)
  );

export const getModelNumberFilePath = (dealId: number): string =>
  paths.show.replace(':deal_id', String(dealId));
