import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { DealEstimationContext } from '~/models/DealEstimate';

const paths = {
  index: '/deals/:deal_id/deal_estimations'
};
type TIndex = (id: number | null) => SwrReturnType<DealEstimationContext>;
type ReturnType = {
  index: TIndex;
};

export const useDealEstimations = (): ReturnType => {
  const index: TIndex = (dealId: number | null) => {
    const { data, mutate, error } = useSWR<DealEstimationContext>(
      dealId === null ? null : paths.index.replace(':deal_id', String(dealId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
