import React from 'react';
import { toast } from 'react-toastify';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import clsx from 'clsx';
import Styles from './ConstructionList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { isSuccess } from '~/utils/api/api_handler';
import { ConstructionEditModal } from '~/components/common/constructions/list/ConstructionEditModal';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';
import { Switcher } from '~/components/common/parts/switcher/Switcher';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { OTHER_CONSTRUCTION_ITEM_ID } from '~/constants/DealConstructionConst';

type Props = {
  readonly: boolean;
  dealConstruction: DealConstruction;
  handleMutateConstructions: () => Promise<void>;
  constructionItems: ConstructionItem[];
  handleUpdate:
    | ((
        submitValue: DealConstructionEdit,
        estimationId: number
      ) => Promise<NskResponse<void>>)
    | null;
  handleDestroy:
    | ((deleteConstruction: DealConstruction) => Promise<NskResponse<void>>)
    | null;
  handleUpdateEnabled:
    | ((construction: DealConstruction) => Promise<void>)
    | null;
  handleUpdateSeq:
    | ((constructions: DealConstruction[]) => Promise<void>)
    | null;
  index: number;
  target: 'owner' | 'rentee' | null;
};

const getConstructionItemName = (
  dealConstruction: DealConstruction
): string | null => {
  if (dealConstruction === null || dealConstruction.constructionItem === null) {
    return null;
  }
  return dealConstruction.constructionItem.id === OTHER_CONSTRUCTION_ITEM_ID
    ? dealConstruction.otherName
    : dealConstruction.constructionItem.name;
};

export const ConstructionListRow: React.FC<Props> = ({
  readonly,
  dealConstruction,
  constructionItems,
  handleMutateConstructions,
  handleUpdate,
  handleDestroy,
  handleUpdateEnabled,
  handleUpdateSeq,
  index,
  target
}: Props) => {
  const [showDestroyConfirm, setShowDestroyConfirm] =
    React.useState<boolean>(false);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const onClickEdit = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowEdit(true);
  };
  const onClickDelete = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowDestroyConfirm(true);
  };
  const handleDeleteEstimation = async (): Promise<void> => {
    if (handleDestroy === null) {
      return;
    }
    const result = await handleDestroy(dealConstruction);
    if (isSuccess(result)) {
      await handleMutateConstructions();
      toast.success('見積りを削除しました');
      setShowDestroyConfirm(false);
      return;
    }
    toast.error('エラーが発生しました');
  };
  const getUnitText = (): string => {
    if (dealConstruction.constructionItem?.unit == null) {
      return '';
    }
    return dealConstruction.constructionItem.unit;
  };
  const handleChangeEnabled = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const newConstruction = { ...dealConstruction, enabled: e.target.checked };
    handleUpdateEnabled && (await handleUpdateEnabled(newConstruction));
    await handleMutateConstructions();
  };
  return (
    <Draggable
      key={dealConstruction.id}
      draggableId={String(dealConstruction.id)}
      index={index}
      isDragDisabled={handleUpdateSeq === null || readonly}
    >
      {(provided: DraggableProvided): React.ReactElement<HTMLElement> => (
        <tr
          ref={provided.innerRef}
          className={clsx(
            Styles.ListRow,
            dealConstruction.enabled ? Styles.Enabled : Styles.UnEnabled
          )}
          style={provided.draggableProps.style}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
        >
          <td
            className={Styles.OrderHandle}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
          >
            {handleUpdateSeq !== null && !readonly && (
              <img alt="drag handle" src="/assets/DragHandle.svg" />
            )}
          </td>
          {handleUpdateEnabled !== null && (
            <td className={Styles.SwitchEnable}>
              <Switcher
                checked={dealConstruction.enabled}
                disabled={
                  readonly ||
                  !dealConstruction.constructionItem?.excludeEstimation
                }
                onChange={handleChangeEnabled}
              />
            </td>
          )}
          <td className={Styles.Estimation}>
            <div className={Styles.EstimationPointAndItem}>
              {dealConstruction.point != null &&
                dealConstruction.point !== '' && (
                  <div className={Styles.Point}>{dealConstruction.point}</div>
                )}
              <div>{getConstructionItemName(dealConstruction)}</div>
            </div>

            <div className={Styles.SPInformation}>
              <div>
                ¥{toThousandSeparator(dealConstruction.unitAmount)} *{' '}
                {toThousandSeparator(dealConstruction.unit)}
                {getUnitText()}
              </div>
              <div>
                借主{dealConstruction.renteeChargeWithPercentage} : 貸主
                {dealConstruction.ownerChargeWithPercentage} : 管理
                {dealConstruction.companyChargeWithPercentage}
              </div>
            </div>
            {dealConstruction.note != null && dealConstruction.note !== '' && (
              <div className={Styles.Note}>備考）{dealConstruction.note}</div>
            )}
          </td>
          <td className={Styles.UnitAmount}>
            ¥{toThousandSeparator(dealConstruction.unitAmount)} *{' '}
            {dealConstruction.unit}
            {getUnitText()}
          </td>
          <td className={Styles.ChargePer}>
            借主{dealConstruction.renteeChargeWithPercentage} : 貸主
            {dealConstruction.ownerChargeWithPercentage} : 管理
            {dealConstruction.companyChargeWithPercentage}
          </td>
          {target !== null && (
            <td className={Styles.ForAmount}>
              {target === 'rentee' && (
                <span>
                  ¥{toThousandSeparator(dealConstruction.renteeAmount)}
                </span>
              )}
              {target === 'owner' && (
                <span>
                  ¥{toThousandSeparator(dealConstruction.ownerAmount)}
                </span>
              )}
            </td>
          )}
          <td className={Styles.Amount}>
            ¥{toThousandSeparator(dealConstruction.totalAmount)}
          </td>
          {!readonly && (
            <td className={Styles.Edit}>
              {handleUpdate !== null && (
                <a className={Styles.EditLink} href="#" onClick={onClickEdit}>
                  編集
                </a>
              )}
              {handleDestroy !== null && (
                <a
                  className={Styles.DeleteLink}
                  href="#"
                  onClick={onClickDelete}
                >
                  削除
                </a>
              )}
              {showDestroyConfirm && (
                <ConfirmModal
                  confirmType="delete"
                  handleAccept={handleDeleteEstimation}
                  handleCancel={(): void => setShowDestroyConfirm(false)}
                  isOpen={showDestroyConfirm}
                >
                  見積りを削除してよろしいですか？
                </ConfirmModal>
              )}
              {showEdit && handleUpdate !== null && (
                <ConstructionEditModal
                  constructionItems={constructionItems}
                  dealConstruction={dealConstruction}
                  handleClose={(): void => setShowEdit(false)}
                  handleMutateConstructions={handleMutateConstructions}
                  handleUpdate={handleUpdate}
                />
              )}
            </td>
          )}
        </tr>
      )}
    </Draggable>
  );
};
