import React from 'react';
import Styles from './ChangeAmountModalButtons.module.scss';

type Props = {
  handleCancel: () => void;
  handleAccept: () => void;
  submitInvalid?: boolean;
};

export const ChangeAmountModalButtons: React.FC<Props> = ({
  handleCancel,
  handleAccept,
  submitInvalid = false
}: Props) => (
  <div className={Styles.ModalButtons}>
    <button
      className={Styles.CancelButton}
      onClick={handleCancel}
      type="button"
    >
      キャンセル
    </button>
    <button
      className={Styles.AcceptButton}
      disabled={submitInvalid}
      onClick={handleAccept}
      type="button"
    >
      変更を実行
    </button>
  </div>
);
