import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useDeals } from '~/hooks/useDeals';
import { PATH } from '~/pages/property_deals/[id]';

export const ClientRelateDealsButton: React.FC = () => {
  const router = useRouter();
  const { id = null } = router.query;
  const { data: deal } = useDeals().show(id === null ? null : Number(id));
  const onClick = React.useCallback(() => {
    if (deal === null) {
      return;
    }
    router.push(PATH(deal.propertyDealId)).then();
  }, [router, deal]);
  if (deal === null) {
    return null;
  }
  return (
    <Button onClick={onClick} type="button" variant="text">
      {deal.relatedDeals.length === 1
        ? '関連する案件一覧'
        : `他${deal.relatedDeals.length - 1}件の関連する案件`}
    </Button>
  );
};
