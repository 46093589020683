import React from 'react';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { CompanyStoreMailList } from './CompanyStoreMailList';

export const Page: React.FC = () => (
  <DefaultV2>
    <ClientSettingLayout>
      <CompanyStoreMailList />
    </ClientSettingLayout>
  </DefaultV2>
);
