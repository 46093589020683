import React, { ReactElement } from 'react';
import { Box, Table, Tbody, Thead } from '@itandi/itandi-bb-ui';
import {
  DealIndexClient,
  DealSearchCondition,
  DealSortKey
} from '~/models/Deal';
import { useStatuses } from '~/hooks/useStatuses';
import { DealTableHeader } from './DealTableHeader';
import { DealTableRow } from './DealTableRow';
import { DealTableFooter } from './DealTableFooter';
import Style from './DealTable.module.scss';
import { DealTableSPHeader } from '~/components/specifics/deals/index/table/DealTableSPHeader';
import { DealTableSPRow } from '~/components/specifics/deals/index/table/DealTableSPRow';
import { DealTableSPFooter } from '~/components/specifics/deals/index/table/DealTableSPFooter';

type Props = {
  deals: DealIndexClient[];
  dealSearchCondition: DealSearchCondition;
  handleChangeCondition: (newCondition: DealSearchCondition) => Promise<void>;
  total: number;
  isSp: boolean;
};

export const DealTable: React.FC<Props> = ({
  deals,
  dealSearchCondition,
  handleChangeCondition,
  total,
  isSp
}: Props) => {
  const { data: statuses } = useStatuses().index();
  const nowSortKey = dealSearchCondition.sortKey || 'registration_date';
  const nowSortDirection = dealSearchCondition.sortDirection || 'desc';
  const sortCondition = React.useCallback(
    (sortKey: DealSortKey): 'asc' | 'desc' | 'none' =>
      sortKey === nowSortKey ? nowSortDirection : 'none',
    [nowSortKey, nowSortDirection]
  );
  const handleSort =
    (selectedSortKey: DealSortKey) => async (): Promise<void> => {
      const sortDirection: 'asc' | 'desc' =
        selectedSortKey === nowSortKey && nowSortDirection === 'desc'
          ? 'asc'
          : 'desc';
      const newCondition: DealSearchCondition = {
        ...dealSearchCondition,
        sortKey: selectedSortKey,
        sortDirection
      };
      await handleChangeCondition(newCondition);
    };
  if (statuses === null) {
    return null;
  }
  const statusNames = Object.fromEntries(
    statuses.map((status) => [status.id, status.name])
  );
  return (
    <>
      {!isSp && (
        <Box minW={1200}>
          <Table fixedHeader>
            <Thead>
              <DealTableHeader
                handleSort={handleSort}
                sortCondition={sortCondition}
              />
            </Thead>
            <Tbody>
              {deals.map(
                (deal: DealIndexClient): ReactElement => (
                  <DealTableRow
                    key={deal.id}
                    deal={deal}
                    statusNames={statusNames}
                  />
                )
              )}
            </Tbody>
            <tfoot className={Style.TableFooter}>
              <DealTableFooter
                dealSearchCondition={dealSearchCondition}
                handleChangeCondition={handleChangeCondition}
                total={total}
              />
            </tfoot>
          </Table>
        </Box>
      )}
      {isSp && (
        <Table fixedHeader>
          <Thead>
            <DealTableSPHeader
              handleSort={handleSort}
              sortCondition={sortCondition}
            />
          </Thead>
          <Tbody>
            {deals.map(
              (deal: DealIndexClient): ReactElement => (
                <DealTableSPRow
                  key={deal.id}
                  deal={deal}
                  statusNames={statusNames}
                />
              )
            )}
          </Tbody>
          <tfoot className={Style.TableFooter}>
            <DealTableSPFooter
              dealSearchCondition={dealSearchCondition}
              handleChangeCondition={handleChangeCondition}
              total={total}
            />
          </tfoot>
        </Table>
      )}
    </>
  );
};
