import React from 'react';
import { Button, Input, InputText, ItandiChip } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './DealRepairCardModalForm.module.scss';
import { DealRepairParam } from '~/models/DealRepair';
import { onChangeFile, onClickFileLink } from '~/utils/FormUtil';
import { Deal } from '~/models/Deal';

type Props = DeepReadonly<{
  deal: Deal;
}>;

export const DealRepairCardModalForm: React.FC<Props> = ({ deal }) => {
  const [modelNumberFileUrl, setModelNumberFileUrl] = React.useState<string>(
    deal?.dealRepair?.modelNumberFile ?? ''
  );
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    getValues
  } = useFormContext<DealRepairParam>();
  const modelNumberFile: File | null = watch('modelNumberFile');
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className={Styles.Forms}>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          依頼内容
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <InputText
              isInvalid={errors.requestContent != null}
              rows={7}
              {...register('requestContent')}
            />
            {errors.requestContent != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.requestContent.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>商品のカテゴリー</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.itemCategory != null}
              placeholder="例）エアコン"
              {...register('itemCategory')}
            />
          </div>
          {errors.itemCategory != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.itemCategory.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>商品名</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.itemName != null}
              placeholder="例）霜ヶ峰 OOシリーズ 6畳用"
              {...register('itemName')}
            />
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>メーカー</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.manufacturer != null}
              placeholder="例）OO電機"
              {...register('manufacturer')}
            />
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>型番</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.modelNumber != null}
              placeholder="例）ABC-1234"
              {...register('modelNumber')}
            />
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>型番画像</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            {modelNumberFileUrl !== '' && modelNumberFile === null ? (
              <a
                className={Styles.FileLink}
                href={modelNumberFileUrl}
                rel="noreferrer"
                target="_blank"
              >
                型番画像
              </a>
            ) : (
              <a
                className={Styles.FileLink}
                href="#"
                onClick={(e: React.MouseEvent): void =>
                  onClickFileLink(e, 'modelNumberFile', getValues)
                }
                rel="noreferrer"
                target="_blank"
              >
                {modelNumberFile?.name}
              </a>
            )}
            {modelNumberFileUrl === '' && modelNumberFile === null ? (
              <input
                accept="image/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  onChangeFile(e, 'modelNumberFile', setValue);
                  setValue('checkDestroy', false);
                }}
                type="file"
              />
            ) : (
              <>
                <Button
                  className={Styles.ActionButton}
                  height="small"
                  onClick={(): void => {
                    inputFileRef.current?.click();
                  }}
                  variant="secondary"
                >
                  変更
                </Button>
                <input
                  ref={inputFileRef}
                  accept="image/*"
                  className={Styles.HideInputFile}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    onChangeFile(e, 'modelNumberFile', setValue);
                  }}
                  type="file"
                />
                <Button
                  className={Styles.ActionButton}
                  height="small"
                  onClick={(): void => {
                    setValue('modelNumberFile', null);
                    setValue('checkDestroy', true);
                    setModelNumberFileUrl('');
                  }}
                  variant="danger"
                >
                  削除
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
