import React from 'react';
import {
  Button,
  FormControl,
  FormControlText,
  InputText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2,
  RiEdit2Line
} from '@itandi/itandi-bb-ui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Deal } from '~/models/Deal';
import { isSuccess } from '~/utils/api/api_handler';
import { update } from '~/utils/api/vendors/deals/DealVendorCompany';
import {
  DealVendorCompanyVendorUpdateParam,
  getOriginDealVendor,
  validationSchema
} from '~/models/DealVendorCompany';

type Props = {
  deal: Deal;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate: () => Promise<void>;
};

export const DealNoteWithinVendorModal: React.FC<Props> = ({
  deal,
  isOpen,
  handleClose,
  handleMutate
}: Props) => {
  const originDealVendor = getOriginDealVendor(deal);
  const noteWithinVendor = originDealVendor?.noteWithinVendor ?? '';
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm<DealVendorCompanyVendorUpdateParam>({
    resolver: yupResolver(validationSchema),
    defaultValues: { noteWithinVendor }
  });
  const handleModalSubmit = React.useCallback(() => {
    handleSubmit(async (submitValue: DealVendorCompanyVendorUpdateParam) => {
      if (originDealVendor === null) return;
      const response = await update(
        deal.id,
        originDealVendor,
        null,
        submitValue.noteWithinVendor
      );
      if (isSuccess(response)) {
        handleMutate();
        handleClose();
        toast.success('更新しました');
        return;
      }
      handleClose();
      toast.error('エラーが発生しました');
    })();
  }, [deal.id, originDealVendor, handleClose, handleMutate, handleSubmit]);

  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader
        description="施工会社のみ編集可能/実施会社は閲覧可能です。"
        icon={RiEdit2Line}
        title="案件メモの更新"
      />
      <ModalBody>
        <FormControl
          chip="任意"
          isInvalid={errors.noteWithinVendor != null}
          label="社内案件メモ"
          variant="Vertical"
        >
          <InputText
            autoHeightLimit={20}
            isInvalid={errors.noteWithinVendor != null}
            placeholder="社内で共有したいメモを記載してください"
            rows={8}
            {...register('noteWithinVendor')}
          />
          <FormControlText help="改行、URLはマークダウンで記載可能です。改行は半角スペース2つ、URLは[リンク名](URL)で記載可能です" />
          {errors.noteWithinVendor != null && (
            <FormControlText error={errors.noteWithinVendor.message || ''} />
          )}
        </FormControl>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={formState.isSubmitting}
            onClick={handleModalSubmit}
            variant="primary"
          >
            更新
          </Button>
        }
      />
    </Modalv2>
  );
};
