import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { useSubtypes } from '~/hooks/useSubtypes';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { Table } from '~/components/common/parts/table/Table';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { SubtypeListRow } from '~/components/specifics/settings/subtypes/SubtypeListRow';
import { Subtype } from '~/models/Subtype';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { SubtypeModal } from '~/components/specifics/settings/subtypes/SubtypeModal';
import Styles from './SubtypeList.module.scss';
import { PATH as RESTORATION_PATH } from '~/pages/settings/subtypes/restoration/todo_templates';
import { PATH as REPAIR_PATH } from '~/pages/settings/subtypes/repair/todo_templates';

export const SubtypeList: React.FC = () => {
  const router = useRouter();
  const { data: subtypes, mutate: mutateSubtypes } = useSubtypes().useIndex();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const renderSubtype = React.useCallback(
    (subtype: Subtype) => <SubtypeListRow key={subtype.id} subtype={subtype} />,
    []
  );
  const handleMutate = React.useCallback(async () => {
    mutateSubtypes && (await mutateSubtypes());
  }, [mutateSubtypes]);
  if (subtypes === null) {
    return null;
  }
  return (
    <div>
      <Table>
        <TableHeader>
          <th>タイプ名</th>
          <th>取込キー</th>
          <th>TODOテンプレート</th>
          <th className={Styles.ButtonCol}>操作</th>
        </TableHeader>
        <TableBody>
          <TableRow>
            <td>原状回復</td>
            <td>restoration</td>
            <td>
              <Button
                height="small"
                onClick={(): void => {
                  router.push(RESTORATION_PATH).then();
                }}
              >
                設定
              </Button>
            </td>
            <td>
              <Dropdown disabled title="操作" variant="normal" />
            </td>
          </TableRow>
          <TableRow>
            <td>修繕</td>
            <td>repair</td>
            <td>
              <Button
                height="small"
                onClick={(): void => {
                  router.push(REPAIR_PATH).then();
                }}
              >
                設定
              </Button>
            </td>
            <td>
              <Dropdown disabled title="操作" variant="normal" />
            </td>
          </TableRow>
          {subtypes.map(renderSubtype)}
        </TableBody>
      </Table>
      <div className={Styles.ButtonArea}>
        <Button height="default" onClick={handleOpen} variant="secondary">
          案件タイプの追加
        </Button>
      </div>
      <SubtypeModal
        handleClose={handleClose}
        handleMutate={handleMutate}
        isOpen={isOpen}
      />
    </div>
  );
};
