import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import Link from 'next/link';
import Styles from './SummaryFileTemplateRow.module.scss';
import { SummaryFileTemplate } from '~/models/SummaryFileTemplate';
import { useSummaryFileTemplates } from '~/hooks/useSummaryFileTemplates';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { destroy, getShowPath } from '~/utils/api/SummaryFileTemplate';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  template: SummaryFileTemplate;
};

export const SummaryFileTemplateRowUploaded: React.FC<Props> = ({
  template
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { mutate } = useSummaryFileTemplates().useIndex();
  const handleAcceptDelete = React.useCallback(async () => {
    const response = await destroy(template.id);
    if (isSuccess(response)) {
      mutate != null && (await mutate());
      toast.success('削除しました');
      return;
    }
    toast.error(`エラーが発生しました: ${response.message}`);
  }, [mutate, template.id]);
  return (
    <div className={Styles.SettingContents}>
      <span className={clsx(Styles.SettingLabel, Styles.Uploaded)}>設定済</span>
      <Link
        className={Styles.Link}
        href={getShowPath(template.id)}
        target="_blank"
      >
        設定ファイル
      </Link>
      <Button height="small" onClick={handleOpen} variant="danger">
        削除
      </Button>
      <ConfirmModal
        confirmType="delete"
        handleAccept={handleAcceptDelete}
        handleCancel={handleClose}
        isOpen={isOpen}
      >
        削除してよろしいですか？
      </ConfirmModal>
    </div>
  );
};
