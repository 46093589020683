import { TodoTemplateEdit } from '~/models/TodoTemplate';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';

const paths = {
  create: '/todo_templates',
  update: '/todo_templates/:id',
  destroy: '/todo_templates/:id'
};

export const create = async (
  todoTemplate: TodoTemplateEdit
): Promise<NskResponse> => postRequest(paths.create, { todoTemplate });

export const update = async (
  todoTemplateId: number,
  todoTemplate: TodoTemplateEdit
): Promise<NskResponse> =>
  putRequest(paths.update.replace(':id', todoTemplateId.toString()), {
    todoTemplate
  });

export const destroy = async (todoTemplateId: number): Promise<NskResponse> =>
  deleteRequest(paths.destroy.replace(':id', todoTemplateId.toString()));
