import React from 'react';
import { NextPage } from 'next';
import { NskHead } from '~/components/common/head/NskHead';
import { Page } from '~/containers/specifics/deals/[id]/estimations/Page';

export const PATH = (id: number): string => `/deals/${id}/estimations`;

const Estimations: NextPage = () => (
  <>
    <NskHead title="見積り確認" />
    <Page />
  </>
);

export default Estimations;
