import React from 'react';
import { toast } from 'react-toastify';
import { Modal } from '~/components/common/parts/modal/Modal';
import { isSuccess } from '~/utils/api/api_handler';
import { ConstructionForm } from '~/components/common/constructions/ConstructionForm';
import { DealConstructionEdit } from '~/models/DealConstruction';
import { ConstructionItem } from '~/models/ConstructionItem';
import { NskResponse } from '~/models/Api';

type Props = {
  handleClose: () => void;
  constructionItems: ConstructionItem[];
  mutateConstructions: () => Promise<void>;
  handleCreate: (
    submitValue: DealConstructionEdit
  ) => Promise<NskResponse<void>>;
};

export const ConstructionAddModal: React.FC<Props> = ({
  handleClose,
  handleCreate,
  constructionItems,
  mutateConstructions
}: Props) => {
  const handleSave = async (
    submitValue: DealConstructionEdit,
    reInput: boolean
  ): Promise<boolean> => {
    const response = await handleCreate(submitValue);
    if (isSuccess(response)) {
      await mutateConstructions();
      toast.success('追加しました');
      if (!reInput) {
        handleClose();
      }
      return true;
    }
    toast.error('エラーが発生しました');
    return false;
  };
  return (
    <Modal handleClose={handleClose} isOpen title="見積り追加">
      <ConstructionForm
        constructionItems={constructionItems}
        handleSave={handleSave}
        type="add_modal"
      />
    </Modal>
  );
};
