import React from 'react';
import cx from 'clsx';
import Styles from './ConstructionList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealConstructionContext } from '~/models/DealConstruction';

type Props = {
  constructionContext: DealConstructionContext;
  showSwitchEnable: boolean;
  showButtons: boolean;
  target: 'owner' | 'rentee' | null;
  companyType: 'client' | 'vendor' | 'partner';
  docType: 'billing' | 'estimation';
};

export const ConstructionListFooterRow: React.FC<Props> = ({
  constructionContext,
  showSwitchEnable,
  showButtons,
  target,
  companyType,
  docType
}: Props) => {
  const {
    sumRenteeAmount,
    sumRenteeAmountWithTax,
    sumOwnerAmount,
    sumOwnerAmountWithTax,
    totalAmount,
    totalAmountWithTax
  } = constructionContext;
  const isClientEstimation =
    companyType === 'client' && docType === 'estimation';
  return (
    <tr className={cx(Styles.ListRow, Styles.Footer)}>
      <td className={Styles.OrderHandle} />
      {showSwitchEnable && <td className={Styles.SwitchEnable} />}
      <td className={Styles.Estimation}>
        <div className={isClientEstimation ? Styles.SumPoint : Styles.Point}>
          合計
        </div>
        {isClientEstimation && (
          <span className={Styles.TaxAnnotate}>
            課税方法の変更に伴う、イタンジ請求書金額とのずれについては
            <a
              className={Styles.TaxChangeLink}
              href="https://naiso-kojikun.zendesk.com/hc/ja/articles/17488949382163"
              rel="noreferrer"
              target="_blank"
            >
              こちら
            </a>
          </span>
        )}
      </td>
      <td className={Styles.UnitAmount} />
      <td className={Styles.ChargePer} />
      {target !== null && (
        <td className={Styles.ForAmount}>
          {target === 'rentee' && (
            <div>
              ¥{toThousandSeparator(sumRenteeAmountWithTax)}
              <div className={Styles.WithoutTax}>
                税抜：¥{toThousandSeparator(sumRenteeAmount)}
              </div>
            </div>
          )}
          {target === 'owner' && (
            <div>
              ¥{toThousandSeparator(sumOwnerAmountWithTax)}
              <div className={Styles.WithoutTax}>
                税抜：¥{toThousandSeparator(sumOwnerAmount)}
              </div>
            </div>
          )}
        </td>
      )}
      <td className={Styles.Amount}>
        ¥{toThousandSeparator(totalAmountWithTax)}
        <div className={Styles.WithoutTax}>
          税抜：¥{toThousandSeparator(totalAmount)}
        </div>
      </td>
      {showButtons && <td className={Styles.Buttons} />}
    </tr>
  );
};
