import React from 'react';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import { SWRConfig } from 'swr';
import { ToastContainer } from 'react-toastify';
import { AxiosResponse } from 'axios';
import ApiClient from '~/utils/api/api_client';
import '../styles/global.scss';

const App: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => (
  <SWRConfig
    value={{
      fetcher: (url: string): Promise<{ data: unknown }> =>
        ApiClient(url).then((r: AxiosResponse<{ data: unknown }>) => r.data),
      revalidateOnFocus: false
    }}
  >
    <ToastContainer />
    <Component {...pageProps} />
  </SWRConfig>
);

export default App;
