import React from 'react';
import clsx from 'clsx';
import Styles from './RoomSelectRow.module.scss';
import { Property } from '~/models/kensaku_kun/property';

type Props = DeepReadonly<{
  isSelected: boolean;
  room: Property<'room'>;
  handleSelectRoom: (room: Property<'room'>) => void;
}>;

export const RoomSelectRow: React.FC<Props> = ({
  room,
  isSelected,
  handleSelectRoom
}: Props) => {
  const onClickRow = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      handleSelectRoom(room);
    },
    [handleSelectRoom, room]
  );
  return (
    <a
      className={clsx(Styles.Row, isSelected && Styles.Selected)}
      href="#"
      onClick={onClickRow}
    >
      {isSelected && <span>[選択中]</span>}
      {room.roomNumber}
    </a>
  );
};
