import React, { ReactNode } from 'react';
import Styles from './Page.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PropertyDealLayout } from '~/components/common/layouts/property_deals/PropertyDealLayout';
import { DealWithPrices } from '~/models/Deal';
import { RelatedDealRow } from '~/components/specifics/property_deals/[id]/RelatedDealRow';
import { RelatedDealListTop } from '~/components/specifics/property_deals/[id]/RelatedDealListTop';
import { usePropertyDeals } from '~/hooks/usePropertyDeals';
import { RelatedDealTotalRow } from '~/components/specifics/property_deals/[id]/RelatedDealTotalRow';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

const renderDealRow = (deal: DealWithPrices): ReactNode => (
  <RelatedDealRow key={deal.id} deal={deal} />
);

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: propertyDeal } = usePropertyDeals().show(id);
  if (propertyDeal === null) {
    return null;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: null, caption: '案件グループ', active: true }
  ];
  return (
    <DefaultV2>
      <PropertyDealLayout breadcrumbItems={breadcrumbItems}>
        <div className={Styles.Container}>
          <RelatedDealListTop />
          <div className={Styles.DealList}>
            {propertyDeal.deals.map(renderDealRow)}
          </div>
          <RelatedDealTotalRow propertyDeal={propertyDeal} />
        </div>
      </PropertyDealLayout>
    </DefaultV2>
  );
};
