import { NskResponse } from '~/models/Api';
import { StaffParam } from '~/models/Staff';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';

const paths = {
  create: '/staffs',
  update: '/staffs/:id',
  login: '/staffs/login',
  logout: '/staffs/logout'
};

export const create = async (staff: StaffParam): Promise<NskResponse<void>> =>
  postRequest(paths.create, { staff });

export const update = async (
  staff: StaffParam,
  staffId: number
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':id', String(staffId)), {
    staff
  });

export const login = async (
  id: number,
  password: string | null
): Promise<NskResponse<void>> => postRequest(paths.login, { id, password });

export const logout = async (): Promise<NskResponse<void>> =>
  deleteRequest(paths.logout);
