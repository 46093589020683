import React from 'react';
import Styles from './ChatDealListPane.module.scss';
import { ChatDealListRowsClient } from './ChatDealListRowsClient';
import { ChatDealListRowsVendor } from './ChatDealListRowsVendor';

type Props = DeepReadonly<{
  isVendor: boolean;
}>;

export const ChatDealListPane: React.FC<Props> = ({ isVendor }: Props) => (
  <div className={Styles.ChatDealListPane}>
    <div className={Styles.TitleWrapper}>
      <h1 className={Styles.Title}>
        {isVendor ? '管理会社とのチャット' : '施工会社とのチャット'}
      </h1>
    </div>
    <div>
      {isVendor ? <ChatDealListRowsVendor /> : <ChatDealListRowsClient />}
    </div>
  </div>
);
