import React from 'react';
import cx from 'clsx';
import Styles from './CancelButton.module.scss';

type Props = {
  disabled?: boolean;
  label?: string;
  className?: string;
  onClick: () => void;
};

const PCancelButton: React.FC<Props> = ({
  disabled = false,
  label = 'キャンセル',
  className,
  onClick
}: Props) => (
  <button
    className={cx(Styles.Button, className)}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {label}
  </button>
);

export const CancelButton = React.memo(PCancelButton);
