import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { CompanyNotificationSetting } from '~/models/CompanyNotificationSetting';

const paths = {
  show: '/company_notification_setting'
};
type TShow = () => SwrReturnType<CompanyNotificationSetting>;
type ReturnType = {
  show: TShow;
};

export const useCompanyNotificationSetting = (): ReturnType => {
  const show: TShow = () => {
    const { data, mutate, error } = useSWR<CompanyNotificationSetting>(
      paths.show
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { show };
};
