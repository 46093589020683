import React from 'react';

import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/vendors/403/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/vendors/403';

const error403: NextPage = () => (
  <>
    <NskHead title="権限エラー" />
    <Component />
  </>
);

export default error403;
