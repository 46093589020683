const REDIRECT_KEY = 'redirect-key';
const VENDOR_REDIRECT_KEY = 'vendor-redirect-key';

export const savePurposeUrl = (purposePath: string): void => {
  // 行きたかったURLをlocalStorageに書き込む
  localStorage.setItem(REDIRECT_KEY, purposePath);
};

export const getPurposeUrl = (): string | null => {
  const purposeUrl = localStorage.getItem(REDIRECT_KEY);
  if (purposeUrl !== null) {
    localStorage.removeItem(REDIRECT_KEY);
    return purposeUrl;
  }
  return null;
};

// vendor用
export const saveVendorPurposeUrl = (purposePath: string): void => {
  // 行きたかったURLをlocalStorageに書き込む
  localStorage.setItem(VENDOR_REDIRECT_KEY, purposePath);
};

export const getVendorPurposeUrl = (): string | null => {
  const purposeUrl = localStorage.getItem(VENDOR_REDIRECT_KEY);
  if (purposeUrl !== null) {
    localStorage.removeItem(VENDOR_REDIRECT_KEY);
    return purposeUrl;
  }
  return null;
};
