import useSWR from 'swr';
import { PropertyDealWithPrices } from '~/models/PropertyDeal';
import { SwrReturnType } from '~/models/Api';

const paths = {
  show: '/property_deals/:id'
};
type TShow = (id: number | null) => SwrReturnType<PropertyDealWithPrices>;
type ReturnType = {
  show: TShow;
};

export const usePropertyDeals = (): ReturnType => {
  const show: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<PropertyDealWithPrices>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  return { show };
};
