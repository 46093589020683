import React from 'react';
import Link from 'next/link';
import Styles from './DealMainSpLinks.module.scss';
import { PATH as DEAL_DETAIL_PATH } from '~/pages/vendors/deals/[id]/detail';
import { PATH as DEAL_MESSAGES_PATH } from '~/pages/vendors/deals/[id]/messages';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useMessages } from '~/hooks/vendors/deals/useMessages';
import { getUnreadCount, getVendorMessageSpeaker } from '~/models/Message';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { canMessage } from '~/models/VendorSession';

/**
 * スマートフォンで見た時だけ表示する詳細画面への誘導リンク
 *
 * @constructor
 * @for vendor
 */
export const DealMainSpLinksVendor: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: session } = useSessions().index();
  const { data: messages } = useMessages().index(id);
  const unreadMessageCount = React.useMemo((): number => {
    if (messages === null || deal === null || session === null) {
      return 0;
    }
    return getUnreadCount(messages, getVendorMessageSpeaker(deal, session));
  }, [deal, messages, session]);
  if (deal === null || messages === null || session === null) {
    return null;
  }
  return (
    <div className={Styles.SPDealDetail}>
      <Link
        className={Styles.SPDealDetailLink}
        href={DEAL_DETAIL_PATH(deal.id)}
      >
        案件内容
      </Link>
      {canMessage(session, deal) && (
        <Link
          className={Styles.SPDealDetailLink}
          href={DEAL_MESSAGES_PATH(deal.id)}
        >
          管理会社とチャット
          {unreadMessageCount !== 0 && (
            <span className={Styles.UnreadCountBadge}>
              {unreadMessageCount}
            </span>
          )}
        </Link>
      )}
    </div>
  );
};
