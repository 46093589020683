import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';
import { VendorCompanyCreateParams } from '~/models/VendorCompany';

const paths = {
  create: '/admin/vendor_companies',
  proxyLogin: '/admin/vendor_companies/:id/proxy_login'
};

export const create = async (
  vendorCompany: VendorCompanyCreateParams
): Promise<NskResponse<void>> => postRequest(paths.create, { vendorCompany });

export const proxyLogin = async (
  vendorCompanyId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.proxyLogin.replace(':id', String(vendorCompanyId)));
