import React from 'react';
import Styles from './RelatedDealRow.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { PropertyDealWithPrices } from '~/models/PropertyDeal';
import { DealWithPrices } from '~/models/Deal';
import { isCanceled, PARTIALLY_FINISHED } from '~/models/Status';
import { RelatedDealTotalRowSkLink } from '~/components/specifics/property_deals/[id]/RelatedDealTotalRowSkLink';

type Props = {
  propertyDeal: PropertyDealWithPrices;
};

const profitStyles = {
  positive: Styles.ProfitPositive,
  negative: Styles.ProfitNegative,
  zero: Styles.ProfitZero
};
export const RelatedDealTotalRow: React.FC<Props> = ({
  propertyDeal
}: Props) => {
  const targetDeals = propertyDeal.deals.filter(
    (deal: DeepReadonly<DealWithPrices>) =>
      !isCanceled(deal.id) && deal.status.id !== PARTIALLY_FINISHED
  );
  const totalEstimationAmount = React.useMemo(
    () =>
      targetDeals.reduce(
        (acc: number, deal: DeepReadonly<DealWithPrices>) =>
          acc + deal.dealEstimationContext.totalAmount,
        0
      ),
    [targetDeals]
  );
  const totalBillingRenteeAmount = React.useMemo(
    () =>
      targetDeals.reduce(
        (acc: number, deal: DeepReadonly<DealWithPrices>) =>
          acc + deal.dealRenteeBillingContext.sumRenteeAmount,
        0
      ),
    [targetDeals]
  );
  const totalBillingOwnerAmount = React.useMemo(
    () =>
      targetDeals.reduce(
        (acc: number, deal: DeepReadonly<DealWithPrices>) =>
          acc + deal.dealOwnerBillingContext.sumOwnerAmount,
        0
      ),
    [targetDeals]
  );
  const totalProfit = React.useMemo(() => {
    if (totalEstimationAmount === 0) {
      return null;
    }
    return (
      totalBillingRenteeAmount + totalBillingOwnerAmount - totalEstimationAmount
    );
  }, [
    totalBillingRenteeAmount,
    totalBillingOwnerAmount,
    totalEstimationAmount
  ]);
  const totalProfitRate = React.useMemo(() => {
    if (
      totalProfit === null ||
      totalBillingRenteeAmount + totalBillingOwnerAmount === 0
    ) {
      return null;
    }
    return Math.round(
      (totalProfit / (totalBillingRenteeAmount + totalBillingOwnerAmount)) * 100
    );
  }, [totalProfit, totalBillingRenteeAmount, totalBillingOwnerAmount]);
  const profitStyle = React.useMemo(() => {
    if (totalProfitRate === null) {
      return profitStyles.zero;
    }
    if (totalProfitRate > 0) {
      return profitStyles.positive;
    }
    if (totalProfitRate < 0) {
      return profitStyles.negative;
    }
    return profitStyles.zero;
  }, [totalProfitRate]);
  return (
    <div className={Styles.TotalRow}>
      <div className={Styles.TotalBlock} />
      <div className={Styles.DealEstimationBlock}>
        <h4 className={Styles.ContextLabel}>施工会社からの見積総額</h4>
        <div className={Styles.ContentText}>
          ¥{toThousandSeparator(totalEstimationAmount)}
        </div>
      </div>
      <div className={Styles.DealBillingBlock}>
        <h4 className={Styles.ContextLabel}>入居者への請求総額</h4>
        <div className={Styles.ContentText}>
          ¥{toThousandSeparator(totalBillingRenteeAmount)}
        </div>
      </div>
      <div className={Styles.DealBillingBlock}>
        <h4 className={Styles.ContextLabel}>オーナーへの請求総額</h4>
        <div className={Styles.ContentText}>
          ¥{toThousandSeparator(totalBillingOwnerAmount)}
        </div>
      </div>
      <div className={Styles.ProfitBlock}>
        <h4 className={Styles.ContextLabel}>総利益額</h4>
        <div className={Styles.ProfitText}>
          <span className={profitStyle}>
            {totalProfit === null
              ? '-'
              : `¥${toThousandSeparator(totalProfit)}`}
          </span>
        </div>
      </div>
      <div className={Styles.ProfitRateBlock}>
        <h4 className={Styles.ContextLabel}>総利益率</h4>
        <div className={Styles.ProfitText}>
          <span className={profitStyle}>
            {totalProfitRate === null
              ? '-'
              : toThousandSeparator(totalProfitRate)}
          </span>
          <span className={Styles.Unit}>%</span>
        </div>
      </div>
      <RelatedDealTotalRowSkLink targetDeals={targetDeals} />
    </div>
  );
};
