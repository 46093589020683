import React from 'react';
import { RiCommunityLine } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useSessions } from '~/hooks/vendors/useSessions';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { DealVendorChangeModal } from '~/components/common/deals/show/vendor_card/DealVendorChangeModal';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

/**
 * 管理会社から依頼をうけた施工会社の情報を表示する。
 * Vendorが2次請け構造のとき、1次請けの施工会社情報を表示する
 *
 * @constructor
 * @for vendor
 */
export const DealOriginVendorCard: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: session } = useSessions().index();
  const dealVendor = React.useMemo(() => {
    if (deal === null) {
      return null;
    }
    return getOriginDealVendor(deal);
  }, [deal]);
  if (
    deal === null ||
    session === null ||
    dealVendor === null ||
    dealVendor.vendorCompany === null
  ) {
    return null;
  }
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCommunityLine className={Styles.TitleIcon} />
        施工会社情報
        {isOriginVendor(deal, session.vendorCompany.id) && (
          <div className={Styles.EditButtonWrapper}>
            <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
            {isOpen && (
              <DealVendorChangeModal
                dealVendorCompany={dealVendor}
                handleClose={handleClose}
              />
            )}
          </div>
        )}
      </h3>
      <div className={Styles.Content}>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>会社名</dt>
          <dd className={Styles.ListContentValue}>
            {dealVendor.vendorCompany.name}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>担当者</dt>
          <dd className={Styles.ListContentValue}>
            {dealVendor.user === null ? (
              <span className={Styles.NotYet}>未定</span>
            ) : (
              <>
                <div>{dealVendor.user.name}</div>
                <div>
                  <a
                    className={Styles.Link}
                    href={`tel:${dealVendor.user.tel}`}
                  >
                    {dealVendor.user.tel}
                  </a>
                </div>
                <div>
                  <a
                    className={Styles.Link}
                    href={`mailto:${dealVendor.user.email}`}
                  >
                    {dealVendor.user.email}
                  </a>
                </div>
              </>
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};
