import React from 'react';
import { useRouter } from 'next/router';
import { Alert, Form } from 'react-bootstrap';
import { create as login } from '~/utils/api/vendors/sessions/VendorCompany';
import Styles from './Page.module.scss';
import { PATH } from '~/pages/vendors/deals';
import { isSuccess } from '~/utils/api/api_handler';
import { getVendorPurposeUrl } from '~/utils/AuthenticateRenderer';
import { VendorWithoutAuthLayout } from '~/components/common/layouts/VendorWithoutAuthLayout';

export const Page: React.FC = () => {
  const router = useRouter();
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const handleSubmit = async (): Promise<void> => {
    setProcessing(true);
    const result = await login({
      email,
      password
    });
    if (isSuccess(result)) {
      await router.push(getVendorPurposeUrl() ?? PATH);
      setProcessing(false);
      return;
    }
    setLoginError(result.message ?? null);
    setProcessing(false);
  };

  const onKeyPressEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit().then();
    }
  };
  return (
    <VendorWithoutAuthLayout>
      <div className={Styles.Card}>
        <h1 className={Styles.Title}>施工会社ログイン</h1>
        {loginError !== null && <Alert variant="danger">{loginError}</Alert>}
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>メールアドレス（ログインID）</Form.Label>
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setEmail(e.target.value);
              }}
              onKeyPress={onKeyPressEnter}
              placeholder="メールアドレス"
              type="email"
              value={email}
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setPassword(e.target.value);
              }}
              onKeyPress={onKeyPressEnter}
              placeholder="パスワード"
              type="password"
              value={password}
            />
          </Form.Group>
          <div>
            <button
              className={Styles.LoginButton}
              disabled={processing}
              onClick={handleSubmit}
              type="button"
            >
              ログイン
            </button>
          </div>
        </Form>
      </div>
    </VendorWithoutAuthLayout>
  );
};
