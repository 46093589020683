import React from 'react';
import { RiCalendarLine } from 'react-icons/ri';
import { Input, ItandiChip } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';
import { DealParam } from '~/models/DealForm';
import { toDate } from '~/utils/DateUtil';
import { onChangeDate } from '~/utils/FormUtil';

export const MoveOutBlock: React.FC = () => {
  const { register, setValue, watch } = useFormContext<DealParam>();
  const terminationDate = watch('terminationDate');
  const inspectionCandidateDate1 = watch('inspectionCandidateDate1');
  const inspectionCandidateDate2 = watch('inspectionCandidateDate2');
  const inspectionCandidateDate3 = watch('inspectionCandidateDate3');
  const isRestorationDealType = watch('dealType') === 'restoration';
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiCalendarLine className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>
            {isRestorationDealType ? '退去情報' : '立会日程情報'}
          </span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        {isRestorationDealType && (
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabel}>
              <span className={Styles.FormLabelText}>解約日</span>
              <span className={Styles.RequiredLabel}>
                <ItandiChip label="任意" />
              </span>
            </div>
            <div className={Styles.FormContent}>
              <Datepicker
                onChange={(date): void => {
                  onChangeDate(date, 'terminationDate', setValue);
                }}
                selected={toDate(terminationDate)}
              />
            </div>
          </div>
        )}
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>
              {`${isRestorationDealType ? '退去' : ''}立会希望日1`}
            </span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.DateTimeGroup}>
              <div className={Styles.DateInput}>
                <Datepicker
                  onChange={(date): void => {
                    onChangeDate(date, 'inspectionCandidateDate1', setValue);
                  }}
                  selected={toDate(inspectionCandidateDate1)}
                />
              </div>
              <div className={Styles.TimeInput}>
                <Input
                  placeholder="例）午前中"
                  {...register('inspectionCandidateDateTime1')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>
              {`${isRestorationDealType ? '退去' : ''}立会希望日2`}
            </span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.DateTimeGroup}>
              <div className={Styles.DateInput}>
                <Datepicker
                  onChange={(date): void => {
                    onChangeDate(date, 'inspectionCandidateDate2', setValue);
                  }}
                  selected={toDate(inspectionCandidateDate2)}
                />
              </div>
              <div className={Styles.TimeInput}>
                <Input
                  placeholder="例）12時-14時"
                  {...register('inspectionCandidateDateTime2')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>
              {`${isRestorationDealType ? '退去' : ''}立会希望日3`}
            </span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.DateTimeGroup}>
              <div className={Styles.DateInput}>
                <Datepicker
                  onChange={(date): void => {
                    onChangeDate(date, 'inspectionCandidateDate3', setValue);
                  }}
                  selected={toDate(inspectionCandidateDate3)}
                />
              </div>
              <div className={Styles.TimeInput}>
                <Input
                  placeholder="例）午後"
                  {...register('inspectionCandidateDateTime3')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
