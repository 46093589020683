import React from 'react';
import cx from 'clsx';
import Styles from './ConstructionList.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { DealConstructionContext } from '~/models/DealConstruction';

type Props = {
  constructionContext: DealConstructionContext;
};

export const ConstructionAmountRow: React.FC<Props> = ({
  constructionContext
}: Props) => {
  const { constructionTotalAmount, constructionTotalAmountWithTax } =
    constructionContext;
  return (
    <tr className={cx(Styles.ListRow, Styles.Footer)}>
      <td className={Styles.OrderHandle} />
      <td className={Styles.Estimation}>
        <div className={Styles.Point}>
          工事代金（実施会社と管理会社には表示されません）
        </div>
      </td>
      <td className={Styles.UnitAmount} />
      <td className={Styles.ChargePer} />
      <td className={Styles.Amount}>
        ¥{toThousandSeparator(constructionTotalAmountWithTax)}
        <div className={Styles.WithoutTax}>
          税抜：¥{toThousandSeparator(constructionTotalAmount)}
        </div>
      </td>
    </tr>
  );
};
