import React from 'react';
import { useRouter } from 'next/router';
import { Page as Component } from '~/components/specifics/deals/index/Page';

export const Page: React.FC = () => {
  const router = useRouter();
  if (!router.isReady) {
    return <></>;
  }
  return <Component />;
};
