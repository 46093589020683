import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@itandi/itandi-bb-ui';
import { RiCheckLine, RiErrorWarningLine } from 'react-icons/ri';
import Styles from './CompanyRow.module.scss';
import { CompanySettingWithStore } from '~/models/CompanySetting';
import { useCompanySettings } from '~/hooks/useCompanySettings';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { CompanySettingEditModal } from '~/components/specifics/admin/CompanySettingEditModal';
import { CompanyDetailModal } from '~/components/specifics/admin/CompanyDetailModal';
import useItandiAccount from '~/hooks/useItandiAccount';
import { CompanyStoreProxyLoginModal } from '~/components/specifics/admin/CompanyStoreProxyLoginModal';

type Props = {
  companySetting: CompanySettingWithStore;
  handleMutate: () => Promise<void>;
};

export const CompanyRow: React.FC<Props> = ({
  companySetting,
  handleMutate
}: Props) => {
  const {
    isOpen: openId,
    handleOpen: handleOpenId,
    handleClose: handleCloseId
  } = useOpenHandler();
  const {
    isOpen: openDetail,
    handleOpen: handleOpenDetail,
    handleClose: handleCloseDetail
  } = useOpenHandler();
  const {
    isOpen: openStoreLogin,
    handleOpen: handleOpenStoreLogin,
    handleClose: handleCloseStoreLogin
  } = useOpenHandler();
  const router = useRouter();
  const itandiAccountApi = useItandiAccount();
  const { mutate } = itandiAccountApi.currentAccount();
  const companySettingsApi = useCompanySettings();
  const onClickProxyLogin = React.useCallback(async (): Promise<void> => {
    if (companySetting.companyStoreSettings.length > 1) {
      handleOpenStoreLogin();
      return;
    }
    await companySettingsApi.proxyLogin(
      companySetting.companyMasterId,
      companySetting.authId
    );
    mutate && (await mutate());
    router.push(DEALS_PATH).then();
  }, [
    companySettingsApi,
    companySetting,
    mutate,
    router,
    handleOpenStoreLogin
  ]);
  return (
    <tr className={Styles.CompanyRow}>
      <td>
        <div className={Styles.CompanyMasterId}>
          {companySetting.companyMasterId}
        </div>
        <div className={Styles.AuthId}>{companySetting.authId}</div>
      </td>
      <td>
        <div className={Styles.ContractType}>
          <span className={Styles.ContractTypeLabel}>
            {companySetting.fromMarket ? 'マケプレ' : 'SaaS'}
          </span>
        </div>
        <div className={Styles.CompanyName}>
          {companySetting.fromMarket ? (
            <button
              className={Styles.CompanyDetailButton}
              onClick={handleOpenDetail}
              type="button"
            >
              {companySetting.name}
            </button>
          ) : (
            <span>{companySetting.name}</span>
          )}
          {openDetail && (
            <CompanyDetailModal
              companySetting={companySetting}
              handleClose={handleCloseDetail}
              handleMutate={handleMutate}
              isOpen={openDetail}
            />
          )}
        </div>
      </td>
      <td className={Styles.ActionButtonCell}>
        {companySetting.fromMarket && (
          <Button
            height="small"
            onClick={handleOpenId}
            startIcon={
              companySetting.antisocialCheckId !== null ? (
                <RiCheckLine className={Styles.CheckIcon} />
              ) : (
                <RiErrorWarningLine className={Styles.WarningIcon} />
              )
            }
          >
            反社チェックID入力
          </Button>
        )}
        <Button height="small" onClick={onClickProxyLogin}>
          代理ログイン
        </Button>
        {openStoreLogin && (
          <CompanyStoreProxyLoginModal
            companySetting={companySetting}
            handleClose={handleCloseStoreLogin}
          />
        )}
        {openId && (
          <CompanySettingEditModal
            companySetting={companySetting}
            handleClose={handleCloseId}
            handleMutate={handleMutate}
            isOpen={openId}
          />
        )}
      </td>
    </tr>
  );
};
