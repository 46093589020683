import { AdminSettingCompanySetting } from '~/models/CompanySetting';
import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';

const paths = {
  update: '/company_settings'
} as const;

export const update = async (
  companySetting: AdminSettingCompanySetting
): Promise<NskResponse> => putRequest(paths.update, { companySetting });
