import React from 'react';
import SettingStyles from '../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { convertImportType, ImportType, importTypes } from '~/models/ImportLog';
import { useImportLogs } from '~/hooks/admin/useImportLogs';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { ImportTypeRow } from '~/components/specifics/settings/imports/ImportTypeRow';

export const Page: React.FC = () => {
  const { data: importLogs } = useImportLogs().useIndex();
  if (importLogs === null) {
    return null;
  }
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <h2 className={SettingStyles.SettingTitle}>過去データ取込</h2>
        <Table>
          <TableHeader>
            <th>取込タイプ</th>
            <th>取込画面</th>
            <th>取込件数</th>
            <th>最終取込日時</th>
          </TableHeader>
          <TableBody>
            {importTypes.map((importType: ImportType) => (
              <ImportTypeRow
                key={importType}
                importLogWithCount={importLogs[convertImportType(importType)]}
                importType={importType}
              />
            ))}
          </TableBody>
        </Table>
      </ClientSettingLayout>
    </DefaultV2>
  );
};
