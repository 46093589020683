import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';

const paths = {
  update: '/vendors/deal_statuses/:deal_id'
};

export const update = async (
  dealId: number,
  statusId: number
): Promise<NskResponse<void>> =>
  putRequest(paths.update.replace(':deal_id', String(dealId)), {
    statusId
  });
