import React from 'react';
import cx from 'clsx';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { Button } from '@itandi/itandi-bb-ui';
import Styles from './CompanyVendorList.module.scss';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import { CompanyVendor } from '~/models/CompanyVendor';
import { CompanyVendorListRow } from '~/components/specifics/settings/company_vendors/CompanyVendorListRow';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { CompanyVendorModal } from '~/components/specifics/settings/company_vendors/CompanyVendorModal';
import { updateSeq } from '~/utils/api/CompanyVendor';
import useItandiAccount from '~/hooks/useItandiAccount';

export const CompanyVendorList: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { data: currentAccount } = useItandiAccount().currentAccount();
  const { data: companyVendors } = useCompanyVendors().index();
  // 並び替えしたときにmutate前に画面に反映するためのstate
  const [showCompanyVendors, setShowCompanyVendors] = React.useState<
    CompanyVendor[]
  >([]);
  React.useEffect(() => {
    companyVendors && setShowCompanyVendors(companyVendors);
  }, [companyVendors]);
  const renderRow = React.useCallback(
    (companyVendor: CompanyVendor, index: number): React.ReactNode => {
      if (currentAccount === null) return null;
      return (
        <CompanyVendorListRow
          key={companyVendor.id}
          companyVendor={companyVendor}
          currentAccount={currentAccount}
          index={index}
        />
      );
    },
    [currentAccount]
  );
  const handleDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const moveCompanyVendor =
      showCompanyVendors.find(
        (v: CompanyVendor) => String(v.id) === draggableId
      ) ?? null;
    const newCompanyVendors: CompanyVendor[] = [];
    const wkCompanyVendors = [...showCompanyVendors];
    wkCompanyVendors.splice(source.index, 1);
    if (moveCompanyVendor !== null) {
      wkCompanyVendors.splice(destination.index, 0, moveCompanyVendor);
    }
    wkCompanyVendors.forEach((e: CompanyVendor, i: number) => {
      newCompanyVendors.push({ ...e, seq: i + 1 });
    });
    setShowCompanyVendors(newCompanyVendors);
    updateSeq(newCompanyVendors).then();
  };
  if (currentAccount === null) return null;
  const canAddCompanyVendor =
    !currentAccount.company?.fromMarket || currentAccount.isAdmin;
  return (
    <div>
      <table className={Styles.List}>
        <thead>
          <tr className={cx(Styles.ListRow, Styles.Header)}>
            <th className={Styles.OrderHandle}>
              <span>並替</span>
            </th>
            <th>施工会社</th>
            <th />
            <th>単価</th>
            <th>操作</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided): React.ReactElement<HTMLElement> => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {showCompanyVendors.map(renderRow)}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      {canAddCompanyVendor && (
        <div>
          <Button height="default" onClick={handleOpen} variant="secondary">
            施工会社の追加
          </Button>
          <CompanyVendorModal handleClose={handleClose} isOpen={isOpen} />
        </div>
      )}
    </div>
  );
};
