import useSWR from 'swr';
import { ImportLog, ImportLogsByType } from '~/models/ImportLog';
import { SwrReturnType } from '~/models/Api';

const paths = {
  index: '/admin/import_logs',
  show: (id: number) => `/admin/import_logs/${id}`
} as const;

type TIndex = () => SwrReturnType<ImportLogsByType>;
type TShow = (id: number | null) => SwrReturnType<ImportLog>;
type ReturnType = {
  useIndex: TIndex;
  useShow: TShow;
};

export const useImportLogs = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error, isLoading } = useSWR<ImportLogsByType>(
      paths.index
    );
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading,
      error
    };
  };
  const useShow: TShow = (id: number | null) => {
    const { data, mutate, error, isLoading } = useSWR<ImportLog>(
      id === null ? null : paths.show(id)
    );
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      error,
      isLoading
    };
  };
  return { useIndex, useShow };
};
