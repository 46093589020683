import React, { ReactNode } from 'react';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { useImportConversions } from '~/hooks/admin/useImportConversions';
import { ImportType } from '~/models/ImportLog';
import { ImportConversion } from '~/models/ImportConversion';
import { ImportConversionTableRow } from '~/components/specifics/settings/imports/[importType]/import_conversions/ImportConversionTableRow';

type Props = {
  importType: ImportType;
};

export const ImportConversionTable: React.FC<Props> = ({
  importType
}: Props) => {
  const { data: importConversions } =
    useImportConversions().useIndex(importType);
  return (
    <Table>
      <TableHeader>
        <th>列</th>
        <th>変換前</th>
        <th>変換後</th>
        <th>操作</th>
      </TableHeader>
      <TableBody>
        {importConversions?.map(
          (importConversion: ImportConversion): ReactNode => (
            <ImportConversionTableRow
              key={importConversion.id}
              importConversion={importConversion}
              importType={importType}
            />
          )
        )}
      </TableBody>
    </Table>
  );
};
