import React from 'react';
import Styles from './DealCardModalForm.module.scss';
import { RoomBlock } from '~/components/common/deals/show/deal_card/block/RoomBlock';
import { TenantBlock } from '~/components/common/deals/show/deal_card//block/TenantBlock';
import { DealBlock } from '~/components/common/deals/show/deal_card/block/DealBlock';
import { Deal } from '~/models/Deal';

type Props = {
  deal: Deal;
};
export const DealCardModalForm: React.FC<Props> = ({ deal }: Props) => (
  <div className={Styles.Forms}>
    <RoomBlock deal={deal} />
    <TenantBlock />
    <DealBlock deal={deal} />
  </div>
);
