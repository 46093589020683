import React from 'react';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { useMessageSummaries } from '~/hooks/useMessageSummaries';
import { ChatPagesContainer } from '~/components/common/chat/ChatPagesContainer';

export const Page: React.FC = () => {
  const { data: messageSummaries } = useMessageSummaries().index();
  if (messageSummaries === null) {
    return null;
  }
  return (
    <DefaultV2>
      <ChatPagesContainer />
    </DefaultV2>
  );
};
