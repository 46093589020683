import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  ConfirmModal,
  InputText,
  Flex
} from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import {
  CompanyStoreSetting,
  CompanyStoreSettingEdit
} from '~/models/CompanyStoreSetting';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { update } from '~/utils/api/CompanyStoreSetting';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  companyStoreSetting: CompanyStoreSetting;
};

export const CompanyStoreSettingForm: React.FC<Props> = ({
  companyStoreSetting
}) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { register, getValues, handleSubmit } =
    useForm<CompanyStoreSettingEdit>({
      defaultValues: companyStoreSetting
    });
  const onClickSubmit = React.useCallback(() => {
    handleSubmit(() => {
      handleOpen();
    })();
  }, [handleSubmit, handleOpen]);
  const handleAccept = React.useCallback(async () => {
    const values = getValues();
    const response = await update(values);
    if (isSuccess(response)) {
      toast.success('保存しました');
      handleClose();
      return;
    }
    toast.error('保存に失敗しました');
  }, [getValues, handleClose]);
  return (
    <Flex direction="column" gap={16} maxWidth={600} rowGap={16}>
      <FormControl chip="任意" label="オーナー請求書用備考" variant="Vertical">
        <InputText
          autoHeightLimit={10}
          placeholder="オーナー用請求書エクセルファイルで記載したい備考を入力してください"
          rows={7}
          {...register('billingNoteForOwner')}
        />
      </FormControl>
      <FormControl chip="任意" label="入居者請求書用備考" variant="Vertical">
        <InputText
          autoHeightLimit={10}
          placeholder="入居者用請求書エクセルファイルで記載したい備考を入力してください"
          rows={7}
          {...register('billingNoteForRentee')}
        />
      </FormControl>
      <div>
        <Button onClick={onClickSubmit} variant="primary">
          保存
        </Button>
      </div>
      <ConfirmModal
        handleClose={handleClose}
        handleSubmit={handleAccept}
        headerTitle="確認"
        isOpen={isOpen}
      >
        保存してよろしいですか？
      </ConfirmModal>
    </Flex>
  );
};
