import { DealWithCompanyAndVendor } from '~/models/Deal';
import { MessageSummaryUnread } from '~/models/MessageSummaryUnread';

export type MessageSummary = {
  id: number;
  deal: DealWithCompanyAndVendor;
  companyUnread: boolean;
  vendorUnread: boolean;
  lastSendAt: string | null;
  lastMessage: string | null;
  messageSummaryUnreads: MessageSummaryUnread[];
};

export const isUnreadForClient = (
  messageSummary: MessageSummary,
  authId: number
): boolean =>
  messageSummary.messageSummaryUnreads.some(
    (unread) => unread.authId === authId && unread.unread
  );

export const isUnreadForVendor = (
  messageSummary: MessageSummary,
  vendorCompanyId: number
): boolean =>
  messageSummary.messageSummaryUnreads.some(
    (unread) => unread.vendorCompanyId === vendorCompanyId && unread.unread
  );
