import React from 'react';
import { Chip, colors, colorUtility } from '@itandi/itandi-bb-ui';
import { Subtype } from '~/models/Subtype';
import { DealType } from '~/models/Deal';

type Props = {
  dealType: DealType;
  dealSubtype: Subtype | null;
};

export const DealTypeChip: React.FC<Props> = ({
  dealType,
  dealSubtype
}: Props) => {
  const dealTypeChipProperty = React.useMemo(() => {
    if (dealSubtype !== null) {
      return {
        label: dealSubtype.name,
        textColor: colors['expressive-yellow-dark'],
        background: colorUtility(colors['expressive-yellow-dark']).alpha(0.1)
      };
    }
    if (dealType === 'restoration') {
      return {
        label: '原状回復',
        textColor: colors['product-nsk'],
        background: colorUtility(colors['product-nsk']).alpha(0.1)
      };
    }
    if (dealType === 'repair') {
      return {
        label: '修繕',
        textColor: colors['product-nsk'],
        background: colorUtility(colors['product-nsk']).alpha(0.1)
      };
    }
    return {
      label: 'その他',
      textColor: colors['expressive-yellow-dark'],
      background: colorUtility(colors['expressive-yellow-dark']).alpha(0.1)
    };
  }, [dealSubtype, dealType]);

  return (
    <Chip
      background={dealTypeChipProperty.background}
      borderColor={null}
      fontWeight="Bold"
      label={dealTypeChipProperty.label}
      textColor={dealTypeChipProperty.textColor}
    />
  );
};
