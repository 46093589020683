import { object, string } from 'yup';
import { ImportType } from '~/models/ImportLog';

export type ImportConversion = {
  id: number;
  importType: ImportType;
  columnName: string;
  originalValue: string;
  convertedValue: string;
};

export type ImportConversionParam = Omit<ImportConversion, 'id'>;

export const buildDefault = (
  importType: ImportType
): ImportConversionParam => ({
  importType,
  columnName: '',
  originalValue: '',
  convertedValue: ''
});

export const validationSchema = object({
  columnName: string().required('入力してください'),
  originalValue: string().required('入力してください'),
  convertedValue: string().required('入力してください')
});
