import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { CompanyStoreMail } from '~/models/CompanyStoreMail';

const paths = {
  index: '/company_store_mails'
};

type TIndex = () => SwrReturnType<CompanyStoreMail[]>;
type ReturnType = {
  index: TIndex;
};

export const useCompanyStoreMails = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<CompanyStoreMail[]>(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
