import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  RiAdminLine,
  RiChat1Line,
  RiListOrdered,
  RiSettings2Line
} from 'react-icons/ri';
import styles from './GlobalHeader.module.scss';

type Props = {
  isAdmin: boolean;
};

export const GlobalHeaderSecondNavVendor: React.FC<Props> = ({
  isAdmin
}: Props) => {
  const { pathname } = useRouter();
  const isActivePage = React.useCallback(
    (currentPath: string, targetPath: string): boolean =>
      targetPath === '/' ? pathname === '/' : pathname.includes(targetPath),
    [pathname]
  );

  return (
    <nav className={styles.SecondNav}>
      <ul className={styles.SecondNavMenu}>
        <li
          className={styles.SecondNavMenuItem}
          is-active={isActivePage(pathname, '/vendors/deals') ? 'true' : null}
        >
          <Link className={styles.Menu} href="/vendors/deals">
            <RiListOrdered className={styles.MenuIcon} />
            案件一覧
          </Link>
        </li>
        <li
          className={styles.SecondNavMenuItem}
          is-active={
            isActivePage(pathname, '/vendors/messages') ? 'true' : null
          }
        >
          <Link className={styles.Menu} href="/vendors/messages">
            <RiChat1Line className={styles.MenuIcon} />
            <span>チャット</span>
          </Link>
        </li>
        <li
          className={styles.SecondNavMenuItem}
          is-active={
            isActivePage(pathname, '/vendors/settings') ? 'true' : null
          }
        >
          <Link className={styles.Menu} href="/vendors/settings">
            <RiSettings2Line className={styles.MenuIcon} />
            設定
          </Link>
        </li>
        {isAdmin && (
          <li className={styles.SecondNavMenuItem} is-active={null}>
            <Link className={styles.Menu} href="/admin/vendor_companies">
              <RiAdminLine className={styles.MenuIcon} />
              Admin
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};
