import React from 'react';
import Styles from './statuses.module.scss';

export const PartiallyFinished: React.FC = () => (
  <div>
    <h3 className={Styles.Title}>案件が完了になりました</h3>
    <p className={Styles.Description}>
      管理会社により案件がクローズされました。工事を行う必要はありません
    </p>
  </div>
);
