import React from 'react';
import clsx from 'clsx';
import Styles from './DealTabs.module.scss';
import { TabType } from '~/components/common/deals/show/main/DealMain';

type Props = {
  tabType: TabType;
  handleChangeTab: (newTabType: TabType) => void;
};

export const DealTabs: React.FC<Props> = ({
  tabType,
  handleChangeTab
}: Props) => {
  const getActiveStyle = React.useCallback(
    (renderTabType: TabType): string =>
      tabType === renderTabType ? Styles.Active : '',
    [tabType]
  );
  const handleClickTab = React.useCallback(
    (e: React.MouseEvent, newTabType: TabType): void => {
      e.preventDefault();
      handleChangeTab(newTabType);
    },
    [handleChangeTab]
  );
  return (
    <div className={Styles.Tabs}>
      <a
        className={clsx(Styles.Tab, getActiveStyle('workflow'))}
        href="#"
        onClick={(e: React.MouseEvent): void => handleClickTab(e, 'workflow')}
      >
        手続き
      </a>
      <a
        className={clsx(Styles.Tab, getActiveStyle('reports'))}
        href="#"
        onClick={(e: React.MouseEvent): void => handleClickTab(e, 'reports')}
      >
        報告画像
      </a>
      <a
        className={clsx(Styles.Tab, getActiveStyle('estimation'))}
        href="#"
        onClick={(e: React.MouseEvent): void => handleClickTab(e, 'estimation')}
      >
        見積り
      </a>
      <a
        className={clsx(Styles.Tab, getActiveStyle('histories'))}
        href="#"
        onClick={(e: React.MouseEvent): void => handleClickTab(e, 'histories')}
      >
        履歴
      </a>
      <a
        className={clsx(Styles.Tab, getActiveStyle('files'))}
        href="#"
        onClick={(e: React.MouseEvent): void => handleClickTab(e, 'files')}
      >
        ファイル
      </a>
    </div>
  );
};
