import React, { ReactNode } from 'react';
import Link from 'next/link';
import { RiArrowDownSFill } from 'react-icons/ri';
import Styles from './MarketRegisterLayout.module.scss';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import useItandiAccount from '~/hooks/useItandiAccount';
import { useCompanySettings } from '~/hooks/markets/useCompanySettings';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const MarketRegisterLayout: React.FC<Props> = ({ children }: Props) => {
  const { data: initialCompanySetting } = useCompanySettings().show();
  const itandiAccountApi = useItandiAccount();
  const handleLogout = React.useCallback(() => {
    itandiAccountApi.logout();
  }, [itandiAccountApi]);
  if (initialCompanySetting === null) {
    return null;
  }
  return (
    <>
      <header className={Styles.BBHeader} id="itandi_bb_header">
        <nav className={Styles.FirstNav}>
          <Link className={Styles.ItandiBBLogoLink} href="/">
            <img
              alt="ITANDI BB"
              className={Styles.ItandiBBLogo}
              src="/itandi_bb_logo.svg"
            />
          </Link>
          <span className={Styles.ModeNameText}>
            管理会社
            <br />
            メニュー
          </span>
          <ul className={Styles.FirstNavMenu}>
            <li className={Styles.FirstNavMenuItem} product-id="bb">
              <Link
                href={process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_BB || ''}
                prefetch={false}
              >
                物件検索
              </Link>
            </li>
            <li className={Styles.FirstNavMenuItem} product-id="bk">
              <Link
                href={`${process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_BK}/rooms`}
                prefetch={false}
              >
                物件管理
              </Link>
            </li>
            <li className={Styles.FirstNavMenuItem} product-id="nk">
              <Link
                href={`${process.env.NEXT_PUBLIC_ITANDIBB_SERVICES_BK}/bosyu_kanri/tab_redirect`}
                prefetch={false}
              >
                募集管理
              </Link>
            </li>
            <li className={Styles.FirstNavMenuItem} is-active="true">
              <Link href="/">修繕管理</Link>
            </li>
          </ul>
          <div className={Styles.NavAccountInformation}>
            <SimpleDropdown
              placement="bottom-end"
              title={
                <div className={Styles.DropdownTitle}>
                  <span>{initialCompanySetting.companyName}</span>
                  <RiArrowDownSFill className={Styles.Caret} />
                </div>
              }
            >
              <DropdownList>
                <DropdownMenu onClick={handleLogout}>ログアウト</DropdownMenu>
              </DropdownList>
            </SimpleDropdown>
          </div>
        </nav>
      </header>
      <main>{children}</main>
    </>
  );
};
