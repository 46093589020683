import React, { ReactNode } from 'react';
import cx from 'clsx';
import Styles from './Button.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => void | boolean | Promise<void> | Promise<boolean>;
  variant?:
    | 'black_primary'
    | 'primary'
    | 'secondary'
    | 'normal'
    | 'danger'
    | 'link'
    | 'outline';
  size?: 'lg' | 'md' | 'sm';
  width?: 'full' | 'auto';
  type?: 'button' | 'submit';
  round?: boolean;
};

export const Button: React.FC<Props> = ({
  children,
  className = '',
  disabled = false,
  onClick,
  variant = 'primary',
  size = 'md',
  width = 'auto',
  type = 'button',
  round = false
}: Props) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const variantStyle = ((): string => {
    switch (variant) {
      case 'black_primary':
        return Styles.BlackPrimary;
      case 'primary':
        return Styles.Primary;
      case 'secondary':
        return Styles.Secondary;
      case 'normal':
        return Styles.Normal;
      case 'danger':
        return Styles.Danger;
      case 'link':
        return Styles.Link;
      case 'outline':
        return Styles.Outline;
      default:
        return '';
    }
  })();
  const sizeStyle = ((): string => {
    switch (size) {
      case 'lg':
        return Styles.Large;
      case 'md':
        return Styles.Medium;
      case 'sm':
        return Styles.Small;
      default:
        return '';
    }
  })();
  const widthStyle = ((): string => {
    switch (width) {
      case 'auto':
        return Styles.WidthAuto;
      case 'full':
        return Styles.WidthFull;
      default:
        return '';
    }
  })();
  const handleClick = async (): Promise<void> => {
    setProcessing(true);
    // Router.push等でComponentがDestructされる場合はtrueを返してもらう
    const notNeedRestore = await onClick();
    if (notNeedRestore !== true) {
      setProcessing(false);
    }
  };
  return (
    <button
      className={cx(
        Styles.Button,
        className,
        variantStyle,
        sizeStyle,
        widthStyle,
        round ? Styles.Round : ''
      )}
      disabled={disabled || processing}
      onClick={handleClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  );
};
