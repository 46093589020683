import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { InputCheckBox } from '@itandi/itandi-bb-ui';
import Styles from './CompanyStoreMailList.module.scss';
import { useCompanyStoreMails } from '~/hooks/useCompanyStoreMails';
import { CompanyStoreMail } from '~/models/CompanyStoreMail';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { CompanyStoreMailModal } from './CompanyStoreMailModal';
import { destroy as destroyMails } from '~/utils/api/CompanyStoreMails';
import { isSuccess } from '~/utils/api/api_handler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';

export const CompanyStoreMailList: React.FC = () => {
  const [checkNumbers, setCheckNumbers] = React.useState<number[]>([]);
  const { data: companyMails, mutate: mutateMails } =
    useCompanyStoreMails().index();
  const {
    isOpen: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const {
    isOpen: openDeleteConfirm,
    handleOpen: handleOpenDeleteConfirm,
    handleClose: handleCloseDeleteConfirm
  } = useOpenHandler();
  const isChecked = checkNumbers.length > 0;
  const handleDestroyMails = async (): Promise<void> => {
    const result = await destroyMails(checkNumbers);
    if (isSuccess(result)) {
      handleCloseDeleteConfirm();
      mutateMails && mutateMails();
      setCheckNumbers([]);
      toast.success('削除しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  const handleCheckNumbers = React.useCallback(
    (deleteIds: number): void => {
      if (checkNumbers.includes(deleteIds)) {
        setCheckNumbers(checkNumbers.filter((num) => num !== deleteIds));
      } else {
        setCheckNumbers([...checkNumbers, deleteIds]);
      }
    },
    [checkNumbers]
  );
  const renderRow = React.useCallback(
    (companyMail: CompanyStoreMail): ReactNode => (
      <tr key={companyMail.id} className={Styles.ListRow}>
        {companyMails !== null && (
          <td className={Styles.DropdownCheck}>
            <InputCheckBox
              checked={checkNumbers.includes(companyMail.id)}
              onChange={(): void => {
                handleCheckNumbers(companyMail.id);
              }}
            />
          </td>
        )}
        <td className={Styles.ListCol}>{companyMail.email}</td>
      </tr>
    ),
    [checkNumbers, companyMails, handleCheckNumbers]
  );
  return (
    <div>
      <div className={Styles.SettingList}>
        <h2 className={Styles.SettingTitle}>通知先メールアドレス設定</h2>
        <p className={Styles.ActionButton}>
          <button
            className={clsx(
              Styles.ModalButton,
              isChecked ? Styles.DeleteButton : Styles.AddButton
            )}
            onClick={isChecked ? handleOpenDeleteConfirm : handleOpenConfirm}
            type="button"
          >
            {isChecked
              ? '選択されたメールアドレスを削除する'
              : 'メールアドレスを追加する'}
          </button>
        </p>
      </div>
      <table className={Styles.List}>
        <thead>
          <tr className={clsx(Styles.ListRow, Styles.Header)}>
            {companyMails?.length !== 0 && <th />}
            <th>
              メールアドレス
              {isChecked && <>: {checkNumbers.length}件削除します</>}
            </th>
          </tr>
        </thead>
        <tbody>{companyMails?.map(renderRow)}</tbody>
      </table>
      <ConfirmModal
        confirmType="delete"
        handleAccept={handleDestroyMails}
        handleCancel={handleCloseDeleteConfirm}
        isOpen={openDeleteConfirm}
      >
        選択したメールアドレスを削除してよろしいですか？
      </ConfirmModal>
      <CompanyStoreMailModal
        handleClose={handleCloseConfirm}
        isOpen={openConfirm}
      />
    </div>
  );
};
