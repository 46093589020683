import { NskResponse } from '~/models/Api';
import { postRequest } from '~/utils/api/api_handler';
import { BillingBulkUpdateContext, BillingType } from '~/models/DealBilling';

const paths = {
  create: '/deals/:deal_id/deal_billing_updates'
};

export const postUpdate = async (
  billingUpdateContext: BillingBulkUpdateContext | null,
  dealId: number,
  billingType: BillingType
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    billingUpdateContext,
    billingType
  });
