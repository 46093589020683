import React, { ReactNode } from 'react';
import Styles from './TableRow.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const TableRow: React.FC<Props> = ({ children }: Props) => (
  <tr className={Styles.TableRow}>{children}</tr>
);
