import { Deal } from '~/models/Deal';

export type Property<T extends 'room' | 'building'> = {
  type: T;
  propertyId: number;
  name: string;
  roomNumber: T extends 'room' ? string : null;
  zipcode: string;
  address: string;
};

export const buildPropertyByDeal = (
  deal: Deal
): Property<'room' | 'building'> | null => {
  if (deal.propertyType === null || deal.propertyId === null) return null;
  return {
    type: deal.propertyType,
    propertyId: deal.propertyId,
    name: deal.name,
    roomNumber: deal.roomNumber,
    zipcode: '',
    address: deal.address
  };
};
