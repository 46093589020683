import React, { useState, useCallback, ReactElement } from 'react';
import Loading from '~/components/common/parts/loading/Loading';

type ReturnType = {
  Loader(): ReactElement;
  showLoader(): void;
  hideLoader(): void;
};

const useLoader = (loading = false): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(loading);
  const showLoader = useCallback((): void => setIsLoading(true), []);
  const hideLoader = useCallback((): void => setIsLoading(false), []);
  const Loader = (): ReactElement => <Loading isLoading={isLoading} />;

  return { Loader, showLoader, hideLoader };
};

export default useLoader;
