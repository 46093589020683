import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '@itandi/itandi-bb-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { isSuccess } from '~/utils/api/api_handler';
import { ProfileForm } from '../forms/ProfileForm';
import { AuthorizationForm } from '../forms/AuthorizationForm';
import Styles from '../forms/Form.module.scss';
import {
  Staff,
  StaffParam,
  buildDefaultStaff,
  createValidationSchema
} from '~/models/Staff';
import { onInvalidScroll } from '~/utils/FormUtil';
import { create, update } from '~/utils/api/Staff';
import { AUTH_NAME } from '~/models/StaffAuthorization';

type Props = {
  staff?: Staff | null;
  handleMutate?: (() => void) | null;
};

export const FormInputPage: React.FC<Props> = ({
  staff = null,
  handleMutate
}: Props) => {
  const router = useRouter();
  const methods = useForm<StaffParam>({
    mode: 'onBlur',
    resolver: yupResolver(createValidationSchema),
    defaultValues: staff ?? buildDefaultStaff()
  });
  const [checked, setChecked] = React.useState(
    methods.getValues(AUTH_NAME.completeStatus)
  );
  const onClickSubmit = React.useCallback(() => {
    methods.handleSubmit(
      async (submitValue: StaffParam) => {
        const response =
          staff === null
            ? await create(submitValue)
            : await update(submitValue, staff.id);
        if (isSuccess(response)) {
          handleMutate && (await handleMutate());
          router.push('/settings/staffs');
          toast.success(staff === null ? '追加しました' : '更新しました');
          return;
        }
        toast.error('エラーが発生しました');
      },
      () => onInvalidScroll(methods.formState)
    )();
  }, [methods, staff, handleMutate, router]);
  const isChanged = React.useMemo(() => {
    if (staff === null) return true;
    return methods.formState.isDirty;
  }, [methods.formState.isDirty, staff]);
  const handleUpdateStatus = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (methods.watch(AUTH_NAME.completeStatus)) {
        methods.setValue(AUTH_NAME.completeStatus, false);
        setChecked(false);
      }
      methods.setValue(AUTH_NAME.status, e.target.checked, {
        shouldDirty: true
      });
    },
    [methods]
  );
  const handleUpdateAuth = React.useCallback(
    (
      value: (typeof AUTH_NAME)[keyof typeof AUTH_NAME],
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      methods.setValue(value, e.target.checked, {
        shouldDirty: true
      });
    },
    [methods]
  );
  return (
    <div>
      <FormProvider {...methods}>
        <div className={Styles.FormGroup}>
          <div className={Styles.GroupLabel}>詳細</div>
          <ProfileForm
            errorMessage={methods.formState.errors.name?.message}
            errors={methods.formState.errors.name}
            formLabel="名前"
            isInvalid={methods.formState.errors.name != null}
            placeholder="例）内装 太郎"
            registerName="name"
          />
          <ProfileForm
            errorMessage={methods.formState.errors.email?.message}
            errors={methods.formState.errors.email}
            formLabel="メールアドレス"
            isInvalid={methods.formState.errors.email != null}
            placeholder="例）itandi.tarou@example.com"
            registerName="email"
          />
          <ProfileForm
            errorMessage={methods.formState.errors.phoneNumber?.message}
            errors={methods.formState.errors.phoneNumber}
            formLabel="電話番号"
            isInvalid={methods.formState.errors.phoneNumber != null}
            optional
            placeholder="例）070-1234-5678"
            registerName="phoneNumber"
          />
          <div className={Styles.GroupLabel}>権限</div>
          <AuthorizationForm
            defaultChecked={methods.getValues(AUTH_NAME.setting)}
            description="設定画面へのアクセスを許可します。"
            formLabel="設定画面へのアクセス"
            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              handleUpdateAuth(AUTH_NAME.setting, e);
            }}
          />
          <AuthorizationForm
            defaultChecked={methods.getValues(AUTH_NAME.status)}
            description="案件のステータス移行を許可します。"
            formLabel="案件のステータス移行"
            handleChange={handleUpdateStatus}
          />
          <AuthorizationForm
            checked={checked}
            description="案件の完了ステータスへの移行を許可します。"
            disabled={!methods.watch(AUTH_NAME.status)}
            formLabel="案件のステータスを完了へ移行"
            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              handleUpdateAuth(AUTH_NAME.completeStatus, e);
              setChecked(e.target.checked);
            }}
          />
          <AuthorizationForm
            defaultChecked={methods.getValues(AUTH_NAME.cancel)}
            description="案件の取り下げや削除を許可します。"
            formLabel="案件を取り下げ・削除"
            handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              handleUpdateAuth(AUTH_NAME.cancel, e);
            }}
          />
        </div>
      </FormProvider>
      <div>
        <div className={Styles.SubmitButton}>
          <Button
            disabled={!isChanged}
            onClick={onClickSubmit}
            variant="primary"
            width="fill"
          >
            {staff === null ? '追加' : '更新'}
          </Button>
        </div>
      </div>
    </div>
  );
};
