import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import { CompanyVendor } from '~/models/CompanyVendor';

const paths = {
  index: '/company_vendors'
};
type TIndex = () => SwrReturnType<CompanyVendor[]>;
type ReturnType = {
  index: TIndex;
};

export const useCompanyVendors = (): ReturnType => {
  const index: TIndex = () => {
    const { data, mutate, error } = useSWR<CompanyVendor[]>(paths.index);
    return {
      data: data ?? [],
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { index };
};
