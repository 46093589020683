import React from 'react';
import { RiEdit2Line } from 'react-icons/ri';
import Link from 'next/link';
import Styles from './ConstructionNavigation.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import {
  DealConstruction,
  DealConstructionContext
} from '~/models/DealConstruction';

type Props = {
  title: string;
  detailPagePath: string;
  dealConstructionContext: DealConstructionContext;
};

export const ConstructionNavigation: React.FC<Props> = ({
  title,
  detailPagePath,
  dealConstructionContext
}: Props) => {
  const { totalAmountWithTax, dealConstructions } = dealConstructionContext;
  const estimationNum = dealConstructions.filter(
    (e: DealConstruction) => e.enabled
  ).length;
  return (
    <div className={Styles.NavigationBlock}>
      <div className={Styles.IconWrapper}>
        <RiEdit2Line className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>{title}</h4>
          <div>
            {estimationNum}件（¥
            {toThousandSeparator(totalAmountWithTax)}
            ）が工事対象となっています。
          </div>
        </div>
        <div className={Styles.DetailLinkBlock}>
          <Link className={Styles.DetailLink} href={detailPagePath}>
            見積り明細画面
          </Link>
        </div>
      </div>
    </div>
  );
};
