import { number, object, string } from 'yup';

type TriggerType = 'status' | 'specific_date' | 'register' | 'order' | 'cancel';
type TriggerDateType =
  | 'inspection_date'
  | 'construction_start_date'
  | 'construction_end_date';
type NotifyType = 'immediate' | 'reservation';
type SendType = 'mail' | 'slack';
type MailSendTo = 'staff' | 'store';
type DealType = 'all' | 'restoration' | 'repair' | 'other';
export type NotificationDealStateFormName =
  | 'dealEstimationState'
  | 'dealInspectionReportState'
  | 'dealCompletionReportState'
  | 'dealConstructionScheduleState';
export type NotificationDealState = 'no_setting' | 'yet' | 'registered';
export type Notification = {
  id: number;
  dealType: DealType;
  dealSubtypeId: number | null;
  triggerType: TriggerType;
  triggerStatusId: number | null;
  triggerDateType: TriggerDateType | null;
  notifyType: NotifyType;
  notifyReservationDays: number | null;
  resend: boolean;
  sendType: SendType;
  mailSendTo: MailSendTo | null;
  dealEstimationState: NotificationDealState;
  dealInspectionReportState: NotificationDealState;
  dealCompletionReportState: NotificationDealState;
  dealConstructionScheduleState: NotificationDealState;
  slackWebhookUrl: string | null;
  slackUserName: string | null;
  slackIconEmoji: string | null;
  title: string | null;
  content: string | null;
  sendPartner: boolean;
};

export type NotificationEdit = Omit<Notification, 'id' | 'triggerType'> & {
  triggerType: TriggerType | null;
};

export const buildInitialNotification = (): NotificationEdit => ({
  dealType: 'all',
  dealSubtypeId: null,
  triggerType: null,
  triggerStatusId: null,
  triggerDateType: null,
  notifyType: 'immediate',
  notifyReservationDays: null,
  resend: false,
  sendType: 'mail',
  mailSendTo: 'staff',
  dealEstimationState: 'no_setting',
  dealInspectionReportState: 'no_setting',
  dealCompletionReportState: 'no_setting',
  dealConstructionScheduleState: 'no_setting',
  slackWebhookUrl: '',
  slackUserName: '',
  slackIconEmoji: '',
  title: '',
  content: '',
  sendPartner: false
});

export const validationSchema = object().shape({
  triggerType: string().nullable().required('入力してください'),
  triggerStatusId: number()
    .nullable()
    .when('triggerType', {
      is: 'status',
      then: number().nullable().required('入力してください')
    }),
  triggerDateType: string()
    .nullable()
    .when('triggerType', {
      is: 'specific_date',
      then: string().nullable().required('入力してください')
    }),
  notifyType: string().nullable().required('入力してください'),
  notifyReservationDays: number()
    .nullable()
    .typeError('数値で入力してください')
    .when('notifyType', {
      is: 'reservation',
      then: number()
        .nullable()
        .required('入力してください')
        .when('triggerType', {
          is: 'specific_date',
          otherwise: number().nullable().min(1, '1以上の数値を入力してください')
        })
    }),
  sendType: string().nullable().required('入力してください'),
  mailSendTo: string()
    .nullable()
    .when('sendType', {
      is: 'mail',
      then: string().nullable().required('入力してください')
    }),
  slackWebhookUrl: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  slackUserName: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  slackIconEmoji: string()
    .nullable()
    .when('sendType', {
      is: 'slack',
      then: string().nullable().required('入力してください')
    }),
  dealType: string().nullable().required('入力してください'),
  dealSubtypeId: number()
    .nullable()
    .when('dealType', {
      is: 'other',
      then: number()
        .nullable()
        .when('type', {
          is: 'CompanyNotification',
          then: number().required('入力してください')
        })
    })
});
export const DEAL_TYPE_LABELS = {
  all: 'すべて',
  restoration: '原状回復',
  repair: '修繕',
  other: 'その他'
} as const satisfies {
  [field in DealType]: string;
};
export const TRIGGER_TYPE_LABELS = {
  status: 'ステータス',
  specific_date: '特定日時',
  register: '案件登録時',
  order: '施工会社依頼時',
  cancel: '案件取り下げ時'
} as const satisfies {
  [field in TriggerType]: string;
};

export const TRIGGER_DATE_TYPE_LABELS = {
  inspection_date: '立会日',
  construction_start_date: '工事開始日',
  construction_end_date: '工事完了日'
} as const satisfies {
  [field in TriggerDateType]: string;
};

export const NOTIFY_TYPE_LABELS = {
  immediate: '即時通知',
  reservation: '通知予約'
} as const satisfies {
  [field in NotifyType]: string;
};

export const SEND_TYPE_LABELS = {
  mail: 'メール',
  slack: 'Slack'
} as const satisfies {
  [field in SendType]: string;
};

export const MAIL_SEND_TO_LABELS = {
  staff: '担当者',
  store: '店舗'
} as const satisfies {
  [field in MailSendTo]: string;
};

export const DEAL_STATE_NAMES = {
  dealEstimationState: '見積り',
  dealInspectionReportState: '立会報告画像',
  dealCompletionReportState: '完了報告画像',
  dealConstructionScheduleState: '工事日程の入力状況'
} as const satisfies {
  [field in NotificationDealStateFormName]: string;
};

export const DEAL_STATE_LABELS = {
  no_setting: '登録状況に関わらず通知',
  yet: '未登録のみ',
  registered: '登録済みのみ'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const ESTIMATION_DESCRIPTIONS = {
  no_setting: '',
  registered: '見積りが1件以上登録されている場合に通知されます',
  yet: '見積りが登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const REPORT_DESCRIPTIONS = {
  no_setting: '',
  registered: '画像が1件以上登録されている場合に通知されます',
  yet: '画像が登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};

export const SCHEDULE_DESCRIPTIONS = {
  no_setting: '',
  registered: 'いずれかの日程が登録されている場合に通知されます',
  yet: '日程が一つも登録されていない場合に通知されます'
} as const satisfies {
  [field in NotificationDealState]: string;
};
