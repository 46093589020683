import React, { ReactNode } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import { RiAddBoxLine, RiArrowRightLine } from 'react-icons/ri';
import cx from 'clsx';
import { BigNumber } from 'bignumber.js';
import Styles from './ConstructionForm.module.scss';
import { ConstructionItem } from '~/models/ConstructionItem';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import {
  buildDefaultConstruction,
  DealConstruction,
  DealConstructionEdit,
  validationSchemaConstruction
} from '~/models/DealConstruction';
import { OTHER_CONSTRUCTION_ITEM_ID } from '~/constants/DealConstructionConst';

type Props = {
  dealConstruction?: DealConstruction | null;
  constructionItems: ConstructionItem[];
  handleSave: (
    submitValue: DealConstructionEdit,
    reInput: boolean
  ) => Promise<boolean>;
  type: 'add' | 'add_modal' | 'edit';
};

export const ConstructionForm: React.FC<Props> = ({
  dealConstruction = null,
  constructionItems,
  handleSave,
  type
}: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const findConstructionItem = (value: number): ConstructionItem | null =>
    constructionItems.find((i: ConstructionItem) => i.id === value) ?? null;
  const initialValues: DealConstructionEdit =
    dealConstruction === null
      ? buildDefaultConstruction('DealEstimation')
      : dealConstruction;
  return (
    <div className={Styles.EstimationForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          submitValue: DealConstructionEdit,
          { resetForm }
        ): Promise<void> => {
          setIsSubmitting(true);
          const success = await handleSave(submitValue, true);
          if (success) {
            resetForm({ values: buildDefaultConstruction('DealEstimation') });
          }
          setIsSubmitting(false);
        }}
        validationSchema={validationSchemaConstruction}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          values,
          errors,
          validateForm,
          submitForm
        }: FormikProps<DealConstructionEdit>): ReactNode => {
          const reCalcTotalAmount = (
            unit: number,
            unitAmount: number
          ): void => {
            if (Number.isNaN(unit) || Number.isNaN(unitAmount)) {
              return;
            }
            const newTotalAmount: number = BigNumber(unit)
              .multipliedBy(BigNumber(unitAmount))
              .toNumber();
            setFieldValue('totalAmount', newTotalAmount);
          };
          return (
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                e.preventDefault();
              }}
            >
              <div className={Styles.FormGroup}>
                <div className={Styles.FormLabel}>工事項目</div>
                <div>
                  <Form.Control
                    as="select"
                    isInvalid={
                      !!(touched.constructionItem && errors.constructionItem)
                    }
                    name="constructionItem"
                    onBlur={handleBlur}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      const { value } = e.target;
                      const constructionItem = findConstructionItem(
                        Number(value)
                      );
                      setFieldValue('constructionItem', constructionItem);
                      setFieldValue(
                        'unitAmount',
                        constructionItem === null
                          ? 0
                          : constructionItem.unitAmount
                      );
                      if (constructionItem !== null) {
                        reCalcTotalAmount(
                          values.unit,
                          constructionItem.unitAmount
                        );
                      }
                    }}
                    value={values.constructionItem?.id ?? -1}
                  >
                    <option key={-1} value={-1}>
                      工事項目を選択
                    </option>
                    {constructionItems.map(
                      (item: ConstructionItem): ReactNode => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {String(errors.constructionItem)}
                  </Form.Control.Feedback>
                </div>
              </div>
              {values.constructionItem?.id === OTHER_CONSTRUCTION_ITEM_ID && (
                <div className={Styles.FormGroup}>
                  <div className={Styles.FormLabel}>工事項目名</div>
                  <div>
                    <Form.Control
                      isInvalid={!!(touched.otherName && errors.otherName)}
                      name="otherName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="例）諸経費"
                      value={values.otherName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.otherName}
                    </Form.Control.Feedback>
                  </div>
                </div>
              )}
              <div className={Styles.FormGroup}>
                <div className={Styles.FormLabel}>施工箇所</div>
                <div>
                  <Form.Control
                    isInvalid={!!(touched.point && errors.point)}
                    name="point"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="例）リビング"
                    value={values.point}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.point}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className={Styles.FormGroup}>
                <div className={Styles.Row}>
                  <div className={Styles.Col}>
                    <div className={Styles.FormLabel}>単価</div>
                    <Form.Control
                      isInvalid={!!(touched.unitAmount && errors.unitAmount)}
                      name="unitAmount"
                      onBlur={handleBlur}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        const { value } = e.target;
                        setFieldValue(
                          'unitAmount',
                          value === '' ? '' : Number(value)
                        );
                        reCalcTotalAmount(values.unit, Number(value));
                      }}
                      placeholder="単価"
                      type="number"
                      value={values.unitAmount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.unitAmount}
                    </Form.Control.Feedback>
                  </div>
                  <div className={Styles.Col}>
                    <div className={Styles.FormLabel}>数量</div>
                    <div className={Styles.UnitFormWrapper}>
                      {values.constructionItem?.unit != null && (
                        <span className={Styles.UnitText}>
                          {values.constructionItem.unit}
                        </span>
                      )}
                      <Form.Control
                        className={cx(
                          values.constructionItem?.unit != null
                            ? Styles.WithUnit
                            : ''
                        )}
                        isInvalid={!!(touched.unit && errors.unit)}
                        name="unit"
                        onBlur={handleBlur}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          const { value } = e.target;
                          setFieldValue(
                            'unit',
                            value === '' ? '' : Number(value)
                          );
                          reCalcTotalAmount(Number(value), values.unitAmount);
                        }}
                        placeholder="数量"
                        type="number"
                        value={values.unit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.unit}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.FormGroup}>
                <div className={Styles.Row}>
                  <div className={Styles.Col}>
                    <div className={Styles.FormLabel}>借主負担％</div>
                    <Form.Control
                      isInvalid={
                        !!(touched.renteeChargePer && errors.renteeChargePer)
                      }
                      name="renteeChargePer"
                      onBlur={handleBlur}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        const { value } = e.target;
                        setFieldValue(
                          'renteeChargePer',
                          value === '' ? '' : Number(value)
                        );
                        if (Number(value) > 100) {
                          return;
                        }
                        const ownerChargePer =
                          100 - Number(value) - Number(values.companyChargePer);
                        setFieldValue('ownerChargePer', ownerChargePer);
                      }}
                      type="number"
                      value={values.renteeChargePer}
                    />
                    <Form.Control.Feedback
                      className={Styles.NoWrap}
                      type="invalid"
                    >
                      {errors.renteeChargePer}
                    </Form.Control.Feedback>
                  </div>
                  <div className={Styles.Col}>
                    <div className={Styles.FormLabel}>貸主負担％</div>
                    <Form.Control
                      isInvalid={
                        !!(touched.ownerChargePer && errors.ownerChargePer)
                      }
                      name="ownerChargePer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.ownerChargePer}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ownerChargePer}
                    </Form.Control.Feedback>
                  </div>
                  <div className={Styles.Col}>
                    <div className={Styles.FormLabel}>管理負担％</div>
                    <Form.Control
                      isInvalid={
                        !!(touched.companyChargePer && errors.companyChargePer)
                      }
                      name="companyChargePer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.companyChargePer}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyChargePer}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
              <div className={Styles.FormGroup}>
                <div className={Styles.FormLabel}>備考</div>
                <Form.Control
                  as="textarea"
                  isInvalid={!!(touched.note && errors.note)}
                  name="note"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="備考）その他連絡事項など"
                  value={values.note}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.note}
                </Form.Control.Feedback>
              </div>
              {type === 'add' && (
                <div className={Styles.Row}>
                  <div className={Styles.Col}>
                    <div className={Styles.TotalAmountView}>
                      ¥{toThousandSeparator(values.totalAmount)}
                    </div>
                  </div>
                  <div className={Styles.Col}>
                    <button
                      className={Styles.OutlineButton}
                      onClick={(): void => handleSubmit()}
                      type="submit"
                    >
                      <RiAddBoxLine className={Styles.Icon} />
                      追加
                      <RiArrowRightLine className={Styles.Icon} />
                    </button>
                  </div>
                </div>
              )}
              {type === 'edit' && (
                <div className={Styles.Row}>
                  <div className={Styles.Col}>
                    <div className={Styles.TotalAmountView}>
                      ¥{toThousandSeparator(values.totalAmount)}
                    </div>
                  </div>
                  <div className={Styles.Col}>
                    <button
                      className={Styles.SubmitButton}
                      onClick={(): void => handleSubmit()}
                      type="submit"
                    >
                      <RiAddBoxLine className={Styles.Icon} />
                      保存
                    </button>
                  </div>
                </div>
              )}
              {type === 'add_modal' && (
                <div className={Styles.Row}>
                  <div className={Styles.Col}>
                    <button
                      className={Styles.SecondaryButton}
                      disabled={isSubmitting}
                      onClick={async (): Promise<void> => {
                        setIsSubmitting(true);
                        const innerErrors = await validateForm(values);
                        if (Object.keys(innerErrors).length > 0) {
                          await submitForm(); // set touched for All Fields
                          setIsSubmitting(false);
                          return;
                        }
                        await handleSave(values, false);
                        setIsSubmitting(false);
                      }}
                      type="button"
                    >
                      追加
                    </button>
                  </div>
                  <div className={Styles.Col}>
                    <button
                      className={Styles.SubmitButton}
                      disabled={isSubmitting}
                      onClick={(): void => handleSubmit()}
                      type="submit"
                    >
                      <RiAddBoxLine className={Styles.Icon} />
                      続けて追加
                    </button>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
