import { object, string } from 'yup';
import { CompanySetting } from '~/models/CompanySetting';
import {
  StaffAuthParam,
  StaffAuthorization,
  buildDefaultStaffAuth,
  hasAuthorization
} from './StaffAuthorization';
import { ItandiAccount } from './ItandiAccount';

export type Staff = {
  id: number;
  name: string;
  companyMasterId: number;
  authId: number;
  ktStaffId: number | null;
  email: string | null;
  phoneNumber: number;
  role: 'member' | 'officer' | 'admin';
  editable: boolean;
  hasPassword: boolean;
  staffAuthorization: StaffAuthorization;
};
export type StaffWithCompany = Staff & {
  companySetting: CompanySetting;
};

export type StaffLogonParams = {
  id: number;
  password?: string;
};

export type StaffParam = {
  name: string;
  email: string | null;
  phoneNumber: number | null;
  staffAuthorization: StaffAuthParam;
};

export const canMovingStatus = (account: ItandiAccount): boolean =>
  hasAuthorization(account, 'enableStatus');

export const canOperatingDeal = (account: ItandiAccount): boolean =>
  hasAuthorization(account, 'enableCancel');

export const canOpenSetting = (account: ItandiAccount): boolean =>
  hasAuthorization(account, 'enableSetting');

export const canMovingCompeteStatus = (account: ItandiAccount): boolean =>
  hasAuthorization(account, 'enableCompleteStatus');

export const buildDefaultStaff = (): StaffParam => ({
  name: '',
  email: null,
  phoneNumber: null,
  staffAuthorization: buildDefaultStaffAuth()
});

export const createValidationSchema = object().shape({
  name: string().required('入力してください'),
  email: string()
    .required('入力してください')
    .email('メールアドレスの形式を確認してください'),
  phoneNumber: string()
    .nullable()
    .matches(/^[-\d]*$/, {
      message: '電話番号は数字とハイフンのみで入力してください'
    })
    .test(
      'length',
      '電話番号は10桁または11桁で入力してください',
      (value: string | undefined | null) => {
        const val = value ?? '';
        if (val === '') return true;
        return [10, 11].includes(val.replace(/-/g, '').length);
      }
    )
});
