import { NskResponse } from '~/models/Api';
import { DealRepairParam } from '~/models/DealRepair';
import { postRequest } from '~/utils/api/api_handler';
import { appendToFormData } from './DealRepair';

const paths = {
  create: '/deals/:deal_id/deal_duplications'
};

export const create = async (
  dealId: number
): Promise<NskResponse<{ id: number }>> =>
  postRequest<{ id: number }, null>(
    paths.create.replace(':deal_id', String(dealId))
  );

export const duplicateWithRepair = async (
  dealRepair: DealRepairParam,
  dealId: number
): Promise<NskResponse<{ id: number }>> =>
  postRequest<{ id: number }, null>(
    paths.create.replace(':deal_id', String(dealId)),
    appendToFormData(dealRepair)
  );
