import React from 'react';
import Styles from './Page.module.scss';
import SettingStyles from '../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { summaryFileTemplateTypes } from '~/models/SummaryFileTemplate';
import { SummaryFileTemplateRow } from '~/components/specifics/settings/file_templates/SummaryFileTemplateRow';

export const Page: React.FC = () => (
  <DefaultV2>
    <ClientSettingLayout>
      <h2 className={SettingStyles.SettingTitle}>帳票設定</h2>
      <h3 className={SettingStyles.SettingSubTitle}>案件グループ帳票</h3>
      <Table>
        <TableHeader>
          <th className={Styles.NameCol}>帳票名</th>
          <th>設定</th>
        </TableHeader>
        <TableBody>
          {summaryFileTemplateTypes.map((fileType) => (
            <SummaryFileTemplateRow key={fileType} fileType={fileType} />
          ))}
        </TableBody>
      </Table>
    </ClientSettingLayout>
  </DefaultV2>
);
