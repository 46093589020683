import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { FieldError, useFormContext } from 'react-hook-form';
import Styles from './Form.module.scss';
import { StaffParam } from '~/models/Staff';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useStaffs } from '~/hooks/useStaffs';

type Props = DeepReadonly<{
  formLabel: string;
  placeholder: string;
  registerName: keyof StaffParam;
  errors: FieldError | undefined;
  errorMessage: string | undefined;
  isInvalid: boolean;
  optional?: boolean;
}>;

export const ProfileForm: React.FC<Props> = ({
  formLabel,
  placeholder,
  registerName,
  errors,
  errorMessage,
  isInvalid,
  optional = false
}: Props) => {
  const { register } = useFormContext<StaffParam>();
  const id = useIdQueryHandler('staffId');
  const { data: staff } = useStaffs().show(id);
  const isEditable = React.useMemo(
    (): boolean =>
      // 追加の時は編集可能にする
      staff === null || staff.editable,
    [staff]
  );
  return (
    <div className={Styles.FormList}>
      <div className={Styles.FormBlock}>
        <div className={Styles.FormLabel}>
          {formLabel}
          <span
            className={optional ? Styles.OptionalLabel : Styles.RequireLabel}
          >
            {optional ? '任意' : '必須'}
          </span>
        </div>
        <div className={Styles.FormContent}>
          <Input
            disabled={!isEditable}
            isInvalid={isInvalid}
            placeholder={placeholder}
            {...register(registerName)}
          />
          {errors != null && (
            <div className={Styles.ErrorMessage}>{errorMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};
