import { Staff } from '~/models/Staff';
import { VendorUser } from '~/models/VendorUser';
import { DealIndexVendor } from '~/models/Deal';

type HistoryChanger = 'company' | 'vendor' | 'partner' | 'system';
export type DealHistory = {
  id: number;
  changer: HistoryChanger;
  historyType: string;
  historyText: string;
  beforeStatusId: number | null;
  afterStatusId: number | null;
  staff: Staff | null;
  vendorUser: VendorUser | null;
  createdAt: string;
};

export const ChangerLabels = {
  company: '管理会社',
  vendor: '施工会社',
  partner: '実施会社',
  system: 'システム'
} as const satisfies {
  [key in HistoryChanger]: string;
};

export const processedHistory = (
  deal: DealIndexVendor,
  historyType: 'estimations_created' | 'completion_report_created'
): boolean =>
  deal.dealHistories.some((history) => history.historyType === historyType);
